// eslint-disable-next-line no-restricted-imports
import importedDayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

importedDayjs.extend(utc);
importedDayjs.extend(weekOfYear);
importedDayjs.extend(quarterOfYear);

importedDayjs.extend(customParseFormat);
importedDayjs.extend(isoWeek);
importedDayjs.extend(dayOfYear);

export const dayjs = importedDayjs;
export type UnitType = importedDayjs.UnitType;
export type Dayjs = importedDayjs.Dayjs;
