import { Flex } from '@chakra-ui/react';
import React from 'react';
import { LockFiltersButton } from 'src/components/molecules/LockFiltersButton';
import { MasterDataDownloadButton } from 'src/components/molecules/MasterDataDownloadButton';
import { AggregationSelector } from 'src/features/MM/components/molecules/AggregationSelector';
import { DimensionSelector } from 'src/features/MM/components/molecules/DimensionSelector';
import { FilterHeading } from '../../atoms/FilterHeading';
import { FilterBarContainer } from '../../molecules/FilterBarContainer';
import { ModelVersionSelect } from '../../molecules/ModelVersionSelect';
import { BenchmarkSelector } from '../BenchmarkSelector';
import { BenchmarkSelectorError } from '../BenchmarkSelector/BenchmarkSelectorError';
import { TimeRangeSelector } from '../TimeRangeSelector';
import { CampaignsMenu } from '../menus/CampaignsMenu';
import { MediaMenu } from '../menus/MediaMenu';
import { TagGroupMenu } from '../menus/TagGroupMenu';
import { TagMenu } from '../menus/TagMenu';

type FilterBarProps = {
  additionalTopFilters?: React.ReactElement;
  includeBenchmark?: boolean;
  includeAggregation?: boolean; // Requires AggregationContext, right now only used in MM
  includeDimension?: boolean; // Requires DimensionContext, right now only used in MM
};

export const FilterBar: React.FC<FilterBarProps> = ({
  additionalTopFilters,
  includeBenchmark,
  includeAggregation,
  includeDimension,
}) => {
  return (
    <FilterBarContainer>
      <Flex flexDir={'column'}>
        {/* Top Row */}
        <Flex direction="row" align="center" w="100%">
          {/* Tag group */}
          <Flex direction="row" alignItems="flex-end">
            <FilterHeading headingText="Tag Group" />
            <TagGroupMenu />
          </Flex>

          {/* Tags */}
          <Flex direction="row" pl="40px" alignItems="flex-end">
            <FilterHeading headingText="Tags" />
            <TagMenu />
          </Flex>

          {/* Campaigns */}
          <Flex direction="row" pl="40px" alignItems="flex-end">
            <FilterHeading headingText="Campaigns" />
            <CampaignsMenu />
            <LockFiltersButton filterType="campaigns" />
          </Flex>

          {/* Media */}
          <Flex direction="row" pl="40px" alignItems="flex-end">
            <FilterHeading headingText="Media" />
            <MediaMenu />
            <LockFiltersButton filterType="media" />
          </Flex>

          {additionalTopFilters}
        </Flex>

        {/* Bottom Row */}
        <Flex direction="row" align="center" mt="10px" w="100%">
          {/* Time Range */}
          <Flex direction="column">
            <FilterHeading headingText="Time Range" />
            <Flex direction={'row'}>
              <TimeRangeSelector includeTimePeriodButtons />
            </Flex>
          </Flex>
          {/* Benchmark Range */}
          {includeBenchmark && (
            <Flex direction="column">
              <Flex alignItems={'center'}>
                <FilterHeading headingText="Benchmark" />
                <BenchmarkSelectorError />
              </Flex>
              <BenchmarkSelector />
            </Flex>
          )}
          <Flex direction={'column'}>
            <LockFiltersButton filterType="time range" mt="20px" />
          </Flex>

          {includeAggregation && (
            <Flex ml="30px" direction="column">
              <AggregationSelector />
            </Flex>
          )}

          {includeDimension && (
            <Flex ml="30px" direction="column">
              <DimensionSelector />
            </Flex>
          )}
        </Flex>
      </Flex>

      {/* Model version selection */}
      <Flex
        flexDir={'row'}
        justifyContent={'flex-end'}
        flexGrow={1}
        alignItems="flex-end">
        <Flex mr="10px">
          <MasterDataDownloadButton />
        </Flex>
        <Flex
          direction="column"
          alignItems={'flex-end'}
          justifyContent="flex-end">
          <ModelVersionSelect />
        </Flex>
      </Flex>
    </FilterBarContainer>
  );
};
