import { dayjs } from 'src/libs/dayjs';
import { formatDate } from './date';

/**
 * All these formatter is designed to only work for Recharts axis tick formatting,
 * which passes the number for the X/Y-axis to the functions and expects a string in return.
 * https://recharts.org/en-US/api/YAxis#tickFormatter
 */

/**
 * This function takes an input number in Euros and formats it to a
 * more readable format (e.g. 10000000 -> 10M€). Any numbers in the billion or million
 * range will have a maximum of 2 decimal places. Any numbers in the thousands range will
 * have no decimal places. All other numbers will be made rounded to the nearest whole number.
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function EuroValueTickFormatter(value: number | string): string {
  const number = +value;
  if (isNaN(number)) {
    return '';
  }
  if (number > 1000000000) {
    const fixed = (number / 1000000000).toFixed(2);
    return `${fixed}B€`;
  }
  if (number > 1000000) {
    const fixed = (number / 1000000).toFixed(2);
    return `${fixed}M€`;
  }
  if (number > 1000) {
    const fixed = (number / 1000).toFixed(2);
    return `${fixed}K€`;
  }
  if (number < 10) {
    return `${number.toFixed(2)}€`;
  }
  return `${Math.round(number).toString()}€`;
}

/**
 * This function takes an input number formats it to a
 * more readable format (e.g. 10000000 -> 10M). Any numbers in the billion or million
 * range will have a maximum of 2 decimal places. Any numbers in the thousands range will
 * have no decimal places. All other numbers will be made rounded to the nearest whole number.
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function ValueTickFormatter(value: number | string): string {
  const number = +value;
  if (number > 1000000000) {
    const fixed = (number / 1000000000).toFixed(2);
    return `${fixed}B`;
  }

  if (number > 1000000) {
    const fixed = (number / 1000000).toFixed(2);
    return `${fixed}M`;
  }
  if (number > 1000) {
    const fixed = (number / 1000).toFixed(2);
    return `${fixed}K`;
  }
  return `${number.toFixed(2)}`;
}

/**
 * Adds % symbol to axis that are handling percentages
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function PercentageValueTickFormatter(value: number | string): string {
  const number = +value;
  if (isNaN(number)) {
    return '';
  }
  return `${number.toFixed(2)}%`;
}

/** Takes an UTC epoch an formats it to "Oct 2020" format
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function MonthNameYearDateFormatter(value: string | number): string {
  return formatDate('MMM YYYY', value);
}

/** Takes an UTC epoch an formats it to "M.YYYY"
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function MonthYearDateFormatter(value: string | number): string {
  return formatDate('MM.YY', value);
}

/** Takes an UTC epoch an formats it to "YYYY"
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function YearDateFormatter(value: string | number): string {
  return formatDate('YYYY', value);
}

/** Takes an UTC epoch an formats it to "YYYY"
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function QuarterDateFormatter(value: string | number): string {
  const date = dayjs(value);
  const quarter = date.quarter();

  return formatDate(`Q${quarter} YY`, value);
}

/** Takes an UTC epoch an formats it to "Week n (YYYY)"
 * @param {number} number - The value passed from the Recharts Axis for the tick mark.
 * @returns {string} The formatted string for the tick mark
 */
export function WeekYearDateFormatter(value: string | number): string {
  return formatDate(`DD.MM.YY`, value);
}

export function KeyMetricsDateFormatter(epoch: string | number): string {
  return formatDate('D MMM', epoch);
}

export function KeyMetricsNumberFormatter(number: number): [string, string] {
  if (number > 1e9) {
    return [`${(number / 1e9).toFixed(2)}`, 'B'];
  }
  if (number > 1e6) {
    return [`${(number / 1e6).toFixed(2)}`, 'M'];
  }
  if (number > 1e3) {
    return [`${(number / 1e3).toFixed(2)}`, 'K'];
  }
  if (number < 1) {
    return [number.toFixed(2), ''];
  }
  return [number.toFixed(2), ''];
}
