import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
import { DateRangeSelector } from 'src/components/molecules/DateRangeSelector';
import { DateRangeSelectorButton } from 'src/components/molecules/DateRangeSelector/AdditionalDateRangeSelectorButton';
import {
  BenchmarkDates,
  benchmarkDatesAreDefault,
  getDefaultBenchmarkDates,
} from 'src/features/SOS/utils/getDefaultBenchmarkDates';
import { dayjs } from 'src/libs/dayjs';

type DateRangeSelectorLabelProps = {
  benchmarkDates: BenchmarkDates;
  isActive: boolean;
};

const DateRangeSelectorLabel: React.FC<DateRangeSelectorLabelProps> = ({
  benchmarkDates,
  isActive,
}) => {
  const fontActiveColor = useColorModeValue(
    'text.light.active',
    'text.dark.active',
  );

  const dateFormat = 'YYYY-MM';

  const dateString = isActive
    ? 'Previous years'
    : `${benchmarkDates.benchmarkFrom
        .startOf('month')
        .format(dateFormat)} - ${benchmarkDates.benchmarkTo
        .startOf('month')
        .format(dateFormat)}`;

  return (
    <Flex ml="30px">
      <Text as="button" fontSize="16px" mr="12px">
        Benchmark
      </Text>
      <Text as="button" fontSize="16px" mr="16px" color={fontActiveColor}>
        {dateString}
      </Text>
    </Flex>
  );
};

type PreviousYearsDateRangeButtonProps = {
  onClick: () => void;
  isActive: boolean;
};

const PreviousYearsDateRangeButton: React.FC<
  PreviousYearsDateRangeButtonProps
> = ({ onClick, isActive }) => {
  return (
    <DateRangeSelectorButton
      activeText="Previous years selected"
      nonActiveText="Select previous years"
      onClick={onClick}
      isActive={isActive}
    />
  );
};

type BenchmarkDateRangeSelectorProps = {
  benchmarkDates: BenchmarkDates;
  onBenchmarkDatesChange: (bechmarkDates: BenchmarkDates) => void;
};

export const BenchmarkDateRangeSelector: FC<
  BenchmarkDateRangeSelectorProps
> = ({ onBenchmarkDatesChange, benchmarkDates }) => {
  const {
    benchmarkFrom: defaultBenchmarkFrom,
    benchmarkTo: defaultBenchmarkTo,
  } = getDefaultBenchmarkDates();

  const onPreviousYearButtonClick = () => {
    onBenchmarkDatesChange({
      benchmarkFrom: defaultBenchmarkFrom,
      benchmarkTo: defaultBenchmarkTo,
    });
  };

  const startDate = benchmarkDates.benchmarkFrom;
  const endDate = benchmarkDates.benchmarkTo;

  const isActive = benchmarkDatesAreDefault(benchmarkDates);

  const selectedStartDate = dayjs(startDate).toDate();
  const selectedEndDate = dayjs(endDate).toDate();

  const minDate = defaultBenchmarkFrom.toDate();

  const maxDate = dayjs().startOf('month').toDate();

  const handleDateRangeChange = (start: Date, end: Date) => {
    onBenchmarkDatesChange({
      benchmarkFrom: dayjs(start),
      benchmarkTo: dayjs(end),
    });
  };

  return (
    <DateRangeSelector
      minDate={minDate}
      maxDate={maxDate}
      additionalDateRangeButton={
        <PreviousYearsDateRangeButton
          isActive={isActive}
          onClick={onPreviousYearButtonClick}
        />
      }
      onDateRangeChange={handleDateRangeChange}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}>
      <DateRangeSelectorLabel
        isActive={isActive}
        benchmarkDates={benchmarkDates}
      />
    </DateRangeSelector>
  );
};
