import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppServices, getActiveRoute } from 'src/app/routes/utils';

export const useCurrentAppService = () => {
  // Record the currently view service for deciding which sets of model versions and customer data to fetch
  const [currentAppService, setCurrentAppService] =
    useState<AppServices | null>(null);

  const location = useLocation();
  useEffect(() => {
    if (location) {
      const activeRoute = getActiveRoute(location.pathname);
      setCurrentAppService(activeRoute?.service || null);
    }
  }, [location]);

  return currentAppService;
};
