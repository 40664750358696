import { SimplifiedTimeframeData } from 'src/types';
import { sortEpoch } from './date';

export const combineTwoSimplifiedTimeframeData = <
  AValueKey extends string,
  BValueKey extends string,
>(
  a: Array<SimplifiedTimeframeData>,
  aKey: AValueKey,
  b: Array<SimplifiedTimeframeData>,
  bKey: BValueKey,
): Array<
  {
    utcEpoch: number;
  } & Record<AValueKey | BValueKey, number | null>
> => {
  const byEpochRecord: Record<
    number,
    {
      utcEpoch: number;
      a: number | null;
      b: number | null;
    }
  > = {};

  const insertIfMissingEpoch = (epoch: number) => {
    if (!byEpochRecord[epoch]) {
      byEpochRecord[epoch] = {
        utcEpoch: epoch,
        a: null,
        b: null,
      };
    }
  };

  a.forEach(({ value, utcEpoch }) => {
    insertIfMissingEpoch(utcEpoch);
    byEpochRecord[utcEpoch].a = value;
  });

  b.forEach(({ value, utcEpoch }) => {
    insertIfMissingEpoch(utcEpoch);
    byEpochRecord[utcEpoch].b = value;
  });

  return Object.values(byEpochRecord)
    .map(
      (d) =>
        ({
          utcEpoch: d.utcEpoch,
          [aKey]: d.a,
          [bKey]: d.b,
        }) as {
          utcEpoch: number;
        } & Record<AValueKey | BValueKey, number | null>,
    )
    .sort(sortEpoch);
};
