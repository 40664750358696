// eslint-disable-next-line no-restricted-imports
import * as amplitude from '@amplitude/analytics-browser';
import { AuthUser } from 'aws-amplify/auth';
import { Customer } from 'src/api/queries';

import { AMPLITUDE_API_KEY, BUILD_VERSION } from 'src/constants';
import { getUserType } from 'src/utils/getUserType';

amplitude.init(AMPLITUDE_API_KEY, {
  appVersion: BUILD_VERSION,
  useBatch: true,
  serverZone: 'EU',
  defaultTracking: {
    attribution: false, // We do not have marketing campaigns to mmms that require this. Adding this only adds more spam in user properties
    fileDownloads: true,
    pageViews: true,
    sessions: true,
    formInteractions: true,
  },
});

const NON_DEF_ROLE = 'no-role-defined';

export const setAmplitudeUser = (user: AuthUser, role: string | null) => {
  amplitude.setUserId(user.username);
  const identifyObj = new amplitude.Identify();
  identifyObj.setOnce('username', user.username);
  identifyObj.set('role', role ?? NON_DEF_ROLE);
  identifyObj.setOnce('cognitoId', user.userId);

  const userType = getUserType(user);

  identifyObj.set('userType', userType);
  amplitude.identify(identifyObj);
};

export const unsetAmplitudeUser = () => {
  amplitude.reset();
};

export { amplitude };

type ButtonClickedEventProperties = {
  buttonName: string;
};

export const buttonClickedEvent = (
  eventProperties: ButtonClickedEventProperties,
) => {
  const pathname = window.location.pathname;
  amplitude.track('Button Clicked', {
    ...eventProperties,
    pathname,
  });
};

export const iconButtonClickedEvent = (
  eventProperties: ButtonClickedEventProperties,
) => {
  const pathname = window.location.pathname;
  amplitude.track('Icon Button Clicked', {
    ...eventProperties,
    pathname,
  });
};

type MenuButtonClickedEventProperties = {
  buttonName: string;
};

export const menuButtonClickedEvent = (
  eventProperties: MenuButtonClickedEventProperties,
) => {
  const pathname = window.location.pathname;
  amplitude.track('Menu Button Clicked', {
    ...eventProperties,
    pathname,
  });
};

type MenuOptionClickedEventProperties = {
  buttonName: string;
  value: string;
  label: string;
};

export const menuOptionClickedEvent = (
  eventProperties: MenuOptionClickedEventProperties,
) => {
  const pathname = window.location.pathname;
  amplitude.track('Menu Option Clicked', {
    ...eventProperties,
    pathname,
  });
};

export const selectClientEvent = ({ customerName }: Customer) => {
  amplitude.track('Client Selected', { clientName: customerName });
};

type SelectModelEventProperties = {
  clientName: string;
  modelVersion: string;
  modelName: string;
  modelType: string;
};
export const selectModelEvent = (
  eventProperties: SelectModelEventProperties,
) => {
  amplitude.track('Model Selected', eventProperties);
};
