import {
  ApiObservedConversionDataPoint,
  ApiOrganicSearchDataPoint,
} from 'src/api/queries';
import { Media } from 'src/types';

export function filterObservedConversionData(
  conversionData: ApiObservedConversionDataPoint[],
  campaignIds: string[],
  media: Media[],
): ApiObservedConversionDataPoint[] {
  if (conversionData.length === 0) {
    return [];
  }

  const campaignsSet = new Set(campaignIds);
  const mediaSet = new Set(media.filter((m) => m.selected).map((m) => m.id));

  const data = conversionData.map((dataPoint) => {
    return {
      ...dataPoint,
      conversions: dataPoint.conversions.filter((conversion) => {
        const inCampaigns = campaignsSet.has(conversion.campaignId);
        const inMedia = mediaSet.has(conversion.channel);
        return inCampaigns && inMedia;
      }),
    };
  });
  return data;
}

export const filterOrganicSearchData = (
  apiData: ApiOrganicSearchDataPoint[],
  keywordClusterFilters: Record<string, boolean>,
) => {
  return apiData.map((d) => {
    return {
      ...d,
      keywordClusters: d.keywordClusters.filter(
        (c) => keywordClusterFilters[c.keywordCluster],
      ),
    };
  });
};
