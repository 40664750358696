import { useCallback, useMemo } from 'react';
import { CustomerSettingsContext } from '.';
import { useMediaNames } from './useMediaNames';

type CustomerSettingsContextProviderProps = {
  children: React.ReactNode;
};

export const CustomerSettingsContextProvider: React.FC<
  CustomerSettingsContextProviderProps
> = ({ children }) => {
  const { mediaNamesMap } = useMediaNames();

  const getMediaName = useCallback(
    (mediaName: string) => {
      const newName = mediaNamesMap[mediaName];
      if (newName) {
        return newName;
      }
      return mediaName;
    },
    [mediaNamesMap],
  );

  const value = useMemo(() => {
    return {
      getMediaName,
    };
  }, [getMediaName]);
  return (
    <CustomerSettingsContext.Provider value={value}>
      {children}
    </CustomerSettingsContext.Provider>
  );
};
