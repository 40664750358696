import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type ChartType = 'bar' | 'line';

export type ChartTypeTabsProps = {
  selectedTab: ChartType;
  onSelect: (value: ChartType) => void;
};

const tabs: Array<TabOption<ChartType>> = [
  {
    label: 'Bar',
    value: 'bar',
  },
  {
    label: 'Line',
    value: 'line',
  },
];

export const ChartTypeTabs: React.FC<ChartTypeTabsProps> = ({
  onSelect,
  selectedTab,
}) => {
  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
