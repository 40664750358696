import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MMMRoute, getActiveRoute } from 'src/app/routes/utils';

export const useActiveRoute = () => {
  const location = useLocation();

  const [activeRoute, setActiveRoute] = useState<MMMRoute | null>();

  useEffect(() => {
    const newActiveRoute = getActiveRoute(location.pathname);
    if (newActiveRoute) {
      setActiveRoute(newActiveRoute);
    }
  }, [location.pathname]);

  return { activeRoute };
};
