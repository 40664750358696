import { matchPath } from 'react-router';
import { AvailableIcon } from 'src/components/atoms/Icon';

export enum AppServices {
  MM = 'mm',
  DA = 'da',
  MO = 'mo',
  LT = 'lt',
  SOS = 'sos',
}

export type MMMRoute = {
  url: string;
  name: string;
  subroutes?: MMMRoute[];
  icon: AvailableIcon;
  service?: AppServices;
};

const routes: Array<MMMRoute> = [
  {
    url: '/mmm',
    name: 'Marketing Mix Modelling',
    icon: 'mmm',
    service: AppServices.MM,
    subroutes: [
      {
        url: '/mmm/overview',
        name: 'Overview',
        icon: 'overview',
      },
      {
        url: '/mmm/summary',
        name: 'Summary',
        icon: 'mmm-performance',
      },
      {
        url: '/mmm/trends',
        name: 'Trends',
        icon: 'mmm-cost-per-unit',
      },
      {
        url: '/mmm/modelling',
        name: 'Modelling',
        icon: 'mmm-modelling',
      },
    ],
  },
  {
    url: '/dso',
    name: 'DSO',
    icon: 'da',
    service: AppServices.DA,
    subroutes: [
      {
        url: '/dso/overview',
        name: 'Overview',
        icon: 'overview',
      },
      {
        url: '/dso/explorer',
        name: 'Explorer',
        icon: 'da-explorer',
      },
      {
        url: '/dso/deep-dive',
        name: 'Deep dive',
        icon: 'da-deep-dive',
      },
      {
        url: '/dso/channels',
        name: 'Channels',
        icon: 'da-channels',
      },
      {
        url: '/dso/search',
        name: 'Search',
        icon: 'da-search',
      },
    ],
  },
  {
    url: '/marketing-optimization',
    name: 'Marketing Optimization',
    icon: 'mo',
    service: AppServices.MO,
  },
  {
    url: '/longterm-effect',
    name: 'Long-term Effects',
    icon: 'lt',
    service: AppServices.LT,
  },
  {
    url: '/share-of-search',
    name: 'Share Of Search',
    icon: 'sos',
    service: AppServices.SOS,
    subroutes: [
      {
        url: '/share-of-search/overview',
        name: 'Overview',
        icon: 'overview',
      },
      {
        url: '/share-of-search/municipalities',
        name: 'Municipalities',
        icon: 'sos-municipality',
      },
    ],
  },
];

export function getAllRoutes(): MMMRoute[] {
  return Object.values(routes);
}

export function getActiveRoute(pathname: string): MMMRoute | null {
  for (const route of routes) {
    if (matchPath(`${route.url}/*`, pathname)) {
      return route;
    }
  }

  return null;
}
