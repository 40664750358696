import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type TimePeriod = 'monthly' | 'weekly' | 'daily';

export type TimePeriodTabsProps = {
  selectedTimePeriod: TimePeriod;
  onTimePeriodSelect: (value: TimePeriod) => void;
};

const tabs: Array<TabOption<TimePeriod>> = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
];

export const TimePeriodTabs: React.FC<TimePeriodTabsProps> = ({
  selectedTimePeriod,
  onTimePeriodSelect,
}) => {
  return (
    <Tabs
      options={tabs}
      onSelect={onTimePeriodSelect}
      selectedTab={selectedTimePeriod}
    />
  );
};
