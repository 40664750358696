import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Slider = {
  baseStyle: (props: StyleFunctionProps) => ({
    track: {
      bg: mode('background.light.paleGrey', 'background.dark.highest')(props),
      minHeight: '8px',
      maxHeight: '8px',
      borderRadius: '4px',
    },
    filledTrack: {
      bg: mode('omnicomBlue', 'omnicomBlue')(props),
      minHeight: '8px',
      maxHeight: '8px',
      borderRadius: '4px',
    },
    thumb: {
      bg: mode('background.light.highest', 'background.dark.highest')(props),
      borderColor: mode('border.blueGrey', 'border.mediumBlue')(props),
      borderRadius: '4px',
      maxWidth: '10px',
      minWidth: '10px',
      maxHeight: '20px',
      minHeight: '20px',
    },
  }),
};
