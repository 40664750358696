import {
  DataBox,
  DataBoxLegendProps,
  DataBoxBaseProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { EuroValueTickFormatter } from 'src/utils/TickFormatters';
import { Optimization } from '../../types';

type InvestmentByMediaBarGraphProps = {
  optimization: Optimization;
} & DataBoxBaseProps;

const INITIAL_KEY = 'Initial investment';
const OPTIMIZED_KEY = 'Optimized investment';

export const InvestmentByMediaBarGraph: React.FC<
  InvestmentByMediaBarGraphProps
> = ({ optimization, ...dataBoxProps }) => {
  const data = optimization.data.map((opt) => ({
    [INITIAL_KEY]: opt.initialInvestment,
    [OPTIMIZED_KEY]: opt.optimizedInvestment,
    channel: opt.channelName,
  }));

  const dataKeys = {
    xAxis: 'channel',
    data: [INITIAL_KEY, OPTIMIZED_KEY],
  };

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'top',
  };

  return (
    <DataBox
      {...dataBoxProps}
      legendProps={legendProps}
      isError={false}
      isLoading={false}
      hasData={data.length > 0}>
      <BarGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        stacked={false}
        yAxisFormatter={EuroValueTickFormatter}
      />
    </DataBox>
  );
};
