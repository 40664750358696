import { useColorModeValue } from '@chakra-ui/react';

type KeyMetricsColors = {
  fontHighContrast: string;
  fontPrimary: string;
  boxBorderColor: string;
  benchmarkColor: string;
};

export const useKeyMetricsColors = (): KeyMetricsColors => {
  return {
    fontHighContrast: useColorModeValue(
      'text.light.highContrast',
      'text.dark.highContrast',
    ),
    fontPrimary: useColorModeValue('text.light.primary', 'text.dark.primary'),
    boxBorderColor: useColorModeValue('border.light.box', 'border.dark.box'),
    benchmarkColor: useColorModeValue(
      'text.light.benchmark',
      'text.dark.benchmark',
    ),
  };
};
