import { AggregateLevel } from 'src/types';

export const getAggregationName = (value: AggregateLevel): string => {
  switch (value) {
    case AggregateLevel.day:
      return 'Day';
    case AggregateLevel.week:
      return 'Week';
    case AggregateLevel.month:
      return 'Month';
    case AggregateLevel.quarter:
      return 'Quarter';
    case AggregateLevel.biannual:
      return 'Biannual';
    case AggregateLevel.year:
      return 'Year';
  }
};
