import { TotalDecompsState } from 'src/features/MM/types';
import { sortByMetric } from 'src/utils/sort';
import { BaseSelectorOption } from './';

export const totalDecompsByMetricToBaseSelectorOptions = (
  totalDecompsState: TotalDecompsState,
): Array<BaseSelectorOption> => {
  return sortByMetric(
    Object.keys(totalDecompsState.totalByOtherMetric).map((metric) => {
      return {
        metric,
        isSelected: false,
      };
    }),
  );
};
