import React from 'react';
import { Overview } from 'src/features/MM/components/templates/Overview';
import { Page } from '../../components/molecules/Page';
import { FilterBar } from '../../components/organisms/FilterBar';
import { MMKeyMetrics } from '../../features/MM/components/organisms/MMKeyMetrics';

export const OverviewPage: React.FC = () => {
  return (
    <Page
      filterBar={<FilterBar includeAggregation />}
      keyMetrics={<MMKeyMetrics />}>
      <Overview />
    </Page>
  );
};
