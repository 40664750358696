import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Link = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: mode('text.light.secondary', 'text.dark.secondary')(props),
    _hover: {
      color: mode('text.light.primary', 'text.dark.primary')(props),
    },
  }),
};
