import { Grid } from '@chakra-ui/react';
import React, { FC } from 'react';

type DataBoxesContainerProps = {
  children: React.ReactNode;
};

/**
 * For all pages using the DataBoxes, this component provides the container
 * that can control the number of columsn. It is currently set to have 12.
 */
export const DataBoxesContainer: FC<DataBoxesContainerProps> = ({
  children,
}) => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={'24px'}>
      {children}
    </Grid>
  );
};
