import {
  ApiDecompDataPoint,
  ApiInvestmentDataPoint,
  ApiProductionCostsDatapoint,
} from 'src/api/queries';
import { Media } from 'src/types';

export function filterInvestmentsData(
  investmentsData: ApiInvestmentDataPoint[],
  campaignIds: string[],
  media: Media[],
): ApiInvestmentDataPoint[] {
  const campaignsSet = new Set(campaignIds);
  const mediaSet = new Set(media.filter((m) => m.selected).map((m) => m.id));

  const data = investmentsData.map((dataPoint) => {
    return {
      ...dataPoint,
      investments: dataPoint.investments.filter((investment) => {
        const inCampaigns = campaignsSet.has(investment.campaignId);
        const inMedia = mediaSet.has(investment.channel);
        return inCampaigns && inMedia;
      }),
    };
  });
  return data;
}

export function filterProductionCostData(
  productionData: ApiProductionCostsDatapoint[],
  campaignIds: string[],
): ApiProductionCostsDatapoint[] {
  const campaignsSet = new Set(campaignIds);

  const data = productionData.map((d) => {
    return {
      ...d,
      productionCosts: d.productionCosts.filter((datapoint) => {
        const inCampaigns = campaignsSet.has(datapoint.campaignId);
        return inCampaigns;
      }),
    };
  });
  return data;
}

export const filterDecompsData = (
  decompsData: ApiDecompDataPoint[],
  campaignIds: string[],
  media: Media[],
): ApiDecompDataPoint[] => {
  const campaignsSet = new Set(campaignIds);
  const mediaSet = new Set(media.filter((m) => m.selected).map((m) => m.id));

  const data = decompsData.map((dataPoint) => {
    return {
      ...dataPoint,
      createdValue: dataPoint.createdValue.filter((createdValue) => {
        const inCampaigns = campaignsSet.has(createdValue.campaignId);
        const inMedia = mediaSet.has(createdValue.channel);
        return inCampaigns && inMedia;
      }),
    };
  });
  return data;
};
