import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { isKeywordCustomerBrand } from '../utils/isKeywordCustomerBrand';
import { SelectableKeyword } from './useShareOfSearchCountryMonthly';

export const useHighlightKeywordsForMap = (
  selectableKeywords: Array<SelectableKeyword>,
) => {
  const { currentCustomer } = useAppContext();

  const [highlightedKeywordsForMap, setHighlightedKeywordsForMap] = useState<
    Array<SelectableKeyword>
  >([]);

  useEffect(() => {
    const newHighlightedKeywords = selectableKeywords.map(
      (v): SelectableKeyword => ({
        keyword: v.keyword,
        selected: currentCustomer
          ? isKeywordCustomerBrand(v.keyword, currentCustomer)
          : false,
      }),
    );

    setHighlightedKeywordsForMap(newHighlightedKeywords);
  }, [selectableKeywords, currentCustomer]);

  const toggleHighlightedKeywordsForMap = (
    keywordsToBeToggled: Array<SelectableKeyword>,
  ) => {
    const allPassedKeywordsToggled = keywordsToBeToggled.reduce(
      (prev, curr) => {
        return prev && curr.selected;
      },
      true,
    );

    const toggledKeywordsSet = new Set(
      keywordsToBeToggled.map((k) => k.keyword),
    );

    const existingSelectedKeywords = highlightedKeywordsForMap;

    const newHighlightedKeywords = existingSelectedKeywords.map(
      (selectableKeyword) => {
        if (toggledKeywordsSet.has(selectableKeyword.keyword)) {
          return {
            ...selectableKeyword,
            selected: !allPassedKeywordsToggled,
          };
        }
        return selectableKeyword;
      },
    );

    setHighlightedKeywordsForMap(newHighlightedKeywords);
  };

  return {
    toggleHighlightedKeywordsForMap,
    highlightedKeywordsForMap,
  };
};
