import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  floatCellFunc,
  floatEuroCellFunction,
  identityCellFunc,
  intCellFunc,
  intEuroCellFunc,
  pctCellFormatter,
} from 'src/components/molecules/DataTable/cellFuncs';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { useByDimensionNameAndId } from 'src/features/MM/hooks/useByDimensionId';
import { useCPULabel } from 'src/features/MM/hooks/useCPULabel';
import { getDimensionLevelTotalData } from 'src/features/MM/utils/getDimensionLevelData';
import { getDimensionName } from 'src/features/MM/utils/getDimensionName';
import { useKpiUnit } from 'src/hooks/useKpiUnit';

export type TableDataRow = {
  dimension: string;
  cpu: number | null;
  romi: number | null;
  roi: number | null;
  incrementalSales: number | null;
  investment: number | null;
  mediaEffect: number | null;
};

export const useTableData = () => {
  const { dimension } = useSelectedDimensionContext();

  const byDimensionNameAndId = useByDimensionNameAndId();
  const { cpu, decomps, investments, roi, romi, mediaEffect } =
    useMMDataContext();

  return useMemo(() => {
    const cpuByDimension = getDimensionLevelTotalData(cpu, dimension);
    const investmentsByDimension = getDimensionLevelTotalData(
      investments,
      dimension,
    );
    const decompsByDimension = getDimensionLevelTotalData(decomps, dimension);
    const roiByDimension = getDimensionLevelTotalData(roi, dimension);
    const romiByDimension = getDimensionLevelTotalData(romi, dimension);
    const mediaEffectByDimension = getDimensionLevelTotalData(
      mediaEffect,
      dimension,
    );

    const data = byDimensionNameAndId.map(({ id, name }): TableDataRow => {
      return {
        dimension: name,
        cpu: cpuByDimension[id] ?? 0,
        incrementalSales: decompsByDimension[id] ?? 0,
        investment: investmentsByDimension[id] ?? 0,
        romi: romiByDimension[id] ?? 0,
        roi: roiByDimension[id] ?? 0,
        mediaEffect: mediaEffectByDimension[id] ?? 0,
      };
    });

    return {
      data,
      isLoading: investments.isLoading || decomps.isLoading,
      isError: investments.isError || decomps.isError,
    };
  }, [
    cpu,
    decomps,
    investments,
    roi,
    romi,
    mediaEffect,
    dimension,
    byDimensionNameAndId,
  ]);
};

const columnHelper = createColumnHelper<TableDataRow>();

export const useColumns = () => {
  const { dimension } = useSelectedDimensionContext();

  const { isEuro } = useKpiUnit();
  const costPerName = useCPULabel();

  const { romiEnabled } = useMMDataContext();

  const columns = useMemo(() => {
    const effectCellFun = isEuro ? intEuroCellFunc : intCellFunc;

    const romiColumns = romiEnabled
      ? [
          columnHelper.accessor('romi', {
            cell: floatCellFunc,
            header: 'ROMI',
            sortingFn: 'basic',
          }),
        ]
      : [];

    const allColumns = [
      columnHelper.accessor('dimension', {
        cell: identityCellFunc,
        header: getDimensionName(dimension),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('investment', {
        cell: intEuroCellFunc,
        header: 'Media investment',
        sortingFn: 'basic',
      }),
      columnHelper.accessor('incrementalSales', {
        cell: effectCellFun,
        header: 'Incremental sales',
        sortingFn: 'basic',
      }),
      columnHelper.accessor('cpu', {
        cell: floatEuroCellFunction,
        header: costPerName,
        sortingFn: 'basic',
      }),
      columnHelper.accessor('mediaEffect', {
        cell: pctCellFormatter,
        header: 'Media effect',
        sortingFn: 'basic',
      }),
      columnHelper.accessor('roi', {
        cell: floatCellFunc,
        header: 'ROI',
        sortingFn: 'basic',
      }),
      ...romiColumns,
    ];

    return allColumns;
  }, [costPerName, isEuro, dimension, romiEnabled]);

  return columns;
};
