import { PropsWithChildren, useMemo, useState } from 'react';
import { SelectedDimensionContext } from '.';
import { Dimension } from '../../types';

export const SelectedDimensionContextProvider: React.FC<PropsWithChildren> = (
  props,
) => {
  const [dimension, setDimension] = useState<Dimension>(Dimension.campagin);

  const value = useMemo(() => {
    return {
      dimension,
      setDimension,
    };
  }, [dimension]);

  return (
    <SelectedDimensionContext.Provider value={value}>
      {props.children}
    </SelectedDimensionContext.Provider>
  );
};
