import React from 'react';
import { Page } from 'src/components/molecules/Page';
import { LTFilterBar } from 'src/features/LT/components/organisms/LTFilterBar';
import { LTKeyMetrics } from 'src/features/LT/components/organisms/LTKeyMetrics';
import { LongTermEffects } from 'src/features/LT/components/templates/LongTermEffects';

export const LongTermEffectsPage: React.FC = () => {
  return (
    <Page filterBar={<LTFilterBar />} keyMetrics={<LTKeyMetrics />}>
      <LongTermEffects />
    </Page>
  );
};
