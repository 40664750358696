import {
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Line,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from 'recharts';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';
import { useLegendContext } from '../../Legend/LegendContext';

export type LineScatterDataType<A> = {
  name: string;
  data: A[];
  points: A[];
};

export type LineScatterGraphProps<A> = {
  data: LineScatterDataType<A>[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  dataKeys: {
    xAxis: string;
    yAxis: string;
  };

  xAxisFormatter?: (value: number | string) => string;
  yAxisFormatter?: (value: number) => string;
  children?: JSX.Element;
};

export function LineScatterGraph<A>({
  data,
  dataKeys,
  xAxisLabel,
  yAxisLabel,

  xAxisFormatter,
  yAxisFormatter,
  children,
}: LineScatterGraphProps<A>) {
  const { keyToSelected, keyToColor } = useLegendContext();

  const { fontColor, gridColor } = useChartColors();
  const axisStyles = useAxisStyles();

  return (
    <ResponsiveContainer>
      <ComposedChart>
        <CartesianGrid strokeDasharray="0" strokeWidth={1} stroke={gridColor} />
        <YAxis
          dataKey={dataKeys.yAxis}
          type="number"
          width={70}
          {...axisStyles}
          tickFormatter={yAxisFormatter}>
          {yAxisLabel && (
            <Label
              dy={50}
              offset={-15}
              angle={-90}
              style={{ fill: fontColor }}
              value={yAxisLabel}
              position="insideLeft"
            />
          )}
        </YAxis>
        <XAxis
          dataKey={dataKeys.xAxis}
          type="number"
          tickFormatter={xAxisFormatter}
          {...axisStyles}>
          {xAxisLabel && (
            <Label
              style={{ fill: fontColor }}
              value={xAxisLabel}
              offset={-20}
              position="insideBottom"
            />
          )}
        </XAxis>

        {data.map((k: LineScatterDataType<A>) => {
          if (!keyToSelected[k.name]) {
            return null;
          }

          return (
            <Line
              isAnimationActive={false}
              activeDot={false}
              name={k.name}
              key={k.name}
              data={k.data}
              dataKey={dataKeys.yAxis}
              stroke={keyToColor[k.name]}
              dot={false}
              strokeWidth={2}
              type="basis"
            />
          );
        })}
        {data.map((k: LineScatterDataType<A>) => {
          if (!keyToSelected[k.name]) {
            return null;
          }

          const color = keyToColor[k.name];

          return (
            <Scatter
              isAnimationActive={false}
              name={`${k.name}_points`}
              key={`${k.name}_points`}
              data={k.points}
              dataKey={dataKeys.yAxis}
              fill={color}
              legendType="none">
              <LabelList
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data={k.points as any}
                dataKey={dataKeys.xAxis}
                position="insideTopLeft"
                fill={color}
                offset={12}
              />
            </Scatter>
          );
        })}
        {children}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
