import React from 'react';
import { KeyMetricsGrid } from '../../../../../components/molecules/KeyMetricsGrid';
import { CPA } from './CPA';
import { ModelledMediaConversions } from './ModelledMediaConversions';
import { Spend } from './Spend';
import { TotalConversions } from './TotalConversions';

export const DAKeyMetrics: React.FC = () => {
  return (
    <KeyMetricsGrid>
      <CPA />
      <Spend />
      <TotalConversions />
      <ModelledMediaConversions />
    </KeyMetricsGrid>
  );
};
