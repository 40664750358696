import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FilterHeading } from 'src/components/atoms/FilterHeading';
import { FilterBarContainer } from 'src/components/molecules/FilterBarContainer';
import { CityMenu } from '../menus/CityMenu';
import { CountryMenu } from '../menus/CountryMenu';
import { KeywordMenu } from '../menus/KeywordMenu';
import { RegionMenu } from '../menus/RegionMenu';

type SOSFilterBarProps = {
  displayLocationMenus: boolean;
};

export const SOSFilterBar: React.FC<SOSFilterBarProps> = ({
  displayLocationMenus,
}) => {
  return (
    <FilterBarContainer>
      <Flex w="100%" alignItems="center">
        <Flex mr="40px" direction="row" alignItems="flex-end">
          <FilterHeading headingText="Country" />
          <CountryMenu />
        </Flex>

        <Flex direction="row" alignItems="flex-end">
          <FilterHeading headingText="Keywords" />
          <KeywordMenu />
        </Flex>
        {displayLocationMenus && (
          <>
            <Flex pl="40px" direction="row" alignItems="flex-end">
              <FilterHeading headingText="Region" />
              <RegionMenu />
            </Flex>
            <Flex pl="40px" direction="row" alignItems="flex-end">
              <FilterHeading headingText="Cities" />
              <CityMenu />
            </Flex>
          </>
        )}
      </Flex>
    </FilterBarContainer>
  );
};
