import { Text } from '@chakra-ui/react';
import React from 'react';
import { Coordinate } from 'recharts/types/util/types';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { ResponseCurveDatapoint } from 'src/types';
import { isNonNullable } from 'src/utils/Data';
import { EuroValueTickFormatter } from 'src/utils/TickFormatters';
import { getResponseForSpend } from 'src/utils/responseCurve';
import { LineScatterDataType } from '../../molecules/graphs/LineScatterGraph';
import { TooltipColoredLegend } from './TooltipColoredLegend';
import { TooltipContentContainer } from './TooltipContentContainer';

type ResponseCurveTooltipContentProps = {
  label?: number;
  payload?: Array<{ name: string; color: string }>;
  responseCurveData: LineScatterDataType<ResponseCurveDatapoint>[];
  valueFormatter: (x: number) => string;
  coordinate?: Coordinate;
};
export const ResponseCurveTooltipContent: React.FC<
  ResponseCurveTooltipContentProps
> = ({ label: spend, responseCurveData, payload, valueFormatter }) => {
  const { keyToSelected } = useLegendContext();

  const colorMap: Record<string, string> = {};
  payload?.forEach(({ name, color }) => {
    colorMap[name] = color;
  });
  // Shouldnt happen but required for types. Recharts injects this
  if (!spend || responseCurveData.length === 0) {
    return null;
  }

  const selectedReponseCurveData = responseCurveData.filter(
    (r) => keyToSelected[r.name],
  );

  const dataPoints = selectedReponseCurveData
    .map(({ data, name }) => {
      const maxSpend = data[data.length - 1].spend;
      if (maxSpend < spend) {
        return null;
      }
      const closestResponse = getResponseForSpend(spend, data);
      return {
        name,
        response: closestResponse,
        color: colorMap[name],
      };
    })
    .filter(isNonNullable)
    .sort((a, b) => b.response - a.response);
  return (
    <TooltipContentContainer>
      <Text fontSize="18px">{EuroValueTickFormatter(spend)}</Text>
      {dataPoints.map((dataPoint) => {
        return (
          <TooltipColoredLegend
            key={dataPoint.name}
            color={dataPoint.color}
            label={dataPoint.name}
            value={valueFormatter(dataPoint.response)}
          />
        );
      })}
    </TooltipContentContainer>
  );
};
