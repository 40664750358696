import { ApiTotalDecompDataPoint } from 'src/api/queries';
import {
  getBiannualStartEpoch,
  getMonthStartEpoch,
  getQuarterStartEpoch,
  getWeekStartEpoch,
  getYearStartEpoch,
  sortEpoch,
} from 'src/utils/date';
import { TotalDecompsState, TotalDecompsTimeframeData } from '../types';

const addToRecordTotal = (
  record: Record<string, number>,
  key: string,
  value: number,
) => {
  if (!record[key]) {
    record[key] = 0;
  }

  record[key] += value;
};

const mapByAggregateToTimeframeData = (
  byAggregate: Record<string, Array<ApiTotalDecompDataPoint>>,
): Array<TotalDecompsTimeframeData> => {
  const timeframeData: Array<TotalDecompsTimeframeData> = [];

  Object.entries(byAggregate).forEach(([utcEpoch, dataForUtcEpoch]) => {
    const d: TotalDecompsTimeframeData = {
      base: 0,
      total: 0,
      mediaEffect: 0,
      byOtherMetric: {},
      utcEpoch: Number(utcEpoch),
    };

    dataForUtcEpoch.forEach((data) => {
      d.base += data.base;
      d.total += data.decompTotal;
      d.mediaEffect += data.mediaEffect;

      data.decompMetrics.forEach(({ metric, value }) => {
        if (!d.byOtherMetric[metric]) {
          d.byOtherMetric[metric] = 0;
        }
        d.byOtherMetric[metric] += value;
      });
    });

    timeframeData.push(d);
  });

  return timeframeData.sort(sortEpoch);
};

export const aggregateTotalDecomps = (
  decompsData: Array<ApiTotalDecompDataPoint>,
  isLoading: boolean,
  isError: boolean,
): TotalDecompsState => {
  const dataState: TotalDecompsState = {
    week: [],
    month: [],
    quarter: [],
    biannual: [],
    year: [],
    total: 0,
    baseTotal: 0,
    mediaEffectTotal: 0,
    totalByOtherMetric: {},
    isLoading,
    isError,
  };

  const byWeek: Record<number, Array<ApiTotalDecompDataPoint>> = {};
  const byMonth: Record<number, Array<ApiTotalDecompDataPoint>> = {};
  const byQuarter: Record<number, Array<ApiTotalDecompDataPoint>> = {};
  const byBiannual: Record<number, Array<ApiTotalDecompDataPoint>> = {};
  const byYear: Record<number, Array<ApiTotalDecompDataPoint>> = {};

  decompsData.forEach((utcDatapoint) => {
    const utcEpoch = utcDatapoint.utcEpoch;
    const weekEpoch = getWeekStartEpoch(utcEpoch);
    const monthEpoch = getMonthStartEpoch(utcEpoch);
    const quarterEpoch = getQuarterStartEpoch(utcEpoch);
    const biannualEpoch = getBiannualStartEpoch(utcEpoch);
    const yearEpoch = getYearStartEpoch(utcEpoch);

    // Week data
    if (!byWeek[weekEpoch]) {
      byWeek[weekEpoch] = [];
    }
    byWeek[weekEpoch].push(utcDatapoint);

    // Month data
    if (!byMonth[monthEpoch]) {
      byMonth[monthEpoch] = [];
    }
    byMonth[monthEpoch].push(utcDatapoint);

    // Quarterly data
    if (!byQuarter[quarterEpoch]) {
      byQuarter[quarterEpoch] = [];
    }
    byQuarter[quarterEpoch].push(utcDatapoint);

    // Biannual data
    if (!byBiannual[biannualEpoch]) {
      byBiannual[biannualEpoch] = [];
    }
    byBiannual[biannualEpoch].push(utcDatapoint);

    // Yearly data
    if (!byYear[yearEpoch]) {
      byYear[yearEpoch] = [];
    }
    byYear[yearEpoch].push(utcDatapoint);
  });

  dataState.week = mapByAggregateToTimeframeData(byWeek);
  dataState.month = mapByAggregateToTimeframeData(byMonth);
  dataState.quarter = mapByAggregateToTimeframeData(byQuarter);
  dataState.biannual = mapByAggregateToTimeframeData(byBiannual);
  dataState.year = mapByAggregateToTimeframeData(byYear);

  // Use year to get total as least amount of datapoints
  dataState.year.forEach((data) => {
    dataState.total += data.total;
    dataState.mediaEffectTotal += data.mediaEffect;
    dataState.baseTotal += data.base;

    Object.entries(data.byOtherMetric).forEach(([otherMetric, value]) => {
      addToRecordTotal(dataState.totalByOtherMetric, otherMetric, value);
    });
  });

  return dataState;
};
