import { useColorMode, useTheme } from '@chakra-ui/react';

export const useChartDataColors = () => {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  const chartDataColors = theme.colors?.chart.line[colorMode];

  return { chartDataColors };
};

export const useChartColors = (): {
  fontColor: string;
  benchmarkLineColor: string;
  gridColor: string;
} => {
  const theme = useTheme();

  const { colorMode } = useColorMode();
  const fontColor =
    colorMode === 'light'
      ? theme.colors?.text.light.primary
      : theme.colors?.text.dark.primary;

  const benchmarkLineColor = theme.colors?.chart.benchmarkLine;
  const gridColor =
    colorMode === 'light'
      ? theme.colors?.chart.grid.light
      : theme.colors?.chart.grid.dark;

  return {
    fontColor,
    benchmarkLineColor,
    gridColor,
  };
};

export const useAxisStyles = () => {
  const { fontColor } = useChartColors();

  return {
    tick: { fill: fontColor },
    tickLine: { stroke: fontColor },
    tickSize: 4,
    fontSize: 12,
    axisLine: { stroke: fontColor },
    tickMargin: 8,
  };
};
