import { Flex, GridItem, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { useMaximumChartHeight } from 'src/hooks/useMaximumChartHeight';
import { DataStatusWrapper } from '../DataStatusWrapper';
import { ExportCsvProvider } from '../ExportCsv/ExportCsvContext/ExportCsvContextProvider';

import { useDataBoxBackgroundColor } from 'src/hooks/colors/useBackgroundColor';
import {
  LegendContextProvider,
  LegendContextProviderProps,
} from '../Legend/LegendContext/LegendContextProvider';
import { RightLegend } from '../Legend/RightLegend';
import { TopLegend } from '../Legend/TopLegend';
import { DataBoxHeader, LegendPlacement } from './DataBoxHeader';
import { TitleProps } from './Title';

export type DataBoxLegendProps = Omit<
  LegendContextProviderProps,
  'children'
> & {
  placement: LegendPlacement;
  minWidth?: string;
};

export type DataBoxBaseProps = TitleProps & {
  colSpan?: number;
  rowSpan?: number | null;
  minH?: number | string;
  maxH?: number | string;
  h?: number | string;
  titleComponent?: React.ReactNode;
};

type ExportWrapperProps = {
  exportEnabled?: boolean;
  children: React.ReactNode;
};
// Avoid unncessary contexts as most data providers do not need export support
const ExportWrapper: React.FC<ExportWrapperProps> = ({
  exportEnabled,
  children,
}) => {
  if (exportEnabled) {
    return <ExportCsvProvider>{children}</ExportCsvProvider>;
  }

  return <>{children}</>;
};

type LegendWrapperProps = {
  legendProps?: DataBoxLegendProps;
  children: React.ReactNode;
};
// Avoid unncessary contexts as most data providers do not need export support
const LegendWrapper: React.FC<LegendWrapperProps> = ({
  legendProps,
  children,
}) => {
  if (legendProps) {
    return (
      <LegendContextProvider {...legendProps}>{children}</LegendContextProvider>
    );
  }

  return <>{children}</>;
};

export type DataBoxProps = DataBoxBaseProps & {
  exportEnabled?: boolean;
  exportButtonEnabled?: boolean;
  hasData: boolean;
  isError: boolean;
  isLoading: boolean;
  legendProps?: DataBoxLegendProps;
  rightContent?: React.ReactNode;
  tabs?: React.ReactNode;
  setTableDataModalIsOpen?: (isOpen: boolean) => void;
  secondaryTabs?: React.ReactNode;
  restrictChartHeight?: boolean;
  onInfoButtonClick?: () => void;
  tooltip?: React.ReactNode;
};

export const DataBox: React.FC<DataBoxProps> = ({
  colSpan = 2,
  minH = 0,
  title,
  tabs,
  secondaryTabs,
  children,
  titleComponent,
  exportEnabled,
  legendProps,
  hasData,
  isError,
  isLoading,
  rightContent,
  restrictChartHeight,
  maxH: passedMaxH,
  setTableDataModalIsOpen,
  exportButtonEnabled = true,
  h,
  onInfoButtonClick,
  tooltip,
}) => {
  const showExportButton = !!exportEnabled && exportButtonEnabled !== false;

  const maxChartHeight = useMaximumChartHeight();

  const { bgColor } = useDataBoxBackgroundColor();

  const boxBorderColor = useColorModeValue(
    'border.light.box',
    'border.dark.box',
  );

  const legendPlacement = legendProps?.placement;

  const maxH = restrictChartHeight ? maxChartHeight : passedMaxH;

  return (
    <GridItem position={'relative'} colSpan={colSpan}>
      <ExportWrapper exportEnabled={exportEnabled}>
        <LegendWrapper legendProps={legendProps}>
          <Flex
            p="16px"
            pt="10px"
            h={h ?? '100%'}
            minH={minH}
            maxH={maxH ? maxH : '100%'}
            direction="column"
            borderWidth="thin"
            borderRadius="4px"
            borderColor={boxBorderColor}
            background={bgColor}>
            <DataBoxHeader
              showExportButton={showExportButton}
              isLegendOnTop={legendPlacement === 'top'}
              hasData={hasData}
              title={title}
              titleComponent={titleComponent}
              tabs={tabs}
              secondaryTabs={secondaryTabs}
              rightContent={rightContent}
              setTableDataModalIsOpen={setTableDataModalIsOpen}
              onInfoButtonClick={onInfoButtonClick}
            />
            {tooltip}
            {/* Content */}
            {legendPlacement === 'right' ? (
              <Flex
                overflow={'hidden'}
                justifyContent={'center'}
                direction="row"
                flex="1">
                <DataStatusWrapper
                  isLoading={isLoading}
                  isError={isError}
                  hasData={hasData}>
                  {children}
                </DataStatusWrapper>
                {hasData && <RightLegend minW={legendProps?.minWidth} />}
              </Flex>
            ) : (
              <Flex
                overflow={'scroll'}
                justifyContent={'center'}
                direction="column"
                flex="1">
                {legendPlacement === 'top' && hasData && <TopLegend />}
                <DataStatusWrapper
                  isLoading={isLoading}
                  isError={isError}
                  hasData={hasData}>
                  {children}
                </DataStatusWrapper>
              </Flex>
            )}
          </Flex>
        </LegendWrapper>
      </ExportWrapper>
    </GridItem>
  );
};
