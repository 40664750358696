import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { useChartColors } from 'src/hooks/colors/useChartColors';

type LegendIconProps = {
  isLine: boolean;
  color: string;
  legendIconWidth?: string;
};

const defaultLegendIconWidth = '14px';

export const LegendIcon: React.FC<LegendIconProps> = ({
  isLine,
  color,
  legendIconWidth = defaultLegendIconWidth,
}) => {
  if (isLine) {
    return (
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        w={legendIconWidth}
        minW={legendIconWidth}
        h={legendIconWidth}
        position={'relative'}>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          w="100%"
          bgColor={color}
          h="2px">
          <Box bgColor={color} w={'6px'} h="6px" borderRadius={'50%'} />
        </Flex>
      </Flex>
    );
  }

  return (
    <Box
      bgColor={color}
      w={legendIconWidth}
      minW={legendIconWidth}
      h={legendIconWidth}
      borderRadius={'50%'}
    />
  );
};

export type LegendItemProps = {
  label: string;
  color: string;
  onClick?: () => void;
  isSelected?: boolean;
  isLine?: boolean;
} & BoxProps;

export const LegendItem: React.FC<LegendItemProps> = ({
  label,
  onClick,
  color,
  isSelected = true,
  isLine = false,
  ...boxProps
}) => {
  const { fontColor } = useChartColors();

  return (
    <Flex
      opacity={isSelected ? 1 : 0.3}
      cursor={onClick ? 'pointer' : 'inherit'}
      alignItems={'center'}
      onClick={onClick}
      {...boxProps}>
      <LegendIcon isLine={isLine} color={color} />
      <Box ml="10px" fontSize={'14px'} userSelect={'none'} color={fontColor}>
        {label}
      </Box>
    </Flex>
  );
};
