import React, { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { useLTDataContext } from 'src/features/LT/contexts/LTDataContext';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import {
  MonthYearDateFormatter,
  YearDateFormatter,
} from 'src/utils/TickFormatters';
import { TimePeriodTabs, TimePeriod } from '../molecules/TimePeriodTabs';

type ProcessedDataPoint = {
  key: number;
  Base: number;
  'Total media effect': number;
};

export const EvolutionOfBaseBox: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { effects, isError, isLoading } = useLTDataContext();

  const yAxisFormatter = useChartEffectTickFormatter();

  const processedData = useMemo(() => {
    const monthlyRecord: Record<number, ProcessedDataPoint> = {};
    effects.monthly.forEach(({ utcEpoch, base, totalMediaEffect }) => {
      monthlyRecord[utcEpoch] = {
        key: utcEpoch,
        Base: base,
        'Total media effect': totalMediaEffect,
      };
    });

    const yearlyRecord: Record<number, ProcessedDataPoint> = {};
    effects.yearly.forEach(({ utcEpoch, base, totalMediaEffect }) => {
      yearlyRecord[utcEpoch] = {
        key: utcEpoch,
        Base: base,
        'Total media effect': totalMediaEffect,
      };
    });

    const monthly = Object.values(monthlyRecord);
    const yearly = Object.values(yearlyRecord);

    return {
      monthly,
      yearly,
    };
  }, [effects]);

  const dataKeys = {
    xAxis: 'key',
    data: ['Base', 'Total media effect'],
  };

  const [timePeriod, setTimePeriod] = useState<TimePeriod>('monthly');

  let data: ProcessedDataPoint[];
  let dateAxisFormatter: (val: string | number) => string;
  switch (timePeriod) {
    case 'monthly':
      data = processedData.monthly;
      dateAxisFormatter = MonthYearDateFormatter;
      break;
    case 'yearly':
      data = processedData.yearly;
      dateAxisFormatter = YearDateFormatter;
      break;
  }

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'top',
  };

  return (
    <DataBox
      {...props}
      tabs={
        <TimePeriodTabs onSelect={setTimePeriod} selectedTab={timePeriod} />
      }
      legendProps={legendProps}
      isError={isError}
      isLoading={isLoading}
      hasData={data.length > 0}>
      <BarGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        stacked
        xAxisFormatter={dateAxisFormatter}
        yAxisFormatter={yAxisFormatter}
      />
    </DataBox>
  );
};
