import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { LegendIcon } from 'src/components/molecules/Legend/LegendItem';

export type TooltipColoredLegendProps = {
  label: string;
  value: string;
  color: string;
};

export const TooltipColoredLegend: React.FC<TooltipColoredLegendProps> = ({
  value,
  label,
  color,
}) => {
  return (
    <Flex py="1px" alignItems={'center'}>
      <LegendIcon isLine={false} color={color} legendIconWidth="12px" />
      <Box ml="10px" fontSize="12px">{`${label}: ${value}`}</Box>
    </Flex>
  );
};
