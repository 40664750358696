import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { useFilterContext } from 'src/contexts/FilterContext';
import { MenuList } from '../../molecules/MenuList';

const MENU_BUTTON_NAME = 'media-select';

export const MediaMenu: React.FC = () => {
  const { media, toggleMediaItem, toggleAllMediaItems } = useFilterContext();

  const selectedMediaCount = media.filter((t) => t.selected).length;

  const onAllClick = () => {
    toggleAllMediaItems();
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px">
        {selectedMediaCount}/{media.length}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME} onAllClick={onAllClick}>
        {media.map((item) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            label={item.name}
            value={item.id}
            key={item.id}
            isDisabled={!item.isAvailable}
            isSelected={item.selected}
            onClick={() => toggleMediaItem(item)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
