import { useMemo } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { useSOSDataContext } from '../../../contexts/SOSDataContext';
import { SosPastAndCurrentBarChart } from '../../molecules/SosPastAndCurrentBarChart';
import { benchmarkDatesAreDefault } from 'src/features/SOS/utils/getDefaultBenchmarkDates';

type SosCitiesChartBoxProps = DataBoxBaseProps;

export const SosCitiesChartBox: React.FC<SosCitiesChartBoxProps> = ({
  ...dataBoxProps
}) => {
  const { shareOfSearchCitiesState, selectedKeywords, citiesBenchmarkDates } =
    useSOSDataContext();

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      placement: 'right',
      baseLegendKeys: selectedKeywords,
      minWidth: '150px',
    };
  }, [selectedKeywords]);

  const isBenchmarkDefault = benchmarkDatesAreDefault(citiesBenchmarkDates);

  return (
    <DataBox
      {...dataBoxProps}
      legendProps={legendProps}
      restrictChartHeight
      hasData={shareOfSearchCitiesState.data.length > 0}
      isError={shareOfSearchCitiesState.isError}
      isLoading={shareOfSearchCitiesState.isLoading}>
      <SosPastAndCurrentBarChart
        data={shareOfSearchCitiesState.data}
        isBenchmarkDefault={isBenchmarkDefault}
      />
    </DataBox>
  );
};
