import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { useBenchmarkEnabled } from 'src/features/DA/hooks/useBenchmarkEnabled';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import { useMergeBenchmarkTimeseries } from 'src/hooks/useMergeBenchmarkTimeseries';
import { useDADataContext } from '../../../contexts/DADataContext';

export const TotalConversions: React.FC = () => {
  const { sales, benchmarkSales } = useDADataContext();
  const { salesBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMergedTimeSeries } = useMergeBenchmarkTimeseries();

  const { isEuro } = useKpiUnit();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    const data = getMergedTimeSeries(
      sales.day,
      benchmarkSales.day,
      (v) => v,
      (v) => ({ benchmark: v.value }),
      salesBenchmarkEnabled,
      'day',
    );

    return {
      name: 'Total Conversions',
      data,
      isEuro,
      summaryValue: sales.total,
      benchmarkSummaryValue: salesBenchmarkEnabled
        ? benchmarkSales.total
        : undefined,
      isError: sales.isError,
      isLoading: sales.isLoading,
    };
  }, [
    sales,
    isEuro,
    benchmarkSales,
    salesBenchmarkEnabled,
    getMergedTimeSeries,
  ]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
