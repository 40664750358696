import { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { SosMonthlyMovingAverageData } from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import {
  Mode,
  SosByMonthsKeywordChart,
} from '../../molecules/SosByMonthsKeywordChart';
import { SosTabs } from '../../molecules/SosTabs';

type SoSMonthlyForLocationMovingAverageLineChartBoxProps = DataBoxBaseProps & {
  data: Array<SosMonthlyMovingAverageData>;
  isLoading: boolean;
  isError: boolean;
};

export const SoSMonthlyForLocationMovingAverageLineChartBox: React.FC<
  SoSMonthlyForLocationMovingAverageLineChartBoxProps
> = ({ data, isLoading, isError, ...dataBoxProps }) => {
  const { selectedKeywords } = useSOSDataContext();

  const [chartMode, setChartMode] = useState<Mode>('shareOfSearch');

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      placement: 'right',
      autoSortKeys: true,
      baseLegendKeys: selectedKeywords,
      legendSelectBehaviour: 'single',
      minWidth: '160px',
    };
  }, [selectedKeywords]);

  return (
    <DataBox
      tabs={<SosTabs selectedTab={chartMode} onSelect={setChartMode} />}
      exportEnabled
      restrictChartHeight
      legendProps={legendProps}
      {...dataBoxProps}
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <SosByMonthsKeywordChart data={data} mode={chartMode} />
    </DataBox>
  );
};
