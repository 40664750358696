import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { useFilterContext } from 'src/contexts/FilterContext';
import { MenuList } from '../../molecules/MenuList';

const MENU_BUTTON_NAME = 'tag-group-select';

export const TagGroupMenu: React.FC = () => {
  const {
    campaignTagGroups,
    toggleAllCampaignTagGroups,
    toggleCampaignTagGroup,
  } = useFilterContext();

  const selectedTagGroupCount = campaignTagGroups.filter(
    (t) => t.selected,
  ).length;

  const onAllClick = () => {
    toggleAllCampaignTagGroups();
  };

  return (
    <Menu>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px" textAlign="left">
        {selectedTagGroupCount}/{campaignTagGroups.length}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME} onAllClick={onAllClick}>
        {campaignTagGroups.map(({ tagGroup, name, selected }) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={name}
            isSelected={selected}
            label={name}
            value={name}
            onClick={() => toggleCampaignTagGroup(tagGroup)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
