import { DecompositionsBox } from '../../organisms/DecompositionsBox';
import { ResponseCurvesBox } from '../../organisms/ResponseCurvesBox';
import { SShapeInsightsBox } from '../../organisms/SShapeInsightsBox';
import { TotalDecompByAttrBox } from '../../organisms/TotalDecompByAttrBox';
import { TotalDecompsBox } from '../../organisms/TotalDecompsBox';

export const Modelling: React.FC = () => {
  return (
    <>
      <DecompositionsBox title="Decompositions" colSpan={12} minH="400px" />
      <TotalDecompByAttrBox
        title="Total Decomposition By Attribute"
        colSpan={12}
        minH="400px"
      />
      <TotalDecompsBox title="Total Decompositions" colSpan={12} minH="400px" />
      <ResponseCurvesBox title="Response Curves" colSpan={12} minH="400px" />
      <SShapeInsightsBox title="S-Shape Insights" colSpan={12} minH="400px" />
    </>
  );
};
