import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { isNonNullable } from 'src/utils/Data';
import { sortStrings } from 'src/utils/sort';

export const useAvailableCategories = () => {
  const { campaignToTags, campaigns } = useFilterContext();

  return useMemo(() => {
    const nonUniqueCategories = campaigns
      .filter((c) => c.selected)
      .map(({ id }) => {
        const campaignTagInfo = campaignToTags[id];
        if (campaignTagInfo) {
          return campaignTagInfo.category;
        }
        return null;
      })
      .filter(isNonNullable);

    const uniqueCategories = sortStrings(uniq(nonUniqueCategories));

    return { categories: uniqueCategories };
  }, [campaigns, campaignToTags]);
};
