import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DATA_QUERY_EXPIRATION, QUERY_RETRY } from 'src/api/queries';
import request from 'src/api/request';
import { SosLocation } from '../types';

export type ApiShareOfSearchByLocationDataPoint = {
  location: SosLocation;
  utcEpoch: number;
  keywords: Array<{ keyword: string; searchVolume: number }>;
};

export type ApiShareOfSearchAggregatedDataPoint = {
  location: SosLocation;
  keywords: Array<{
    keyword: string;
    last12Months: number;
    benchmark: number;
  }>;
};

export type ApiShareOfSearchLocationsAggregateDataPoint = {
  utcEpoch: number;
  keywords: Array<{ keyword: string; searchVolume: number }>;
};

export type ShareOfSearchByLocationQueryParams = {
  customer: string;
  location_id: number;
};

export type ShareOfSearchAvailableCountriesQueryParams = {
  customer: string;
};

export type ShareOfSearchByLocationResponse = {
  data: Array<ApiShareOfSearchByLocationDataPoint>;
};

export type ShareOfSearchAggregatedResponse = {
  data: Array<ApiShareOfSearchAggregatedDataPoint>;
};

export type ShareOfSearchAggregatedQueryParams = {
  customer: string;
  country_code: string;
  country_id: number;
  benchmark_from: string;
  benchmark_to: string;
};

export type ShareOfSearchAvailableCountriesResponse = {
  data: Array<SosLocation>;
};

export type ShareOfSearchLocationsAggregateQueryParams = {
  customer: string;
  locationIds: Array<number>;
};

export type ShareOfSearchLocationsAggregateResponse = {
  data: Array<ApiShareOfSearchLocationsAggregateDataPoint>;
};

export type ShareOfSearchAvailableRegionsAndCitiesForCountryQueryParams = {
  customer: string;
  country_code: string;
  country_id: number;
};

export type ShareOfSearchAvailableRegionsAndCitiesForCountryResponse = {
  cities: Array<SosLocation>;
  regions: Array<SosLocation>;
};

export function useShareOfSearchByLocationQuery(
  params: ShareOfSearchByLocationQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useShareOfSearchByLocationQuery',
      params.customer,
      params.location_id,
    ];
  }, [params.customer, params.location_id]);

  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchByLocationResponse>(`data/shareofsearch/location`, {
          params,
        })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useShareOfSearchLocationsAggregateQuery(
  params: ShareOfSearchLocationsAggregateQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useShareOfSearchLocationsAggregateQuery',
      params.customer,
      params.locationIds,
    ];
  }, [params.customer, params.locationIds]);

  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchLocationsAggregateResponse>(
          `data/shareofsearch/locations-aggregate`,
          {
            params: {
              customer: params.customer,
              id: params.locationIds,
            },
            paramsSerializer: {
              indexes: null,
            },
          },
        )
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useShareOfSearchCitiesQuery(
  params: ShareOfSearchAggregatedQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useShareOfSearchCitiesQuery',
      params.customer,
      params.country_id,
      params.benchmark_from,
      params.benchmark_to,
    ];
  }, [
    params.customer,
    params.country_id,
    params.benchmark_from,
    params.benchmark_to,
  ]);

  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchAggregatedResponse>(`data/shareofsearch/cities`, {
          params,
        })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useShareOfSearchRegionsQuery(
  params: ShareOfSearchAggregatedQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useShareOfSearchRegionsQuery',
      params.customer,
      params.country_id,
      params.benchmark_from,
      params.benchmark_to,
    ];
  }, [
    params.customer,
    params.country_id,
    params.benchmark_from,
    params.benchmark_to,
  ]);

  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchAggregatedResponse>(`data/shareofsearch/regions`, {
          params,
        })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useShareOfSearchAvailableCountriesQuery(
  params: ShareOfSearchAvailableCountriesQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return ['useShareOfSearchAvailableCountriesQuery', params.customer];
  }, [params.customer]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchAvailableCountriesResponse>(
          'data/shareofsearch/availablecountries',
          {
            params,
          },
        )
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useShareOfSearchAvailableRegionsAndCitiesForCountryQuery(
  params: ShareOfSearchAvailableRegionsAndCitiesForCountryQueryParams,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useShareOfSearchAvailableRegionsAndCitiesForCountryQuery',
      params.customer,
      params.country_code,
      params.country_id,
    ];
  }, [params.customer, params.country_code, params.country_id]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<ShareOfSearchAvailableRegionsAndCitiesForCountryResponse>(
          'data/shareofsearch/availableregionsandcities',
          {
            params,
          },
        )
        .then((response) => {
          return response.data;
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}
