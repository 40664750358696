import { AggregateLevel } from 'src/types';
import {
  KeyMetricsDateFormatter,
  MonthNameYearDateFormatter,
  MonthYearDateFormatter,
  QuarterDateFormatter,
  WeekYearDateFormatter,
  YearDateFormatter,
} from 'src/utils/TickFormatters';
import { useAggregationLevelContext } from '../contexts/AggregationLevelContext';

export const useGetDateAxisFormatter = () => {
  const { aggregateLevel } = useAggregationLevelContext();

  switch (aggregateLevel) {
    case AggregateLevel.day:
      return KeyMetricsDateFormatter;
    case AggregateLevel.week:
      return WeekYearDateFormatter;
    case AggregateLevel.biannual:
      return MonthNameYearDateFormatter;
    case AggregateLevel.year:
      return YearDateFormatter;
    case AggregateLevel.month:
      return MonthYearDateFormatter;
    case AggregateLevel.quarter:
      return QuarterDateFormatter;
  }
};
