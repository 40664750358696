type CalculateCostPerUnitParams = {
  investment: number;
  mediaIncrementalSales: number;
};

export const calculateCostPerUnit = ({
  investment,
  mediaIncrementalSales,
}: CalculateCostPerUnitParams) => {
  if (mediaIncrementalSales < 1) {
    return 0;
  }
  return investment / mediaIncrementalSales;
};
