import React from 'react';
import { MetricsByTagBarGraph } from '../../organisms/MetricsByTagBarGraph';
import { MetricsByTagTable } from '../../organisms/MetricsByTagTable';

export const DeepDive: React.FC = () => {
  return (
    <>
      <MetricsByTagBarGraph minH={400} title="Sessions overview" colSpan={12} />
      <MetricsByTagTable title="Tag explorer" colSpan={12} />
    </>
  );
};
