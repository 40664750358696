import { Fragment, useMemo } from 'react';
import {
  Bar,
  ComposedChart,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TooltipContent } from 'src/components/atoms/tooltips/TooltipContent';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { ApiShareOfSearchAggregatedDataPoint } from 'src/features/SOS/api/queries';
import { useAxisStyles } from 'src/hooks/colors/useChartColors';
import { PercentageValueTickFormatter } from 'src/utils/TickFormatters';
import { getLast12MonthsKey, getPreviousKey } from './getDataKey';

type ChartDataPoint = {
  locationName: string;
  locationId: number;
} & Record<string, number>;

const getChartData = (
  data: Array<ApiShareOfSearchAggregatedDataPoint>,
): Array<ChartDataPoint> => {
  return data.map(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ location, keywords }) => {
      const locationName = location.locationName;
      const locationId = location.locationId;

      const {
        last12MonthsSum,
        benchmarkSum,
      }: { last12MonthsSum: number; benchmarkSum: number } = keywords.reduce(
        (prev, curr) => {
          return {
            last12MonthsSum: prev.last12MonthsSum + curr.last12Months,
            benchmarkSum: prev.benchmarkSum + curr.benchmark,
          };
        },
        { last12MonthsSum: 0, benchmarkSum: 0 },
      );

      const datapoint = {
        locationName,
        locationId,
      } as ChartDataPoint;

      for (const { keyword, last12Months, benchmark } of keywords) {
        const benchmarkShareOfSearch =
          benchmarkSum > 0 ? benchmark / benchmarkSum : 0;

        const last12MonthsShareOfSearch =
          last12MonthsSum > 0 ? last12Months / last12MonthsSum : 0;

        datapoint[getPreviousKey(keyword)] = benchmarkShareOfSearch * 100;
        datapoint[getLast12MonthsKey(keyword)] =
          last12MonthsShareOfSearch * 100;
      }
      return datapoint;
    },
  );
};

type SosPastAndCurrentBarChartProps = {
  data: Array<ApiShareOfSearchAggregatedDataPoint>;
  isBenchmarkDefault: boolean;
};

type RenderCustomBarLabelProps = LabelProps & {
  labelText: string;
};

const RenderCustomBarLabel: React.FC<RenderCustomBarLabelProps> = ({
  x,
  y,
  width,
  height,
  labelText,
}) => {
  if (
    typeof x !== 'number' ||
    typeof width !== 'number' ||
    typeof y !== 'number' ||
    typeof height !== 'number'
  ) {
    return null;
  }

  return (
    <text
      fontSize={'10px'}
      x={x + width / 2}
      y={y + height + 18}
      fill="#666"
      textAnchor="middle"
      dy={-6}>
      {labelText}
    </text>
  );
};

export const SosPastAndCurrentBarChart: React.FC<
  SosPastAndCurrentBarChartProps
> = ({ data, isBenchmarkDefault }) => {
  const { selectedBaseLegendValues } = useLegendContext();

  const axisStyles = useAxisStyles();

  const renderLabel = data.length < 7;

  const chartData: Array<ChartDataPoint> = useMemo(() => {
    return getChartData(data);
  }, [data]);

  return (
    <ResponsiveContainer>
      <ComposedChart data={chartData}>
        <YAxis
          width={70}
          yAxisId="left"
          tickFormatter={PercentageValueTickFormatter}
          domain={[0, 1]}
          {...axisStyles}
        />
        <XAxis
          type="category"
          dataKey={'locationName'}
          {...axisStyles}
          tickMargin={14}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          offset={25}
          content={
            <TooltipContent
              valueFormatter={PercentageValueTickFormatter}
              labelFormatter={(x) => x.toString()}
            />
          }
        />

        {selectedBaseLegendValues.map(({ key: keyword, color }, i) => {
          return (
            <Fragment key={keyword}>
              <Bar
                label={
                  renderLabel && i == 0 ? (
                    <RenderCustomBarLabel
                      labelText={isBenchmarkDefault ? 'Prev. years' : 'Bench.'}
                    />
                  ) : undefined
                }
                yAxisId="left"
                dataKey={getPreviousKey(keyword)}
                stackId={'previous'}
                isAnimationActive={false}
                fill={color}
              />
              <Bar
                label={
                  renderLabel && i == 0 ? (
                    <RenderCustomBarLabel labelText="Last 12m" />
                  ) : undefined
                }
                yAxisId="left"
                dataKey={getLast12MonthsKey(keyword)}
                stackId={'current'}
                isAnimationActive={false}
                fill={color}
              />
            </Fragment>
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
