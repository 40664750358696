import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { useSearch } from 'src/hooks/useSearch';

const MENU_BUTTON_NAME = 'map-highlighted-keyword-select';

export const RegionMapHighlightedKeywordsMenu: React.FC = () => {
  const { highlightedKeywordsForMap, toggleHighlightedKeywordsForMap } =
    useSOSDataContext();

  const selectedKeywords = highlightedKeywordsForMap.filter((c) => c.selected);

  const {
    results: searchedKeywords,
    searchValue,
    setSearchValue,
  } = useSearch(highlightedKeywordsForMap, ['keyword']);

  const onAllClick = () => {
    toggleHighlightedKeywordsForMap(searchedKeywords);
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px" fontSize="16px">
        {selectedKeywords.length}/{highlightedKeywordsForMap.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedKeywords.map((d) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            label={d.keyword}
            value={d.keyword}
            key={d.keyword}
            isSelected={d.selected}
            onClick={() => toggleHighlightedKeywordsForMap([d])}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
