import { PropsWithChildren, useMemo, useState } from 'react';
import { AggregateLevel } from 'src/types';
import { AggregationLevelContext } from '.';

export const AggregationLevelContextProvider: React.FC<PropsWithChildren> = (
  props,
) => {
  const [aggregateLevel, setAggregateLevel] = useState<AggregateLevel>(
    AggregateLevel.week,
  );

  const value = useMemo(() => {
    return {
      aggregateLevel,
      setAggregateLevel,
    };
  }, [aggregateLevel]);

  return (
    <AggregationLevelContext.Provider value={value}>
      {props.children}
    </AggregationLevelContext.Provider>
  );
};
