import { ModelVersion } from 'src/contexts/FilterContext/useModelVersions';
import { dayjs } from 'src/libs/dayjs';

export const getModelMaxStartAndEndDatePickerDates = (
  currentModelVersion: ModelVersion | null,
) => {
  const datePickerMinDate = currentModelVersion?.dataStartDate
    ? dayjs(currentModelVersion.dataStartDate).toDate()
    : undefined;

  const datePickerMaxDate = currentModelVersion?.dataEndDate
    ? dayjs(currentModelVersion.dataEndDate).toDate()
    : undefined;

  return {
    datePickerMaxDate,
    datePickerMinDate,
  };
};
