import { useEffect, useState } from 'react';
import {
  ApiABCResponseCurveDatapoint,
  useABCResponseCurvesQuery,
} from 'src/api/queries';
import { useFilterContext } from '../../contexts/FilterContext';

export type ResponseCurveState = {
  data: ApiABCResponseCurveDatapoint[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_RESPONSE_CURVE_STATE: Readonly<ResponseCurveState> = {
  data: [],
  isError: false,
  isLoading: false,
};

export const useABCResponseCurveFetch = (enabled: boolean) => {
  const { currentModelVersion, media } = useFilterContext();

  const [responseCurve, setResponseCurve] = useState<ResponseCurveState>(
    INIT_RESPONSE_CURVE_STATE,
  );

  const {
    data: responseCurveApiData,
    isLoading: isLoadingResponseCurve,
    isError: isErrorResponseCurve,
  } = useABCResponseCurvesQuery(
    {
      customer: currentModelVersion?.customerName ?? '',
      model_build: currentModelVersion?.buildID ?? '',
      model_id: currentModelVersion?.modelID ?? '',
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setResponseCurve(INIT_RESPONSE_CURVE_STATE);
      return;
    }

    const filtered =
      responseCurveApiData?.filter((r) =>
        media.some((m) => m.selected && m.id === r.channel),
      ) ?? [];

    setResponseCurve({
      data: filtered,
      isLoading: isLoadingResponseCurve,
      isError: isErrorResponseCurve,
    });
  }, [
    responseCurveApiData,
    isErrorResponseCurve,
    isLoadingResponseCurve,
    enabled,
    media,
  ]);

  return { responseCurve };
};
