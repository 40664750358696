import { useState } from 'react';
import { ActionAlert } from 'src/components/atoms/alerts/ActionAlert';
import { Button } from 'src/components/atoms/Button';
import { useMODataContext } from 'src/features/MO/contexts/MODataContext';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';

export type DeleteOptimizationButtonProps = {
  optimizationId: string;
};

export const DeleteOptimizationButton: React.FC<
  DeleteOptimizationButtonProps
> = ({ optimizationId }) => {
  const { deleteOptimization } = useMODataContext();
  const color = useFontErrorColor();

  const [alertOpen, setAlertOpen] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const onClick = async () => {
    if (deleting) {
      return;
    }

    setDeleting(true);

    try {
      await deleteOptimization(optimizationId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Deletion failed', e);
      window.alert('Deletion of optimization failed');
      // On success this button gets unmounted
      setDeleting(false);
    }
  };

  return (
    <>
      <Button
        buttonName="delete-optimization"
        isLoading={deleting}
        onClick={() => setAlertOpen(true)}
        color={color}
        variant={'outline'}>
        Delete
      </Button>
      <ActionAlert
        headerText="Delete Optimization"
        bodyText={`Are you sure? You can't undo this action afterwards.`}
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        actionColor={color}
        actionName={'Delete'}
        onActionClick={onClick}
      />
    </>
  );
};
