import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const DataLoading: React.FC = () => {
  return (
    <Center w="100%" h="100%">
      <Spinner speed="0.8s" size="xl" thickness="4px" />
    </Center>
  );
};
