import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { PropsWithChildren, useRef } from 'react';
import {
  FieldPathByValue,
  FieldValues,
  PathValue,
  useFormContext,
} from 'react-hook-form';
import { DatePicker } from 'src/components/atoms/DatePicker';
import { dayjs } from 'src/libs/dayjs';

type FormTimePeriodInputProps<FormInputs extends FieldValues> = {
  periodFromInputPath: FieldPathByValue<FormInputs, Date>;
  periodToInputPath: FieldPathByValue<FormInputs, Date>;
  disclosure?: UseDisclosureReturn;
  periodInput?: string;
  onDateChange?: () => void;
  helperText?: string;
  label?: string;
  sx?: BoxProps;
};

export function FormTimePeriodInput<FormInputs extends FieldValues>({
  periodFromInputPath,
  periodToInputPath,
  disclosure,
  children,
  periodInput,
  onDateChange,
  helperText,
  label,
  sx,
}: PropsWithChildren<FormTimePeriodInputProps<FormInputs>>) {
  const popoverContentRef = useRef<HTMLElement>(null);

  const { register, watch, setValue } = useFormContext<FormInputs>();
  const internalDisclosure = useDisclosure();

  const { isOpen, onClose, onToggle } = disclosure ?? internalDisclosure;

  const periodFrom: Date = watch(periodFromInputPath);
  const periodTo: Date = watch(periodToInputPath);

  const defaultPeriodInput = `${dayjs(periodFrom).format(
    'DD.MM.YYYY',
  )} - ${dayjs(periodTo).format('DD.MM.YYYY')}`;

  const bgColor = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Box minW="300px" w="400px" {...sx}>
          <FormControl isInvalid={false}>
            {label && (
              <FormLabel htmlFor={`${periodFromInputPath}_period`}>
                {label}
              </FormLabel>
            )}
            <Input
              id={`${periodFromInputPath}_period`}
              onClick={() => onToggle()}
              readOnly
              value={periodInput ?? defaultPeriodInput}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        </Box>
      </PopoverTrigger>
      <PopoverContent ref={popoverContentRef} w="100%" h="100%">
        <Flex
          bgColor={bgColor}
          flexDir={'column'}
          alignItems="center"
          justifyContent="center">
          {children}
          <Flex p="8px" pb="7px">
            <DatePicker
              {...register(periodFromInputPath)}
              sx={{ pr: '8px' }}
              selectedDate={periodFrom}
              onChange={(date: Date) => {
                setValue(
                  periodFromInputPath,
                  date as PathValue<
                    FormInputs,
                    FieldPathByValue<FormInputs, Date>
                  >,
                );
                onDateChange?.();
                popoverContentRef.current?.focus();
              }}
            />
            <DatePicker
              {...register(periodToInputPath)}
              sx={{ pr: '8px' }}
              selectedDate={periodTo}
              onChange={(date: Date) => {
                setValue(
                  periodToInputPath,
                  date as PathValue<
                    FormInputs,
                    FieldPathByValue<FormInputs, Date>
                  >,
                );
                onDateChange?.();
                popoverContentRef.current?.focus();
              }}
            />
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
