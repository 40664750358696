import { Box } from '@chakra-ui/react';
import { useMenuBackgroundHoverColor } from 'src/hooks/colors/useMenuBackgroundHoverColor';
import { useMenuFontActiveColor } from 'src/hooks/colors/useMenuFontActiveColor';
import { Icon } from '../Icon';
import { menuOptionClickedEvent } from 'src/libs/amp';

type MenuOptionProps = {
  colorSelected?: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  value: number | string;
  label: string;
  buttonName: string;
  children?: React.ReactNode;
};

export const MenuOption: React.FC<MenuOptionProps> = ({
  colorSelected = false,
  isSelected,
  onClick,
  buttonName,
  value,
  label,
  isDisabled = false,
  children,
}) => {
  const { fontActiveColor } = useMenuFontActiveColor();
  const { hoverColor } = useMenuBackgroundHoverColor();

  const color = isDisabled
    ? 'red'
    : colorSelected && isSelected
      ? fontActiveColor
      : 'inherit';

  return (
    <Box
      p={2}
      onClick={() => {
        if (isDisabled) {
          return;
        }

        onClick();
        menuOptionClickedEvent({
          buttonName,
          value: value.toString(),
          label,
        });
      }}
      color={color}
      cursor={!isDisabled ? 'pointer' : 'default'}
      display="flex"
      textTransform={'none'}
      alignItems="center"
      _hover={{ bg: !isDisabled ? hoverColor : 'inherit' }}>
      <Box
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        w="8"
        pr="2">
        {isSelected && <Icon iconName="check" w="3" />}
      </Box>
      {children ?? label}
    </Box>
  );
};
