import { AggregateLevel, SimplifiedDataState } from 'src/types';
import {
  MMDataState,
  TimeframeData,
  TotalDecompsState,
  TotalDecompsTimeframeData,
} from '../types';

export const getAggregateLevelData = (
  state: MMDataState | SimplifiedDataState,
  aggregateLevel: AggregateLevel,
) => {
  switch (aggregateLevel) {
    case AggregateLevel.day: {
      if ('day' in state) {
        return state.day;
      }
      return state.week; // Default to week if no day data available
    }
    case AggregateLevel.week:
      return state.week;
    case AggregateLevel.month:
      return state.month;
    case AggregateLevel.quarter:
      return state.quarter;
    case AggregateLevel.biannual:
      return state.biannual;
    case AggregateLevel.year:
      return state.year;
  }
};

export const getMMDataStateAggregateLevelData = (
  state: MMDataState,
  aggregateLevel: AggregateLevel,
): Array<TimeframeData> => {
  switch (aggregateLevel) {
    case AggregateLevel.day: {
      return state.week;
    }
    case AggregateLevel.week:
      return state.week;
    case AggregateLevel.month:
      return state.month;
    case AggregateLevel.quarter:
      return state.quarter;
    case AggregateLevel.biannual:
      return state.biannual;
    case AggregateLevel.year:
      return state.year;
  }
};

export const getTotalDecompsStateAggregateLevelData = (
  state: TotalDecompsState,
  aggregateLevel: AggregateLevel,
): Array<TotalDecompsTimeframeData> => {
  switch (aggregateLevel) {
    case AggregateLevel.day: {
      return state.week;
    }
    case AggregateLevel.week:
      return state.week;
    case AggregateLevel.month:
      return state.month;
    case AggregateLevel.quarter:
      return state.quarter;
    case AggregateLevel.biannual:
      return state.biannual;
    case AggregateLevel.year:
      return state.year;
  }
};
