import React from 'react';
import { DeepDive } from 'src/features/DA/components/templates/DeepDive';
import { Page } from '../../components/molecules/Page';
import { DAKeyMetrics } from '../../features/DA/components/organisms/DAKeyMetrics';
import { FilterBar } from '../../components/organisms/FilterBar';

export const DeepDivePage: React.FC = () => {
  return (
    <Page
      filterBar={<FilterBar includeBenchmark />}
      keyMetrics={<DAKeyMetrics />}>
      <DeepDive />
    </Page>
  );
};
