import { createContext, useContext } from 'react';

export type CustomerSettingsContextValue = {
  getMediaName: (mediaName: string) => string;
};

export const CustomerSettingsContext =
  createContext<CustomerSettingsContextValue | null>(null);
CustomerSettingsContext.displayName = 'CustomerSettingsContext';

export function useCustomerSettingsContext() {
  const value = useContext(CustomerSettingsContext);
  if (!value) {
    throw new Error(
      'useCustomerSettingsContext has to be used under CustomerSettingsContextProvider',
    );
  }
  return value;
}
