import { useEffect, useState } from 'react';
import {
  ApiObservedConversionDataPoint,
  useObservedConversionsQuery,
} from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';
import { filterObservedConversionData } from '../../utils/filters';

export type ObsConversionsDataState = {
  data: ApiObservedConversionDataPoint[];
  isError: boolean;
  isLoading: boolean;
};

const INIT_CONVERSIONS_DATA: Readonly<ObsConversionsDataState> = {
  data: [],
  isError: false,
  isLoading: false,
};

// Supposed to only be called from context
export const useFetchObservedConversions = (enabled: boolean) => {
  const {
    currentModelVersion,
    timeRange,
    filteredCampaignIds,
    media,
    campaignsMap,
    benchmarkTimerange,
  } = useFilterContext();

  const [conversions, setConversions] = useState<ObsConversionsDataState>(
    INIT_CONVERSIONS_DATA,
  );

  const [benchmarkConversions, setBenchmarkConversions] =
    useState<ObsConversionsDataState>(INIT_CONVERSIONS_DATA);

  const conversionsQueryBase = {
    customer: currentModelVersion?.customerName ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useObservedConversionsQuery(
    {
      ...conversionsQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Benchmark timerange conversions data
  const benchmarkQueryEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;
  const {
    data: benchmarkData,
    isLoading: isBenchmarkLoading,
    isError: isBenchmarkError,
  } = useObservedConversionsQuery(
    {
      ...conversionsQueryBase,
      ...toApiDates(benchmarkTimerange),
    },
    benchmarkQueryEnabled,
  );

  // Normal data
  useEffect(() => {
    if (!enabled) {
      setConversions(INIT_CONVERSIONS_DATA);
      return;
    }

    const filteredData = filterObservedConversionData(
      apiData ?? [],
      filteredCampaignIds,
      media,
    );

    setConversions({
      data: filteredData,
      isError,
      isLoading,
    });
  }, [
    enabled,
    apiData,
    isError,
    isLoading,
    filteredCampaignIds,
    media,
    campaignsMap,
  ]);

  // Benchmark data
  useEffect(() => {
    if (!benchmarkQueryEnabled) {
      setBenchmarkConversions(INIT_CONVERSIONS_DATA);
      return;
    }

    const fitleredBenchmarkData = filterObservedConversionData(
      benchmarkData ?? [],
      filteredCampaignIds,
      media,
    );

    setBenchmarkConversions({
      data: fitleredBenchmarkData,
      isError: isBenchmarkError,
      isLoading: isBenchmarkLoading,
    });
  }, [
    isBenchmarkLoading,
    isBenchmarkError,
    benchmarkData,
    benchmarkQueryEnabled,
    campaignsMap,
    filteredCampaignIds,
    media,
  ]);

  return {
    observedConversions: conversions,
    benchmarkObservedConversions: benchmarkConversions,
  };
};
