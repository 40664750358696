import React, { useMemo } from 'react';
import { useLTDataContext } from '../../../contexts/LTDataContext';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from '../../../../../components/organisms/KeyMetricChart';

export const Investment: React.FC = () => {
  const { investments, isError, isLoading } = useLTDataContext();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    let sum = 0;
    const data = investments.monthly.map((x) => {
      const value = x.totalInvestment;
      sum += value;
      return {
        utcEpoch: x.utcEpoch,
        value,
      };
    });

    return {
      name: 'Investment',
      data,
      isEuro: true,
      summaryValue: sum,
      isError,
      isLoading,
    };
  }, [investments, isError, isLoading]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
