import { Customer } from 'src/api/queries';

export const getCustomerBrandKeyword = (currentCustomer: Customer) =>
  currentCustomer.sosKeyword ?? currentCustomer.customerName;

export const isKeywordCustomerBrand = (
  keyword: string,
  currentCustomer: Customer,
) => {
  const nameToMatch = getCustomerBrandKeyword(currentCustomer);

  const customer = nameToMatch
    .toLowerCase()
    .replaceAll('_', ' ')
    .replaceAll('-', ' ');

  const lowercaseKeyword = keyword
    .toLowerCase()
    .replaceAll('_', ' ')
    .replaceAll('-', ' ');

  return lowercaseKeyword.includes(customer);
};
