import { Box, Center, Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useKeyMetricsColors } from 'src/hooks/colors/useKeyMetricsColors';
import {
  KeyMetricsDateFormatter,
  KeyMetricsNumberFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';
import { DataError } from '../../molecules/DataStatusWrapper/DataError';
import { DataLoading } from '../../molecules/DataStatusWrapper/DataLoading';
import KeyMetricLineGraph from '../../molecules/graphs/KeyMetricLineGraph';
import { BenchmarkComparison } from './BenchmarkComparison';
import { useDataBoxBackgroundColor } from 'src/hooks/colors/useBackgroundColor';

export type KeyMetricChartDataPoint = {
  utcEpoch: number;
  value?: number;
  benchmark?: number;
};

export type KeyMetricChartData = {
  name: string;
  isLoading: boolean;
  isError: boolean;
  isEuro: boolean;
  summaryValue: number;
  benchmarkSummaryValue?: number;
  data: KeyMetricChartDataPoint[];
  renderChart?: boolean;
};

type KeyMetricChartProps = {
  keyMetricData: KeyMetricChartData;
  dateFormatter?: (value: string | number) => string;
} & FlexProps;

export const KeyMetricChart: React.FC<KeyMetricChartProps> = ({
  keyMetricData,
  dateFormatter = KeyMetricsDateFormatter,
  ...flexProps
}) => {
  const {
    name,
    isError,
    summaryValue,
    isLoading,
    data,
    isEuro,
    benchmarkSummaryValue,
    renderChart = true,
  } = keyMetricData;

  const dataKeys: { xAxis: string; data: string } = {
    xAxis: 'utcEpoch',
    data: 'value',
  };

  const { bgColor } = useDataBoxBackgroundColor();

  const colors = useKeyMetricsColors();

  return (
    <Flex
      key={name}
      h="100%"
      borderWidth="thin"
      borderRadius="4px"
      borderColor={colors.boxBorderColor}
      background={bgColor}
      direction="column"
      {...flexProps}>
      {isError && <DataError />}
      {!isError && isLoading && <DataLoading />}
      {data.length === 0 && renderChart && (
        <Center h="100%">No data available</Center>
      )}
      {!isLoading && !isError && (data.length !== 0 || !renderChart) && (
        <>
          <Box ml="20px" my="16px">
            <Flex
              justifyContent="space-between"
              mb="8px"
              alignItems={'flex-start'}>
              <Flex>
                <Text
                  lineHeight="1"
                  fontSize="32px"
                  color={colors.fontHighContrast}>
                  {KeyMetricsNumberFormatter(summaryValue)[0]}
                </Text>
                <Text
                  mt="auto"
                  textAlign="end"
                  mr="8px"
                  color={colors.fontHighContrast}>
                  {`${KeyMetricsNumberFormatter(summaryValue)[1]}${
                    isEuro ? '€' : ''
                  }`}
                </Text>
              </Flex>
              {benchmarkSummaryValue !== undefined && (
                <Flex alignItems={'flex-end'} flexDir={'column'} mr="8px">
                  <Flex alignItems={'flex-end'}>
                    <Text
                      lineHeight="1"
                      fontSize="18px"
                      color={colors.benchmarkColor}>
                      {KeyMetricsNumberFormatter(benchmarkSummaryValue)[0]}
                    </Text>
                    <Text fontSize="12px" color={colors.benchmarkColor}>
                      {`${KeyMetricsNumberFormatter(benchmarkSummaryValue)[1]}${
                        isEuro ? '€' : ''
                      }`}
                    </Text>
                  </Flex>
                  <BenchmarkComparison
                    fontSize={'12px'}
                    benchmarkSummaryValue={benchmarkSummaryValue}
                    summaryValue={summaryValue}
                  />
                </Flex>
              )}
            </Flex>
            <Text lineHeight="1" fontSize="22px" color={colors.fontPrimary}>
              {name}
            </Text>
          </Box>
          {renderChart && (
            <Box flex="1" mb="12px">
              <KeyMetricLineGraph
                benchmarkEnabled={benchmarkSummaryValue !== undefined}
                dataKeys={dataKeys}
                data={data}
                xAxisFormatter={dateFormatter}
                yAxisFormatter={ValueTickFormatter}
              />
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};
