import { Center, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ChangeClientMenu } from '../../organisms/menus/ChangeClientMenu';
import { UserMenu } from '../../organisms/menus/UserMenu';
import { TOP_BAR_HEIGHT } from './styles';

export const TopNavBar: React.FC<FlexProps> = ({ ...props }) => {
  const bg = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );
  const borderColor = useColorModeValue('border.light.box', 'border.dark.box');

  return (
    <Flex
      as="nav"
      style={{ width: `calc(100% - 80px)` }}
      left="80px"
      h={TOP_BAR_HEIGHT}
      borderBottomWidth="thin"
      borderColor={borderColor}
      bg={bg}
      {...props}>
      <Center flex="1" />
      <Flex align="center" justify="center" pr="40px">
        <ChangeClientMenu />
        <UserMenu />
      </Flex>
    </Flex>
  );
};
