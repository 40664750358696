import { Dayjs, dayjs } from 'src/libs/dayjs';
import { AggregateLevel } from 'src/types';

const DATE_FORMAT = 'YYYY-MM-DD';

type DecomposedDate = {
  year: number;
  month: number;
  weekOfYear: number;
  dayOfYear: number;
};

export const toApiDates = (timerange: {
  start: Dayjs;
  end: Dayjs;
}): {
  from: string;
  to: string;
} => {
  return {
    from: timerange.start.format(DATE_FORMAT),
    to: timerange.end.format(DATE_FORMAT),
  };
};

/**
 * Parses and then formats a date to the desired format.
 * @param {string} format - The format for the date. This needs to be a format supported by dayjs
 * @param {string | number} date - The date string is ISO8601 format or an epoch number. If nothing is passed the current date is used.
 * @returns {string} The correctly formatted date string.
 */
export function formatDate(format: string, date?: string | number): string {
  return dayjs.utc(date).format(format);
}

/**
 * Parses and then decomposes a date to the required pieces. This currently only returns the weekOfYear & Year
 * as that is all that is needed, but it can be easily extended as required.
 * @param {string | number} date - The date string is ISO8601 format or an epoch number. If nothing is passed the current date is used.
 * @returns {DecomposedDate} The decomposed date date object.
 */
export function decomposeDate(date?: string | number): DecomposedDate {
  const parsed = dayjs.utc(date);
  return {
    year: parsed.year(),
    month: parsed.month(),
    dayOfYear: parsed.dayOfYear(),
    weekOfYear: parsed.isoWeek(),
  };
}

function getDateAgo(
  years: number,
  months: number,
  weeks: number,
  days: number,
  fromDate: Dayjs,
): Dayjs {
  return dayjs(fromDate)
    .subtract(years, 'year')
    .subtract(months, 'month')
    .subtract(weeks, 'week')
    .subtract(days, 'day');
}

export function parseToDateString(date?: Date): string {
  return dayjs(date).format(DATE_FORMAT);
}

export function getDaysAgo(numberOfDays: number, fromDate: Dayjs): Dayjs {
  return getDateAgo(0, 0, 0, numberOfDays, fromDate);
}

export function getWeeksAgo(numberOfWeeks: number, fromDate: Dayjs): Dayjs {
  return getDateAgo(0, 0, numberOfWeeks, 0, fromDate);
}

export function getMonthsAgo(numberOfMonths: number, fromDate: Dayjs): Dayjs {
  return getDateAgo(0, numberOfMonths, 0, 0, fromDate);
}

export function getYearsAgo(numberOfYears: number, fromDate: Dayjs): Dayjs {
  return getDateAgo(numberOfYears, 0, 0, 0, fromDate);
}

export function formatEpoch(epoch: number): string {
  return dayjs(epoch).format(DATE_FORMAT);
}

/**
 * Parses a data from the database and returns it as an UTC Epoch in milliseconds since 1970.
 * @param {string} date - The local date in ISO 8601 format.
 * @returns {number} the epoch in milliseconds.
 */
export const dateToEpoch = (date: Dayjs): number => {
  return date.utc().valueOf();
};

/**
 * Finds the start of the day of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getDayStartEpoch = (epoch: number) => {
  return dayjs(epoch).utc().startOf('day').unix() * 1000;
};

/**
 * Finds the start of the week of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getWeekStartEpoch = (epoch: number) => {
  return dayjs(epoch).utc().startOf('week').unix() * 1000;
};

/**
 * Finds the start of the month of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getMonthStartEpoch = (epoch: number) => {
  return dayjs(epoch).utc().startOf('month').unix() * 1000;
};

/**
 * Finds the start of the year of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getYearStartEpoch = (epoch: number) => {
  return dayjs(epoch).utc().startOf('year').unix() * 1000;
};

/**
 * Finds the start of the quarter of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getQuarterStartEpoch = (epoch: number) => {
  return dayjs(epoch).utc().startOf('quarter').unix() * 1000;
};

/**
 * Finds the start of the quarter of the given timestamp and returns the epoch timestamp of that day at 00:00
 * @param {number} epoch - Millisecond epoch
 * @returns {number} Millisecond epoch
 */
export const getBiannualStartEpoch = (epoch: number) => {
  const utcDate = dayjs(epoch).utc();

  // months are 0-11 in dayjs
  const month = utcDate.month();

  // 6 = July
  if (month < 6) {
    return getYearStartEpoch(epoch);
  }

  return utcDate.set('month', 6).startOf('month').unix() * 1000;
};

export const sortEpoch = <A extends { utcEpoch: number }>(a: A, b: A) => {
  if (!Number(a.utcEpoch) || Number(!a.utcEpoch)) {
    return 0;
  }
  return Number(a.utcEpoch) - Number(b.utcEpoch);
};

type AggregateStartEpochFunc = (utcEpoch: number) => number;

export const getAggregateStartEpochFunc = (
  aggregateLevel: AggregateLevel,
): AggregateStartEpochFunc => {
  switch (aggregateLevel) {
    case AggregateLevel.day:
      return getDayStartEpoch;
    case AggregateLevel.week:
      return getWeekStartEpoch;
    case AggregateLevel.month:
      return getMonthStartEpoch;
    case AggregateLevel.biannual:
      return getBiannualStartEpoch;
    case AggregateLevel.quarter:
      return getQuarterStartEpoch;
    case AggregateLevel.year:
      return getYearStartEpoch;
  }
};
