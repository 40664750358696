import { ApiModelVersionDataPoint } from 'src/api/queries';
import { Modal } from 'src/components/atoms/Modal';
import { OptimizationModalContent } from './OptimizationModalContent';

type OptimizationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentModelVersion: { customerName: string } & ApiModelVersionDataPoint;
};

export const OptimizationModal: React.FC<OptimizationModalProps> = ({
  isOpen,
  onClose,
  currentModelVersion,
}) => {
  return (
    <Modal header="Create optimization" isOpen={isOpen} onClose={onClose}>
      <OptimizationModalContent
        currentModel={currentModelVersion}
        onClose={onClose}
      />
    </Modal>
  );
};
