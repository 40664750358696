import { getToggledData } from 'src/utils/getToggleFunctions';
import { SelectableLocation } from '../types';

export const toggleLocations = (
  locationsToBeToggled: Array<SelectableLocation>,
  selectableLocations: Array<SelectableLocation>,
) => {
  return getToggledData(selectableLocations, locationsToBeToggled, (l) =>
    l.locationId.toString(),
  );
};
