import { Box } from '@chakra-ui/react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { KeyMetricChartDataPoint } from 'src/components/organisms/KeyMetricChart';
import {
  useAxisStyles,
  useChartColors,
  useChartDataColors,
} from 'src/hooks/colors/useChartColors';
import { TooltipContent } from '../../atoms/tooltips/TooltipContent';

export interface KeyMetricLineGraphProps {
  dataKeys: { xAxis: string; data: string };
  data: KeyMetricChartDataPoint[];
  xAxisFormatter?: (value: number | string) => string;
  yAxisFormatter?: (value: number) => string;
  benchmarkEnabled: boolean;
}

export default function KeyMetricLineGraph({
  dataKeys,
  data,
  xAxisFormatter = (value: number | string) => value.toString(),
  yAxisFormatter = (value: number) => value.toString(),
  benchmarkEnabled,
}: KeyMetricLineGraphProps) {
  const axisStyles = useAxisStyles();

  const { chartDataColors } = useChartDataColors();

  const { benchmarkLineColor } = useChartColors();

  return (
    <Box w="100%" h="100%">
      <ResponsiveContainer>
        <LineChart data={data}>
          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            offset={25}
            allowEscapeViewBox={{ x: true, y: true }}
            content={
              <TooltipContent
                valueFormatter={yAxisFormatter}
                labelFormatter={xAxisFormatter}
              />
            }
          />
          <XAxis
            dataKey={dataKeys.xAxis}
            minTickGap={20}
            tickFormatter={xAxisFormatter}
            {...axisStyles}
            tickLine={false}
            axisLine={false}
          />
          <Line
            connectNulls
            dataKey={'value'}
            isAnimationActive={false}
            stroke={chartDataColors[0]}
            strokeWidth={2}
            dot={false}
          />
          {benchmarkEnabled && (
            <Line
              connectNulls
              dataKey={'benchmark'}
              isAnimationActive={false}
              stroke={benchmarkLineColor}
              strokeWidth={2}
              dot={false}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
