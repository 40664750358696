import type { GlobalStyleProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

export const styles = {
  global: (props: GlobalStyleProps) => ({
    body: {
      color: mode('text.light.primary', 'text.dark.primary')(props),
      bg: mode('background.light.lowest', 'background.dark.lowest')(props),
    },
  }),
};
