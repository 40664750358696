import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { sortDataWithLocation } from 'src/utils/sort';
import {
  ApiShareOfSearchAggregatedDataPoint,
  useShareOfSearchCitiesQuery,
} from '../api/queries';
import { SelectableLocation, SosLocation } from '../types';
import {
  BenchmarkDates,
  getDefaultBenchmarkDates,
} from '../utils/getDefaultBenchmarkDates';
import { SelectableKeyword } from './useShareOfSearchCountryMonthly';

const mapFilterAndSortCitiesData = (
  citiesData: Array<ApiShareOfSearchAggregatedDataPoint>,
  selectableKeywords: Array<SelectableKeyword>,
  selectableCities: Array<SelectableLocation>,
): Array<ApiShareOfSearchAggregatedDataPoint> => {
  const selectedKeywordsSet = new Set(
    selectableKeywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword),
  );

  const selectableCitiesSet = new Set(
    selectableCities
      .filter(({ selected }) => selected)
      .map(({ locationId }) => locationId),
  );

  const filteredCitiesData = citiesData
    .filter((d) => selectableCitiesSet.has(d.location.locationId))
    .map((d) => {
      return {
        ...d,
        keywords: d.keywords.filter(({ keyword }) =>
          selectedKeywordsSet.has(keyword),
        ),
      };
    });

  const data = sortDataWithLocation(filteredCitiesData);

  return data;
};

export type ShareOfSearchCitiesState = {
  data: Array<ApiShareOfSearchAggregatedDataPoint>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_SHARE_OF_SEARCH_CITIES_STATE: Readonly<ShareOfSearchCitiesState> = {
  data: [],
  isError: false,
  isLoading: false,
};

export const useShareOfSearchCities = (
  enabled: boolean,
  selectableKeywords: Array<SelectableKeyword>,
  selectedCountry: SosLocation,
  selectableCities: Array<SelectableLocation>,
) => {
  const { currentCustomer } = useAppContext();

  const [shareOfSearchCitiesState, setShareOfSearchCitiesState] =
    useState<ShareOfSearchCitiesState>(INIT_SHARE_OF_SEARCH_CITIES_STATE);

  const [citiesBenchmarkDates, setCitiesBenchmarkDates] =
    useState<BenchmarkDates>(getDefaultBenchmarkDates());

  const {
    data: apiData,
    isError,
    isLoading,
  } = useShareOfSearchCitiesQuery(
    {
      customer: currentCustomer?.customerName ?? 'no_customer',
      country_code: selectedCountry.countryCode,
      country_id: selectedCountry.locationId,
      benchmark_from: citiesBenchmarkDates.benchmarkFrom
        .startOf('month')
        .format('YYYY-MM-DD'),
      benchmark_to: citiesBenchmarkDates.benchmarkTo
        .startOf('month')
        .format('YYYY-MM-DD'),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setShareOfSearchCitiesState(INIT_SHARE_OF_SEARCH_CITIES_STATE);
      setCitiesBenchmarkDates(getDefaultBenchmarkDates());
      return;
    }

    const data = mapFilterAndSortCitiesData(
      apiData ?? [],
      selectableKeywords,
      selectableCities,
    );

    setShareOfSearchCitiesState({
      data,
      isLoading,
      isError,
    });
  }, [
    apiData,
    enabled,
    isError,
    isLoading,
    selectedCountry,
    selectableKeywords,
    selectableCities,
  ]);

  return {
    shareOfSearchCitiesState,
    citiesBenchmarkDates,
    setCitiesBenchmarkDates,
  };
};
