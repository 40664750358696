import { createContext, useContext } from 'react';
import { AvailableCountriesState } from '../../hooks/useAvailableCountries';
import { AvailableRegionsAndCitiesState } from '../../hooks/useAvailableRegionsAndCitiesForCountry';
import { ShareOfSearchCitiesState } from '../../hooks/useShareOfSearchCities';
import {
  SelectableKeyword,
  ShareOfSearchMonthlyState,
} from '../../hooks/useShareOfSearchCountryMonthly';
import { ShareOfSearchLocationsAggregateState } from '../../hooks/useShareOfSearchLocationsAggregate';
import { ShareOfSearchRegionsState } from '../../hooks/useShareOfSearchRegions';
import { SelectableLocation, SosLocation } from '../../types';
import { BenchmarkDates } from '../../utils/getDefaultBenchmarkDates';

export type SOSDataContextValue = {
  shareOfSearchCountryMonthlyState: ShareOfSearchMonthlyState;
  shareOfSearchRegionsState: ShareOfSearchRegionsState;
  shareOfSearchCitiesState: ShareOfSearchCitiesState;
  shareOfSearchLocationsAggregateState: ShareOfSearchLocationsAggregateState;
  availableRegionsAndCitiesState: AvailableRegionsAndCitiesState;
  selectableAggregateLocations: Array<SelectableLocation>;
  toggleAggregateLocations: (
    locationsToBeToggled: Array<SelectableLocation>,
  ) => void;
  toggleKeywords: (keywordsToBeToggled: Array<SelectableKeyword>) => void;
  toggleCities: (citiesToBeToggled: Array<SelectableLocation>) => void;
  toggleRegions: (citiesToBeToggled: Array<SelectableLocation>) => void;
  selectedCountry: SosLocation;
  selectedKeywords: Array<string>;
  setSelectedCountry: (country: SosLocation) => void;
  availableCountriesState: AvailableCountriesState;
  citiesBenchmarkDates: BenchmarkDates;
  regionsBenchmarkDates: BenchmarkDates;
  setCitiesBenchmarkDates: (benchmarkDates: BenchmarkDates) => void;
  setRegionsBenchmarkDates: (benchmarkDates: BenchmarkDates) => void;
  highlightedKeywordsForMap: Array<SelectableKeyword>;
  toggleHighlightedKeywordsForMap: (
    keywordsToBeToggled: Array<SelectableKeyword>,
  ) => void;
};

export const SOSDataContext = createContext<SOSDataContextValue | null>(null);
SOSDataContext.displayName = 'SOSDataContext';

export function useSOSDataContext() {
  const value = useContext(SOSDataContext);
  if (!value) {
    throw new Error(
      'useSOSDataContext has to be used under SOSDataContextProvider',
    );
  }
  return value;
}
