import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { DataTable } from 'src/components/molecules/DataTable';
import { useMetricTableColumns } from '../../hooks/useMetricTableColumns';
import { useMetrics } from '../../hooks/useMetrics';

export const MetricsByChannelTable: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { data, isError, isLoading } = useMetrics('byChannel');

  const columns = useMetricTableColumns('Channel');
  return (
    <DataBox
      {...props}
      exportEnabled
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <DataTable csvFileName="media-coverage" data={data} columns={columns} />
    </DataBox>
  );
};
