import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import 'src/libs/sentry';

import '@fontsource/nunito-sans';
import 'mapbox-gl/dist/mapbox-gl.css';

import { App } from './app';
import { AppContextProvider } from './contexts/AppContext/AppContextProvider';
import { AuthContextProvider } from './contexts/AuthContext/AuthContextProvider';
import theme from './theme';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);

/**
 * A note on the provider order:
 * AuthContextProvider is responsible for fetching & refreshing JWT
 * The JWT is required in the QueryClientProvider, this must be a child of the AuthContextProvider
 * The AppContextProvider uses queries from the QueryClientProvider and requires information from the AuthContextProvider.
 * The FilterContextProvider uses queries from the QueryClientProvider and data from the AppContextProvider.
 */
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthContextProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
