export const INVESTMENT_KEY = 'Investment';

export const getInvestmentKey = (mediaName: string) => {
  return `${INVESTMENT_KEY}_${mediaName}`;
};

export const parseKeyForInvestmentMedia = (key: string) => {
  const split = key.split(`${INVESTMENT_KEY}_`);
  if (split.length === 2) {
    return split[1];
  }
  return null;
};

export const mapByMediaSpendToInvestmentKeys = (
  renameByChannel: (
    byChannel: Record<string, number>,
  ) => Record<string, number>,
  byMedia: Record<string, number>,
) => {
  const renamedByMedia = renameByChannel(byMedia);

  const withInvestmentKey: Record<string, number> = {};

  Object.entries(renamedByMedia).forEach(([mediaName, spend]) => {
    withInvestmentKey[getInvestmentKey(mediaName)] = spend;
  });

  return withInvestmentKey;
};
