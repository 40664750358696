import { Box, Flex } from '@chakra-ui/react';
import {
  floatFormatter,
  intEuroFormatter,
} from 'src/components/molecules/DataTable/cellFuncs';
import { Optimization } from 'src/features/MO/types';
import { useTableEffectFormatter } from 'src/hooks/useEffectFormatter';
import {
  CONSTRAINT_WIDTH,
  INVESTMENT_WIDTH,
  MEDIA_EFFECT_WIDTH,
  MR,
  NAME_WIDTH,
  PERIOD_WIDTH,
  ROI_OR_ROMI_WIDTH,
} from '../OptimizationHeader/sizeConstants';

type OptimizationRowProps = {
  optimization: Optimization;
};

export const OptimizationRow: React.FC<OptimizationRowProps> = ({
  optimization,
}) => {
  const effectFormatter = useTableEffectFormatter();

  return (
    <Flex textAlign={'left'} whiteSpace={'nowrap'} flex={1}>
      <Box mr={MR} width={NAME_WIDTH}>
        {optimization.optimizationName}
      </Box>
      <Box mr={MR} width={PERIOD_WIDTH}>{`${optimization.period.from.format(
        'YYYY-MM-DD',
      )} - ${optimization.period.to.format('YYYY-MM-DD')}`}</Box>
      <Box mr={MR} width={INVESTMENT_WIDTH}>
        {intEuroFormatter(optimization.optimizedInvestmentSum)}
      </Box>
      <Box mr={MR} width={MEDIA_EFFECT_WIDTH}>
        {effectFormatter(optimization.optimizedEffectSum)}
      </Box>
      {optimization.totalRomi && (
        <Box mr={MR} width={ROI_OR_ROMI_WIDTH}>
          {floatFormatter(optimization.totalRomi.optimized)}
        </Box>
      )}
      <Box mr={MR} width={ROI_OR_ROMI_WIDTH}>
        {floatFormatter(optimization.totalRoi.optimized)}
      </Box>
      <Box width={CONSTRAINT_WIDTH}>{optimization.constraints.length}</Box>
    </Flex>
  );
};
