import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Search } from 'src/features/DA/components/templates/Search';
import { FilterHeading } from '../../components/atoms/FilterHeading';
import { OrganicSearchMenu } from '../../features/DA/components/molecules/OrganicSearchMenu';
import { Page } from '../../components/molecules/Page';
import { DAKeyMetrics } from '../../features/DA/components/organisms/DAKeyMetrics';
import { FilterBar } from '../../components/organisms/FilterBar';
import { useDADataContext } from '../../features/DA/contexts/DADataContext';

export const SearchPage: React.FC = () => {
  const { organicSearch } = useDADataContext();

  return (
    <Page
      filterBar={
        <FilterBar
          includeBenchmark
          additionalTopFilters={
            organicSearch.data.length > 0 ? (
              <Flex direction="row" pl="40px" alignItems="flex-end">
                <FilterHeading headingText="Organic search" />
                <OrganicSearchMenu />
              </Flex>
            ) : undefined
          }
        />
      }
      keyMetrics={<DAKeyMetrics />}>
      <Search />
    </Page>
  );
};
