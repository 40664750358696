import { calculateCostPerUnit } from 'src/utils/calculateCostPerUnit';
import { sortEpoch } from 'src/utils/date';
import { MMDataState, TimeframeData } from '../types';

const calculateCPUFromRecords = (
  byXInvestments: Record<string, number>,
  byXDecomps: Record<string, number>,
): Record<string, number> => {
  const resultRecord: Record<string, number> = {};

  Object.entries(byXInvestments).forEach(([key, investmentValue]) => {
    const decompsValue = byXDecomps[key];

    if (decompsValue === undefined) {
      return;
    }

    resultRecord[key] = calculateCostPerUnit({
      investment: investmentValue,
      mediaIncrementalSales: decompsValue,
    });
  });

  return resultRecord;
};

type CombineTimeframeDataParams = {
  investmentData: Array<TimeframeData>;
  decompsData: Array<TimeframeData>;
};
const combineTimeframeData = ({
  decompsData,
  investmentData,
}: CombineTimeframeDataParams): Array<TimeframeData> => {
  const byUtcEpoch: Record<
    number,
    {
      investment: TimeframeData | null;
      decomps: TimeframeData | null;
    }
  > = {};

  const initByUtcEpochRecordIfNecessary = (utcEpoch: number) => {
    if (!byUtcEpoch[utcEpoch]) {
      byUtcEpoch[utcEpoch] = {
        investment: null,
        decomps: null,
      };
    }
  };

  investmentData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].investment = datapoint;
  });

  decompsData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].decomps = datapoint;
  });

  const timeframeData: Array<TimeframeData> = [];

  Object.entries(byUtcEpoch).forEach(([utcEpoch, { investment, decomps }]) => {
    if (!investment || !decomps) {
      return;
    }

    const value = calculateCostPerUnit({
      investment: investment.value,
      mediaIncrementalSales: decomps.value,
    });

    const d: TimeframeData = {
      byCampaignId: calculateCPUFromRecords(
        investment.byCampaignId,
        decomps.byCampaignId,
      ),
      byMedia: calculateCPUFromRecords(investment.byMedia, decomps.byMedia),
      byTag: calculateCPUFromRecords(investment.byTag, decomps.byTag),
      byCategory: calculateCPUFromRecords(
        investment.byCategory,
        decomps.byCategory,
      ),
      utcEpoch: Number(utcEpoch),
      value,
    };

    timeframeData.push(d);
  });

  return timeframeData.sort(sortEpoch);
};

type AggregateCostPerUnitParams = {
  investments: MMDataState;
  decomps: MMDataState;
};

export const aggregateCostPerUnit = ({
  decomps,
  investments,
}: AggregateCostPerUnitParams): MMDataState => {
  const isLoading = investments.isLoading || decomps.isLoading;
  const isError = investments.isError || decomps.isError;

  const dataState: MMDataState = {
    week: combineTimeframeData({
      investmentData: investments.week,
      decompsData: decomps.week,
    }),
    month: combineTimeframeData({
      investmentData: investments.month,
      decompsData: decomps.month,
    }),
    quarter: combineTimeframeData({
      investmentData: investments.quarter,
      decompsData: decomps.quarter,
    }),
    biannual: combineTimeframeData({
      investmentData: investments.biannual,
      decompsData: decomps.biannual,
    }),
    year: combineTimeframeData({
      investmentData: investments.year,
      decompsData: decomps.year,
    }),
    total: calculateCostPerUnit({
      investment: investments.total,
      mediaIncrementalSales: decomps.total,
    }),
    totalByCategory: calculateCPUFromRecords(
      investments.totalByCategory,
      decomps.totalByCategory,
    ),
    totalByCampaignId: calculateCPUFromRecords(
      investments.totalByCampaignId,
      decomps.totalByCampaignId,
    ),
    totalByMedia: calculateCPUFromRecords(
      investments.totalByMedia,
      decomps.totalByMedia,
    ),
    totalByTag: calculateCPUFromRecords(
      investments.totalByTag,
      decomps.totalByTag,
    ),
    isLoading,
    isError,
  };

  return dataState;
};
