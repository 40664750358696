import React, { FC } from 'react';
import { Flex, Heading, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export interface NoSubscriptionPageProps {
  service?: string;
}

const NoSubscriptionPage: FC<NoSubscriptionPageProps> = ({ service }) => {
  const bg = useColorModeValue(
    'background.light.lowest',
    'background.dark.lowest',
  );

  return (
    <Flex w="100%" h="100vh" direction="row" background={bg}>
      <Flex
        flex="1"
        h="100%"
        w="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px="40px"
        py="24px">
        <Flex direction="column" justifyContent="flexStart">
          {service && (
            <Heading as="h1" size="3xl" mb="24px">
              {service}
            </Heading>
          )}
          <Heading as="p" size="lg" mb={4}>
            You currently are not subscribed to this service.
          </Heading>
          <Text as="p" size="xl">
            You can contact Omnicom Media Group Finland to upgrade your account.
          </Text>
          <Text as="p" size="xl">
            Use the menu to select a different service{' '}
            <Link as={RouterLink} to="/">
              or return to the dashboard.
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NoSubscriptionPage;
