import { Box, Flex } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { PieChart } from 'src/components/molecules/graphs/PieChart';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { RESPONSE_CURVES_DEF } from 'src/utils/metricDefinitions';
import { SShapeInsightsCurveChart } from './SShapeInsightsCurveChart';

const dataKeysPie = { name: 'name', data: 'data' };

export const SShapeInsightsBox: React.FC<DataBoxBaseProps> = ({ ...props }) => {
  const { getMediaName } = useCustomerSettingsContext();
  const { mediaColorMap } = useFilterContext();

  const { responseCurve } = useMMDataContext();

  const [infoOpen, setInfoOpen] = useState(false);
  const [lineDataChannel, setLineDataChannel] = useState<string | null>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const pieOnClick = (channelName: string, index: number) => {
    setLineDataChannel(lineDataChannel === channelName ? null : channelName);
    setActiveIndex(index);
  };

  const responseCurveData = responseCurve.data;
  const isLoading = responseCurve.isLoading;
  const isError = responseCurve.isError;

  const pieData = useMemo(() => {
    return responseCurveData.map((x) => ({
      name: getMediaName(x.channel),
      data: x.meanSpend,
    }));
  }, [responseCurveData, getMediaName]);

  const legendProps: DataBoxLegendProps = {
    placement: 'none',
    lineKeys: responseCurveData.map((d) => getMediaName(d.channel)),
    keyColorMap: mediaColorMap,
  };

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={RESPONSE_CURVES_DEF}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        exportEnabled
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        hasData={responseCurveData.length > 0}
        isError={isError}
        isLoading={isLoading}>
        <Flex direction="row" w="100%" h="100%">
          <Box flex="1">
            <PieChart
              colorMap={mediaColorMap}
              data={pieData}
              dataKeys={dataKeysPie}
              onClick={pieOnClick}
              activeIndex={activeIndex}
            />
          </Box>
          <Box flex="2">
            <SShapeInsightsCurveChart activeIndex={activeIndex} />
          </Box>
        </Flex>
      </DataBox>
    </>
  );
};
