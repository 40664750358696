import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'src/api/queries';
import { useChartDataColors } from 'src/hooks/colors/useChartColors';
import { Media } from 'src/types';
import { sortByName } from 'src/utils/sort';
import { useCustomerSettingsContext } from '../CustomerSettingsContext';
import { ModelVersion } from './useModelVersions';

type MediaState = {
  media: Media[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_MEDIA_STATE: Readonly<MediaState> = {
  media: [],
  isLoading: false,
  isError: false,
};

export const useMedia = (currentModelVersion: ModelVersion | null) => {
  const { getMediaName } = useCustomerSettingsContext();
  const { chartDataColors } = useChartDataColors();

  const [filterLocked, setFilterLocked] = useState(false);

  const [mediaState, setMediaState] = useState<MediaState>(INIT_MEDIA_STATE);
  const [mediaColorMap, setMediaColorMap] = useState<Record<string, string>>(
    {},
  );

  const lockedMediasRef = useRef<Array<Media>>([]);

  const {
    data: mediaData,
    isLoading: mediaLoading,
    isError: mediaError,
  } = useMediaQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
    },
    !!currentModelVersion,
  );

  useEffect(() => {
    if (!currentModelVersion) {
      setMediaState(INIT_MEDIA_STATE);
      setMediaColorMap({});
      return;
    }

    const lockedIdsRecordSet = new Set(
      lockedMediasRef.current.map((m) => m.id),
    );

    const fetchedData = mediaData ?? [];

    const newIdSet = new Set<string>();

    const media = fetchedData.map((m) => {
      const selected = !filterLocked || lockedIdsRecordSet.has(m);
      const name = getMediaName(m);

      newIdSet.add(m);

      return {
        id: m,
        selected,
        name,
        isAvailable: true,
      };
    });

    if (filterLocked) {
      lockedMediasRef.current.forEach((m) => {
        if (!newIdSet.has(m.id)) {
          media.push({
            ...m,
            isAvailable: false,
            selected: false,
          });
        }
      });
    }

    setMediaState({
      media: sortByName(media),
      isError: mediaError,
      isLoading: mediaLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaData, mediaLoading, mediaError, getMediaName]);

  useEffect(() => {
    if (!mediaData) {
      return;
    }
    const newColorMap: Record<string, string> = {};

    mediaData.forEach((m, i) => {
      newColorMap[getMediaName(m)] = chartDataColors[i];
    });

    setMediaColorMap(newColorMap);
  }, [mediaData, chartDataColors, getMediaName]);

  const setMedia = (media: Media[]) => {
    setMediaState((curr) => {
      return {
        ...curr,
        media,
      };
    });
  };

  useEffect(() => {
    if (!filterLocked) {
      lockedMediasRef.current = [];
      return;
    }

    if (mediaState.media.length === 0) {
      return;
    }

    // We want to keep the previous not available media still in the locked medias ref
    lockedMediasRef.current = mediaState.media.filter(
      (c) => c.selected || !c.isAvailable,
    );
  }, [filterLocked, mediaState.media]);

  // Needs another useEffect vs using one above to avoid a recursive setMedia
  useEffect(() => {
    if (!filterLocked) {
      const availableMedia = mediaState.media.filter((m) => m.isAvailable);

      setMedia(availableMedia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLocked]);

  return {
    mediaLocked: filterLocked,
    setMediaLocked: setFilterLocked,
    media: mediaState.media,
    mediaColorMap,
    setMedia,
  };
};
