import { PropsWithChildren, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useFetchSales } from 'src/hooks/fetch/useFetchSales';
import { DADataContext } from '.';

import { useFetchInvestments } from '../../hooks/fetch/useFetchInvestments';
import { useFetchModelledConversions } from '../../hooks/fetch/useFetchModelledConversions';
import { useFetchObservedConversions } from '../../hooks/fetch/useFetchObservedConversions';
import { useFetchOrganicSearch } from '../../hooks/fetch/useFetchOrganicSearch';
import { useConversions } from '../../hooks/useConversions';

export function DADataContextProvider(props: PropsWithChildren) {
  const { subscriptionLevels } = useAppContext();
  const { currentModelVersion, benchmarkTimerange } = useFilterContext();

  // Make sure we only run queries if customers have the correct subscriptions enabled
  // and a model version is selected (so that there are no errors & data is available)
  const enabled = subscriptionLevels.da && !!currentModelVersion;

  const benchmarkEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;

  const { investments, benchmarkInvestments } = useFetchInvestments(enabled);
  const { sales, benchmarkSales } = useFetchSales(enabled);

  const { modelledConversions, benchmarkModelledConversions } =
    useFetchModelledConversions(enabled);

  const { observedConversions, benchmarkObservedConversions } =
    useFetchObservedConversions(enabled);

  const { conversions } = useConversions(
    enabled,
    investments,
    modelledConversions,
    observedConversions,
  );

  const { conversions: benchmarkConversions } = useConversions(
    benchmarkEnabled,
    benchmarkInvestments,
    benchmarkModelledConversions,
    benchmarkObservedConversions,
  );

  const {
    organicSearch,
    benchmarkOrganicSearch,
    toggleAllKeywordClusters,
    toggleKeywordCluster,
    keywordClusterFilters,
  } = useFetchOrganicSearch(enabled);

  const value = useMemo(() => {
    return {
      sales,
      investments,
      benchmarkInvestments,
      conversions,
      organicSearch,
      toggleAllKeywordClusters,
      toggleKeywordCluster,
      keywordClusterFilters,
      benchmarkConversions,
      benchmarkSales,
      benchmarkOrganicSearch,
    };
  }, [
    sales,
    investments,
    benchmarkInvestments,
    conversions,
    organicSearch,
    toggleKeywordCluster,
    toggleAllKeywordClusters,
    keywordClusterFilters,
    benchmarkConversions,
    benchmarkSales,
    benchmarkOrganicSearch,
  ]);

  return (
    <DADataContext.Provider value={value}>
      {props.children}
    </DADataContext.Provider>
  );
}
