import { Dayjs, dayjs } from 'src/libs/dayjs';

export type BenchmarkDates = {
  benchmarkFrom: Dayjs;
  benchmarkTo: Dayjs;
};

export const getDefaultBenchmarkDates = (): BenchmarkDates => {
  const benchmarkTo = dayjs().subtract(13, 'month').startOf('month');
  const benchmarkFrom = dayjs().subtract(4, 'year').startOf('month');

  return { benchmarkFrom, benchmarkTo };
};

export const benchmarkDatesAreDefault = (benchmarkDates: BenchmarkDates) => {
  const {
    benchmarkFrom: defaultBenchmarkFrom,
    benchmarkTo: defaultBenchmarkTo,
  } = getDefaultBenchmarkDates();

  const startDate = benchmarkDates.benchmarkFrom;
  const endDate = benchmarkDates.benchmarkTo;

  return (
    startDate.isSame(defaultBenchmarkFrom, 'day') &&
    endDate.isSame(defaultBenchmarkTo, 'day')
  );
};
