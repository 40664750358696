import { Box, Flex, FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/atoms/Button';
import { useFilterContext } from 'src/contexts/FilterContext';
import { OptimizationFormInputs } from '../OptimizationModal/OptimizationModalContent';
import { ConstraintRow } from './ContraintRow';

export const Constraints: React.FC = () => {
  const { watch, control } = useFormContext<OptimizationFormInputs>();

  const { media } = useFilterContext();

  const periodStart = watch('periodStart');
  const periodEnd = watch('periodEnd');

  const { append, fields, remove } = useFieldArray<OptimizationFormInputs>({
    name: 'constraints',
    control,
  });

  const onAddConstraintClick = () => {
    append({
      media: '',
      period: {
        from: periodStart,
        to: periodEnd,
        entirePeriod: true,
      },
      type: 'FIXED',
      value: null,
    });
  };

  return (
    <Flex flexDir={'column'} mt="20px">
      <Box fontSize={'18px'} mb="16px">
        Do you want to add constraints to this optimization?
      </Box>
      <Grid templateColumns="repeat(4, 1fr) auto" columnGap={6} rowGap={[2]}>
        {fields.length > 0 && (
          <>
            <GridItem>
              <FormLabel>Type</FormLabel>
            </GridItem>
            <GridItem>
              <FormLabel>Media</FormLabel>
            </GridItem>
            <GridItem>
              <FormLabel>Investment</FormLabel>
            </GridItem>
            <GridItem colSpan={2}>
              <FormLabel>Period</FormLabel>
            </GridItem>
          </>
        )}

        {fields.map((field, index) => {
          return (
            <ConstraintRow
              onRemove={remove}
              mediaOptions={media}
              key={field.id}
              index={index}
            />
          );
        })}
      </Grid>

      <Box mt="20px">
        <Button buttonName="opt-add-constraint" onClick={onAddConstraintClick}>
          Add constraint
        </Button>
      </Box>
    </Flex>
  );
};
