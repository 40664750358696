import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { DataBoxesContainer } from 'src/components/atoms/DataBoxesContainer';
import { TOP_BAR_HEIGHT } from 'src/components/templates/TopNavBar/styles';

type PageProps = {
  filterBar: React.ReactElement;
  keyMetrics?: JSX.Element;
  children: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({
  filterBar,
  keyMetrics,
  children,
}) => {
  const bg = useColorModeValue(
    'background.light.lowest',
    'background.dark.lowest',
  );

  // Scroll to top when switching tabs
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Flex w="100%" direction="column" background={bg}>
      <Box zIndex={1} top={TOP_BAR_HEIGHT} pos="sticky">
        {filterBar}
      </Box>
      <Flex flex="1" direction="column" px="40px" py="24px">
        {keyMetrics}
        <DataBoxesContainer>{children}</DataBoxesContainer>
      </Flex>
    </Flex>
  );
};
