import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import {
  FieldPathByValue,
  FieldValues,
  get,
  useFormContext,
} from 'react-hook-form';

type FormSelectProps<
  FormInputs extends FieldValues,
  OptValueType extends string,
> = {
  inputPath: FieldPathByValue<FormInputs, OptValueType>;
  required?: boolean;
  placeholder?: string;
  options: Array<{ value: OptValueType; label: string }>;
  sx?: FormControlProps;
};

export function FormSelect<
  FormInputs extends FieldValues,
  OptValueType extends string,
>({
  inputPath,
  required,
  placeholder,
  options,
  sx,
}: FormSelectProps<FormInputs, OptValueType>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const error = get(errors, inputPath);

  return (
    <FormControl isInvalid={!!error} {...sx}>
      <Select
        {...register(inputPath, {
          required: required ? 'Required' : false,
        })}
        placeholder={placeholder}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      {error?.message && typeof error.message === 'string' && (
        <FormErrorMessage>{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
}
