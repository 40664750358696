import { Flex, Menu } from '@chakra-ui/react';
import { FilterHeading } from 'src/components/atoms/FilterHeading';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { getAggregationName } from 'src/features/MM/utils/getAggregationName';
import { AggregateLevel } from 'src/types';

const aggregateOptions: Array<AggregateLevel> = [
  AggregateLevel.week,
  AggregateLevel.month,
  AggregateLevel.quarter,
  AggregateLevel.biannual,
  AggregateLevel.year,
];

const MENU_BUTTON_NAME = 'aggregate-select';

const AggregationMenu: React.FC = () => {
  const { aggregateLevel, setAggregateLevel } = useAggregationLevelContext();

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME}>
        {getAggregationName(aggregateLevel)}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME}>
        {aggregateOptions.map((aggLevel) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            colorSelected
            key={aggLevel}
            value={aggLevel}
            label={getAggregationName(aggLevel)}
            isSelected={aggregateLevel === aggLevel}
            onClick={() => setAggregateLevel(aggLevel)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
export const AggregationSelector: React.FC = () => {
  return (
    <>
      <FilterHeading headingText="Aggregation" />
      <Flex direction={'row'}>
        <AggregationMenu />
      </Flex>
    </>
  );
};
