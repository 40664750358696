import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { DatePicker } from 'src/components/atoms/DatePicker';

type DateRangeSelectorProps = {
  selectedStartDate: Date;
  selectedEndDate: Date;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  additionalDateRangeButton: React.ReactNode | null;
  children: React.ReactNode;
};

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  minDate,
  maxDate,
  selectedStartDate,
  selectedEndDate,
  onDateRangeChange,
  additionalDateRangeButton,
  children,
}) => {
  // Needed for popever to not lose focus on some cases of changing date.
  // Without this in certain cases the datepicker no longer closes
  const popoverContentRef = React.useRef<HTMLElement>(null);

  const bgColor = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );

  return (
    <Popover matchWidth={false} isLazy>
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <PopoverContent w="100%" ref={popoverContentRef}>
        <Flex bg={bgColor} flexDir={'column'} p="4px">
          {additionalDateRangeButton}
          <Flex>
            <DatePicker
              sx={{ pr: '8px' }}
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedStartDate}
              onChange={(updatedStart: Date) => {
                onDateRangeChange(updatedStart, selectedEndDate);
                popoverContentRef.current?.focus();
              }}
            />
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedEndDate}
              onChange={(updatedEnd: Date) => {
                onDateRangeChange(selectedStartDate, updatedEnd);
                popoverContentRef.current?.focus();
              }}
            />
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
