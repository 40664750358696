import { extendTheme } from '@chakra-ui/react';
import { Alert, Input, Link, Menu, Slider, Table } from './components';
/*
  The following has been adapted from these Reference docs:

  https://chakra-ui.com/docs/features/color-mode
  https://chakra-ui.com/docs/theming/theme
  https://github.com/chakra-ui/chakra-ui/issues/591#issuecomment-719751857

  The layout for the theme customization uses recommendations from Chakra on
  a more scalable structure:

  https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
*/
import { styles } from './styles';

export const baseColorsForCharts = [
  '#2C3865',
  '#EAA16F',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#FF8000',
  '#8000FF',
  '#00FF80',
  '#FF0080',
  '#0080FF',
  '#80FF00',
  '#800080',
  '#FF80FF',
  '#80FFFF',
  '#FFC0CB',
  '#B0E0E6',
  '#DDA0DD',
  '#8B008B',
  '#556B2F',
  '#008000',
  '#800000',
  '#000080',
  '#FFA500',
  '#A0522D',
  '#008080',
  '#FFD700',
  '#FF69B4',
  '#4B0082',
  '#FFFFF0',
  '#F0E68C',
  '#E6E6FA',
  '#FF7F50',
  '#00FF7F',
  '#FFB6C1',
  '#9400D3',
  '#8B0000',
  '#FF6347',
  '#DC143C',
  '#000000',
  '#696969',
  '#808080',
  '#A9A9A9',
  '#C0C0C0',
  '#D3D3D3',
  '#F5F5F5',
  '#B22222',
  '#FA8072',
  '#FFA07A',
  '#BC8F8F',
  '#BEBEBE',
  '#7B68EE',
  '#9370DB',
  '#9400D3',
  '#FF1493',
  '#CD5C5C',
  '#FF4500',
  '#FAEBD7',
  '#8B4513',
  '#2F4F4F',
  '#7FFF00',
  '#00CED1',
  '#20B2AA',
  '#191970',
  '#4B0082',
  '#FF8C00',
  '#696969',
  '#008B8B',
  '#00FA9A',
  '#808000',
  '#008080',
  '#483D8B',
  '#6495ED',
  '#00BFFF',
  '#1E90FF',
  '#87CEEB',
  '#ADD8E6',
  '#4169E1',
  '#0000CD',
  '#00008B',
  '#B0C4DE',
  '#F0F8FF',
  '#AFEEEE',
  '#E0FFFF',
  '#FAF0E6',
  '#FFF5EE',
  '#F5DEB3',
  '#FFFACD',
  '#F0FFF0',
  '#FFFFE0',
  '#FAFAD2',
  '#FFE4B5',
  '#FFE4C4',
  '#FFDAB9',
  '#FFEBCD',
  '#FFEFD5',
  '#FFF8DC',
  '#F5F5DC',
  '#FFFFF0',
];

const lightColors = baseColorsForCharts.map((c, i) => {
  if (i < 2) {
    return c;
  }
  return `${c}BB`;
});

const darkColors = baseColorsForCharts.map((c, i) => {
  if (i < 2) {
    return c;
  }
  return `${c}66`;
});

export const benchmarkColor = '#E36E34';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
  fonts: {
    heading: 'Nunito Sans, sans-serif',
    body: 'Nunito Sans, sans-serif',
  },
  colors: {
    background: {
      light: {
        highest: '#FFFFFF',
        lowest: '#F2F2F2',
        hover: '#F2F2F2',
        error: '#A13D61',
      },
      dark: {
        highest: '#1C1C1C',
        lowest: '#121212',
        hover: '#202020',
        error: '#A13D61',
      },
      paleGrey: '#F0F2F6',
    },
    border: {
      light: {
        box: '#DCE1EA',
        error: '#A13D61',
        focus: '#644CC3',
      },
      dark: {
        box: '#404040',
        error: '#CC6478',
        focus: '#644CC3',
      },
    },
    text: {
      light: {
        primary: '#414B64',
        secondary: '#6B758F',
        highContrast: '#14171F',
        benchmark: benchmarkColor,
        active: '#3D8EB1',
        error: '#A13D61',
        errorBox: '#FFFFFF',
      },
      dark: {
        primary: '#C0C1C7',
        secondary: '#838593',
        highContrast: '#ECEDEE',
        benchmark: benchmarkColor,
        active: '#3D8EB1',
        error: '#CC6478',
        errorBox: '#ECEDEE',
      },
    },
    icon: {
      light: {
        color: '#A0ADC5',
        activeColor: '#14171F',
        background: '#FFFFFF',
        activeBackground: '#F0F2F6',
        hoverBackground: '#F5F7F9',
      },
      dark: {
        color: '#4F4F4F',
        activeColor: '#ECEDEE',
        background: '#1B1B1B',
        activeBackground: '#383838',
        hoverBackground: '#282828',
      },
    },
    button: {
      light: {
        500: '#414B64',
        600: '#6B758F',
        700: '#CCD3E0',
      },
      dark: {
        200: '#C7C9CE',
        300: '#ECEDEE',
        400: '#838593',
      },
    },
    // Colors that can be used in charts, from bottom to top.
    chart: {
      grid: {
        light: '#E5E9F0',
        dark: '#383838',
      },
      dataColors: {
        light: lightColors,
        dark: darkColors,
      },
      benchmarkLine: benchmarkColor,
      // For scatter/line charts /Pie charts
      line: {
        light: lightColors,
        dark: darkColors,
      },
      // Bar
      bar: {
        light: lightColors,
        dark: darkColors,
      },
    },
    red: '#CC6478',
    green: '#90C95C',
    omnicomBlue: '#2C3865',
  },
  styles,
  components: {
    Alert,
    Button: {
      baseStyle: {
        borderRadius: '4px',
      },
    },

    Input,
    Link,
    Menu,
    Table,
    Slider,
  },
});

export default theme;
