import { Text } from '@chakra-ui/react';
import React from 'react';

export type TitleProps = {
  title?: string;
  children?: React.ReactNode;
};

export const Title: React.FC<TitleProps> = ({ title, children }) => {
  return (
    <Text fontSize={'sm'} casing="uppercase">
      {title}
      {children}
    </Text>
  );
};
