import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { Button } from 'src/components/atoms/Button';
import { ErrorNotifBox } from 'src/components/atoms/ErrorNotifBox';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';
import { FormikField } from './types';
import { validateEmail } from './validators';

type ForgotPasswordSendCodeProps = {
  email?: string;
  onSubmit: (email: string) => void;
};

/**
 * This component shows is required when a user needs to set a new password
 * (usually at first sign in with a new account).
 */
export const ForgotPasswordSendCode: FC<ForgotPasswordSendCodeProps> = ({
  email,
  onSubmit,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const errorMessageColor = useFontErrorColor();

  return (
    <Formik
      initialValues={{
        email: email || '',
      }}
      onSubmit={async (values) => {
        try {
          setErrorMessage(null);
          await onSubmit(values.email);
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          }
        }
      }}>
      {(props) => (
        <Form>
          <Field name="email" validate={validateEmail}>
            {({ field, form }: FormikField) => (
              <FormControl
                my={4}
                isInvalid={form.errors.email && form.touched.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  {...field}
                  id="email"
                  placeholder="first.last@email.com"
                />
                <FormErrorMessage color={errorMessageColor}>
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          {errorMessage && <ErrorNotifBox title={errorMessage} />}
          <Flex direction="row" justifyContent="flex-end">
            <Button
              buttonName="reset-password"
              mt={4}
              isLoading={props.isSubmitting}
              type="submit">
              Reset Password
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
