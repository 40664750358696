import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { InvestmentsTimeframeData } from '../types';

export const getInvestmentsTimeFrameData = (
  data: Record<number, Array<ApiLongTermEffectsDataPoint>>,
): Array<InvestmentsTimeframeData> => {
  return Object.entries(data).map(([utcEpoch, dataPoints]) => {
    let totalInvestment = 0;

    const byMedia: Record<string, number> = {};

    dataPoints.forEach(({ mediaLongTermEffects }) => {
      mediaLongTermEffects.forEach(({ media, spend }) => {
        totalInvestment += spend;
        if (!byMedia[media]) {
          byMedia[media] = 0;
        }

        byMedia[media] += spend;
      });
    });

    return {
      utcEpoch: Number(utcEpoch),
      totalInvestment,
      byMedia,
    };
  });
};
