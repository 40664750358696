import React from 'react';
import { MetricsByChannelTable } from '../../organisms/MetricsByChannelTable';
import { MetricsByChannelBarGraph } from '../../organisms/MetricsByChannelBarGraph';

export const Channels: React.FC = () => {
  return (
    <>
      <MetricsByChannelBarGraph
        minH={400}
        title="Sessions overview"
        colSpan={12}
      />
      <MetricsByChannelTable title="Campaign explorer" colSpan={12} />
    </>
  );
};
