import { useEffect, useState } from 'react';
import { useTotalDecompsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';
import { INIT_TOTAL_DECOMPS_STATE, TotalDecompsState } from '../../types';
import { aggregateTotalDecomps } from '../../utils/aggregateTotalDecomps';

export function useFetchTotalDecomps(enabled: boolean) {
  const { currentModelVersion, timeRange } = useFilterContext();

  const [totalDecomps, setTotalDecomps] = useState<TotalDecompsState>(
    INIT_TOTAL_DECOMPS_STATE,
  );

  const { data, isLoading, isError } = useTotalDecompsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      model_id: currentModelVersion?.modelID ?? 'missing_modelid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setTotalDecomps(INIT_TOTAL_DECOMPS_STATE);
      return;
    }

    const totalDecompsState = aggregateTotalDecomps(
      data ?? [],
      isLoading,
      isError,
    );

    setTotalDecomps(totalDecompsState);
  }, [data, isLoading, isError, enabled]);

  return {
    totalDecomps,
  };
}
