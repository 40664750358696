import Fuse from 'fuse.js';

export const searchResults = <D>(
  data: Array<D>,
  searchTerm: string,
  keys: Array<keyof D>,
): Array<D> => {
  if (searchTerm.trim() === '') {
    return data;
  }

  const fuse = new Fuse(data, {
    keys: keys.map((k) => k.toString()),
    threshold: 0.2,
    distance: 1000,
  });

  return fuse.search(searchTerm).map((d) => d.item);
};
