import { Flex } from '@chakra-ui/react';
import { CloseButton } from 'src/components/molecules/CloseButton';
import { ExportCsvButton } from 'src/components/molecules/ExportCsv';

type DataTableModalHeaderProps = {
  onCloseClick: () => void;
  headerName: string;
};

export const DataTableModalHeader: React.FC<DataTableModalHeaderProps> = ({
  onCloseClick,
  headerName,
}) => {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'}>
      <Flex>{headerName}</Flex>
      <Flex fontSize={'16px'} alignItems={'center'}>
        <ExportCsvButton />
        <CloseButton ml="40px" onClick={onCloseClick} />
      </Flex>
    </Flex>
  );
};
