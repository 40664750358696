import { useMemo } from 'react';
import { DataTableProps } from 'src/components/molecules/DataTable';
import { DataTableModal } from 'src/components/organisms/DataTableModal';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';
import {
  TableModalRow,
  useColumns,
} from 'src/features/MM/hooks/useTableModalColumns';
import { getAggregationName } from 'src/features/MM/utils/getAggregationName';
import { getDimensionName } from 'src/features/MM/utils/getDimensionName';
import { isNonNullable } from 'src/utils/Data';
import { TrendsChartDataRow } from '../../organisms/TrendsChartBox/useChartData';

type TrendsDataTableModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  data: Array<TrendsChartDataRow>;
  yFormatter: (value: number | string) => string;
};

export const TrendsDataTableModal: React.FC<TrendsDataTableModalProps> = ({
  setIsOpen,
  isOpen,
  data,
  yFormatter,
}) => {
  const { dimension } = useSelectedDimensionContext();
  const { aggregateLevel } = useAggregationLevelContext();

  const csvFileName = `${getDimensionName(dimension)} - ${getAggregationName(
    aggregateLevel,
  )}`;

  const dateFormatter = useGetDateAxisFormatter();

  const columns = useColumns(yFormatter);

  const dataTableProps = useMemo(() => {
    const columnKeys = columns
      .map((a) => {
        if ('accessorKey' in a) {
          return a.accessorKey;
        }
        return null;
      })
      .filter(isNonNullable);

    const props: DataTableProps<TableModalRow> = {
      columns,
      data: data.map(({ utcEpoch, ...restOfData }) => {
        const record = {
          date: dateFormatter(utcEpoch),
        } as TableModalRow;

        Object.entries(restOfData).forEach(([key, value]) => {
          if (columnKeys.some((c) => c === key)) {
            record[key] = value;
          }
        });

        return record;
      }),
      csvFileName,
    };

    return props;
  }, [columns, data, csvFileName, dateFormatter]);

  return (
    <DataTableModal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      headerName={csvFileName}
      dataTableProps={dataTableProps}
    />
  );
};
