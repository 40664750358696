import { createContext, useContext } from 'react';
import { AggregateLevel } from 'src/types';

export type AggregationLevelContextValue = {
  aggregateLevel: AggregateLevel;
  setAggregateLevel: (level: AggregateLevel) => void;
};

export const AggregationLevelContext =
  createContext<AggregationLevelContextValue | null>(null);
AggregationLevelContext.displayName = 'AggregationLevelContext';

export function useAggregationLevelContext() {
  const value = useContext(AggregationLevelContext);
  if (!value) {
    throw new Error(
      'useAggregationLevelContext has to be used under AggregationLevelContextProvider',
    );
  }
  return value;
}
