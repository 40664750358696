import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { AggregateLevel } from 'src/types';
import { useMMDataContext } from '../../../contexts/MMDataContext';

type MediaInvestmentProps = {
  aggregateLevel: AggregateLevel;
  dateFormatter: (value: string | number) => string;
};

export const MediaInvestment: React.FC<MediaInvestmentProps> = ({
  aggregateLevel,
  dateFormatter,
}) => {
  const { investments } = useMMDataContext();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    return {
      isLoading: investments.isLoading,
      isError: investments.isError,
      name: 'Media Investment',
      isEuro: true,
      summaryValue: investments.total,
      data: getAggregateLevelData(investments, aggregateLevel),
    };
  }, [investments, aggregateLevel]);

  return (
    <KeyMetricChart
      keyMetricData={keyMetricData}
      dateFormatter={dateFormatter}
    />
  );
};
