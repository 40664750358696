import { useMutation } from 'react-query';
import { ApiOptimizationDataPoint, OptimizationConstraint } from './queries';
import request from './request';

export type CreateOptimizationRequest = {
  customer: string;
  optimizationName: string;
  modelBuild: string;
  modelId: string;
  targetInvestment: number;
  period: {
    from: string;
    to: string;
  };
  constraints: Array<OptimizationConstraint>;
};

type CreateOptimizationResponse = { data: ApiOptimizationDataPoint };

export type DeleteOptimizationRequest = {
  customer: string;
  optimizationId: string;
};

// Backend returns empty object but mutation returns optimizationId
export type DeleteOptimizationResponse = {
  optimizationId: string;
};

export function useCreateOptimizationMutation() {
  return useMutation((data: CreateOptimizationRequest) => {
    const { customer, ...bodyData } = data;

    return request
      .post<CreateOptimizationResponse>('/data/optimizations', bodyData, {
        params: {
          customer,
        },
      })
      .then((response) => {
        return response.data.data;
      });
  });
}

export function useDeleteOptimizationMutation() {
  return useMutation(
    ({ customer, optimizationId }: DeleteOptimizationRequest) => {
      return request
        .delete<DeleteOptimizationResponse>('/data/optimizations', {
          params: {
            customer,
            optimization_id: optimizationId,
          },
        })
        .then(() => {
          return { optimizationId };
        });
    },
  );
}
