import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Icon } from './Icon';

type SearchBoxProps = {
  value: string;
  onChange: (value: string) => void;
};
export const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    if (value === '') {
      return;
    }
    onChange('');
    inputRef.current?.focus();
  };

  return (
    <InputGroup pb="2" size="sm">
      <InputLeftAddon onClick={() => inputRef.current?.focus()}>
        <Icon iconName="search" />
      </InputLeftAddon>
      <Input
        ref={inputRef}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {value && (
        <InputRightAddon cursor={'pointer'} onClick={onClose}>
          <Icon iconName="close" width={2} />
        </InputRightAddon>
      )}
    </InputGroup>
  );
};
