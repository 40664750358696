import { Customer } from 'src/api/queries';
import { DataBoxLegendProps } from 'src/components/molecules/DataBox';
import { getCustomerBrandKeyword } from 'src/features/SOS/utils/isKeywordCustomerBrand';
import { cleanCustomerName } from 'src/utils/cleanCustomerName';

export const OTHERS = 'OTHERS';
export const CUSTOMER = 'CUSTOMER';
export const DIFF_IN_SEARCH_VOLUME = 'DIFF_IN_SEARCH_VOLUME';

export const getLegendProps = (
  currentCustomer: Customer | null,
): DataBoxLegendProps => {
  const usedCustomerSosName = currentCustomer
    ? getCustomerBrandKeyword(currentCustomer)
    : '';

  return {
    placement: 'right',
    baseLegendKeys: [CUSTOMER, OTHERS],
    lineKeys: [DIFF_IN_SEARCH_VOLUME],
    labelMap: {
      [OTHERS]: 'Others',
      [DIFF_IN_SEARCH_VOLUME]:
        '% Difference in Search volume from the Previous Year of Data',
      [CUSTOMER]: cleanCustomerName(usedCustomerSosName) ?? '',
    },
    keyColorMap: {
      [OTHERS]: 'rgb(184,176,173)',
      [CUSTOMER]: '#EAA16F',
    },
    minWidth: '120px',
  };
};
