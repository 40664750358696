import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { CSVLink } from 'react-csv';
import { Icon } from 'src/components/atoms/Icon';
import { useExportCsvContext } from './ExportCsvContext';

export type SupportedCsvRowObject = Record<
  string,
  undefined | string | null | number
>;

export const ExportCsvButton: React.FC = () => {
  const { fileName, csvData } = useExportCsvContext();

  if (fileName === '') {
    return null;
  }

  return (
    <Flex ml="20px" justifyContent="flex-end">
      <CSVLink
        className="hidden"
        filename={`${fileName}.csv`}
        data={csvData}
        target="_blank">
        <Flex alignItems="center">
          <Icon iconName="download" mr="3" />
          <Text>Export CSV</Text>
        </Flex>
      </CSVLink>
    </Flex>
  );
};
