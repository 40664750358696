import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { BenchmarkDateRangeSelector } from '../../atoms/BenchmarkDateRangeSelector';

export const CitiesBenchmarkSelector: React.FC = () => {
  const { citiesBenchmarkDates, setCitiesBenchmarkDates } = useSOSDataContext();

  return (
    <BenchmarkDateRangeSelector
      benchmarkDates={citiesBenchmarkDates}
      onBenchmarkDatesChange={setCitiesBenchmarkDates}
    />
  );
};
