import React from 'react';
import { SOSFilterBar } from 'src/features/SOS/components/organisms/SOSFilterBar';
import { Overview } from 'src/features/SOS/components/templates/Overview';
import { Page } from '../../components/molecules/Page';

export const OverviewPage: React.FC = () => {
  return (
    <Page filterBar={<SOSFilterBar displayLocationMenus={false} />}>
      <Overview />
    </Page>
  );
};
