/* eslint-disable no-restricted-imports */
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialog as ChakraAlertDialog,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Button } from '../Button';

export type ActionAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  actionName: string;
  onActionClick: () => void;
  actionColor: string;
  headerText: string;
  bodyText: string;
};

export const ActionAlert: React.FC<ActionAlertProps> = ({
  isOpen,
  onClose,
  onActionClick,
  actionName,
  actionColor,
  headerText,
  bodyText,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const actionClick = () => {
    onClose();
    onActionClick();
  };

  return (
    <ChakraAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody>{bodyText}</AlertDialogBody>
          <AlertDialogFooter>
            <Button buttonName="alert-cancel" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              buttonName={`alert-${actionName}`}
              color={actionColor}
              onClick={actionClick}
              ml={3}>
              {actionName}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
