import { useMemo } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { CampaignTag, CampaignTagGroup } from 'src/types';
import { sortByName } from 'src/utils/sort';

const getUniqueTags = (tags: CampaignTag[]): CampaignTag[] => {
  const tagsRecord: Record<string, CampaignTag> = {};

  tags.forEach((tag) => {
    const existingTag = tagsRecord[tag.name];

    if (!existingTag) {
      tagsRecord[tag.name] = tag;
      return;
    }
  });

  return Object.values(tagsRecord);
};

export const useAvailableTags = () => {
  const { campaignTagGroups } = useFilterContext();

  return useMemo(() => {
    const tags: CampaignTag[] =
      campaignTagGroups
        .map((group: CampaignTagGroup) => group.tags)
        .flat()
        .filter((t: CampaignTag) => t.visible) ?? [];

    const uniqueTags: CampaignTag[] = sortByName(getUniqueTags(tags));
    const selectedTags = uniqueTags
      .filter((t) => t.selected)
      .map((t) => t.name);

    return { uniqueTags, selectedTags };
  }, [campaignTagGroups]);
};
