import { SimplifiedDataState, SimplifiedTimeframeData } from 'src/types';
import {
  getBiannualStartEpoch,
  getMonthStartEpoch,
  getQuarterStartEpoch,
  getWeekStartEpoch,
  getYearStartEpoch,
  sortEpoch,
} from './date';

export type EpochApiData<K extends string> = {
  utcEpoch: number;
} & Record<K, number>;

type ByEpoch<K extends string> = Record<number, Array<EpochApiData<K>>>;

const byEpochToTimeframeData = <K extends string>(
  key: K,
  byEpoch: ByEpoch<K>,
): Array<SimplifiedTimeframeData> => {
  const timeframeData: Array<SimplifiedTimeframeData> = [];

  Object.entries(byEpoch).forEach(([utcEpoch, datapoints]) => {
    const d: SimplifiedTimeframeData = {
      utcEpoch: Number(utcEpoch),
      value: 0,
    };

    datapoints.forEach((datapoint) => {
      d.value += datapoint[key];
    });

    timeframeData.push(d);
  });

  return timeframeData.sort(sortEpoch);
};

export const aggregateSimpleData = <K extends string>(
  key: K,
  filteredData: Array<EpochApiData<K>>,
  isLoading: boolean,
  isError: boolean,
) => {
  const byWeek: ByEpoch<K> = {};
  const byMonth: ByEpoch<K> = {};
  const byQuarter: ByEpoch<K> = {};
  const byBiannual: ByEpoch<K> = {};
  const byYear: ByEpoch<K> = {};

  let total = 0;

  filteredData.forEach((utcDatapoint) => {
    total += utcDatapoint[key];

    const utcEpoch = utcDatapoint.utcEpoch;
    const weekEpoch = getWeekStartEpoch(utcEpoch);
    const monthEpoch = getMonthStartEpoch(utcEpoch);
    const quarterEpoch = getQuarterStartEpoch(utcEpoch);
    const biannualEpoch = getBiannualStartEpoch(utcEpoch);
    const yearEpoch = getYearStartEpoch(utcEpoch);

    // Week data
    if (!byWeek[weekEpoch]) {
      byWeek[weekEpoch] = [];
    }
    byWeek[weekEpoch].push(utcDatapoint);

    // Month data
    if (!byMonth[monthEpoch]) {
      byMonth[monthEpoch] = [];
    }
    byMonth[monthEpoch].push(utcDatapoint);

    // Quarterly data
    if (!byQuarter[quarterEpoch]) {
      byQuarter[quarterEpoch] = [];
    }
    byQuarter[quarterEpoch].push(utcDatapoint);

    // Biannual data
    if (!byBiannual[biannualEpoch]) {
      byBiannual[biannualEpoch] = [];
    }
    byBiannual[biannualEpoch].push(utcDatapoint);

    // Yearly data
    if (!byYear[yearEpoch]) {
      byYear[yearEpoch] = [];
    }
    byYear[yearEpoch].push(utcDatapoint);
  });

  const dataState: SimplifiedDataState = {
    isLoading,
    isError,
    day: filteredData.map((d) => ({
      utcEpoch: d.utcEpoch,
      value: d[key],
    })),
    week: byEpochToTimeframeData(key, byWeek),
    month: byEpochToTimeframeData(key, byMonth),
    quarter: byEpochToTimeframeData(key, byQuarter),
    biannual: byEpochToTimeframeData(key, byBiannual),
    year: byEpochToTimeframeData(key, byYear),
    total,
  };

  return dataState;
};
