import React, { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { LineGraph } from 'src/components/molecules/graphs/LineGraph';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { useMergeBenchmarkTimeseries } from 'src/hooks/useMergeBenchmarkTimeseries';
import {
  KeyMetricsDateFormatter,
  MonthYearDateFormatter,
  WeekYearDateFormatter,
} from 'src/utils/TickFormatters';
import { TimePeriod, TimePeriodTabs } from '../molecules/TimePeriodTabs';
import { useDADataContext } from '../../contexts/DADataContext';
import { useBenchmarkEnabled } from '../../hooks/useBenchmarkEnabled';
import { MODELLED_CONV_NAME } from '../../utils/constants';

type ProcessedDataPoint = {
  utcEpoch: number;
  [MODELLED_CONV_NAME]?: number;
  Benchmark?: number;
};

export const ConversionsOTLineGraph: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { conversions, benchmarkConversions } = useDADataContext();
  const { conversionsBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMergedTimeSeries } = useMergeBenchmarkTimeseries();

  const valueTickFormatter = useChartEffectTickFormatter();

  const processedTableData = useMemo(() => {
    const daily: Array<ProcessedDataPoint> = getMergedTimeSeries(
      conversions.data,
      benchmarkConversions.data,
      (v) => ({ [MODELLED_CONV_NAME]: v.measurements.modelledConversions }),
      (v) => ({ Benchmark: v.measurements.modelledConversions }),
      conversionsBenchmarkEnabled,
      'day',
    );
    const weekly: Array<ProcessedDataPoint> = getMergedTimeSeries(
      conversions.weekly,
      benchmarkConversions.weekly,
      (v) => ({ [MODELLED_CONV_NAME]: v.measurements.modelledConversions }),
      (v) => ({ Benchmark: v.measurements.modelledConversions }),
      conversionsBenchmarkEnabled,
      'week',
    );

    const monthly: Array<ProcessedDataPoint> = getMergedTimeSeries(
      conversions.monthly,
      benchmarkConversions.monthly,
      (v) => ({ [MODELLED_CONV_NAME]: v.measurements.modelledConversions }),
      (v) => ({ Benchmark: v.measurements.modelledConversions }),
      conversionsBenchmarkEnabled,
      'month',
    );

    return {
      daily,
      weekly,
      monthly,
    };
  }, [
    conversions,
    benchmarkConversions,
    conversionsBenchmarkEnabled,
    getMergedTimeSeries,
  ]);

  const dataKeys = {
    xAxis: 'utcEpoch',
    data: [MODELLED_CONV_NAME],
  };

  if (conversionsBenchmarkEnabled) {
    dataKeys.data.push('Benchmark');
  }

  const [timePeriod, setTimePeriod] = useState<TimePeriod>('daily');

  let data: object[];
  let xAxisFormatter: (epoch: number | string) => string;
  switch (timePeriod) {
    case 'daily':
      data = processedTableData.daily;
      xAxisFormatter = KeyMetricsDateFormatter;
      break;
    case 'weekly':
      data = processedTableData.weekly;
      xAxisFormatter = WeekYearDateFormatter;
      break;
    case 'monthly':
      data = processedTableData.monthly;
      xAxisFormatter = MonthYearDateFormatter;
      break;
  }

  const legendProps: DataBoxLegendProps = {
    placement: 'none',
    lineKeys: dataKeys.data,
  };

  return (
    <DataBox
      {...props}
      legendProps={legendProps}
      tabs={
        <TimePeriodTabs
          onTimePeriodSelect={setTimePeriod}
          selectedTimePeriod={timePeriod}
        />
      }
      hasData={data.length > 0}
      isError={conversions.isError}
      isLoading={conversions.isLoading}>
      <LineGraph
        benchmarkKey={'Benchmark'}
        data={data}
        xAxisKey={dataKeys.xAxis}
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={valueTickFormatter}
      />
    </DataBox>
  );
};
