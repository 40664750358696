import numbro from 'numbro';
import locale from 'numbro/languages/fi-FI';
import { dayjs } from 'src/libs/dayjs';

// Uses finnish standards for thousand separator
const finnishLocale = locale;
finnishLocale.delimiters.decimal = '.';
numbro.registerLanguage(locale);
numbro.setLanguage('fi-FI');

export type CellFunc<A extends string | number> = (info: {
  getValue: () => A | null | undefined;
}) => string;

export const identityCellFunc: CellFunc<string | number> = ({ getValue }) => {
  const value = getValue();

  if (value === null || value === undefined) {
    return '-';
  }
  return String(value);
};

export const dateCellFunc: CellFunc<string | number> = ({ getValue }) => {
  const value = getValue();
  return value === null ? '-' : dayjs.utc(value).format('DD/MM/YYYY');
};

export const intFormatter = (value: number) => {
  const int = Math.round(value);
  return numbro(int).format({ thousandSeparated: true });
};

export const intCellFunc: CellFunc<number> = ({ getValue }) => {
  const value = getValue();
  if (value === null || value === undefined) {
    return '-';
  }
  return intFormatter(value);
};

export const intEuroFormatter = (value: number) => {
  return `${intFormatter(value)}€`;
};

export const intEuroCellFunc: CellFunc<number> = ({ getValue }) => {
  const value = getValue();
  if (value === null || value === undefined) {
    return '-';
  }

  return intEuroFormatter(value);
};

export const floatFormatter = (value: number) => {
  return numbro(value).format({ thousandSeparated: true, mantissa: 2 });
};

export const floatCellFunc: CellFunc<number> = ({ getValue }) => {
  const value = getValue();
  if (value === null || value === undefined) {
    return '-';
  }

  return floatFormatter(value);
};

export const floatEuroCellFunction: CellFunc<number> = ({ getValue }) => {
  const value = getValue();
  if (value === null || value === undefined) {
    return '-';
  }

  return `${floatFormatter(value)}€`;
};

export const pctCellFormatter: CellFunc<number> = ({ getValue }) => {
  const value = getValue();
  if (value === null) {
    return '-';
  }

  return `${floatCellFunc({ getValue })}%`;
};

export type FormatterFunc = (value: number | string) => string;

export const formatterToCellFunc = (
  formatterFunc: FormatterFunc,
): CellFunc<number | string> => {
  return ({ getValue }) => {
    const value = getValue();
    if (value === null || value === undefined) {
      return '-';
    }

    return formatterFunc(value);
  };
};
