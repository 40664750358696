import { dayjs } from 'src/libs/dayjs';
import { sortEpoch } from 'src/utils/date';
import { KeywordsWithEpoch } from '../types';
import { getLast12MonthsEpochs } from './getLast12MonthsEpochs';

export type Keyword = string;

export type SosMonthlyMovingAverageData = {
  utcEpoch: number;
  absoluteValues: Record<Keyword, number>;
  absoluteMovingAverages: Record<Keyword, number | null>;
  shareOfSearchValues: Record<Keyword, number>;
  shareOfSearchMovingAverages: Record<Keyword, number | null>;
  hasMovingAverageData: boolean; // Quick way to filter away data without moving average data available if need be
};

export const mapLocationDataToMovingAverageData = <A extends KeywordsWithEpoch>(
  data: Array<A>,
): Array<SosMonthlyMovingAverageData> => {
  const results: Array<SosMonthlyMovingAverageData> = [];
  const monthCounts: Record<number, { month: number; searchVolume: number }> =
    {};

  const keywordMonthlySearchAndEarliestVolume: Record<
    string,
    {
      earliestMonth: number;
      monthlyData: Record<number, number>;
    }
  > = {};

  data.forEach(({ utcEpoch, keywords }) => {
    const datapoint: SosMonthlyMovingAverageData = {
      utcEpoch,
      absoluteMovingAverages: {},
      absoluteValues: {},
      shareOfSearchMovingAverages: {},
      shareOfSearchValues: {},
      hasMovingAverageData: false,
    };

    if (!monthCounts[utcEpoch]) {
      monthCounts[utcEpoch] = {
        month: utcEpoch,
        searchVolume: 0,
      };
    }

    keywords.forEach(({ keyword, searchVolume }) => {
      monthCounts[utcEpoch].searchVolume += searchVolume;
      datapoint.absoluteValues[keyword] = searchVolume;

      if (!keywordMonthlySearchAndEarliestVolume[keyword]) {
        keywordMonthlySearchAndEarliestVolume[keyword] = {
          earliestMonth: utcEpoch,
          monthlyData: {},
        };
      }

      keywordMonthlySearchAndEarliestVolume[keyword].monthlyData[utcEpoch] =
        searchVolume;

      if (
        keywordMonthlySearchAndEarliestVolume[keyword].earliestMonth > utcEpoch
      ) {
        keywordMonthlySearchAndEarliestVolume[keyword].earliestMonth = utcEpoch;
      }
    });

    results.push(datapoint);
  });

  return results
    .map(({ utcEpoch, absoluteValues }) => {
      const absoluteMovingAverages: Record<string, number | null> = {};
      const shareOfSearchMovingAverages: Record<string, number | null> = {};
      const shareOfSearchValues: Record<string, number> = {};
      const monthsSearchSumForAllKeywords = monthCounts[utcEpoch].searchVolume;

      const monthsKeywords = Object.keys(absoluteValues);

      // 11 months as we include the current month in calculation
      const yearBackDate = dayjs(utcEpoch)
        .utc()
        .subtract(11, 'month')
        .startOf('month');

      const yearBackUtcEpoch = yearBackDate.valueOf();

      // Used later to calculate moving averages
      let yearlySumForAllKeywords = 0;

      const monthlyEpochs = getLast12MonthsEpochs(utcEpoch);

      let hasMovingAverageData = true;

      for (const monthEpoch of monthlyEpochs) {
        const monthCount = monthCounts[monthEpoch];
        if (monthCount !== undefined) {
          const searchVolume = monthCounts[monthEpoch]?.searchVolume ?? 0;
          yearlySumForAllKeywords += searchVolume;
        } else {
          hasMovingAverageData = false;
        }
      }

      for (const keyword of monthsKeywords) {
        const monthSearchVolume = absoluteValues[keyword];

        const keywordsData = keywordMonthlySearchAndEarliestVolume[keyword];

        shareOfSearchValues[keyword] =
          monthsSearchSumForAllKeywords > 0
            ? (100 * monthSearchVolume) / monthsSearchSumForAllKeywords
            : 0;

        const earliestAvailableData = keywordsData.earliestMonth;

        // Calculate moving averages only for keywords that have data far back enough
        if (yearBackUtcEpoch >= earliestAvailableData) {
          let yearlySumForKeyword = 0;
          for (const monthEpoch of monthlyEpochs) {
            const searchVolume = keywordsData.monthlyData[monthEpoch] ?? 0;
            yearlySumForKeyword += searchVolume;
          }

          const movingAverage = yearlySumForKeyword / 12;
          const shareOfSearchMovingAverage =
            yearlySumForAllKeywords > 0
              ? (100 * yearlySumForKeyword) / yearlySumForAllKeywords
              : null;

          absoluteMovingAverages[keyword] = movingAverage;
          shareOfSearchMovingAverages[keyword] = shareOfSearchMovingAverage;
        } else {
          absoluteMovingAverages[keyword] = null;
          shareOfSearchMovingAverages[keyword] = null;
        }
      }

      return {
        utcEpoch,
        absoluteValues,
        absoluteMovingAverages,
        shareOfSearchMovingAverages,
        shareOfSearchValues,
        hasMovingAverageData,
      };
    })
    .sort(sortEpoch);
};
