import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';
import { MODELLED_CONV_NAME } from 'src/features/DA/utils/constants';

export type MetricOption = 'modelledConversions' | 'spend';

const tabs: Array<TabOption<MetricOption>> = [
  {
    label: 'Spend',
    value: 'spend',
  },
  {
    label: MODELLED_CONV_NAME,
    value: 'modelledConversions',
  },
];

type MediaMatrixTabsProps = {
  selectedTab: MetricOption;
  onSelect: (value: MetricOption) => void;
};

export const MediaMatrixTabs: React.FC<MediaMatrixTabsProps> = ({
  onSelect,
  selectedTab,
}) => {
  return <Tabs selectedTab={selectedTab} onSelect={onSelect} options={tabs} />;
};
