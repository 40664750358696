export function isSearchChannel(channel: string): boolean {
  const channelLowercase = channel.toLowerCase();
  return (
    channelLowercase.includes('adwords') ||
    channelLowercase.includes('google ads') ||
    channelLowercase.includes('googleads') ||
    channelLowercase.includes('bing')
  );
}

export const isNonNullable = <T>(item: T): item is NonNullable<T> =>
  item !== undefined && item !== null;
