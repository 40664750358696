import { Text, TextProps } from '@chakra-ui/react';

type BenchmarkComparisonProps = {
  summaryValue: number;
  benchmarkSummaryValue: number;
} & TextProps;

export const BenchmarkComparison: React.FC<BenchmarkComparisonProps> = ({
  summaryValue,
  benchmarkSummaryValue,
  ...txtProps
}) => {
  if (benchmarkSummaryValue === 0) {
    return null;
  }

  const pct = 100 * (summaryValue / benchmarkSummaryValue - 1);

  return <Text {...txtProps}>{`${pct > 0 ? '+' : ''}${pct.toFixed(1)}%`}</Text>;
};
