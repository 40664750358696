import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useDADataContext } from '../../contexts/DADataContext';

const MENU_BUTTON_NAME = 'organic-search-select';

// Note, only useable in DA
export const OrganicSearchMenu: React.FC = () => {
  const {
    keywordClusterFilters,
    toggleKeywordCluster,
    toggleAllKeywordClusters,
  } = useDADataContext();

  const keywords: string[] = Object.keys(keywordClusterFilters);
  const selectedKeywords: string[] = Object.entries(keywordClusterFilters)
    .filter(([, val]) => val)
    .map(([key]) => key);

  const onAllClick = () => {
    toggleAllKeywordClusters();
  };

  return (
    <Menu>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px" fontSize="16px">
        {selectedKeywords.length}/{keywords.length}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME} onAllClick={onAllClick}>
        {keywords.map((keyword) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={keyword}
            value={keyword}
            isSelected={selectedKeywords.includes(keyword)}
            onClick={() => toggleKeywordCluster(keyword)}
            label={keyword}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
