import { Navigate, Route, Routes } from 'react-router-dom';
import NoSubscriptionPage from '../NoSubscriptionPage';
import { MunicipalitiesPage } from './Municipalities';
import { OverviewPage } from './Overview';
import { SOSDataContextProvider } from 'src/features/SOS/contexts/SOSDataContext/SOSDataContextProvider';

type ShareOfSearchPagesProps = {
  isSubscribed: boolean;
};

export const ShareOfSearchPages: React.FC<ShareOfSearchPagesProps> = ({
  isSubscribed,
}) => {
  if (!isSubscribed) {
    return <NoSubscriptionPage service="Share Of Search" />;
  }

  return (
    <SOSDataContextProvider>
      <Routes>
        <Route element={<OverviewPage />} path="overview" />
        <Route element={<MunicipalitiesPage />} path="municipalities" />
        <Route
          element={<Navigate replace to="/share-of-search/overview" />}
          path="/"
        />
      </Routes>
    </SOSDataContextProvider>
  );
};
