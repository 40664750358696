/* eslint-disable no-restricted-imports */
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { buttonClickedEvent } from 'src/libs/amp';

type Variant = 'solid' | 'outline' | 'ghost' | 'link';

export type ButtonProps = Omit<ChakraButtonProps, 'colorScheme' | 'variant'> & {
  variant?: Variant;
  buttonName: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, buttonName, onClick, ...btnProps }, ref) => {
    const buttonColorScheme = useColorModeValue('button.light', 'button.dark');

    return (
      <ChakraButton
        ref={ref}
        alignSelf={'baseline'}
        onClick={(e) => {
          if (onClick) {
            buttonClickedEvent({
              buttonName,
            });
            onClick(e);
          }
        }}
        colorScheme={buttonColorScheme}
        {...btnProps}>
        {children}
      </ChakraButton>
    );
  },
);

Button.displayName = 'Button';
