import { useEffect, useState } from 'react';
import { useFetchMargins } from 'src/features/MM/hooks/fetch/useFetchMargins';
import { useFetchSales } from 'src/hooks/fetch/useFetchSales';
import { getMarginPct } from 'src/utils/marginPct';

// We assume margin to be constant for the model. In the future this should probably be calcualted and queried directly from the backend
export const useModelMarginPct = (enabled: boolean): null | number => {
  const { margins } = useFetchMargins(enabled);
  const { sales } = useFetchSales(enabled);

  const [marginPct, setMarginPct] = useState<number | null>(null);

  useEffect(() => {
    if (!enabled) {
      setMarginPct(null);
      return;
    }

    if (
      margins.isLoading ||
      margins.isError ||
      sales.isLoading ||
      sales.isError
    ) {
      setMarginPct(null);
      return;
    }

    const marginTotal = margins.total;

    if (marginTotal === 0) {
      setMarginPct(null);
      return;
    }

    const salesTotal = sales.total;

    setMarginPct(getMarginPct(marginTotal, salesTotal));
  }, [margins, sales, enabled]);

  return marginPct;
};
