import { createContext, useContext } from 'react';
import { SupportedCsvRowObject } from '..';

export type ExportNameAndData = {
  fileName: string;
  csvData: Array<SupportedCsvRowObject>;
};

export type ExportCsvContextValue = ExportNameAndData & {
  setExportData: (data: ExportNameAndData) => void;
};

export const ExportCsvContext = createContext<ExportCsvContextValue>({
  fileName: '',
  csvData: [],
  setExportData: () => ({}),
});

ExportCsvContext.displayName = 'ExportCsvContext';

export function useExportCsvContext() {
  const value = useContext(ExportCsvContext);
  if (!value) {
    throw new Error('useExportCsvContext has to be used under FilterProvider');
  }
  return value;
}
