import { ApiOptimizationDataPoint } from 'src/api/queries';
import { dayjs } from 'src/libs/dayjs';
import { calculateRoi } from 'src/utils/calculateRoi';
import { calculateRomi } from 'src/utils/calculateRomi';
import { Optimization, OptimziationDataPoint } from '../types';

export const mapApiOptimizationToOptimization = (
  apiOptimization: ApiOptimizationDataPoint,
  marginPct: number | null,
  getChannelName: (channel: string) => string,
): Optimization => {
  let initialEffectSum = 0;
  let optimizedEffectSum = 0;
  let initialInvestmentSum = 0;
  let optimizedInvestmentSum = 0;

  const data: Array<OptimziationDataPoint> = apiOptimization.data.map((d) => {
    initialEffectSum += d.initialEffect;
    optimizedEffectSum += d.optimizedEffect;
    initialInvestmentSum += d.initialInvestment;
    optimizedInvestmentSum += d.optimizedInvestment;

    const initialRoi = calculateRoi({
      mediaIncrementalSales: d.initialEffect,
      investment: d.initialInvestment,
      customer: null,
    });
    const optimizedRoi = calculateRoi({
      mediaIncrementalSales: d.optimizedEffect,
      investment: d.optimizedInvestment,
      customer: null,
    });

    const optimizationDataPoint: OptimziationDataPoint = {
      ...d,
      channelName: getChannelName(d.channel),
      roi: {
        initial: initialRoi,
        optimized: optimizedRoi,
      },
      romi: null,
    };

    if (marginPct !== null) {
      const intialRomi = calculateRomi({
        marginPct,
        mediaIncrementalSales: d.initialEffect,
        totalSpend: d.initialInvestment,
      });
      const optimizedRomi = calculateRomi({
        marginPct,
        mediaIncrementalSales: d.optimizedEffect,
        totalSpend: d.optimizedInvestment,
      });

      optimizationDataPoint.romi = {
        initial: intialRomi,
        optimizied: optimizedRomi,
      };
    }

    return optimizationDataPoint;
  });

  const period = {
    from: dayjs(apiOptimization.period.from),
    to: dayjs(apiOptimization.period.to),
  };

  const initialTotalRoi = calculateRoi({
    mediaIncrementalSales: initialEffectSum,
    investment: initialInvestmentSum,
    customer: null,
  });
  const optimizedTotalRoi = calculateRoi({
    mediaIncrementalSales: optimizedEffectSum,
    investment: optimizedInvestmentSum,
    customer: null,
  });

  const optimization: Optimization = {
    ...apiOptimization,
    data,
    period,
    initialEffectSum,
    optimizedEffectSum,
    initialInvestmentSum,
    optimizedInvestmentSum,
    totalRoi: {
      initial: initialTotalRoi,
      optimized: optimizedTotalRoi,
    },
    totalRomi: null,
  };

  if (marginPct !== null) {
    const initialTotalRomi = calculateRomi({
      marginPct,
      mediaIncrementalSales: initialEffectSum,
      totalSpend: initialInvestmentSum,
    });

    const optimizedTotalRomi = calculateRomi({
      marginPct,
      mediaIncrementalSales: optimizedEffectSum,
      totalSpend: optimizedInvestmentSum,
    });

    optimization.totalRomi = {
      initial: initialTotalRomi,
      optimized: optimizedTotalRomi,
    };
  }

  return optimization;
};
