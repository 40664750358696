import React from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { ValueTickFormatter } from 'src/utils/TickFormatters';
import { useMetrics } from '../../hooks/useMetrics';

const MAX_NAME_LENGTH = 20;
const campaignNameFormatter = (campaignName: string | number): string => {
  if (typeof campaignName !== 'string') {
    return campaignName.toString();
  }
  if (campaignName.length > MAX_NAME_LENGTH) {
    return `${campaignName.slice(0, MAX_NAME_LENGTH)}...`;
  }
  return campaignName;
};

export const MetricsByCampaignBarGraph: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { data, isError, isLoading, dataKeys } = useMetrics('byCampaign');

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'top',
  };

  return (
    <DataBox
      {...props}
      legendProps={legendProps}
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <BarGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        stacked={false}
        yAxisFormatter={ValueTickFormatter}
        xAxisTooltipFormatter={(c) => c.toString()}
        xAxisFormatter={campaignNameFormatter}
      />
    </DataBox>
  );
};
