import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import { AggregateLevel } from 'src/types';
import { useMMDataContext } from '../../../contexts/MMDataContext';

type IncrementalSalesProps = {
  aggregateLevel: AggregateLevel;
  dateFormatter: (value: string | number) => string;
};

export const IncrementalSales: React.FC<IncrementalSalesProps> = ({
  aggregateLevel,
  dateFormatter,
}) => {
  const { decomps } = useMMDataContext();

  const { isEuro } = useKpiUnit();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    return {
      isLoading: decomps.isLoading,
      isError: decomps.isError,
      name: 'Incremental sales',
      isEuro,
      summaryValue: decomps.total,
      data: getAggregateLevelData(decomps, aggregateLevel),
    };
  }, [decomps, isEuro, aggregateLevel]);

  return (
    <KeyMetricChart
      dateFormatter={dateFormatter}
      keyMetricData={keyMetricData}
    />
  );
};
