import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

export type TabOption<A> = {
  label: string;
  value: A;
};

export type TabProps<A> = {
  options: Array<TabOption<A>>;
  onSelect: (value: A) => void;
  selectedTab: A;
};

export function Tabs<A>({ options, onSelect, selectedTab }: TabProps<A>) {
  const selectedColor = useColorModeValue(
    'text.light.primary',
    'text.dark.primary',
  );
  const unselectedColor = useColorModeValue(
    'border.light.box',
    'border.dark.box',
  );
  const hoverBgColor = useColorModeValue(
    'background.light.hover',
    'background.dark.hover',
  );

  return (
    <Flex ml="20px" direction="row">
      {options.map(({ label, value }) => {
        const isSelected = value === selectedTab;
        return (
          <Flex
            key={label}
            align="flex-end"
            px="12px"
            borderBottom="2px"
            borderBottomColor={isSelected ? selectedColor : unselectedColor}
            _hover={{ bg: hoverBgColor, cursor: 'pointer' }}
            onClick={() => {
              onSelect(value);
            }}>
            <Text size="xs" fontWeight={isSelected ? 'bold' : 'normal'}>
              {label}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
