import sortBy from 'lodash/sortBy';
import React, { useEffect, useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { PercentageValueTickFormatter } from 'src/utils/TickFormatters';
import { TOTAL_DECOMPS_DEF } from 'src/utils/metricDefinitions';
import {
  BaseSelectorMenu,
  BaseSelectorOption,
} from '../molecules/BaseSelectorMenu';
import { totalDecompsByMetricToBaseSelectorOptions } from '../molecules/BaseSelectorMenu/utils';

type DataPoint = {
  name: string;
} & ({ Increase: number } | { Decrease: number });

const getDataPoint = (
  name: string,
  value: number,
  total: number,
): DataPoint => {
  const percent = (value / total) * 100;
  const rounded = Math.round(percent * 100) / 100;
  if (value >= 0) {
    return {
      name,
      Increase: rounded,
    };
  }
  return {
    name,
    Decrease: rounded,
  };
};

const dataKeys = { xAxis: 'name', data: ['Increase', 'Decrease'] };

const legendProps: DataBoxLegendProps = {
  baseLegendKeys: dataKeys.data,
  placement: 'top',
};

export const TotalDecompByAttrBox: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { totalDecomps } = useMMDataContext();

  const [infoOpen, setInfoOpen] = useState(false);
  const [baseSelectOptions, setBaseSelectOptions] = useState<
    Array<BaseSelectorOption>
  >(totalDecompsByMetricToBaseSelectorOptions(totalDecomps));

  useEffect(() => {
    setBaseSelectOptions(
      totalDecompsByMetricToBaseSelectorOptions(totalDecomps),
    );
  }, [totalDecomps]);

  const isError = totalDecomps.isError;
  const isLoading = totalDecomps.isLoading;

  const data: Array<DataPoint> = useMemo(() => {
    const metricsToIncludeInBase: Record<string, true> = {};

    baseSelectOptions.forEach((opt) => {
      if (opt.isSelected) {
        metricsToIncludeInBase[opt.metric] = true;
      }
    });

    const total = totalDecomps.total;

    let totalBase = totalDecomps.baseTotal;

    const otherMetrics: Array<DataPoint> = sortBy(
      Object.entries(totalDecomps.totalByOtherMetric).filter(
        ([metric, value]) => {
          if (metricsToIncludeInBase[metric]) {
            totalBase += value;
          }

          return !metricsToIncludeInBase[metric];
        },
      ),
      ([, value]) => value,
    ).map(([metric, value]) => {
      return getDataPoint(metric, value, total);
    });
    return [
      getDataPoint('Base', totalBase, total),
      ...otherMetrics,
      getDataPoint('Media Effect', totalDecomps.mediaEffectTotal, total),
    ];
  }, [totalDecomps, baseSelectOptions]);

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={TOTAL_DECOMPS_DEF}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        isError={isError}
        isLoading={isLoading}
        hasData={totalDecomps.week.length > 0}
        rightContent={
          <BaseSelectorMenu
            options={baseSelectOptions}
            setOptions={setBaseSelectOptions}
          />
        }>
        <BarGraph
          data={data}
          xAxisKey={dataKeys.xAxis}
          stacked={false}
          yAxisFormatter={PercentageValueTickFormatter}
        />
      </DataBox>
    </>
  );
};
