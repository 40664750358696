import React, { useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { AreaGraph } from 'src/components/molecules/graphs/AreaGraph';
import { BarLineGraph } from 'src/components/molecules/graphs/BarLineGraph';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';
import { Dimension, MMDataState } from 'src/features/MM/types';
import { benchmarkColor } from 'src/theme';
import { PercentageValueTickFormatter } from 'src/utils/TickFormatters';
import { ChartType, ChartTypeTabs } from '../../molecules/ChartTypeTabs';
import {
  PecentOrUnit,
  PercentOrUnitTabs,
} from '../../molecules/PercentOrUnitTabs';
import { TrendsDataTableModal } from '../../molecules/TrendsDataTableModal';
import { useChartData } from './useChartData';

type TrendsChartBoxProps = {
  metricState: MMDataState;
  totalLineLabel?: string;
  unitTabLabel?: string;
  yAxisFormatter: (value: number | string) => string;
  infoTitle?: string;
  infoBodyText: string;
} & DataBoxBaseProps;

export const TrendsChartBox: React.FC<TrendsChartBoxProps> = ({
  metricState,
  yAxisFormatter,
  totalLineLabel,
  unitTabLabel,
  infoBodyText,
  infoTitle,
  ...props
}) => {
  const { mediaColorMap } = useFilterContext();

  const { dimension } = useSelectedDimensionContext();
  const [chartType, setChartType] = useState<ChartType>('bar');
  const [percentOrUnit, setPercentOrUnit] = useState<PecentOrUnit>('unit');

  const [dataModalIsOpen, setDataModalIsOpen] = useState(false);

  const xAxisFormatter = useGetDateAxisFormatter();

  const shouldUsePercentData = percentOrUnit === 'percent';

  const { legendKeys, data } = useChartData(
    metricState,
    shouldUsePercentData,
    totalLineLabel,
  );

  const [infoOpen, setInfoOpen] = useState(false);
  const legendProps = useMemo(() => {
    const keyColorMap = dimension === Dimension.media ? mediaColorMap : {};

    const lProps: DataBoxLegendProps = {
      baseLegendKeys: legendKeys,
      placement: 'right',
      lineKeysFirst: true,
      keyColorMap,
    };

    if (totalLineLabel) {
      lProps.lineKeys = [totalLineLabel];
      keyColorMap[totalLineLabel] = benchmarkColor;
    }

    return lProps;
  }, [dimension, legendKeys, mediaColorMap, totalLineLabel]);

  const yFormatter = shouldUsePercentData
    ? PercentageValueTickFormatter
    : yAxisFormatter;

  return (
    <>
      <InfoAlert
        headerText={infoTitle ?? props.title ?? ''}
        bodyText={infoBodyText}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />

      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        restrictChartHeight
        exportEnabled
        exportButtonEnabled={false}
        tabs={<ChartTypeTabs onSelect={setChartType} selectedTab={chartType} />}
        secondaryTabs={
          unitTabLabel ? (
            <PercentOrUnitTabs
              unitLabel={unitTabLabel}
              onSelect={setPercentOrUnit}
              selectedTab={percentOrUnit}
            />
          ) : undefined
        }
        legendProps={legendProps}
        setTableDataModalIsOpen={setDataModalIsOpen}
        hasData={data.length > 0}
        isError={metricState.isError}
        isLoading={metricState.isLoading}>
        <>
          <TrendsDataTableModal
            setIsOpen={setDataModalIsOpen}
            isOpen={dataModalIsOpen}
            data={data}
            yFormatter={yFormatter}
          />
          {chartType === 'line' && (
            <AreaGraph
              percentRange={shouldUsePercentData}
              data={data}
              xAxisKey={'utcEpoch'}
              xAxisFormatter={xAxisFormatter}
              yAxisFormatter={yFormatter}
              stacked={true}
            />
          )}
          {chartType === 'bar' && (
            <BarLineGraph
              percentRange={shouldUsePercentData}
              data={data}
              xAxisKey={'utcEpoch'}
              stacked
              xAxisFormatter={xAxisFormatter}
              yAxisFormatter={yFormatter}
            />
          )}
        </>
      </DataBox>
    </>
  );
};
