import { Flex } from '@chakra-ui/react';
import React from 'react';
import { TimePeriod } from 'src/types';
import { CustomDateRange } from './CustomDateRange';
import { TimePeriodButton } from './TimePeriodButton';

type TimeRangeSelectorProps = {
  includeTimePeriodButtons?: boolean;
};

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  includeTimePeriodButtons,
}) => {
  return (
    <Flex direction="row">
      {includeTimePeriodButtons &&
        Object.values(TimePeriod).map((timePeriod) => {
          if (timePeriod === TimePeriod.CUSTOM) {
            return null;
          }
          return <TimePeriodButton key={timePeriod} timePeriod={timePeriod} />;
        })}
      <CustomDateRange />
    </Flex>
  );
};
