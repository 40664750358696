import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Table = {
  baseStyle: (props: StyleFunctionProps) => ({
    th: {
      borderBottom: '1px',
      borderColor: mode('border.light.box', 'border.dark.box')(props),
      textTransform: 'unset',
      verticalAlign: 'bottom',
    },
    td: {
      borderBottom: '1px',
      borderColor: mode('border.light.box', 'border.dark.box')(props),
      verticalAlign: 'top',
    },
  }),
  sizes: {
    sm: {
      th: {
        fontSize: '16px',
        lineHeight: '20px',
        py: '6px',
      },
      td: {
        py: '12px',
        fontSize: '20px',
        lineHeight: '20px',
      },
    },
    md: {
      th: {
        ps: '0',
        fontSize: '16px',
      },
      td: {
        ps: '0',
      },
    },
  },
};
