import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Alert = {
  baseStyle: () => ({
    container: {
      borderWidth: 'thin',
      borderRadius: '4px',
    },
  }),
  variants: {
    subtle: (props: StyleFunctionProps) => ({
      container: {
        color: mode('text.light.errorBox', 'text.dark.errorBox')(props),
        bg: mode('background.light.error', 'background.dark.error')(props),
      },
    }),
  },
};
