import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { DataTable } from 'src/components/molecules/DataTable';
import { useMetricTableColumns } from '../../hooks/useMetricTableColumns';
import { useMetricsByOrganicSearchCluster } from '../../hooks/useMetricsByOrganicSearchCluster';

export const MetricsByOrganicSearchClusterTable: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { data, isError, isLoading } = useMetricsByOrganicSearchCluster();

  const columns = useMetricTableColumns('Keyword cluster');

  return (
    <DataBox
      {...props}
      exportEnabled
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <DataTable csvFileName="keyword-clusters" data={data} columns={columns} />
    </DataBox>
  );
};
