import { Dimension } from '../types';

export const getDimensionName = (dimension: Dimension) => {
  switch (dimension) {
    case Dimension.campagin:
      return 'Campaign';
    case Dimension.category:
      return 'Category';
    case Dimension.media:
      return 'Media';
    case Dimension.tag:
      return 'Tag';
  }
};
