import { ApiOrganicSearchDataPoint } from '../../../api/queries';

export const getUniqueKeywordClusters = (data: ApiOrganicSearchDataPoint[]) => {
  const keywordClusters: Record<string, boolean> = {};

  data.forEach((d) => {
    d.keywordClusters.forEach(({ keywordCluster }) => {
      keywordClusters[keywordCluster] = true;
    });
  });

  return keywordClusters;
};
