import { Box } from '@chakra-ui/react';
import React from 'react';
import {
  Cell,
  Pie,
  // eslint-disable-next-line no-restricted-imports
  PieChart as RechartsPiechart,
  ResponsiveContainer,
} from 'recharts';

export type PieChartProps = {
  data: Array<object>;
  dataKeys: { name: string; data: string };
  onClick?: (channel: string, index: number) => void;
  colorMap: Record<string, string>;
  activeIndex?: number;
};

export const PieChart: React.FC<PieChartProps> = ({
  data,
  dataKeys,
  onClick,
  colorMap,
  activeIndex,
}) => {
  return (
    <Box w="100%" h="100%">
      <ResponsiveContainer>
        <RechartsPiechart data={data}>
          <Pie
            isAnimationActive={false}
            data={data}
            nameKey={dataKeys.name}
            dataKey={dataKeys.data}
            cx="50%"
            cy="50%"
            paddingAngle={5}
            innerRadius="50%"
            outerRadius="80%"
            labelLine={false}
            strokeWidth={5}
            strokeOpacity={0}
            activeIndex={activeIndex}
            activeShape={
              onClick
                ? {
                    strokeOpacity: 1,
                  }
                : {}
            }
            onClick={(x, i: number) => {
              if (onClick) {
                onClick(x.name, i);
              }
            }}
            label={(x) => {
              return x.name;
            }}>
            {data.map((entry, i) => {
              const name = (entry as Record<string, string>)[dataKeys.name];

              const color = colorMap[name];

              return (
                <Cell
                  key={`cell-${name}`}
                  cursor={onClick ? 'pointer' : 'default'}
                  fill={color}
                  stroke={color}
                  fontSize={i === activeIndex ? '20px' : '16px'}
                />
              );
            })}
          </Pie>
        </RechartsPiechart>
      </ResponsiveContainer>
    </Box>
  );
};
