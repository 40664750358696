import { Box, useColorModeValue } from '@chakra-ui/react';
import { SelectedDateRange } from 'src/components/molecules/SelectedDateRange';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';

export const SelectedBenchmarkRange: React.FC = () => {
  const {
    benchmarkTimerange: { error, end, start, enabled },
    setBenchmarkEnabled,
  } = useFilterContext();

  const fontActiveColor = useColorModeValue(
    'text.light.benchmark',
    'text.dark.benchmark',
  );

  const fontErrorColor = useFontErrorColor();

  return (
    <>
      {enabled ? (
        <SelectedDateRange
          bold={true}
          start={start}
          end={end}
          fontActiveColor={error ? fontErrorColor : fontActiveColor}
        />
      ) : (
        <Box cursor={'pointer'} onClick={() => setBenchmarkEnabled(true)}>
          Enable
        </Box>
      )}
    </>
  );
};
