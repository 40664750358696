import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Input = {
  sizes: {
    lg: {
      field: {
        borderRadius: '4px',
      },
    },
    md: {
      field: {
        borderRadius: '4px',
      },
    },
    sm: {
      field: {
        borderRadius: '4px',
      },
    },
    xs: {
      field: {
        borderRadius: '4px',
      },
    },
  },
  variants: {
    outline: (props: StyleFunctionProps) => ({
      field: {
        borderColor: mode('border.light.box', 'border.dark.box')(props),
        _invalid: {
          borderColor: mode('border.light.error', 'border.dark.error')(props),
        },
        _focus: {
          borderColor: mode('border.light.focus', 'border.dark.focus')(props),
        },
      },
    }),
  },
};
