import { Navigate, Route, Routes } from 'react-router-dom';
import NoSubscriptionPage from '../NoSubscriptionPage';
import { ChannelsPage } from './Channels';
import { DeepDivePage } from './DeepDive';
import { ExplorerPage } from './Explorer';
import { OverviewPage } from './Overview';
import { SearchPage } from './Search';
import { DADataContextProvider } from 'src/features/DA/contexts/DADataContext/DADataContextProvider';

type DigitalAttributionPagesProps = {
  isSubscribed: boolean;
};

export const DigitalAttributionPages: React.FC<
  DigitalAttributionPagesProps
> = ({ isSubscribed }) => {
  if (!isSubscribed) {
    return <NoSubscriptionPage service="DSO" />;
  }

  return (
    <DADataContextProvider>
      <Routes>
        <Route element={<OverviewPage />} path="overview" />
        <Route element={<ExplorerPage />} path="explorer" />
        <Route element={<DeepDivePage />} path="deep-dive" />
        <Route element={<ChannelsPage />} path="channels" />
        <Route element={<SearchPage />} path="search" />
        <Route element={<Navigate replace to="/dso/overview" />} path="/" />
      </Routes>
    </DADataContextProvider>
  );
};
