import {
  intCellFunc,
  intEuroCellFunc,
  intEuroFormatter,
  intFormatter,
} from 'src/components/molecules/DataTable/cellFuncs';
import {
  EuroValueTickFormatter,
  ValueTickFormatter,
} from '../utils/TickFormatters';
import { useKpiUnit } from './useKpiUnit';

// Depending on if the kpi unit is euro or not, we want to format effects differently

export const useChartEffectTickFormatter = () => {
  const { unit } = useKpiUnit();

  return unit === 'euro' ? EuroValueTickFormatter : ValueTickFormatter;
};

export const useTableEffectCellFunc = () => {
  const { unit } = useKpiUnit();

  const effectCellFunc = unit === 'euro' ? intEuroCellFunc : intCellFunc;
  return effectCellFunc;
};

export const useTableEffectFormatter = () => {
  const { unit } = useKpiUnit();

  const effectFormatter = unit === 'euro' ? intEuroFormatter : intFormatter;
  return effectFormatter;
};
