import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  FieldPathByValue,
  FieldValues,
  get,
  useFormContext,
} from 'react-hook-form';

type FormNumberInputProps<FormInputs extends FieldValues> = {
  inputPath: FieldPathByValue<FormInputs, number | null>;
  required?: boolean;
  placeholder: string;
  label?: string;
  helperText?: string;
  suffix?: string;
  sx?: FormControlProps;
};

export function FormNumberInput<FormInputs extends FieldValues>({
  inputPath,
  required,
  placeholder,
  label,
  helperText,
  suffix,
  sx,
}: FormNumberInputProps<FormInputs>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const error = get(errors, inputPath);

  return (
    <FormControl isInvalid={!!error} {...sx}>
      {label && <FormLabel htmlFor={inputPath}>{label}</FormLabel>}
      <InputGroup>
        <Input
          {...register(inputPath, {
            required: required ? 'Required' : false,
            valueAsNumber: true,
          })}
          type={'number'}
          id={inputPath}
          placeholder={placeholder}
        />
        {suffix && <InputRightElement>{suffix}</InputRightElement>}
      </InputGroup>
      {error?.message && typeof error.message === 'string' && (
        <FormErrorMessage>{error.message}</FormErrorMessage>
      )}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
