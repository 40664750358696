import { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import {
  SosMonthlyMovingAverageData,
  mapLocationDataToMovingAverageData,
} from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import { useSOSDataContext } from '../../../contexts/SOSDataContext';
import {
  Mode,
  SosByMonthsKeywordChart,
} from '../../molecules/SosByMonthsKeywordChart';
import { SosTabs } from '../../molecules/SosTabs';

export const SosByMonthsKeywordChartBox: React.FC<DataBoxBaseProps> = (
  props,
) => {
  const { shareOfSearchCountryMonthlyState, selectedKeywords } =
    useSOSDataContext();

  const [chartMode, setChartMode] = useState<Mode>('shareOfSearch');

  const data: Array<SosMonthlyMovingAverageData> = useMemo(
    () =>
      mapLocationDataToMovingAverageData(
        shareOfSearchCountryMonthlyState.data,
      ).filter((d) => d.hasMovingAverageData),
    [shareOfSearchCountryMonthlyState.data],
  );

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      placement: 'right',
      autoSortKeys: true,
      baseLegendKeys: selectedKeywords,
      legendSelectBehaviour: 'single',
      minWidth: '160px',
    };
  }, [selectedKeywords]);

  return (
    <DataBox
      tabs={<SosTabs selectedTab={chartMode} onSelect={setChartMode} />}
      restrictChartHeight
      exportEnabled
      legendProps={legendProps}
      {...props}
      hasData={shareOfSearchCountryMonthlyState.data.length > 0}
      isError={shareOfSearchCountryMonthlyState.isError}
      isLoading={shareOfSearchCountryMonthlyState.isLoading}>
      <SosByMonthsKeywordChart data={data} mode={chartMode} />
    </DataBox>
  );
};
