import { Flex, ModalFooter } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/atoms/Button';
import { OptimizationFormInputs } from './OptimizationModalContent';

type FooterProps = {
  onCancelClick: () => void;
};
export const Footer: React.FC<FooterProps> = ({ onCancelClick }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext<OptimizationFormInputs>();

  return (
    <ModalFooter>
      <Flex>
        <Button buttonName="opt-modal-cancel" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          buttonName="opt-modal-optimize"
          isLoading={isSubmitting}
          type="submit"
          ml="20px">
          Optimize
        </Button>
      </Flex>
    </ModalFooter>
  );
};
