export const validateEmail = (value: string) => {
  let error;
  if (!value) {
    error = 'Email address is required';
  } else if (!/^\S+@\S+$/.test(value)) {
    // This is a _very_ simple test for a valid email address, it is
    // just meant to help the user not make a simple mistake when filling
    // out the form - Cognito will do more in depth email address checking.
    error = "The email address doesn't seem valid";
  }
  return error;
};

export const validatePassword = (value: string) => {
  let error;
  if (!value) {
    error = 'Password is required';
  }
  return error;
};

export const validateCode = (value: string) => {
  let error;
  if (!value) {
    error = 'Code is required';
  }
  return error;
};
