import { KeyMetricChart } from 'src/components/organisms/KeyMetricChart';

type OptimizationMetricChartProps = {
  optimizedValue: number;
  initialValue: number;
  name: string;
  isEuro?: boolean;
};

export const OptimizationMetricChart: React.FC<
  OptimizationMetricChartProps
> = ({ optimizedValue, initialValue, name, isEuro = false }) => {
  return (
    <KeyMetricChart
      w={'240px'}
      mr={'20px'}
      keyMetricData={{
        data: [],
        isError: false,
        isEuro,
        isLoading: false,
        renderChart: false,
        name,
        summaryValue: optimizedValue,
        benchmarkSummaryValue: initialValue,
      }}
    />
  );
};
