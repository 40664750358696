import React from 'react';
import { SOSFilterBar } from 'src/features/SOS/components/organisms/SOSFilterBar';
import { Municipalities } from 'src/features/SOS/components/templates/Municipalities';
import { Page } from '../../components/molecules/Page';

export const MunicipalitiesPage: React.FC = () => {
  return (
    <Page filterBar={<SOSFilterBar displayLocationMenus />}>
      <Municipalities />
    </Page>
  );
};
