import axios from 'axios';
import { API_ENDPOINT } from 'src/constants';
import { getCurrentAuthToken } from 'src/contexts/AuthContext/utils';
import { captureException } from 'src/libs/sentry';

const request = axios.create({
  baseURL: API_ENDPOINT,
});

request.interceptors.request.use(async (config) => {
  let token: string | undefined;

  try {
    token = await getCurrentAuthToken();
  } catch (error) {
    // If there is some error fetching the token, assign to / page so user can login again
    captureException({ error, fn: 'reqInt' });
    window.location.assign('/');
    throw error;
  }

  config.headers.setAuthorization(token ? `Bearer ${token}` : '');

  return config;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    captureException({ error, fn: 'resInt' });
    return Promise.reject(error);
  },
);

export default request;
