import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { Media, TimeRange } from 'src/types';
import { dateToEpoch, getMonthStartEpoch } from 'src/utils/date';

export const filterLongTermEffectsData = (
  apiData: ApiLongTermEffectsDataPoint[],
  media: Media[],
  timeRange: TimeRange,
): ApiLongTermEffectsDataPoint[] => {
  const mediaSet = new Set(media.filter((m) => m.selected).map((m) => m.id));

  const monthStartEpochFilter = getMonthStartEpoch(
    dateToEpoch(timeRange.start),
  );
  const monthEndEpochFilter = getMonthStartEpoch(dateToEpoch(timeRange.end));

  const data = apiData
    .map((dataPoint) => {
      return {
        ...dataPoint,
        mediaLongTermEffects: dataPoint.mediaLongTermEffects.filter((d) => {
          const inMedia = mediaSet.has(d.media);
          return inMedia;
        }),
      };
    })
    .filter(({ utcEpoch }) => {
      return (
        utcEpoch >= monthStartEpochFilter && utcEpoch <= monthEndEpochFilter
      );
    });
  return data;
};
