import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import {
  FieldPathByValue,
  FieldValues,
  get,
  useFormContext,
} from 'react-hook-form';

type FormTextInputProps<FormInputs extends FieldValues> = {
  inputPath: FieldPathByValue<FormInputs, string>;
  required?: boolean;
  placeholder: string;
  label?: string;
  helperText: string;
  sx?: FormControlProps;
};

export function FormTextInput<FormInputs extends FieldValues>({
  inputPath,
  required,
  label,
  placeholder,
  helperText,
  sx,
}: FormTextInputProps<FormInputs>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const error = get(errors, inputPath);

  return (
    <FormControl isInvalid={!!error} {...sx}>
      {label && <FormLabel htmlFor={inputPath}>{label}</FormLabel>}
      <Input
        {...register(inputPath, {
          required: required ? 'Required' : false,
        })}
        id={inputPath}
        placeholder={placeholder}
      />
      {error?.message && typeof error.message === 'string' && (
        <FormErrorMessage>{error.message}</FormErrorMessage>
      )}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
