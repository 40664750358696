import { useDADataContext } from 'src/features/DA/contexts/DADataContext';
import { MetricsByOrganicSearchClusterBarGraph } from '../../organisms/MetricsByOrganicSearchClusterBarGraph';
import { MetricsByOrganicSearchClusterTable } from '../../organisms/MetricsByOrganicSearchClusterTable';
import { MetricsBySearchChannelBarGraph } from '../../organisms/MetricsBySearchChannelBarGraph';
import { MetricsBySearchChannelTable } from '../../organisms/MetricsBySearchChannelTable';

export const Search: React.FC = () => {
  const { organicSearch } = useDADataContext();

  return (
    <>
      <MetricsBySearchChannelBarGraph
        minH={400}
        title="Sessions overview"
        colSpan={12}
      />
      <MetricsBySearchChannelTable title="Search explorer" colSpan={12} />
      {organicSearch.data.length > 0 && (
        <>
          <MetricsByOrganicSearchClusterBarGraph
            minH={400}
            title="Cluster overview"
            colSpan={12}
          />
          <MetricsByOrganicSearchClusterTable
            title="Cluster explorer"
            colSpan={12}
          />
        </>
      )}
    </>
  );
};
