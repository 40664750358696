import { useMemo } from 'react';
import { DataTableProps } from 'src/components/molecules/DataTable';
import { FormatterFunc } from 'src/components/molecules/DataTable/cellFuncs';
import { DataTableModal } from 'src/components/organisms/DataTableModal';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';
import {
  TableModalRow,
  useColumns,
} from 'src/features/MM/hooks/useTableModalColumns';
import { getAggregationName } from 'src/features/MM/utils/getAggregationName';
import { isNonNullable } from 'src/utils/Data';

type DataRow = {
  utcEpoch: number;
} & Record<string, number | null>;

type TimeseriesDataTableModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  headerName: string;
  data: Array<DataRow>;
  yFormatter: FormatterFunc;
};

export const TimeseriesDataTableModal: React.FC<
  TimeseriesDataTableModalProps
> = ({ setIsOpen, isOpen, headerName, yFormatter, data }) => {
  const { aggregateLevel } = useAggregationLevelContext();

  const csvFileName = `${headerName} - ${getAggregationName(aggregateLevel)}`;

  const dateFormatter = useGetDateAxisFormatter();

  const columns = useColumns(yFormatter);

  const dataTableProps = useMemo(() => {
    const columnKeys = columns
      .map((a) => {
        if ('accessorKey' in a) {
          return a.accessorKey;
        }
        return null;
      })
      .filter(isNonNullable);

    const props: DataTableProps<TableModalRow> = {
      columns,
      data: data.map(({ utcEpoch, ...restOfData }) => {
        const record = {
          date: dateFormatter(utcEpoch),
        } as TableModalRow;

        Object.entries(restOfData).forEach(([key, value]) => {
          if (columnKeys.some((c) => c === key)) {
            record[key] = value;
          }
        });

        return record;
      }),
      csvFileName,
    };

    return props;
  }, [columns, data, csvFileName, dateFormatter]);

  return (
    <DataTableModal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      headerName={csvFileName}
      dataTableProps={dataTableProps}
    />
  );
};
