import { useMemo } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { useAppContext } from 'src/contexts/AppContext';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { getLast12MonthsEpochs } from 'src/features/SOS/utils/getLast12MonthsEpochs';
import { getCustomerBrandKeyword } from 'src/features/SOS/utils/isKeywordCustomerBrand';
import { splitSearchVolmeToBrandAndOthers } from 'src/features/SOS/utils/splitSearchVolumeToBrandAndOthers';
import { dayjs } from 'src/libs/dayjs';
import { isNonNullable } from 'src/utils/Data';
import { cleanCustomerName } from 'src/utils/cleanCustomerName';
import { getMonthStartEpoch, sortEpoch } from 'src/utils/date';
import {
  BrandVsOthersChangeInSearchVolumeChart,
  BrandVsOthersChangeInSearchVolumeDataPoint,
} from './BrandVsOthersChangeInSearchVolumeChart';
import { getLegendProps } from './constants';

export const BrandVsOthersChangeInSearchVolumeChartBox: React.FC<
  DataBoxBaseProps
> = (props) => {
  const { currentCustomer } = useAppContext();
  const { shareOfSearchCountryMonthlyState } = useSOSDataContext();

  const data: Array<BrandVsOthersChangeInSearchVolumeDataPoint> =
    useMemo(() => {
      if (!currentCustomer) {
        return [];
      }

      const { brandData, otherData } = splitSearchVolmeToBrandAndOthers(
        currentCustomer,
        shareOfSearchCountryMonthlyState.data,
      );

      const dataPoints: Array<BrandVsOthersChangeInSearchVolumeDataPoint> =
        Object.entries(brandData)
          .map(
            ([
              utcEpochString,
              searchVolume,
            ]): BrandVsOthersChangeInSearchVolumeDataPoint | null => {
              const utcEpoch = Number(utcEpochString);
              const brandSearchVolume = searchVolume;
              const otherSearchVolume = otherData[utcEpoch];

              const previousMonthEpoch = getMonthStartEpoch(
                dayjs(utcEpoch).subtract(1, 'month').valueOf(),
              );

              const previousYearEpoch = getMonthStartEpoch(
                dayjs(utcEpoch).subtract(1, 'year').valueOf(),
              );

              if (otherData[previousYearEpoch] === undefined) {
                return null;
              }

              const monthlyEpochs = getLast12MonthsEpochs(utcEpoch);
              let volumeLast12Month = 0;

              for (const monthlyEpoch of monthlyEpochs) {
                const otherVolumeForMonth = otherData[monthlyEpoch] ?? 0;

                volumeLast12Month += otherVolumeForMonth;
              }

              const previousMonthBrandSearchVolume =
                brandData[previousMonthEpoch] ?? 0;

              const previousMonthOtherSearchVolume =
                otherData[previousMonthEpoch] ?? 0;

              const brandChangeInVolumePrevMonth =
                previousMonthBrandSearchVolume > 0
                  ? (100 *
                      (brandSearchVolume - previousMonthBrandSearchVolume)) /
                    previousMonthBrandSearchVolume
                  : 0;

              const otherChangeInVolumePrevMonth =
                previousMonthOtherSearchVolume > 0
                  ? (100 *
                      (otherSearchVolume - previousMonthOtherSearchVolume)) /
                    previousMonthOtherSearchVolume
                  : 0;

              return {
                otherChangeInVolumePrevMonth,
                brandChangeInVolumePrevMonth,
                otherSearchVolume12Month: volumeLast12Month,
                utcEpoch,
              };
            },
          )
          .filter(isNonNullable);

      return dataPoints.sort(sortEpoch);
    }, [shareOfSearchCountryMonthlyState, currentCustomer]);

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return getLegendProps(currentCustomer);
  }, [currentCustomer]);

  const brandName = currentCustomer
    ? getCustomerBrandKeyword(currentCustomer)
    : null;

  return (
    <DataBox
      {...props}
      legendProps={legendProps}
      hasData={shareOfSearchCountryMonthlyState.data.length > 0}
      isError={shareOfSearchCountryMonthlyState.isError}
      isLoading={shareOfSearchCountryMonthlyState.isLoading}>
      <BrandVsOthersChangeInSearchVolumeChart
        data={data}
        brandName={cleanCustomerName(brandName) ?? ''}
      />
    </DataBox>
  );
};
