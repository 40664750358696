import { FC } from 'react';
import { DateRangeSelector } from 'src/components/molecules/DateRangeSelector';
import { dayjs } from 'src/libs/dayjs';
import { TimePeriod } from 'src/types';
import { getModelMaxStartAndEndDatePickerDates } from 'src/utils/model';
import { useFilterContext } from '../../../contexts/FilterContext';
import { SelectedTimeRange } from './SelectedTimeRange';
import { EntireDateRangeButton } from 'src/components/molecules/DateRangeSelector/AdditionalDateRangeSelectorButton';

export const CustomDateRange: FC = () => {
  const { timeRange, updateTimeRange, currentModelVersion } =
    useFilterContext();

  const { datePickerMaxDate, datePickerMinDate } =
    getModelMaxStartAndEndDatePickerDates(currentModelVersion);

  const selectedStartDate = dayjs(timeRange.start).toDate();
  const selectedEndDate = dayjs(timeRange.end).toDate();

  const onDateRangeChange = (startDate: Date, endDate: Date) => {
    updateTimeRange(TimePeriod.CUSTOM, dayjs(startDate), dayjs(endDate));
  };

  const additionalDateRangeButton =
    datePickerMaxDate && datePickerMinDate ? (
      <EntireDateRangeButton
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        minDate={datePickerMinDate}
        maxDate={datePickerMaxDate}
        onDateRangeChange={onDateRangeChange}
      />
    ) : null;

  return (
    <DateRangeSelector
      additionalDateRangeButton={additionalDateRangeButton}
      minDate={datePickerMinDate}
      maxDate={datePickerMaxDate}
      onDateRangeChange={onDateRangeChange}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}>
      <SelectedTimeRange />
    </DateRangeSelector>
  );
};
