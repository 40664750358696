import {
  Box,
  Center,
  Flex,
  Heading,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  CurrentAuthState,
  useAuthContext,
} from '../../../contexts/AuthContext';
import { Logo } from '../../atoms/Logo';
import { ForgotPasswordSendCode } from './forms/ForgotPasswordSendCode';
import { ForgotPasswordUpdate } from './forms/ForgotPasswordUpdate';
import { NewPassword } from './forms/NewPassword';
import { SignIn } from './forms/SignIn';

export const AuthUIContainer: FC = () => {
  const {
    signIn,
    completeNewPassword,
    forgotPasswordStart,
    forgotPasswordSendCode,
    forgotPasswordUpdatePassword,
    currentAuthState,
  } = useAuthContext();

  const boxBg = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );
  const boxBorderColor = useColorModeValue(
    'border.light.box',
    'border.dark.box',
  );

  return (
    <Center w="100vw" h="100vh">
      <Box
        m="24px"
        minWidth="480px"
        maxWidth="760px"
        p="24px"
        borderWidth="thin"
        borderRadius="4px"
        borderColor={boxBorderColor}
        background={boxBg}>
        <Flex direction="column">
          <Flex direction="column" alignItems="center" pb="24px">
            <Logo height="100px" width="100px" />
            <Heading as="h2" size="sm">
              Omnicom Media Group Finland
            </Heading>
            <Heading as="h1" size="md" py="24px">
              Marketing Mix Modelling Solution
            </Heading>
          </Flex>
          {currentAuthState === CurrentAuthState.SignedOut && (
            <SignIn onSubmit={signIn} onForgotPassword={forgotPasswordStart} />
          )}
          {currentAuthState === CurrentAuthState.ForcePasswordChange && (
            <NewPassword onSubmit={completeNewPassword} />
          )}
          {currentAuthState === CurrentAuthState.ForgotPassword && (
            <ForgotPasswordSendCode onSubmit={forgotPasswordSendCode} />
          )}
          {currentAuthState === CurrentAuthState.PasswordReset && (
            <ForgotPasswordUpdate onSubmit={forgotPasswordUpdatePassword} />
          )}
          {currentAuthState === CurrentAuthState.Loading && (
            <Center>
              <Spinner />
            </Center>
          )}
        </Flex>
      </Box>
    </Center>
  );
};
