import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { SIDE_BAR_WIDTH } from './styles';

export const SideBar: React.FC<FlexProps> = ({ children, ...flexProps }) => {
  const borderColor = useColorModeValue('border.light.box', 'border.dark.box');
  const bg = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );

  return (
    <Flex
      pos="fixed"
      zIndex={1}
      as="nav"
      h="100vh"
      w={SIDE_BAR_WIDTH}
      direction="column"
      justify="start"
      align="center"
      borderRightWidth="thin"
      borderColor={borderColor}
      bg={bg}
      {...flexProps}>
      {children}
    </Flex>
  );
};
