import * as React from 'react';
import { Title } from 'src/components/molecules/DataBox/Title';
import { CitiesBenchmarkSelector } from '../../molecules/CitiesBenchmarkSelector';
import { RegionsBenchmarkSelector } from '../../molecules/RegionsBenchmarkSelector';
import { SosCitiesChartBox } from '../../organisms/SosCitiesChartBox';
import { SosRegionsChartBox } from '../../organisms/SosRegionsChartBox';
import { AggregateChartsAndTables } from './AggregateChartsAndTables';
import { Flex } from '@chakra-ui/react';

export const Municipalities: React.FC = () => {
  return (
    <>
      <SosRegionsChartBox
        minH={600}
        colSpan={12}
        titleComponent={
          <Flex alignItems={'center'}>
            <Title>Search by regions</Title>
            <RegionsBenchmarkSelector />
          </Flex>
        }
      />
      <SosCitiesChartBox
        minH={600}
        colSpan={12}
        titleComponent={
          <Flex alignItems={'center'}>
            <Title>Search by city</Title>
            <CitiesBenchmarkSelector />
          </Flex>
        }
      />
      <AggregateChartsAndTables />
    </>
  );
};
