import {
  AuthTokens,
  AuthUser,
  fetchAuthSession,
  fetchUserAttributes,
} from 'aws-amplify/auth';
import { UserProfile, UserRole } from '.';

export const getUserProfile = async (
  user: AuthUser,
  tokens: AuthTokens,
): Promise<UserProfile | null> => {
  const attributes = await fetchUserAttributes();

  try {
    const username = user?.username;
    const email = attributes.email;
    const name = email?.replace(/@.*/, '').replace('.', ' ');
    const groups = (tokens?.accessToken?.payload['cognito:groups'] ??
      []) as string[];

    const customers = groups.filter((g: string) => {
      return (
        g !== UserRole.ADMIN && g !== UserRole.ANALYST && g !== UserRole.VIEWER
      );
    });

    const role: UserRole = groups.reduce((acc: UserRole, group: string) => {
      if (group === UserRole.ADMIN) {
        return UserRole.ADMIN;
      }
      if (acc !== UserRole.ADMIN && group === UserRole.ANALYST) {
        return UserRole.ANALYST;
      }
      return acc;
    }, UserRole.VIEWER);

    return {
      username,
      email,
      name,
      groups,
      customers,
      role,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

/**
 * This is a helper function to get the current Auth token. It is defined
 * here in the context so that all interactions with the Auth provider (in
 * this case Amplify) are kept in one place. It can easily be moved to
 * another place if needed.
 *
 * This function just fetches the correct token to use in API requests.
 * @returns The required Bearer token or undefined if no token is available.
 */
export async function getCurrentAuthToken(): Promise<string | undefined> {
  const { tokens } = await fetchAuthSession();

  const token = tokens?.idToken?.toString();
  return token;
}
