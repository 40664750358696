import * as React from 'react';
import { MetricsByCampaignTable } from '../../organisms/MetricsByCampaignTable';
import { MetricsByCampaignBarGraph } from '../../organisms/MetricsByCampaignBarGraph';

export const Explorer: React.FC = () => {
  return (
    <>
      <MetricsByCampaignBarGraph
        minH={400}
        title="Sessions overview"
        colSpan={12}
      />
      <MetricsByCampaignTable title="Campaign explorer" colSpan={12} />
    </>
  );
};
