import { Navigate, Route as RouterRoute, Routes } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import Page404 from 'src/pages/404Page';
import { DigitalAttributionPages } from 'src/pages/DigitalAttribution';
import { LongTermEffectsPages } from 'src/pages/LongTermEffects';
import { MarketingMixModellingPages } from 'src/pages/MarketingMixModelling';
import { MarketingOptimizationPages } from 'src/pages/MarketingOptimization';
import { ShareOfSearchPages } from 'src/pages/ShareOfSearch';

const RootNavigation: React.FC = () => {
  const { subscriptionLevels } = useAppContext();

  if (subscriptionLevels.mm) {
    return <Navigate replace to="/mmm/overview" />;
  }

  if (subscriptionLevels.da) {
    return <Navigate replace to="/dso/overview" />;
  }

  if (subscriptionLevels.mo) {
    return <Navigate replace to="/marketing-optimization" />;
  }

  if (subscriptionLevels.lt) {
    return <Navigate replace to="/longterm-effect" />;
  }

  if (subscriptionLevels.sos) {
    return <Navigate replace to="/share-of-search/overview" />;
  }

  return null;
};

export const AppRoutes: React.FC = () => {
  const { subscriptionLevels } = useAppContext();

  return (
    <Routes>
      {/* MM pages */}
      <RouterRoute
        path="/mmm/*"
        element={
          <MarketingMixModellingPages isSubscribed={subscriptionLevels.mm} />
        }
      />
      {/* Digital Attribution pages */}
      <RouterRoute
        path="/dso/*"
        element={
          <DigitalAttributionPages isSubscribed={subscriptionLevels.da} />
        }
      />

      {/* Marketing Optimization pages */}
      <RouterRoute
        path="/marketing-optimization"
        element={
          <MarketingOptimizationPages isSubscribed={subscriptionLevels.mo} />
        }
      />

      {/* Long term effect */}
      <RouterRoute
        path="/longterm-effect"
        element={<LongTermEffectsPages isSubscribed={subscriptionLevels.lt} />}
      />

      {/* Share of search */}
      <RouterRoute
        path="/share-of-search/*"
        element={<ShareOfSearchPages isSubscribed={subscriptionLevels.sos} />}
      />

      {/* Misc pages */}
      <RouterRoute path="/" element={<RootNavigation />} />
      <RouterRoute path="*" element={<Page404 />} />
    </Routes>
  );
};
