import { Flex } from '@chakra-ui/react';
import { useLegendContext } from './LegendContext/index.js';
import { LegendItem } from './LegendItem.js';

export const TopLegend: React.FC = () => {
  const { legendValues, onLegendClick } = useLegendContext();

  return (
    <Flex direction={'row'} mb={'10px'} wrap={'wrap'}>
      {legendValues.map(({ key, selected, color, label, legendType }) => {
        return (
          <LegendItem
            key={key}
            isSelected={selected}
            color={color}
            label={label}
            onClick={() => onLegendClick(key)}
            mr={'15px'}
            isLine={legendType === 'line'}
          />
        );
      })}
    </Flex>
  );
};
