import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ApiModelVersionDataPoint } from '../../../api/queries';
import { getModelDateString } from './getModelDateString';

type SelectedModelProps = {
  model: ApiModelVersionDataPoint;
};

export const SelectedModel: React.FC<SelectedModelProps> = ({ model }) => {
  return (
    <Flex alignItems={'flex-end'} flexDir={'column'}>
      <Text whiteSpace={'nowrap'}>{model.alias || model.modelID}</Text>
      <Text mt={'4px'} fontSize="xs">
        {getModelDateString(model)}
      </Text>
    </Flex>
  );
};
