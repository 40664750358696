import { useMemo } from 'react';
import { useDADataContext } from '../contexts/DADataContext';
import {
  BENCHMARK_IMPRESSIONS_NAME,
  BENCHMARK_MODELLED_CONV_NAME,
  MODELLED_CONV_NAME,
} from '../utils/constants';
import { useBenchmarkEnabled } from './useBenchmarkEnabled';
import { MetricsData } from './useMetrics';

// Copied implementation from useMetricsHook
export const getOrganicSearchDataKeys = (benchmarkEnabled: boolean) => {
  const data: Array<keyof MetricsData> = benchmarkEnabled
    ? [
        MODELLED_CONV_NAME,
        BENCHMARK_MODELLED_CONV_NAME,
        'Impressions',
        BENCHMARK_IMPRESSIONS_NAME,
      ]
    : [MODELLED_CONV_NAME, 'Impressions'];

  return {
    xAxis: 'key',
    data,
  };
};

export type OrganicSearchDataByCluster = Pick<
  MetricsData,
  | 'key'
  | typeof MODELLED_CONV_NAME
  | 'Impressions'
  | typeof BENCHMARK_IMPRESSIONS_NAME
  | typeof BENCHMARK_MODELLED_CONV_NAME
>;

export const useMetricsByOrganicSearchCluster = () => {
  const { organicSearch, benchmarkOrganicSearch } = useDADataContext();
  const { organicSearchBenchmarkEnabled } = useBenchmarkEnabled();

  const isError = organicSearch.isError;
  const isLoading = organicSearch.isLoading;
  const dataKeys = getOrganicSearchDataKeys(organicSearchBenchmarkEnabled);

  const data: Array<OrganicSearchDataByCluster> = useMemo(() => {
    const byCluster: Record<string, OrganicSearchDataByCluster> = {};

    for (const datapoint of organicSearch.data) {
      for (const clusterData of datapoint.keywordClusters) {
        const keywordCluster = clusterData.keywordCluster;
        if (!byCluster[keywordCluster]) {
          byCluster[keywordCluster] = {
            Impressions: 0,
            [MODELLED_CONV_NAME]: 0,
            key: keywordCluster,
          };
        }
        byCluster[keywordCluster][MODELLED_CONV_NAME] +=
          clusterData.modelledConversions;
        byCluster[keywordCluster].Impressions += clusterData.impressions;
      }
    }

    if (organicSearchBenchmarkEnabled) {
      for (const datapoint of benchmarkOrganicSearch.data) {
        for (const clusterData of datapoint.keywordClusters) {
          const keywordCluster = clusterData.keywordCluster;

          // incase benchmark has keyword cluster data that does not exist in normal dataset
          if (!byCluster[keywordCluster]) {
            byCluster[keywordCluster] = {
              Impressions: 0,
              [MODELLED_CONV_NAME]: 0,
              [BENCHMARK_MODELLED_CONV_NAME]: 0,
              [BENCHMARK_IMPRESSIONS_NAME]: 0,
              key: keywordCluster,
            };
          }
          const existingClusterData = byCluster[keywordCluster];

          if (existingClusterData[BENCHMARK_IMPRESSIONS_NAME]) {
            existingClusterData[BENCHMARK_IMPRESSIONS_NAME] +=
              clusterData.impressions;
          } else {
            existingClusterData[BENCHMARK_IMPRESSIONS_NAME] =
              clusterData.impressions;
          }

          if (existingClusterData[BENCHMARK_MODELLED_CONV_NAME]) {
            existingClusterData[BENCHMARK_MODELLED_CONV_NAME] +=
              clusterData.modelledConversions;
          } else {
            existingClusterData[BENCHMARK_MODELLED_CONV_NAME] =
              clusterData.modelledConversions;
          }
        }
      }
    }

    return Object.values(byCluster);
  }, [
    organicSearch.data,
    organicSearchBenchmarkEnabled,
    benchmarkOrganicSearch,
  ]);

  return { data, isError, isLoading, dataKeys };
};
