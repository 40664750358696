import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { useSearch } from 'src/hooks/useSearch';

const MENU_BUTTON_NAME = 'city-select';

export const CityMenu: React.FC = () => {
  const { availableRegionsAndCitiesState, toggleCities } = useSOSDataContext();

  const selectableCities = availableRegionsAndCitiesState.selectableCities;

  const selectedCities = selectableCities.filter(({ selected }) => selected);

  const {
    results: searchedCities,
    searchValue,
    setSearchValue,
  } = useSearch(selectableCities, ['locationName']);

  const onAllClick = () => {
    toggleCities(searchedCities);
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px">
        {selectedCities.length}/{selectableCities.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedCities.map((d) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={d.locationId}
            isSelected={d.selected}
            label={d.locationName}
            value={d.locationId}
            onClick={() => toggleCities([d])}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
