import { useCallback, useState } from 'react';
import { Dayjs, dayjs } from 'src/libs/dayjs';
import { BenchmarkTimeRange, TimePeriod, TimeRange } from 'src/types';
import {
  getDaysAgo,
  getMonthsAgo,
  getWeeksAgo,
  getYearsAgo,
} from 'src/utils/date';
import { ModelVersion } from './useModelVersions';

export type GetRangeForTimePeriodParams =
  | {
      timePeriod: TimePeriod.CUSTOM;
      start: Dayjs;
      end: Dayjs;
    }
  | {
      timePeriod: Exclude<TimePeriod, TimePeriod.CUSTOM>;
      maxEnd: Dayjs | null;
    };

const getEndDate = (params: GetRangeForTimePeriodParams): Dayjs => {
  if (params.timePeriod === TimePeriod.CUSTOM) {
    return params.end;
  }
  const now = dayjs();
  if (params.maxEnd && params.maxEnd.isBefore(now)) {
    return params.maxEnd;
  }
  return now;
};

export function getRangeForTimePeriod(
  params: GetRangeForTimePeriodParams,
): TimeRange {
  const timePeriod = params.timePeriod;
  const end = getEndDate(params);

  switch (timePeriod) {
    case TimePeriod.WEEK:
      return {
        period: timePeriod,
        start: getDaysAgo(7, end),
        end,
      };
    case TimePeriod.TWO_WEEKS:
      return {
        period: timePeriod,
        start: getWeeksAgo(2, end),
        end,
      };
    case TimePeriod.MONTH:
      return {
        period: timePeriod,
        start: getMonthsAgo(1, end),
        end,
      };
    case TimePeriod.QUARTER:
      return {
        period: timePeriod,
        start: getMonthsAgo(3, end),
        end,
      };
    case TimePeriod.HALF:
      return {
        period: timePeriod,
        start: getMonthsAgo(6, end),
        end,
      };
    case TimePeriod.YEAR:
      return {
        period: timePeriod,
        start: getYearsAgo(1, end),
        end,
      };
    case TimePeriod.YTD:
      return {
        period: timePeriod,
        start: dayjs(end).startOf('year'),
        end,
      };
    case TimePeriod.CUSTOM: {
      const start = params.start;
      return {
        period: timePeriod,
        start,
        end,
      };
    }
  }
}

// TODO: Potentially remove this helper function
const getBenchmarkTimerange = (
  benchmarkStartDate: Dayjs,
  benchmarkEndDate: Dayjs,
  benchmarkEnabled: boolean,
): BenchmarkTimeRange => {
  const start = benchmarkStartDate;
  const end = benchmarkEndDate;

  // TODO: Potentially get rid of error completely
  const error = '';

  return {
    start,
    end,
    error,
    enabled: benchmarkEnabled,
  };
};

export const useTimerange = (currentModelVersion: ModelVersion | null) => {
  const [filterLocked, setFilterLocked] = useState(false);

  const [timeRange, setTimeRange] = useState<TimeRange>(
    getRangeForTimePeriod({ timePeriod: TimePeriod.YEAR, maxEnd: null }),
  );

  const [benchmarkTimerange, setBenchmarkTimerangeState] =
    useState<BenchmarkTimeRange>({
      enabled: false,
      start: timeRange.start,
      end: timeRange.end,
      error: '',
    });

  const updateTimeRangeFromModel = useCallback((model: ModelVersion) => {
    const newTimerange: TimeRange = {
      period: TimePeriod.CUSTOM,
      start: dayjs(model.dataStartEpoch),
      end: dayjs(model.dataEndEpoch),
    };
    setTimeRange(newTimerange);
    setBenchmarkTimerangeState((oldBenchmark) => {
      const newBenchmarkTime = getBenchmarkTimerange(
        dayjs(model.dataStartEpoch),
        dayjs(model.dataEndDate),
        oldBenchmark.enabled,
      );
      return newBenchmarkTime;
    });
  }, []);

  const updateTimeRange = useCallback(
    (timePeriod: TimePeriod, start?: Dayjs, end?: Dayjs) => {
      const params: GetRangeForTimePeriodParams =
        timePeriod === TimePeriod.CUSTOM
          ? {
              timePeriod: TimePeriod.CUSTOM,
              start: start ?? dayjs(),
              end: end ?? dayjs(),
            }
          : {
              timePeriod,
              maxEnd: currentModelVersion?.dataEndDate
                ? dayjs(currentModelVersion.dataEndDate)
                : null,
            };

      const newTimerange = getRangeForTimePeriod(params);
      setTimeRange(newTimerange);
    },
    [currentModelVersion],
  );

  const setBenchmarkTimerange = useCallback((start: Dayjs, end: Dayjs) => {
    setBenchmarkTimerangeState(getBenchmarkTimerange(start, end, true));
  }, []);

  const setBenchmarkEnabled = useCallback((benchmarkEnabled: boolean) => {
    setBenchmarkTimerangeState((oldBenchmark) => {
      return { ...oldBenchmark, enabled: benchmarkEnabled };
    });
  }, []);

  return {
    timeRange,
    updateTimeRange,
    setBenchmarkTimerange,
    benchmarkTimerange,
    setBenchmarkEnabled,
    updateTimeRangeFromModel,
    timeRangeLocked: filterLocked,
    setTimeRangeLocked: setFilterLocked,
  };
};
