import { Text, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';

type FilterHeadingProps = {
  headingText: string;
} & TextProps;

export const FilterHeading: FC<FilterHeadingProps> = ({
  headingText,
  ...txtProps
}) => {
  return (
    <Text whiteSpace={'nowrap'} fontSize="16px" fontWeight="600" {...txtProps}>
      {headingText}
    </Text>
  );
};
