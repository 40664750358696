import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  floatCellFunc,
  identityCellFunc,
} from 'src/components/molecules/DataTable/cellFuncs';
import { KeywordsWithEpoch } from 'src/features/SOS/types';
import { dayjs } from 'src/libs/dayjs';
import { sortKeywordData } from '../../../hooks/useShareOfSearchCountryMonthly';

type DataRow = {
  keyword: string;
  pctUnitChange: number;
} & Record<string, string | number>;

export const getTableData = (
  keywordsData: Array<KeywordsWithEpoch>,
): [DataRow[], number[]] => {
  const byKeyword: Record<
    string,
    {
      keyword: string;
      searchVolumeByYear: Record<
        number,
        { year: number; searchVolume: number }
      >;
    }
  > = {};

  // Needed to calculate %
  const yearTotals: Record<number, { year: number; searchVolume: number }> = {};

  keywordsData.forEach((d) => {
    const { utcEpoch: monthEpoch, keywords } = d;

    const year = dayjs(monthEpoch).year();

    keywords.forEach(({ keyword, searchVolume }) => {
      if (!byKeyword[keyword]) {
        byKeyword[keyword] = {
          keyword,
          searchVolumeByYear: {},
        };
      }
      const searchVolumeByYear = byKeyword[keyword].searchVolumeByYear;

      if (!searchVolumeByYear[year]) {
        searchVolumeByYear[year] = {
          year,
          searchVolume: 0,
        };
      }

      if (!yearTotals[year]) {
        yearTotals[year] = {
          year,
          searchVolume: 0,
        };
      }

      yearTotals[year].searchVolume += searchVolume;
      searchVolumeByYear[year].searchVolume += searchVolume;
    });
  });

  const availableYears = Object.values(yearTotals)
    .map(({ year }) => {
      return year;
    })
    .sort();

  // Safer than using dayjs as latest data for that month might not be available immediatly after new years
  const [previousYear, latestYear] = availableYears.slice(
    availableYears.length - 2,
    availableYears.length,
  );

  const data = Object.values(byKeyword)
    .map(({ keyword, searchVolumeByYear }) => {
      const d: DataRow = {
        keyword,
        pctUnitChange: 0,
      };

      let previousYearPct: number | null = null;
      let latestYearPct: number | null = null;

      Object.values(searchVolumeByYear).forEach(({ searchVolume, year }) => {
        const yearTotal = yearTotals[year].searchVolume;

        const pct = 100 * (searchVolume / yearTotal);

        if (year === previousYear) {
          previousYearPct = pct;
        }

        if (year === latestYear) {
          latestYearPct = pct;
        }

        const pctString = `${pct.toFixed(2)}%`;

        d[year.toString()] = pctString;
      });

      if (previousYearPct !== null && latestYearPct !== null) {
        d.pctUnitChange = latestYearPct - previousYearPct;
      }

      return d;
    })
    .sort(sortKeywordData);

  return [data, availableYears];
};

const columnHelper = createColumnHelper<DataRow>();

export const useColumns = (years: number[]) => {
  const columns = useMemo(() => {
    const yearColumns = years.map((year) => {
      return columnHelper.accessor(year.toString(), {
        header: year.toString(),
        cell: identityCellFunc,
        sortingFn: 'alphanumeric',
      });
    });

    const allColumns = [
      columnHelper.accessor('keyword', {
        header: 'Keyword',
        cell: identityCellFunc,
        sortingFn: 'alphanumeric',
      }),
      ...yearColumns,
      columnHelper.accessor('pctUnitChange', {
        header: 'Change',
        cell: floatCellFunc,
        sortingFn: 'basic',
        meta: {
          hightlightChangeRange: [-1, 1],
        },
      }),
    ];

    return allColumns;
  }, [years]);

  return columns;
};
