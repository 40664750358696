import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';

import { TooltipContent } from '../../atoms/tooltips/TooltipContent';
import { useLegendContext } from '../Legend/LegendContext';

export type AreaGraphProps = {
  data: Array<object>;
  xAxisKey: string;
  stacked?: boolean;
  xAxisFormatter?: (value: number | string) => string;
  yAxisFormatter?: (value: number) => string;
  percentRange?: boolean;
};

// Requires legend
export const AreaGraph: React.FC<AreaGraphProps> = ({
  data,
  xAxisKey,
  stacked = false,
  xAxisFormatter = (value: number | string) => value.toString(),
  yAxisFormatter = (value: number) => value.toString(),
  percentRange,
}) => {
  const { selectedBaseLegendValues, selectedLineLegendValues } =
    useLegendContext();

  const { gridColor } = useChartColors();
  const axisStyles = useAxisStyles();

  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="0" strokeWidth={1} stroke={gridColor} />
        <YAxis
          width={70}
          domain={percentRange ? [0, 100] : undefined}
          tickFormatter={yAxisFormatter}
          {...axisStyles}
        />
        <XAxis
          dataKey={xAxisKey}
          tickFormatter={xAxisFormatter}
          {...axisStyles}
        />
        <Tooltip
          offset={25}
          content={
            <TooltipContent
              valueFormatter={yAxisFormatter}
              labelFormatter={xAxisFormatter}
            />
          }
        />

        {selectedBaseLegendValues.map(({ color, key }) => {
          return (
            <Area
              key={key}
              stackId={stacked ? 'a' : key}
              dataKey={key}
              isAnimationActive={false}
              stroke={color}
              fill={color}
              fillOpacity={0.5}
              dot={false}
              strokeWidth={2}
            />
          );
        })}

        {selectedLineLegendValues.map(({ key, color }) => {
          return (
            <Line
              isAnimationActive={false}
              key={`line_${key}`}
              dataKey={key}
              stroke={color}
              dot={false}
              strokeWidth={2}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
