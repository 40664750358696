import { Amplify } from 'aws-amplify';

import { createContext, useContext } from 'react';
import { COGNITO_CLIENT_ID, COGNITO_USERPOOL } from 'src/constants';

const userPoolId = COGNITO_USERPOOL;
const userPoolClientId = COGNITO_CLIENT_ID;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId,
    },
  },
});

// This enum defines the the names of the user groups for
// admins & analysts. Membership of these groups will display
// various other UI components.
// These need to match the groups in the user's JWT ID token.
export enum UserRole {
  ADMIN = 'mmm_admins',
  ANALYST = 'mmm_analysts',
  VIEWER = 'mmm_viewers',
}

export type UserProfile = {
  username: string;
  email?: string;
  name?: string;
  groups?: string[];
  customers?: string[];
  role?: UserRole;
};

/**
 * These states can be used to managed to authentication flow in
 * the app. These are not the full set of states available in Amplify/Cognito
 * but are the only ones relevant to this app.
 */
export enum CurrentAuthState {
  Loading = 'Loading',
  SignedOut = 'SignedOut',
  SignedIn = 'SignedIn',
  ForcePasswordChange = 'ForcePasswordChange',
  ForgotPassword = 'ForgotPassword',
  PasswordReset = 'PasswordReset',
}

export interface AuthContextValue {
  refreshAuthState: () => Promise<void>;
  signIn: (username: string, password: string) => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<void>;
  forgotPasswordStart: () => Promise<void>;
  forgotPasswordSendCode: (username: string) => Promise<void>;
  forgotPasswordUpdatePassword: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<void>;
  signOut: () => Promise<void>;
  userProfile: UserProfile | null;
  currentAuthState: CurrentAuthState;
}

export const AuthContext = createContext<AuthContextValue | null>(null);
AuthContext.displayName = 'AuthContext';

export function useAuthContext() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error('useAuthContext has to be used under AuthProvider');
  }
  return value;
}
