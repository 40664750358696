export type TimeframeData = {
  utcEpoch: number;
  value: number;
  byMedia: Record<string, number>;
  byCampaignId: Record<string, number>;
  byTag: Record<string, number>;
  byCategory: Record<string, number>;
};

export type MMDataState = {
  isLoading: boolean;
  isError: boolean;
  total: number;
  week: Array<TimeframeData>;
  month: Array<TimeframeData>;
  quarter: Array<TimeframeData>;
  biannual: Array<TimeframeData>;
  year: Array<TimeframeData>;
  totalByMedia: Record<string, number>;
  totalByCampaignId: Record<string, number>;
  totalByTag: Record<string, number>;
  totalByCategory: Record<string, number>;
};

export const INIT_MM_DATA_STATE: Readonly<MMDataState> = {
  isLoading: false,
  isError: false,
  total: 0,
  week: [],
  month: [],
  quarter: [],
  biannual: [],
  year: [],
  totalByMedia: {},
  totalByCampaignId: {},
  totalByTag: {},
  totalByCategory: {},
};

// Weekly or monthly production cost
export type ProductionCostTimeframeData = {
  utcEpoch: number;
  totalProductionCost: number;
  byCampaignId: Record<string, number>;
  byTag: Record<string, number>;
  byCategory: Record<string, number>;
};

export enum Dimension {
  category,
  campagin,
  media,
  tag,
}

export type TotalDecompsTimeframeData = {
  utcEpoch: number;
  total: number;
  base: number;
  mediaEffect: number;
  byOtherMetric: Record<string, number>;
};

export type TotalDecompsState = {
  isLoading: boolean;
  isError: boolean;
  total: number;
  baseTotal: number;
  mediaEffectTotal: number;
  totalByOtherMetric: Record<string, number>;
  week: Array<TotalDecompsTimeframeData>;
  month: Array<TotalDecompsTimeframeData>;
  quarter: Array<TotalDecompsTimeframeData>;
  biannual: Array<TotalDecompsTimeframeData>;
  year: Array<TotalDecompsTimeframeData>;
};

export const INIT_TOTAL_DECOMPS_STATE: Readonly<TotalDecompsState> = {
  isLoading: false,
  isError: false,
  total: 0,
  baseTotal: 0,
  mediaEffectTotal: 0,
  totalByOtherMetric: {},
  week: [],
  month: [],
  quarter: [],
  biannual: [],
  year: [],
};
