import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TooltipContent,
  TooltipContentExtraMap,
} from 'src/components/atoms/tooltips/TooltipContent';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';
import {
  MonthYearDateFormatter,
  PercentageValueTickFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';
import { CUSTOMER_CHANGE } from './constants';

export type BrandVsOthersChangeInSearchVolumeDataPoint = {
  utcEpoch: number;
  otherChangeInVolumePrevMonth: number;
  brandChangeInVolumePrevMonth: number;
  otherSearchVolume12Month: number;
};

type BrandVsOthersChangeInSearchVolumeChartProps = {
  data: Array<BrandVsOthersChangeInSearchVolumeDataPoint>;
  brandName: string;
};

export const BrandVsOthersChangeInSearchVolumeChart: React.FC<
  BrandVsOthersChangeInSearchVolumeChartProps
> = ({ data, brandName }) => {
  const { gridColor } = useChartColors();
  const axisStyles = useAxisStyles();

  const toolTopicExtraMap: TooltipContentExtraMap = {
    otherChangeInVolumePrevMonth: {
      label: 'Others Change',
      valueFormatter: PercentageValueTickFormatter,
    },
    brandChangeInVolumePrevMonth: {
      label: `${brandName} Change`,
      valueFormatter: PercentageValueTickFormatter,
    },
    otherSearchVolume12Month: {
      label: 'Search Volume 12m',
      valueFormatter: ValueTickFormatter,
    },
  };

  const { selectedBaseLegendValues, selectedLineLegendValues } =
    useLegendContext();

  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="0" strokeWidth={1} stroke={gridColor} />
        <YAxis
          width={70}
          yAxisId="volume"
          tickFormatter={ValueTickFormatter}
          {...axisStyles}
        />
        <YAxis
          orientation="right"
          width={70}
          yAxisId="percent"
          tickFormatter={PercentageValueTickFormatter}
          {...axisStyles}
        />

        <XAxis
          dataKey="utcEpoch"
          tickFormatter={MonthYearDateFormatter}
          {...axisStyles}
        />
        <Tooltip
          offset={25}
          content={
            <TooltipContent
              forceMulti
              extraMap={toolTopicExtraMap}
              valueFormatter={PercentageValueTickFormatter}
              labelFormatter={MonthYearDateFormatter}
            />
          }
        />

        {selectedBaseLegendValues.map((v) => {
          if (v.key == CUSTOMER_CHANGE) {
            return (
              <Bar
                key={v.key}
                yAxisId="percent"
                dataKey="brandChangeInVolumePrevMonth"
                fill={v.color}
              />
            );
          }

          return (
            <Bar
              key={v.key}
              yAxisId="percent"
              dataKey="otherChangeInVolumePrevMonth"
              fill={v.color}
            />
          );
        })}

        {selectedLineLegendValues.map((v) => {
          return (
            <Line
              key={v.key}
              isAnimationActive={false}
              yAxisId="volume"
              dataKey="otherSearchVolume12Month"
              stroke={v.color}
              dot={false}
              strokeWidth={4}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
