import { Menu } from '@chakra-ui/react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';

const MENU_BUTTON_NAME = 'base-component-select';

export type BaseSelectorOption = {
  metric: string;
  isSelected: boolean;
};

type BaseSelectorMenuProps = {
  options: Array<BaseSelectorOption>;
  setOptions: (opts: Array<BaseSelectorOption>) => void;
};

export const BaseSelectorMenu: React.FC<BaseSelectorMenuProps> = ({
  options,
  setOptions,
}) => {
  const selectedCount = options.reduce((prev: number, curr) => {
    if (curr.isSelected) {
      return prev + 1;
    }
    return prev;
  }, 0);

  const onAllClick = () => {
    const allSelected = selectedCount === options.length;

    setOptions(
      options.map(({ metric }) => ({ isSelected: !allSelected, metric })),
    );
  };

  const onMetricClick = (metric: string) => {
    const newOpts = options.map((opt) => {
      if (opt.metric === metric) {
        return {
          metric,
          isSelected: !opt.isSelected,
        };
      }

      return opt;
    });

    setOptions(newOpts);
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} ml="20px">
        Base: {selectedCount}/{options.length}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME} onAllClick={onAllClick}>
        {options.map((opt) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={opt.metric}
            isSelected={opt.isSelected}
            label={opt.metric}
            value={opt.metric}
            onClick={() => onMetricClick(opt.metric)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
