import { MODataContextProvider } from 'src/features/MO/contexts/MODataContext/MODataContextProvider';
import NoSubscriptionPage from '../NoSubscriptionPage';
import { OptimizationPage } from './Optimization';

type MarketingOptimizationPagesProps = {
  isSubscribed: boolean;
};

export const MarketingOptimizationPages: React.FC<
  MarketingOptimizationPagesProps
> = ({ isSubscribed }) => {
  if (!isSubscribed) {
    return <NoSubscriptionPage service="Marketing Optimization" />;
  }

  return (
    <MODataContextProvider>
      <OptimizationPage />
    </MODataContextProvider>
  );
};
