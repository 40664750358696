import { Box, useDisclosure } from '@chakra-ui/react';
import { Button } from 'src/components/atoms/Button';
import { useFilterContext } from 'src/contexts/FilterContext';
import { OptimizationModal } from '../../organisms/OptimizationModal';

export const CreateOptimizationButton: React.FC = () => {
  const { onClose, isOpen, onOpen } = useDisclosure();

  const { currentModelVersion } = useFilterContext();

  return (
    <Box>
      <Button
        buttonName="create-optimization"
        disabled={!currentModelVersion}
        onClick={onOpen}>
        Create optimization
      </Button>
      {currentModelVersion && (
        <OptimizationModal
          isOpen={isOpen}
          onClose={onClose}
          currentModelVersion={currentModelVersion}
        />
      )}
    </Box>
  );
};
