import { useMemo } from 'react';
import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type PecentOrUnit = 'unit' | 'percent';

export type PercentOrUnitTabsProps = {
  selectedTab: PecentOrUnit;
  onSelect: (value: PecentOrUnit) => void;
  unitLabel: string;
};

export const PercentOrUnitTabs: React.FC<PercentOrUnitTabsProps> = ({
  onSelect,
  selectedTab,
  unitLabel,
}) => {
  const tabs: Array<TabOption<PecentOrUnit>> = useMemo(() => {
    return [
      {
        label: unitLabel,
        value: 'unit',
      },
      {
        label: '%',
        value: 'percent',
      },
    ];
  }, [unitLabel]);

  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
