import { ApiLongTermEffectsDataPoint } from '../../../api/queries';
import { EffectsTimeframeData } from '../types';

export const getEffectsTimeFrameData = (
  data: Record<number, Array<ApiLongTermEffectsDataPoint>>,
): Array<EffectsTimeframeData> => {
  return Object.entries(data).map(([utcEpoch, dataPoints]) => {
    let shortTermMediaEffectTotal = 0;
    let longTermMediaEffectTotal = 0;
    let baseTotal = 0;
    let shareOfSearchTotal = 0;

    const byMediaShort: Record<string, number> = {};
    const byMediaLong: Record<string, number> = {};
    const byMediaTotal: Record<string, number> = {};

    dataPoints.forEach(({ mediaLongTermEffects, base, shareOfSearch }) => {
      baseTotal += base;
      shareOfSearchTotal += shareOfSearch;

      mediaLongTermEffects.forEach(
        ({ media, longTermEffect, shortTermEffect }) => {
          shortTermMediaEffectTotal += shortTermEffect;
          longTermMediaEffectTotal += longTermEffect;
          if (!byMediaShort[media]) {
            byMediaShort[media] = 0;
          }
          if (!byMediaLong[media]) {
            byMediaLong[media] = 0;
          }
          if (!byMediaTotal[media]) {
            byMediaTotal[media] = 0;
          }

          byMediaShort[media] += shortTermEffect;
          byMediaLong[media] += longTermEffect;
          byMediaTotal[media] += shortTermEffect + longTermEffect;
        },
      );
    });

    return {
      utcEpoch: Number(utcEpoch),
      base: baseTotal,
      search: shareOfSearchTotal,
      shortTermMediaEffect: shortTermMediaEffectTotal,
      longTermMediaEffect: longTermMediaEffectTotal,
      totalMediaEffect: shortTermMediaEffectTotal + longTermMediaEffectTotal,
      byMediaShort,
      byMediaLong,
      byMediaTotal,
    };
  });
};
