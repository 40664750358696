import { Center, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';

export interface DataErrorProps {
  message?: string;
  action?: string;
}

export const DataError: React.FC<DataErrorProps> = ({
  message = 'There was an error loading data.',
  action = 'Please refresh the chart.',
}) => {
  const fontColor = useFontErrorColor();
  return (
    <Center h="100%" w="100%" px="40px" py="24px">
      <Flex direction="column" justifyContent="flexStart">
        <Heading as="p" size="md" mb={2} color={fontColor}>
          {message}
        </Heading>
        <Text as="p" size="xl" color={fontColor}>
          {action}
        </Text>
      </Flex>
    </Center>
  );
};
