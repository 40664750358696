import { from } from 'env-var';

export const CUSTOMER_SKODA = 'skoda';
export const CUSTOMER_VAASAN = 'vaasan';

const env = from({
  API_ENDPOINT: import.meta.env.VITE_API_ENDPOINT,
  BUILD_VERSION: import.meta.env.VITE_BUILD_VERSION,
  COGNITO_USERPOOL: import.meta.env.VITE_COGNITO_USERPOOL,
  COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID,
  MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  SENTRY_ENV: import.meta.env.VITE_SENTRY_ENV,
  SENTRY_ENABLED: import.meta.env.VITE_SENTRY_ENABLED,
  IS_DEV: import.meta.env.VITE_IS_DEV,
  DEV_EMAILS: import.meta.env.VITE_DEV_EMAILS,
});

export const API_ENDPOINT = env.get('API_ENDPOINT').required().asUrlString();
export const BUILD_VERSION = env.get('BUILD_VERSION').asString();
export const COGNITO_USERPOOL = env
  .get('COGNITO_USERPOOL')
  .required()
  .asString();

export const COGNITO_CLIENT_ID = env
  .get('COGNITO_CLIENT_ID')
  .required()
  .asString();
export const MAPBOX_ACCESS_TOKEN = env
  .get('MAPBOX_ACCESS_TOKEN')
  .required()
  .asString();
export const AMPLITUDE_API_KEY = env
  .get('AMPLITUDE_API_KEY')
  .required()
  .asString();

export const SENTRY_ENV = env
  .get('SENTRY_ENV')
  .required()
  .asEnum(['develop', 'main']);

export const SENTRY_ENABLED = env.get('SENTRY_ENABLED').required().asBool();

export const IS_DEV = env.get('IS_DEV').required().asBool();

export const DEV_EMAILS = env.get('DEV_EMAILS').default('').asArray();
