import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { DataTable } from 'src/components/molecules/DataTable';
import { useMetricTableColumns } from '../../hooks/useMetricTableColumns';
import { useMetricsBySearchChannel } from '../../hooks/useMetricsBySearchChannel';

export const MetricsBySearchChannelTable: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { data, isError, isLoading } = useMetricsBySearchChannel();
  const columns = useMetricTableColumns('Channel');

  return (
    <DataBox
      {...props}
      exportEnabled
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <DataTable csvFileName="search" data={data} columns={columns} />
    </DataBox>
  );
};
