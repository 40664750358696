import React, { useMemo } from 'react';
import { useMMDataContext } from '../../../contexts/MMDataContext';

import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { useCPULabel } from 'src/features/MM/hooks/useCPULabel';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { AggregateLevel } from 'src/types';

type CostPerUnitProps = {
  aggregateLevel: AggregateLevel;
  dateFormatter: (value: string | number) => string;
};

export const CostPerUnit: React.FC<CostPerUnitProps> = ({
  aggregateLevel,
  dateFormatter,
}) => {
  const { cpu } = useMMDataContext();

  const label = useCPULabel();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    return {
      isLoading: cpu.isLoading,
      isError: cpu.isError,
      name: label,
      isEuro: true,
      summaryValue: cpu.total,
      data: getAggregateLevelData(cpu, aggregateLevel),
    };
  }, [cpu, label, aggregateLevel]);

  return (
    <KeyMetricChart
      keyMetricData={keyMetricData}
      dateFormatter={dateFormatter}
    />
  );
};
