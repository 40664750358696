import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { useCPULabel } from 'src/features/MM/hooks/useCPULabel';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import {
  EuroValueTickFormatter,
  PercentageValueTickFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';
import { useRoiDef } from 'src/utils/calculateRoi';
import {
  INCREMENTAL_SALES_DEF,
  MEDIA_EFFECT_DEF,
  MEDIA_INVESTMENT_DEF,
  ROMI_DEF,
  TOTAL_ROI_DEF,
  TOTAL_ROMI_DEF,
  getCPUDef,
  getTotalCPUDef,
} from 'src/utils/metricDefinitions';
import { TrendsChartBox } from '../../organisms/TrendsChartBox';

const colSpan = 12;
const minH = 350;

export const Trends: React.FC = () => {
  const cpuLabel = useCPULabel();
  const { roi, romi, romiEnabled, mediaEffect, cpu, decomps, investments } =
    useMMDataContext();

  const { unit } = useKpiUnit();

  const unitTabLabel = unit === 'euro' ? 'Euro' : 'Unit';

  const incrementalSalesTickFormatter = useChartEffectTickFormatter();

  const roiDef = useRoiDef();

  return (
    <>
      {romiEnabled ? (
        <TrendsChartBox
          title="ROMI"
          totalLineLabel="Total ROMI"
          colSpan={colSpan}
          minH={minH}
          metricState={romi}
          yAxisFormatter={ValueTickFormatter}
          infoBodyText={`${ROMI_DEF}\n\n${TOTAL_ROMI_DEF}`}
          infoTitle="Return on Marketing Investment"
        />
      ) : (
        <TrendsChartBox
          title="ROI"
          totalLineLabel="Total ROI"
          colSpan={colSpan}
          minH={minH}
          metricState={roi}
          yAxisFormatter={ValueTickFormatter}
          infoBodyText={`${roiDef}\n\n${TOTAL_ROI_DEF}`}
          infoTitle="Return on Investment"
        />
      )}
      <TrendsChartBox
        title="Media investment"
        colSpan={colSpan}
        minH={minH}
        metricState={investments}
        unitTabLabel={'Euro'}
        yAxisFormatter={EuroValueTickFormatter}
        infoBodyText={MEDIA_INVESTMENT_DEF}
      />
      <TrendsChartBox
        title="Incremental sales"
        colSpan={colSpan}
        minH={minH}
        metricState={decomps}
        unitTabLabel={unitTabLabel}
        yAxisFormatter={incrementalSalesTickFormatter}
        infoBodyText={INCREMENTAL_SALES_DEF}
      />
      <TrendsChartBox
        title={cpuLabel}
        totalLineLabel={`Total ${cpuLabel}`}
        colSpan={colSpan}
        minH={minH}
        metricState={cpu}
        yAxisFormatter={EuroValueTickFormatter}
        infoBodyText={`${getCPUDef(cpuLabel)}\n\n${getTotalCPUDef(cpuLabel)}`}
      />
      <TrendsChartBox
        title={'Media effect'}
        colSpan={colSpan}
        minH={minH}
        metricState={mediaEffect}
        yAxisFormatter={PercentageValueTickFormatter}
        infoBodyText={MEDIA_EFFECT_DEF}
      />
    </>
  );
};
