import { ConversionsOTLineGraph } from '../../organisms/ConversionsOTLineGraph';
import { MetricsByChannelTable } from '../../organisms/MetricsByChannelTable';
import { MediaMatrixBox } from '../../organisms/MediaMatrixBox';
import { MetricsByChannelBarGraph } from '../../organisms/MetricsByChannelBarGraph';

export const Overview: React.FC = () => {
  return (
    <>
      <MetricsByChannelBarGraph
        minH={400}
        title="Traffic Overview"
        colSpan={6}
      />
      <ConversionsOTLineGraph
        minH={400}
        title="Conversions over time"
        colSpan={6}
      />
      <MetricsByChannelTable title="Media Coverage" colSpan={12} />
      <MediaMatrixBox minH={600} title="Media matrix" colSpan={12} />
    </>
  );
};
