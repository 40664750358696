import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { calculateRoi } from 'src/utils/calculateRoi';
import { ROITimeFrameData } from '../types';

const calculateRatioRoi = (
  totalRoi: number,
  totalEffect: number,
  longOrShortEffect: number,
) => {
  if (totalEffect === 0) {
    return 0;
  }
  return totalRoi * (longOrShortEffect / totalEffect);
};

export const getRoiTimeFrameData = (
  data: Record<number, Array<ApiLongTermEffectsDataPoint>>,
  currentCustomer: string | null,
): Array<ROITimeFrameData> => {
  return Object.entries(data).map(([utcEpoch, dataPoints]) => {
    let totalEffectTotal = 0;
    let shortEffectTotal = 0;
    let longEffectTotal = 0;
    let investmentsTotal = 0;

    const investmentsByMedia: Record<string, number> = {};
    const effectByMedia: Record<
      string,
      { long: number; short: number; total: number }
    > = {};

    dataPoints.forEach(({ mediaLongTermEffects }) => {
      mediaLongTermEffects.forEach(
        ({ media, longTermEffect, shortTermEffect, spend }) => {
          if (!investmentsByMedia[media]) {
            investmentsByMedia[media] = 0;
          }

          investmentsByMedia[media] += spend;
          investmentsTotal += spend;

          const totalEffect = shortTermEffect + longTermEffect;
          shortEffectTotal += shortTermEffect;
          longEffectTotal += longTermEffect;
          totalEffectTotal += totalEffect;

          if (!effectByMedia[media]) {
            effectByMedia[media] = {
              long: 0,
              total: 0,
              short: 0,
            };
          }

          effectByMedia[media].total += totalEffect;
          effectByMedia[media].short += shortTermEffect;
          effectByMedia[media].long += longTermEffect;
        },
      );
    });

    const roiByTotalMedia: Record<string, number> = {};
    const roiByShortMedia: Record<string, number> = {};
    const roiByLongMedia: Record<string, number> = {};

    Object.entries(investmentsByMedia).forEach(([media, investment]) => {
      const { long, short, total } = effectByMedia[media];
      const totalRoiMedia = calculateRoi({
        mediaIncrementalSales: total,
        investment,
        customer: currentCustomer,
      });
      const shortRoiMedia = calculateRatioRoi(totalRoiMedia, total, short);
      const longRoiMedia = calculateRatioRoi(totalRoiMedia, total, long);
      roiByTotalMedia[media] = totalRoiMedia;
      roiByShortMedia[media] = shortRoiMedia;
      roiByLongMedia[media] = longRoiMedia;
    });

    const totalEffectROI = calculateRoi({
      mediaIncrementalSales: totalEffectTotal,
      investment: investmentsTotal,
      customer: currentCustomer,
    });

    const shortEffectROI = calculateRatioRoi(
      totalEffectROI,
      totalEffectTotal,
      shortEffectTotal,
    );
    const longEffectROI = calculateRatioRoi(
      totalEffectROI,
      totalEffectTotal,
      longEffectTotal,
    );

    return {
      utcEpoch: Number(utcEpoch),
      totalEffect: totalEffectTotal,
      investments: investmentsTotal,
      totalEffectROI: calculateRoi({
        mediaIncrementalSales: totalEffectTotal,
        investment: investmentsTotal,
        customer: currentCustomer,
      }),
      shortEffectROI,
      longEffectROI,
      roiByTotalMedia,
      roiByShortMedia,
      roiByLongMedia,
    };
  });
};
