import React, { useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarLineGraph } from 'src/components/molecules/graphs/BarLineGraph';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { sortEpoch } from 'src/utils/date';
import {
  INCREMENTAL_SALES_DEF,
  PREDICTED_SALES_DEF,
  SALES_DEF,
} from 'src/utils/metricDefinitions';
import { useMMDataContext } from '../../../contexts/MMDataContext';
import { TimeseriesDataTableModal } from '../../molecules/TimeseriesDataTableModal';

type Datapoint = {
  Sales: number;
  'Predicted sales': number;
  'Incremental sales': number;
  utcEpoch: number;
};

const xAxis: keyof Datapoint = 'utcEpoch';
const barData: Array<keyof Datapoint> = ['Incremental sales'];
const lineData: Array<keyof Datapoint> = ['Sales', 'Predicted sales'];

const dataKeys = {
  xAxis,
  barData,
  lineData,
};

const legendProps: DataBoxLegendProps = {
  placement: 'top',
  baseLegendKeys: dataKeys.barData,
  lineKeys: dataKeys.lineData,
};

export const DecompositionsBox: React.FC<DataBoxBaseProps> = ({ ...props }) => {
  const { decomps, sales, predictedSales } = useMMDataContext();
  const { aggregateLevel } = useAggregationLevelContext();

  const xAxisFormatter = useGetDateAxisFormatter();

  const [infoOpen, setInfoOpen] = useState(false);

  const isLoading =
    sales.isLoading || decomps.isLoading || predictedSales.isLoading;
  const isError = sales.isError || decomps.isError || predictedSales.isError;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const yAxisFormatter = useChartEffectTickFormatter();

  const processedChartData: Array<Datapoint> = useMemo(() => {
    const salesData = getAggregateLevelData(sales, aggregateLevel);
    const predictedSalesData = getAggregateLevelData(
      predictedSales,
      aggregateLevel,
    );
    const decompsData = getAggregateLevelData(decomps, aggregateLevel);

    const dataByEpoch: Record<number, Datapoint> = {};

    salesData.forEach((s) => {
      dataByEpoch[s.utcEpoch] = {
        Sales: s.value,
        'Incremental sales': 0,
        'Predicted sales': 0,
        utcEpoch: s.utcEpoch,
      };
    });

    decompsData.forEach((d) => {
      if (dataByEpoch[d.utcEpoch]) {
        dataByEpoch[d.utcEpoch]['Incremental sales'] = d.value;
      } else {
        dataByEpoch[d.utcEpoch] = {
          Sales: 0,
          'Predicted sales': 0,
          'Incremental sales': d.value,
          utcEpoch: d.utcEpoch,
        };
      }
    });

    predictedSalesData.forEach((d) => {
      if (dataByEpoch[d.utcEpoch]) {
        dataByEpoch[d.utcEpoch]['Predicted sales'] = d.value;
      } else {
        dataByEpoch[d.utcEpoch] = {
          Sales: 0,
          'Incremental sales': 0,
          'Predicted sales': d.value,
          utcEpoch: d.utcEpoch,
        };
      }
    });

    return Object.values(dataByEpoch).sort(sortEpoch);
  }, [sales, decomps, predictedSales, aggregateLevel]);

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={`${INCREMENTAL_SALES_DEF}\n\n${SALES_DEF}\n\n${PREDICTED_SALES_DEF}`}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        exportEnabled
        exportButtonEnabled={false}
        setTableDataModalIsOpen={setModalIsOpen}
        isError={isError}
        isLoading={isLoading}
        hasData={processedChartData.length > 0}>
        <>
          <TimeseriesDataTableModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            yFormatter={yAxisFormatter}
            data={processedChartData}
            headerName={props.title ?? ''}
          />
          <BarLineGraph
            data={processedChartData}
            xAxisKey={dataKeys.xAxis}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={yAxisFormatter}
          />
        </>
      </DataBox>
    </>
  );
};
