import { useEffect, useState } from 'react';
import { getToggledData } from 'src/utils/getToggleFunctions';
import { sortByLocationName } from 'src/utils/sort';
import { SelectableLocation, SosLocation } from '../types';
import { AvailableRegionsAndCitiesState } from './useAvailableRegionsAndCitiesForCountry';

export const useSelectableAggregateLocations = (
  availableRegionsAndCitiesState: AvailableRegionsAndCitiesState,
  selectedCountry: SosLocation,
) => {
  const [selectableLocations, setSelectableLocations] = useState<
    Array<SelectableLocation>
  >([{ ...selectedCountry, selected: true }]);

  useEffect(() => {
    setSelectableLocations((currentSelectableLocations) => {
      const selectedLocationIdSet = new Set(
        currentSelectableLocations
          .filter((d) => d.selected)
          .map((d) => d.locationId),
      );

      let regionAlreadySelected = false;
      let cityAlreadySelected = false;

      // From the state, we choose only the selected, but when the data changes, we check the current selectedLocations
      // if they are already selected. If they are we preserve that select status
      const selectableRegions = availableRegionsAndCitiesState.regions
        .filter(({ selected }) => selected)
        .map((loc) => {
          const selected = selectedLocationIdSet.has(loc.locationId);
          if (selected) {
            regionAlreadySelected = true;
          }

          return { ...loc, selected };
        });

      const selectableCities = availableRegionsAndCitiesState.selectableCities
        .filter(({ selected }) => selected)
        .map((loc) => {
          const selected = selectedLocationIdSet.has(loc.locationId);

          if (selected) {
            cityAlreadySelected = true;
          }

          return { ...loc, selected };
        });

      let shouldCountryBeSelected = selectedLocationIdSet.has(
        selectedCountry.locationId,
      );

      // Even if the coutry was not already selected, if there are no regions or cities selected
      // We force the country as selected
      if (!regionAlreadySelected && !cityAlreadySelected) {
        shouldCountryBeSelected = true;
      }

      const sortedRegionsAndCities = sortByLocationName([
        ...selectableRegions,
        ...selectableCities,
      ]);

      // Keep country as first item
      return [
        {
          ...selectedCountry,
          selected: shouldCountryBeSelected,
        },
        ...sortedRegionsAndCities,
      ];
    });
  }, [
    availableRegionsAndCitiesState.selectableCities,
    availableRegionsAndCitiesState.regions,
    selectedCountry,
  ]);

  const toggleAggregateLocations = (
    locationsToToggle: Array<SelectableLocation>,
  ) => {
    const newToggled = getToggledData(
      selectableLocations,
      locationsToToggle,
      (c) => c.locationId.toString(),
    );

    setSelectableLocations(newToggled);
  };

  return {
    toggleAggregateLocations,
    selectableAggregateLocations: selectableLocations,
  };
};
