import { createContext, useContext } from 'react';
import { ResponseCurveState } from 'src/hooks/fetch/useABCResponseCurveFetch';
import { SimplifiedDataState } from 'src/types';
import { MMDataState, TotalDecompsState } from '../../types';

export type MMDataContextValue = {
  sales: SimplifiedDataState;
  predictedSales: SimplifiedDataState;
  totalDecomps: TotalDecompsState;
  responseCurve: ResponseCurveState;
  margins: SimplifiedDataState;
  roi: MMDataState;
  romi: MMDataState;
  romiEnabled: boolean;
  investments: MMDataState;
  decomps: MMDataState;
  cpu: MMDataState;
  mediaEffect: MMDataState;
};

export const MMDataContext = createContext<MMDataContextValue | null>(null);
MMDataContext.displayName = 'MMDataContext';

export function useMMDataContext() {
  const value = useContext(MMDataContext);
  if (!value) {
    throw new Error('useMMDataContext has to be used under FilterProvider');
  }
  return value;
}
