import React from 'react';
import { Page } from 'src/components/molecules/Page';
import { DAKeyMetrics } from 'src/features/DA/components/organisms/DAKeyMetrics';
import { FilterBar } from 'src/components/organisms/FilterBar';
import { Channels } from 'src/features/DA/components/templates/Channels';

export const ChannelsPage: React.FC = () => {
  return (
    <Page
      filterBar={<FilterBar includeBenchmark />}
      keyMetrics={<DAKeyMetrics />}>
      <Channels />
    </Page>
  );
};
