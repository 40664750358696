import { useEffect, useMemo, useState } from 'react';
import { useChartDataColors } from 'src/hooks/colors/useChartColors';
import { sortByLabel } from 'src/utils/sort';
import { LegendContext, LegendContextValue, LegendValue } from '.';

export type LegendSelectBehaviour = 'multi' | 'single';

export type LegendContextProviderProps = {
  baseLegendKeys?: string[]; // Default keys, displayed in circular fashion
  lineKeys?: string[]; // Line keys, displayed with a line
  keyColorMap?: Record<string, string>;
  labelMap?: Record<string, string>; // Used to map keys to labels
  autoSortKeys?: boolean;
  lineKeysFirst?: boolean;
  legendSelectBehaviour?: LegendSelectBehaviour;
  children: React.ReactNode;
};

const getSortedLegendValues = (
  legendValues: Array<LegendValue>,
  autoSortKeys: boolean,
) => {
  if (autoSortKeys) {
    sortByLabel(legendValues);
  }

  return legendValues;
};

// Only toggle clicked one off or on
const getMultiClickedLegends = (key: string, oldValues: LegendValue[]) => {
  return oldValues.map((v) => {
    if (v.key === key) {
      return { ...v, selected: !v.selected };
    }
    return v;
  });
};

// If legend already selected, select all.
// Otherwise only select the chosen one
const getSingleClickedLegends = (
  key: string,
  oldValues: LegendValue[],
  oldSelectedKey: string | null,
) => {
  const isLegendAlreadySelected = key === oldSelectedKey;

  if (isLegendAlreadySelected) {
    return oldValues.map((v) => {
      return { ...v, selected: true };
    });
  }

  return oldValues.map((v) => {
    const selected = v.key === key;
    return { ...v, selected };
  });
};

const getLabel = (key: string, labelMap: Record<string, string> = {}) => {
  return labelMap[key] ?? key;
};

export const LegendContextProvider: React.FC<LegendContextProviderProps> = ({
  children,
  baseLegendKeys = [],
  keyColorMap,
  autoSortKeys = true,
  lineKeysFirst = false,
  legendSelectBehaviour = 'multi',
  lineKeys = [],
  labelMap,
}) => {
  const { chartDataColors } = useChartDataColors();

  const [legendValues, setLegendValues] = useState<Array<LegendValue>>([]);
  // Only supported and used in singe click legends
  const [selectedKey, setSelectedKey] = useState<string | null>('');

  const stringifiedBaseLengedKeys = JSON.stringify(baseLegendKeys);
  const stringifiedLineKeys = JSON.stringify(lineKeys);

  useEffect(() => {
    const newLegendValues: Array<LegendValue> = baseLegendKeys.map((key, i) => {
      const color =
        keyColorMap && keyColorMap[key] ? keyColorMap[key] : chartDataColors[i];

      const label = getLabel(key, labelMap);

      return {
        color,
        key,
        label,
        selected: true, // Default select all when keys change
        legendType: 'base',
      };
    });

    const lineLegendValues: Array<LegendValue> = lineKeys.map((key, i) => {
      const color =
        keyColorMap && keyColorMap[key] ? keyColorMap[key] : chartDataColors[i];

      const label = getLabel(key, labelMap);

      return {
        color,
        key,
        label,
        selected: true,
        legendType: 'line',
      };
    });

    const sortedLegend = getSortedLegendValues(newLegendValues, autoSortKeys);
    const sortedLine = getSortedLegendValues(lineLegendValues, autoSortKeys);

    if (lineKeysFirst) {
      setLegendValues([...sortedLine, ...sortedLegend]);
    } else {
      setLegendValues([...sortedLegend, ...sortedLine]);
    }

    setSelectedKey(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stringifiedBaseLengedKeys,
    chartDataColors,
    stringifiedLineKeys,
    keyColorMap,
    lineKeysFirst,
    labelMap,
  ]);

  const value: LegendContextValue = useMemo(() => {
    const onLegendClick = (key: string) => {
      setLegendValues((oldValues) => {
        if (legendSelectBehaviour === 'single') {
          return getSingleClickedLegends(key, oldValues, selectedKey);
        }
        return getMultiClickedLegends(key, oldValues);
      });

      if (legendSelectBehaviour === 'single') {
        setSelectedKey((oldKey) => {
          if (oldKey === key) {
            return null;
          }

          return key;
        });
      }
    };

    const keyToSelected: Record<string, boolean> = {};
    const keyToColor: Record<string, string> = {};

    legendValues.forEach(({ key, selected, color }) => {
      keyToSelected[key] = selected;
      keyToColor[key] = color;
    });

    const selectedBaseLegendValues = legendValues.filter(
      ({ selected, legendType }) => {
        return selected && legendType === 'base';
      },
    );

    const selectedLineLegendValues = legendValues.filter(
      ({ selected, legendType }) => {
        return selected && legendType === 'line';
      },
    );

    return {
      keyToSelected,
      keyToColor,
      legendValues,
      onLegendClick,
      selectedBaseLegendValues,
      selectedLineLegendValues,
      selectedKey,
    };
  }, [legendValues, legendSelectBehaviour, selectedKey]);

  return (
    <LegendContext.Provider value={value}>{children}</LegendContext.Provider>
  );
};
