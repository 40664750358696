import { SimplifiedDataState, SimplifiedTimeframeData } from 'src/types';
import { calculateMediaEffect } from 'src/utils/calculateMediaEffect';
import { sortEpoch } from 'src/utils/date';
import { MMDataState, TimeframeData } from '../types';

type CalculateMediaEffectFromRecordsParams = {
  byXDecomps: Record<string, number>;
  predictedSales: number;
};

const calculateMediaEffectFromRecords = ({
  byXDecomps,
  predictedSales,
}: CalculateMediaEffectFromRecordsParams): Record<string, number> => {
  const resultRecord: Record<string, number> = {};

  Object.entries(byXDecomps).forEach(([key, mediaIncrementalSales]) => {
    resultRecord[key] = calculateMediaEffect({
      mediaIncrementalSales,
      predictedSales,
    });
  });

  return resultRecord;
};

const combineTimeframeData = (
  decompsData: Array<TimeframeData>,
  predictedSalesData: Array<SimplifiedTimeframeData>,
): Array<TimeframeData> => {
  const byUtcEpoch: Record<
    number,
    {
      decomps: TimeframeData | null;
      predictedSales: number | null;
    }
  > = {};

  const initByUtcEpochRecordIfNecessary = (utcEpoch: number) => {
    if (!byUtcEpoch[utcEpoch]) {
      byUtcEpoch[utcEpoch] = {
        decomps: null,
        predictedSales: null,
      };
    }
  };

  decompsData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].decomps = datapoint;
  });

  predictedSalesData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].predictedSales = datapoint.value;
  });

  const timeframeData: Array<TimeframeData> = [];

  Object.entries(byUtcEpoch).forEach(
    ([utcEpoch, { predictedSales, decomps }]) => {
      if (predictedSales === null || decomps === null) {
        return;
      }

      const value = calculateMediaEffect({
        mediaIncrementalSales: decomps.value,
        predictedSales,
      });

      const d: TimeframeData = {
        byCampaignId: calculateMediaEffectFromRecords({
          byXDecomps: decomps.byCampaignId,
          predictedSales,
        }),
        byMedia: calculateMediaEffectFromRecords({
          byXDecomps: decomps.byMedia,
          predictedSales,
        }),
        byTag: calculateMediaEffectFromRecords({
          byXDecomps: decomps.byTag,
          predictedSales,
        }),
        byCategory: calculateMediaEffectFromRecords({
          byXDecomps: decomps.byCategory,
          predictedSales,
        }),
        utcEpoch: Number(utcEpoch),
        value,
      };

      timeframeData.push(d);
    },
  );

  return timeframeData.sort(sortEpoch);
};

export const aggregateMediaEffect = (
  decomps: MMDataState,
  predictedSales: SimplifiedDataState,
): MMDataState => {
  const isLoading = decomps.isLoading || predictedSales.isLoading;
  const isError = decomps.isError || predictedSales.isError;

  const totalDecomps = decomps.total;
  const totalPredictedSales = predictedSales.total;

  const total = calculateMediaEffect({
    mediaIncrementalSales: totalDecomps,
    predictedSales: totalPredictedSales,
  });

  const data: MMDataState = {
    isLoading,
    isError,
    week: combineTimeframeData(decomps.week, predictedSales.week),
    month: combineTimeframeData(decomps.month, predictedSales.month),
    quarter: combineTimeframeData(decomps.quarter, predictedSales.quarter),
    biannual: combineTimeframeData(decomps.biannual, predictedSales.biannual),
    year: combineTimeframeData(decomps.year, predictedSales.year),
    total,
    totalByCampaignId: calculateMediaEffectFromRecords({
      byXDecomps: decomps.totalByCampaignId,
      predictedSales: totalPredictedSales,
    }),
    totalByCategory: calculateMediaEffectFromRecords({
      byXDecomps: decomps.totalByCategory,
      predictedSales: totalPredictedSales,
    }),
    totalByMedia: calculateMediaEffectFromRecords({
      byXDecomps: decomps.totalByMedia,
      predictedSales: totalPredictedSales,
    }),
    totalByTag: calculateMediaEffectFromRecords({
      byXDecomps: decomps.totalByTag,
      predictedSales: totalPredictedSales,
    }),
  };

  return data;
};
