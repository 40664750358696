import { useMemo } from 'react';
import { SimplifiedDataState } from 'src/types';
import { INIT_MM_DATA_STATE, MMDataState } from '../types';
import { aggregateRomi } from '../utils/aggregateRomi';

export const useRomi = (
  enabled: boolean,
  investments: MMDataState,
  decomps: MMDataState,
  sales: SimplifiedDataState,
  margins: SimplifiedDataState,
  productionCosts: MMDataState,
) => {
  return useMemo(() => {
    if (!enabled) {
      return { romi: INIT_MM_DATA_STATE, romiEnabled: false };
    }

    const romi = aggregateRomi(
      margins,
      investments,
      decomps,
      sales,
      productionCosts,
    );

    const romiEnabled =
      !romi.isError && !romi.isLoading && romi.week.length > 0;

    return { romi, romiEnabled };
  }, [enabled, investments, decomps, sales, margins, productionCosts]);
};
