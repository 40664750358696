import { createContext, useContext } from 'react';
import { EffectsState, InvestmentsState, ROIState } from '../../types';

export interface LTDataContextValue {
  roi: ROIState;
  investments: InvestmentsState;
  effects: EffectsState;
  isLoading: boolean;
  isError: boolean;
}

export const LTDataContext = createContext<LTDataContextValue | null>(null);
LTDataContext.displayName = 'LTDataContext';

export function useLTDataContext() {
  const value = useContext(LTDataContext);
  if (!value) {
    throw new Error('useLTDataContext has to be used under FilterProvider');
  }
  return value;
}
