import { TableCellProps } from '@chakra-ui/react';

export const getHightlightCellStyle = (
  value: number,
  [lowerbound, upperbound]: [number, number],
): TableCellProps => {
  const cellStyle: TableCellProps = {
    textAlign: 'center',
    width: 0.01,
  };

  if (value > upperbound) {
    cellStyle.color = 'black';
    cellStyle.backgroundColor = 'green';
  }

  if (value < lowerbound) {
    cellStyle.color = 'black';
    cellStyle.backgroundColor = 'red';
  }

  return cellStyle;
};
