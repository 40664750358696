import { useEffect, useState } from 'react';
import { useDecompsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';

import { INIT_MM_DATA_STATE, MMDataState } from 'src/features/MM/types';
import { toApiDates } from 'src/utils/date';
import { filterDecompsData } from 'src/utils/filters';
import { aggregateApiDataToDataState } from '../../utils/aggregate';

export function useFetchDecomps(enabled: boolean) {
  const {
    currentModelVersion,
    timeRange,
    filteredCampaignIds,
    media,
    campaignToTags,
  } = useFilterContext();

  const [decomps, setDecomps] = useState<MMDataState>(INIT_MM_DATA_STATE);

  const {
    data: queryData,
    isLoading: queryIsLoading,
    isError: queryIsError,
  } = useDecompsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      model_id: currentModelVersion?.modelID ?? 'missing_mdoelid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setDecomps(INIT_MM_DATA_STATE);
      return;
    }

    const filteredData = filterDecompsData(
      queryData ?? [],
      filteredCampaignIds,
      media,
    );

    const newState = aggregateApiDataToDataState(
      'createdValue',
      'value',
      filteredData,
      queryIsLoading,
      queryIsError,
      campaignToTags,
    );

    setDecomps(newState);
  }, [
    queryData,
    campaignToTags,
    queryIsLoading,
    queryIsError,
    enabled,
    filteredCampaignIds,
    media,
  ]);

  return {
    decomps,
  };
}
