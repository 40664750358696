import { useActiveRoute } from 'src/hooks/useActiveRoute';
import { AppServices } from 'src/app/routes/utils';

export const useFilterBarHeight = () => {
  const { activeRoute } = useActiveRoute();

  if (activeRoute?.service === AppServices.SOS) {
    return '60px';
  }

  if (activeRoute?.service === AppServices.LT) {
    return '90px';
  }

  if (activeRoute?.service === AppServices.MO) {
    return '90px';
  }

  return '110px';
};
