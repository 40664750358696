import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TooltipContent,
  TooltipContentExtraMap,
} from 'src/components/atoms/tooltips/TooltipContent';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';
import {
  MonthYearDateFormatter,
  PercentageValueTickFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';
import { CUSTOMER } from './constants';

export type BrandVsOthersSearchVolumeDataPoint = {
  utcEpoch: number;
  otherSearchVolume: number;
  brandSearchVolume: number;
  previousYearChange: number | null;
};

type BrandVsOthersSearchVolumeChartProps = {
  data: Array<BrandVsOthersSearchVolumeDataPoint>;
  brandName: string;
};

export const BrandVsOthersSearchVolumeChart: React.FC<
  BrandVsOthersSearchVolumeChartProps
> = ({ data, brandName }) => {
  const { gridColor } = useChartColors();

  const { selectedBaseLegendValues, selectedLineLegendValues } =
    useLegendContext();

  const axisStyles = useAxisStyles();

  const toolTopicExtraMap: TooltipContentExtraMap = {
    otherSearchVolume: {
      label: 'Others',
      valueFormatter: ValueTickFormatter,
    },
    brandSearchVolume: {
      label: brandName,
      valueFormatter: ValueTickFormatter,
    },
    previousYearChange: {
      label: 'YoY Change %',
      valueFormatter: PercentageValueTickFormatter,
    },
  };

  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="0" strokeWidth={1} stroke={gridColor} />
        <YAxis
          width={70}
          yAxisId="volume"
          tickFormatter={ValueTickFormatter}
          {...axisStyles}
        />
        <YAxis
          orientation="right"
          width={70}
          yAxisId="percent"
          tickFormatter={PercentageValueTickFormatter}
          {...axisStyles}
        />

        <XAxis
          dataKey={'utcEpoch'}
          tickFormatter={MonthYearDateFormatter}
          {...axisStyles}
        />
        <Tooltip
          offset={25}
          content={
            <TooltipContent
              forceMulti
              extraMap={toolTopicExtraMap}
              valueFormatter={PercentageValueTickFormatter}
              labelFormatter={MonthYearDateFormatter}
            />
          }
        />

        {selectedBaseLegendValues.map((v) => {
          if (v.key == CUSTOMER) {
            return (
              <Bar
                key={v.key}
                yAxisId={'volume'}
                dataKey={'brandSearchVolume'}
                stackId={'a'}
                fill={v.color}
              />
            );
          }

          return (
            <Bar
              key={v.key}
              yAxisId={'volume'}
              dataKey={'otherSearchVolume'}
              stackId={'a'}
              fill={v.color}
            />
          );
        })}

        {selectedLineLegendValues.map((v) => {
          return (
            <Line
              key={v.key}
              isAnimationActive={false}
              yAxisId="percent"
              dataKey={'previousYearChange'}
              stroke={v.color}
              dot={false}
              strokeWidth={4}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
