import { useEffect, useState } from 'react';
import { INIT_SIMPLIFIED_DATA_STATE, SimplifiedDataState } from 'src/types';
import { aggregateSimpleData } from 'src/utils/aggregate';
import { useSalesQuery } from '../../api/queries';
import { useFilterContext } from '../../contexts/FilterContext';
import { toApiDates } from '../../utils/date';

export const useFetchSales = (enabled: boolean) => {
  const { currentModelVersion, timeRange, benchmarkTimerange } =
    useFilterContext();

  const [sales, setSales] = useState<SimplifiedDataState>(
    INIT_SIMPLIFIED_DATA_STATE,
  );

  const [benchmarkSales, setBenchmarkSales] = useState<SimplifiedDataState>(
    INIT_SIMPLIFIED_DATA_STATE,
  );

  const salesQueryBase = {
    customer: currentModelVersion?.customerName ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useSalesQuery(
    {
      ...salesQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Benchmark timerange investments data
  const benchmarkQueryEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;
  const {
    data: benchmarkData,
    isLoading: isBenchmarkLoading,
    isError: isBenchmarkError,
  } = useSalesQuery(
    {
      ...salesQueryBase,
      ...toApiDates(benchmarkTimerange),
    },
    benchmarkQueryEnabled,
  );

  useEffect(() => {
    if (!enabled) {
      setSales(INIT_SIMPLIFIED_DATA_STATE);
      return;
    }

    const data = apiData ?? [];

    const salesData = aggregateSimpleData('sales', data, isLoading, isError);
    setSales(salesData);
  }, [apiData, isLoading, isError, enabled]);

  // Benchmark data
  useEffect(() => {
    if (!benchmarkQueryEnabled) {
      setBenchmarkSales(INIT_SIMPLIFIED_DATA_STATE);
      return;
    }

    const data = benchmarkData ?? [];

    const salesData = aggregateSimpleData(
      'sales',
      data,
      isBenchmarkLoading,
      isBenchmarkError,
    );
    setBenchmarkSales(salesData);
  }, [
    benchmarkData,
    isBenchmarkLoading,
    isBenchmarkError,
    benchmarkQueryEnabled,
  ]);

  return { sales, benchmarkSales };
};
