import React from 'react';
import { KeyMetricsGrid } from 'src/components/molecules/KeyMetricsGrid';
import { Investment } from './Investment';
import { LongTermEffect } from './LongTermEffect';
import { ROI } from './ROI';
import { ShortTermEffect } from './ShortTermEffect';
import { TotalEffect } from './TotalEffect';

export const LTKeyMetrics: React.FC = () => {
  return (
    <KeyMetricsGrid>
      <TotalEffect />
      <ShortTermEffect />
      <LongTermEffect />
      <ROI />
      <Investment />
    </KeyMetricsGrid>
  );
};
