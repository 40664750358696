import {
  MenuDivider,
  // eslint-disable-next-line no-restricted-imports
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
} from '@chakra-ui/react';
import React from 'react';
import { MenuOption } from '../atoms/MenuOption';
import { SearchBox } from '../atoms/SearchBox';

type MenuListProps = {
  onAllClick?: () => void;
  searchProps?: {
    searchValue: string;
    onSearchChange: (value: string) => void;
  };
  buttonName: string;
} & ChakraMenuListProps;

export const MenuList: React.FC<MenuListProps> = ({
  onAllClick,
  searchProps,
  children,
  buttonName,
  ...menuProps
}) => {
  return (
    <ChakraMenuList
      position={'relative'}
      overflowY="auto"
      maxHeight="600px"
      maxWidth={'60vw'}
      pt={searchProps ? 0 : undefined}
      {...menuProps}>
      {searchProps && (
        <SearchBox
          value={searchProps.searchValue}
          onChange={searchProps.onSearchChange}
        />
      )}

      {onAllClick && (
        <>
          <MenuOption
            buttonName={buttonName}
            label="All"
            value="All"
            isSelected={false}
            onClick={onAllClick}
          />

          <MenuDivider />
        </>
      )}
      {children}
    </ChakraMenuList>
  );
};
