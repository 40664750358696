import { AuthUser } from 'aws-amplify/auth';
import { DEV_EMAILS } from 'src/constants';

export const DEVELOPER = 'developer';
export const EMPLOYEE = 'employee';
export const CUSTOMER = 'customer';

export type UserType = typeof DEVELOPER | typeof EMPLOYEE | typeof CUSTOMER;

const DEVELOPER_EMAILS = DEV_EMAILS.map((email) => email.toLowerCase());

const EMPLOYEE_EMAILS_ADDRESSES = [
  'tbwa.fi',
  'omd.com',
  'toinenphd.com',
  'omnicommediagroup.com',
];

export const getUserType = (user: AuthUser): UserType => {
  // When developing we can keep dev emails locally empty, and all users are handled as developers
  if (DEV_EMAILS.length === 0) {
    return 'developer';
  }

  const email = user.username.toLowerCase();

  const isDev = DEVELOPER_EMAILS.includes(email);

  if (isDev) {
    return 'developer';
  }

  const isEmployee = EMPLOYEE_EMAILS_ADDRESSES.some(
    (employee_email_address) => {
      return email.endsWith(`@${employee_email_address}`);
    },
  );

  if (isEmployee) {
    return 'employee';
  }

  return 'customer';
};
