import { createContext, useContext } from 'react';
import { SimplifiedDataState } from 'src/types';
import { ConversionsDataState } from '../../hooks/useConversions';
import { InvestmentsDataState } from '../../hooks/fetch/useFetchInvestments';
import { OrganicSearchData } from '../../hooks/fetch/useFetchOrganicSearch';

type DADataContextValue = {
  sales: SimplifiedDataState;
  benchmarkSales: SimplifiedDataState;
  investments: InvestmentsDataState;
  benchmarkInvestments: InvestmentsDataState;
  conversions: ConversionsDataState;
  benchmarkConversions: ConversionsDataState;
  organicSearch: OrganicSearchData;
  benchmarkOrganicSearch: OrganicSearchData;
  toggleKeywordCluster: (clusterName: string) => void;
  toggleAllKeywordClusters: () => void;
  keywordClusterFilters: Record<string, boolean>;
};

export const DADataContext = createContext<DADataContextValue | null>(null);
DADataContext.displayName = 'DADataContext';

export function useDADataContext() {
  const value = useContext(DADataContext);
  if (!value) {
    throw new Error(
      'useDADataContext has to be used under DADataContextProvider',
    );
  }
  return value;
}
