import { createContext, useContext } from 'react';
import { CreateOptimizationRequest } from 'src/api/mutations';
import { InvestmentsData } from '../../hooks/useFetchInvestments';
import { OptimizationsData } from '../../hooks/useOptimizations';

type MODataContextValue = {
  optimizations: OptimizationsData;
  investments: InvestmentsData;
  createOptimization: (data: CreateOptimizationRequest) => Promise<void>;
  deleteOptimization: (optimizationId: string) => Promise<void>;
  marginPct: number | null;
};

export const MODataContext = createContext<MODataContextValue | null>(null);
MODataContext.displayName = 'MODataContext';

export function useMODataContext() {
  const value = useContext(MODataContext);
  if (!value) {
    throw new Error(
      'useMODataContext has to be used under MODataContextProvider',
    );
  }
  return value;
}
