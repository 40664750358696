/* eslint-disable no-restricted-imports */
import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
} from '@chakra-ui/react';
import { useMenuFontActiveColor } from 'src/hooks/colors/useMenuFontActiveColor';
import { menuButtonClickedEvent } from 'src/libs/amp';

export type MenuButtonProps = Omit<ChakraMenuButtonProps, 'onClick'> & {
  buttonName: string;
};

export const MenuButton: React.FC<MenuButtonProps> = ({
  buttonName,
  ...props
}) => {
  const { fontActiveColor } = useMenuFontActiveColor();

  return (
    <ChakraMenuButton
      onClick={() => {
        menuButtonClickedEvent({ buttonName });
      }}
      color={fontActiveColor}
      fontSize="16px"
      {...props}
    />
  );
};
