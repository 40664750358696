import React from 'react';
import { Title } from 'src/components/molecules/DataBox/Title';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { mapLocationDataToMovingAverageData } from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import { SoSMonthlyForLocationBarChartBox } from '../../organisms/SoSMonthlyForLocationBarChartBox';
import { SoSMonthlyForLocationMovingAverageLineChartBox } from '../../organisms/SoSMonthlyForLocationMovingAverageLineChartBox';
import { SelectedLocationsMenu } from '../../organisms/menus/SelectedLocationsMenu';
import { SosByYearsLocationsAggregateTableBox } from '../../organisms/SosByYearsLocationsAggregateTableBox';

export const AggregateChartsAndTables = () => {
  const { shareOfSearchLocationsAggregateState } = useSOSDataContext();

  const locationMovingAverageData = React.useMemo(
    () =>
      mapLocationDataToMovingAverageData(
        shareOfSearchLocationsAggregateState.data,
      ).filter((d) => d.hasMovingAverageData),
    [shareOfSearchLocationsAggregateState.data],
  );

  return (
    <>
      <SoSMonthlyForLocationMovingAverageLineChartBox
        minH={400}
        colSpan={12}
        titleComponent={
          <Title>
            Share of search 12MRA
            <SelectedLocationsMenu />
          </Title>
        }
        data={locationMovingAverageData}
        isError={shareOfSearchLocationsAggregateState.isError}
        isLoading={shareOfSearchLocationsAggregateState.isLoading}
      />
      <SoSMonthlyForLocationBarChartBox
        minH={400}
        colSpan={12}
        data={locationMovingAverageData}
        isError={shareOfSearchLocationsAggregateState.isError}
        isLoading={shareOfSearchLocationsAggregateState.isLoading}
        titleComponent={
          <Title>
            Share of search by months
            <SelectedLocationsMenu />
          </Title>
        }
      />
      <SosByYearsLocationsAggregateTableBox
        minH={400}
        colSpan={12}
        titleComponent={
          <Title>
            Share of search by years
            <SelectedLocationsMenu />
          </Title>
        }
      />
    </>
  );
};
