import React from 'react';
import { Icon } from './Icon';

type MenuTriangleProps = {
  isOpen: boolean;
};

export const MenuTriangle: React.FC<MenuTriangleProps> = ({ isOpen }) => {
  return (
    <>
      {isOpen ? (
        <Icon iconName="triangle-up" height="12px" width="12px" ml=".5rem" />
      ) : (
        <Icon iconName="triangle-down" height="12px" width="12px" ml=".5rem" />
      )}
    </>
  );
};
