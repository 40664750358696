import React from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { ValueTickFormatter } from 'src/utils/TickFormatters';
import { useMetricsBySearchChannel } from '../../hooks/useMetricsBySearchChannel';

export const MetricsBySearchChannelBarGraph: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { data, isError, isLoading, dataKeys } = useMetricsBySearchChannel();

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'top',
  };

  return (
    <DataBox
      {...props}
      legendProps={legendProps}
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <BarGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        stacked={false}
        yAxisFormatter={ValueTickFormatter}
      />
    </DataBox>
  );
};
