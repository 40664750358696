import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { useSOSDataContext } from '../../../contexts/SOSDataContext';
import { SosByYearsTable } from '../../molecules/SosByYearsTable';

export const SosByYearsCountryTableBox: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { shareOfSearchCountryMonthlyState } = useSOSDataContext();

  const isError = shareOfSearchCountryMonthlyState.isError;
  const isLoading = shareOfSearchCountryMonthlyState.isLoading;

  return (
    <DataBox
      {...props}
      exportEnabled
      hasData={shareOfSearchCountryMonthlyState.data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <SosByYearsTable
        csvFileName="sos-by-years"
        data={shareOfSearchCountryMonthlyState.data}
      />
    </DataBox>
  );
};
