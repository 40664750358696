import { LTDataContextProvider } from 'src/features/LT/contexts/LTDataContext/LTDataContextProvider';
import NoSubscriptionPage from '../NoSubscriptionPage';
import { LongTermEffectsPage } from './LongTermEffects';

type LongTermEffectsPagesProps = {
  isSubscribed: boolean;
};

export const LongTermEffectsPages: React.FC<LongTermEffectsPagesProps> = ({
  isSubscribed,
}) => {
  if (!isSubscribed) {
    return <NoSubscriptionPage service="Long-term Effects" />;
  }

  return (
    <LTDataContextProvider>
      <LongTermEffectsPage />
    </LTDataContextProvider>
  );
};
