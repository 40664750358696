import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { AggregateLevel } from 'src/types';
import { useMMDataContext } from '../../../contexts/MMDataContext';

type RoiProps = {
  aggregateLevel: AggregateLevel;
  dateFormatter: (value: string | number) => string;
};

export const Roi: React.FC<RoiProps> = ({ aggregateLevel, dateFormatter }) => {
  const { roi } = useMMDataContext();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    return {
      isLoading: roi.isLoading,
      isError: roi.isError,
      name: 'ROI',
      isEuro: false,
      summaryValue: roi.total,
      data: getAggregateLevelData(roi, aggregateLevel),
    };
  }, [roi, aggregateLevel]);

  return (
    <KeyMetricChart
      keyMetricData={keyMetricData}
      dateFormatter={dateFormatter}
    />
  );
};
