import React, { useMemo } from 'react';
import { useKpiUnit } from '../../../../../hooks/useKpiUnit';
import { useLTDataContext } from '../../../contexts/LTDataContext';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from '../../../../../components/organisms/KeyMetricChart';

export const TotalEffect: React.FC = () => {
  const { effects, isError, isLoading } = useLTDataContext();

  const { isEuro } = useKpiUnit();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    let sum = 0;
    const data = effects.monthly.map((x) => {
      const value = x.totalMediaEffect;
      sum += value;
      return {
        utcEpoch: x.utcEpoch,
        value,
      };
    });

    return {
      name: 'Total effect',
      data,
      isEuro,
      summaryValue: sum,
      isError,
      isLoading,
    };
  }, [effects, isError, isLoading, isEuro]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
