import request from './request';

type FetchPresignedUrlParams = {
  customer: string;
  model_build: string;
  model_id: string;
};

type FetchPresignedUrlResponse = {
  masterDataUrl: string;
};

export const fetchPresignedMasterDataUrl = async (
  params: FetchPresignedUrlParams,
) => {
  const { data } = await request.get<FetchPresignedUrlResponse>(
    '/data/masterdataurl',
    { params },
  );

  return data.masterDataUrl;
};
