import { useMemo } from 'react';
import { useAvailableTags } from 'src/hooks/useAvailableTags';
import { useMetrics } from './useMetrics';

export const useMetricsByTag = () => {
  const { selectedTags } = useAvailableTags();
  const {
    data: nonFilteredData,
    isError,
    isLoading,
    dataKeys,
  } = useMetrics('byTag');

  const data = useMemo(() => {
    return nonFilteredData.filter((d) => selectedTags.includes(d.key));
  }, [nonFilteredData, selectedTags]);

  return {
    data,
    isError,
    isLoading,
    dataKeys,
  };
};
