import { useMemo } from 'react';
import { isSearchChannel } from 'src/utils/Data';
import { useDADataContext } from '../contexts/DADataContext';
import {
  BENCHMARK_IMPRESSIONS_NAME,
  BENCHMARK_MODELLED_CONV_NAME,
  MODELLED_CONV_NAME,
} from '../utils/constants';
import { useBenchmarkEnabled } from './useBenchmarkEnabled';
import { MetricsData, useMetrics } from './useMetrics';

export const useMetricsBySearchChannel = () => {
  const { organicSearch, benchmarkOrganicSearch } = useDADataContext();
  const { organicSearchBenchmarkEnabled } = useBenchmarkEnabled();

  const {
    data: nonFilteredData,
    isError,
    isLoading,
    dataKeys,
  } = useMetrics('byChannel');

  const data = useMemo(() => {
    const byChannel: Array<Partial<MetricsData>> = nonFilteredData.filter((d) =>
      isSearchChannel(d.key),
    );

    if (organicSearch.data.length === 0) {
      return byChannel;
    }
    let totalOrganicImpressionsSum = 0;
    let totalOrganicConversionsSum = 0;

    organicSearch.data.forEach((d) => {
      d.keywordClusters.forEach(({ impressions, modelledConversions }) => {
        totalOrganicConversionsSum += modelledConversions;
        totalOrganicImpressionsSum += impressions;
      });
    });

    if (organicSearchBenchmarkEnabled) {
      let totalBenchmarkOrganicImpressionsSum = 0;
      let totalBenchmarkOrganicConversionsSum = 0;

      benchmarkOrganicSearch.data.forEach((d) => {
        d.keywordClusters.forEach(({ impressions, modelledConversions }) => {
          totalBenchmarkOrganicConversionsSum += modelledConversions;
          totalBenchmarkOrganicImpressionsSum += impressions;
        });
      });

      byChannel.push({
        key: 'Organic search',
        [MODELLED_CONV_NAME]: totalOrganicConversionsSum,
        [BENCHMARK_MODELLED_CONV_NAME]: totalBenchmarkOrganicConversionsSum,
        Impressions: totalOrganicImpressionsSum,
        [BENCHMARK_IMPRESSIONS_NAME]: totalBenchmarkOrganicImpressionsSum,
      });
    } else {
      byChannel.push({
        key: 'Organic search',
        Impressions: totalOrganicImpressionsSum,
        [MODELLED_CONV_NAME]: totalOrganicConversionsSum,
      });
    }

    return byChannel;
  }, [
    nonFilteredData,
    organicSearchBenchmarkEnabled,
    benchmarkOrganicSearch,
    organicSearch,
  ]);

  return { data, isError, isLoading, dataKeys };
};
