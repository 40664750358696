import { useEffect, useState } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import {
  ConversionsDataPointByCampaign,
  ConversionsDataPointByChannel,
  ConversionsDataPointByTag,
  ConversionsDataPointByTime,
} from '../types';
import { aggregateConversions } from '../utils/conversionsAggregator';
import { InvestmentsDataState } from './fetch/useFetchInvestments';
import { ModelledConversionsDataState } from './fetch/useFetchModelledConversions';
import { ObsConversionsDataState } from './fetch/useFetchObservedConversions';

export type ConversionsDataState = {
  data: ConversionsDataPointByTime[];
  weekly: ConversionsDataPointByTime[];
  monthly: ConversionsDataPointByTime[];
  byChannel: ConversionsDataPointByChannel[];
  byCampaign: ConversionsDataPointByCampaign[];
  byTag: ConversionsDataPointByTag[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_CONVERSIONS_DATA: Readonly<ConversionsDataState> = {
  data: [],
  weekly: [],
  monthly: [],
  byChannel: [],
  byCampaign: [],
  byTag: [],
  isError: false,
  isLoading: false,
};

// Supposed to only be called from context
export const useConversions = (
  enabled: boolean,
  investmentsDataState: InvestmentsDataState,
  modelledConversionsDataState: ModelledConversionsDataState,
  obsConversionsDataState: ObsConversionsDataState,
) => {
  const { filteredCampaignIds, media, campaignsMap } = useFilterContext();

  const [conversions, setConversions] = useState<ConversionsDataState>(
    INIT_CONVERSIONS_DATA,
  );

  // Normal data
  useEffect(() => {
    if (!enabled) {
      setConversions(INIT_CONVERSIONS_DATA);
      return;
    }

    const loading =
      investmentsDataState.isLoading ||
      modelledConversionsDataState.isLoading ||
      obsConversionsDataState.isLoading;

    const error =
      investmentsDataState.isError ||
      modelledConversionsDataState.isError ||
      obsConversionsDataState.isError;

    const newState = aggregateConversions(
      obsConversionsDataState.data,
      modelledConversionsDataState.data,
      investmentsDataState.daily,
      campaignsMap,
      loading,
      error,
    );

    setConversions(newState);
  }, [
    enabled,
    modelledConversionsDataState.data,
    modelledConversionsDataState.isError,
    modelledConversionsDataState.isLoading,
    obsConversionsDataState.data,
    obsConversionsDataState.isError,
    obsConversionsDataState.isLoading,
    investmentsDataState.daily,
    investmentsDataState.isLoading,
    investmentsDataState.isError,
    filteredCampaignIds,
    media,
    campaignsMap,
  ]);

  return {
    conversions,
  };
};
