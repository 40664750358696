import { createContext, useContext } from 'react';

type LegendKey = string;

export type LegendValue = {
  label: string;
  key: string;
  color: string;
  selected: boolean;
  legendType: 'line' | 'base';
};

type SelectedLegendValue = {
  label: string;
  key: string;
  color: string;
};

export type LegendContextValue = {
  legendValues: Array<LegendValue>;
  selectedBaseLegendValues: Array<SelectedLegendValue>; // Precalculate for performance
  selectedLineLegendValues: Array<SelectedLegendValue>; // Precalculate for performance
  keyToSelected: Record<LegendKey, boolean>; // Precalculate for performance
  keyToColor: Record<LegendKey, string>; // Precalculate for performance
  onLegendClick: (key: string) => void; // Legend calls this
  selectedKey: string | null; // Only supported on selectType single (not multi)
};

export const LegendContext = createContext<LegendContextValue | null>(null);
LegendContext.displayName = 'LegendContext';

export function useLegendContext() {
  const value = useContext(LegendContext);
  if (!value) {
    throw new Error(
      'useLegendContext has to be used under LegendContextProvider',
    );
  }
  return value;
}
