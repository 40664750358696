import { Navigate, Route, Routes } from 'react-router-dom';
import { ModellingPage } from 'src/pages/MarketingMixModelling/Modelling';
import { OverviewPage } from 'src/pages/MarketingMixModelling/Overview';

import { AggregationLevelContextProvider } from 'src/features/MM/contexts/AggregationLevelContext/AggregationLevelContextProvider';
import { MMDataContextProvider } from 'src/features/MM/contexts/MMDataContext/MMDataContextProvider';
import { SelectedDimensionContextProvider } from 'src/features/MM/contexts/SelectedDimensionContext/SelectedDimensionContextProvider';
import NoSubscriptionPage from '../NoSubscriptionPage';
import { SummaryPage } from './Summary';
import { TrendsPage } from './Trends';

type MarketingMixModellingPagesProps = {
  isSubscribed: boolean;
};

export const MarketingMixModellingPages: React.FC<
  MarketingMixModellingPagesProps
> = ({ isSubscribed }) => {
  if (!isSubscribed) {
    return <NoSubscriptionPage service="Marketing Mix Modelling" />;
  }

  return (
    <AggregationLevelContextProvider>
      <SelectedDimensionContextProvider>
        <MMDataContextProvider>
          <Routes>
            <Route element={<OverviewPage />} path="overview" />
            <Route element={<SummaryPage />} path="summary" />
            <Route element={<TrendsPage />} path="trends" />
            <Route element={<ModellingPage />} path="modelling" />
            <Route path="/" element={<Navigate replace to="/mmm/overview" />} />
          </Routes>
        </MMDataContextProvider>
      </SelectedDimensionContextProvider>
    </AggregationLevelContextProvider>
  );
};
