import { FC } from 'react';
import { FilterOptionButton } from 'src/components/molecules/FilterOptionButton';
import { TimePeriod } from 'src/types';
import { useFilterContext } from '../../../contexts/FilterContext';

type TimePeriodButtonProps = {
  timePeriod: TimePeriod;
};

export const TimePeriodButton: FC<TimePeriodButtonProps> = ({ timePeriod }) => {
  const { timeRange, updateTimeRange } = useFilterContext();

  const timeRangeSelected = timePeriod === timeRange?.period;

  return (
    <FilterOptionButton
      label={timePeriod}
      isSelected={timeRangeSelected}
      onClick={() => updateTimeRange(timePeriod)}
    />
  );
};
