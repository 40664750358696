import { useMemo, useState } from 'react';
import { searchResults } from 'src/utils/search';

export const useSearch = <D>(data: Array<D>, keys: Array<keyof D>) => {
  const [searchValue, setSearchValue] = useState('');

  const results = useMemo(
    () => searchResults(data, searchValue, keys),
    [keys, data, searchValue],
  );

  return {
    searchValue,
    setSearchValue,
    results,
  };
};
