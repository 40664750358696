import { Text } from '@chakra-ui/react';
import React from 'react';
import { Dayjs } from 'src/libs/dayjs';

export type SelectedDateRangeProps = {
  start: Dayjs;
  end: Dayjs;
  fontActiveColor: string;
  bold: boolean;
};

export const SelectedDateRange: React.FC<SelectedDateRangeProps> = ({
  fontActiveColor,
  start,
  end,
  bold,
}) => {
  const selectedStartDate = start.toDate();
  const selectedEndDate = end.toDate();

  return (
    <Text
      as="button"
      fontSize="16px"
      mr="16px"
      style={
        bold
          ? {
              borderBottom: '2px solid',
              fontWeight: 700,
            }
          : undefined
      }
      color={fontActiveColor}>
      {`${selectedStartDate.toLocaleDateString()} - ${selectedEndDate.toLocaleDateString()}`}
    </Text>
  );
};
