import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthUIContainer } from 'src/components/templates/AuthUIContainer';
import { NavBarLayout } from 'src/components/templates/NavBarLayout';
import { CurrentAuthState, useAuthContext } from 'src/contexts/AuthContext';
import { AppRoutes } from './routes';
import { CustomerSettingsContextProvider } from 'src/contexts/CustomerSettingsContext/CustomerSettingsContextProvider';
import { FilterContextProvider } from 'src/contexts/FilterContext/FilterContextProvider';

export function App() {
  const { userProfile, currentAuthState, refreshAuthState } = useAuthContext();

  /**
   * Make sure the context is kept in sync -
   * whenever the app is reloaded, refresh
   * the authentication state.
   */
  useEffect(() => {
    refreshAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentAuthState === CurrentAuthState.SignedIn &&
    userProfile?.email ? (
    <div className="App">
      <BrowserRouter>
        <NavBarLayout>
          <CustomerSettingsContextProvider>
            <FilterContextProvider>
              <AppRoutes />
            </FilterContextProvider>
          </CustomerSettingsContextProvider>
        </NavBarLayout>
      </BrowserRouter>
    </div>
  ) : (
    <AuthUIContainer />
  );
}
