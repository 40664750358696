import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { useCPULabel } from 'src/features/MM/hooks/useCPULabel';
import { useRoiDef } from 'src/utils/calculateRoi';
import {
  INCREMENTAL_SALES_DEF,
  MEDIA_EFFECT_DEF,
  MEDIA_INVESTMENT_DEF,
  ROMI_DEF,
  getCPUDef,
} from 'src/utils/metricDefinitions';

const getBodyText = (
  romiEnabled: boolean,
  cpuLabel: string,
  roiDef: string,
) => {
  let bodyText = `${MEDIA_INVESTMENT_DEF}

  ${INCREMENTAL_SALES_DEF}

  ${getCPUDef(cpuLabel)}

  ${MEDIA_EFFECT_DEF}

  ${roiDef}`;

  if (romiEnabled) {
    bodyText = bodyText + `\n\n${ROMI_DEF}`;
  }

  return bodyText;
};

type SummaryInfoAlertProps = {
  infoOpen: boolean;
  onClose: () => void;
};

export const SummaryInfoAlert: React.FC<SummaryInfoAlertProps> = ({
  infoOpen,
  onClose,
}) => {
  const cpuLabel = useCPULabel();
  const roiDef = useRoiDef();
  const { romiEnabled } = useMMDataContext();

  const bodyText = getBodyText(romiEnabled, cpuLabel, roiDef);

  return (
    <InfoAlert
      headerText={'Summary'}
      bodyText={bodyText}
      isOpen={infoOpen}
      onClose={onClose}
    />
  );
};
