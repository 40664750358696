import { ApiModelVersionDataPoint, KpiUnit } from '../api/queries';
import { useFilterContext } from '../contexts/FilterContext';

const getKpiUnit = (mv: ApiModelVersionDataPoint | null): KpiUnit => {
  if (!mv) {
    return 'unit';
  }
  return mv.kpiUnit;
};

export const useKpiUnit = () => {
  const { currentModelVersion } = useFilterContext();

  const unit = getKpiUnit(currentModelVersion);

  const isEuro = unit === 'euro';

  return { unit, isEuro };
};
