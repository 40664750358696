import React, { useEffect, useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { TOTAL_DECOMPS_DEF } from 'src/utils/metricDefinitions';
import { useAggregationLevelContext } from '../../contexts/AggregationLevelContext';
import { useGetDateAxisFormatter } from '../../hooks/useGetDateAxisFormatter';
import { getTotalDecompsStateAggregateLevelData } from '../../utils/getAggregateLevelData';
import {
  BaseSelectorMenu,
  BaseSelectorOption,
} from '../molecules/BaseSelectorMenu';
import { totalDecompsByMetricToBaseSelectorOptions } from '../molecules/BaseSelectorMenu/utils';
import { TimeseriesDataTableModal } from '../molecules/TimeseriesDataTableModal';

type DataPoint = {
  utcEpoch: number;
  base: number;
  'Media effect': number;
} & Record<string, number>;

export const TotalDecompsBox: React.FC<DataBoxBaseProps> = (props) => {
  const { totalDecomps } = useMMDataContext();
  const { aggregateLevel } = useAggregationLevelContext();

  const xAxisFormatter = useGetDateAxisFormatter();

  const [baseSelectOptions, setBaseSelectOptions] = useState<
    Array<BaseSelectorOption>
  >(totalDecompsByMetricToBaseSelectorOptions(totalDecomps));

  useEffect(() => {
    setBaseSelectOptions(
      totalDecompsByMetricToBaseSelectorOptions(totalDecomps),
    );
  }, [totalDecomps]);

  const yAxisFormatter = useChartEffectTickFormatter();

  const isError = totalDecomps.isError;
  const isLoading = totalDecomps.isLoading;

  const [infoOpen, setInfoOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const processedData: { data: Array<DataPoint>; dataKeys: string[] } =
    useMemo(() => {
      const data: Array<DataPoint> = [];

      const metricsToIncludeInBase: Record<string, true> = {};

      const renderedMetrics: Record<string, true> = {};

      baseSelectOptions.forEach((opt) => {
        if (opt.isSelected) {
          metricsToIncludeInBase[opt.metric] = true;
        } else {
          renderedMetrics[opt.metric] = true;
        }
      });

      const aggregateData = getTotalDecompsStateAggregateLevelData(
        totalDecomps,
        aggregateLevel,
      );

      aggregateData.forEach((w) => {
        let weeklyBase = w.base;

        const otherMetrics: Record<string, number> = {};

        Object.entries(w.byOtherMetric).forEach(([metric, value]) => {
          if (metricsToIncludeInBase[metric]) {
            weeklyBase += value;
          } else {
            otherMetrics[metric] = value;
          }
        });

        data.push({
          utcEpoch: w.utcEpoch,
          base: weeklyBase,
          'Media effect': w.mediaEffect,
          ...otherMetrics,
        });
      });

      const dataKeys = [
        'base',
        'Media effect',
        ...Object.keys(renderedMetrics),
      ];
      return { data, dataKeys };
    }, [totalDecomps, baseSelectOptions, aggregateLevel]);

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      baseLegendKeys: processedData.dataKeys,
      placement: 'right',
    };
  }, [processedData.dataKeys]);

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={TOTAL_DECOMPS_DEF}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        exportEnabled
        exportButtonEnabled={false}
        setTableDataModalIsOpen={setModalIsOpen}
        hasData={processedData.data.length > 0}
        isError={isError}
        isLoading={isLoading}
        restrictChartHeight
        rightContent={
          <BaseSelectorMenu
            options={baseSelectOptions}
            setOptions={setBaseSelectOptions}
          />
        }>
        <>
          <TimeseriesDataTableModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            yFormatter={yAxisFormatter}
            data={processedData.data}
            headerName={props.title ?? ''}
          />
          <BarGraph
            data={processedData.data}
            xAxisKey={'utcEpoch'}
            stacked
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={yAxisFormatter}
          />
        </>
      </DataBox>
    </>
  );
};
