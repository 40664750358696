import React, { useMemo } from 'react';
import { Tooltip } from 'recharts';
import { ResponseCurveTooltipContent } from 'src/components/atoms/tooltips/ResponseCurveTooltipContent';
import { useExportCsvContext } from 'src/components/molecules/ExportCsv/ExportCsvContext';
import {
  LineScatterDataType,
  LineScatterGraph,
} from 'src/components/molecules/graphs/LineScatterGraph';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { useTimeFreqMultiplier } from 'src/features/MM/hooks/useTimeFreqMultiplier';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { ResponseCurveData, ResponseCurveDatapoint } from 'src/types';
import { EuroValueTickFormatter } from 'src/utils/TickFormatters';
import { generateResponseCurveDatapoints } from 'src/utils/responseCurve';

type CsvRow = {
  spend: number | null;
  response: number | null;
  abcA: number | null;
  abcB: number | null;
  abcC: number | null;
};

type MapDataToExportDataParams = {
  responseCurveDataForChannel: ResponseCurveData;
  abcA: number;
  abcB: number;
  abcC: number;
};

const mapDataToExportData = ({
  abcA,
  abcB,
  abcC,
  responseCurveDataForChannel,
}: MapDataToExportDataParams): Array<CsvRow> => {
  const rows: Array<CsvRow> = [
    { spend: null, response: null, abcA, abcB, abcC },
  ];

  responseCurveDataForChannel.data.forEach(({ response, spend }) => {
    rows.push({
      spend,
      response,
      abcA: null,
      abcB: null,
      abcC: null,
    });
  });
  return rows;
};

const dataKeys = {
  xAxis: 'spend',
  yAxis: 'response',
};

type SShapeInsightsCurveChartProps = {
  activeIndex: number;
};

export const SShapeInsightsCurveChart: React.FC<
  SShapeInsightsCurveChartProps
> = ({ activeIndex }) => {
  const { setExportData } = useExportCsvContext();

  const { getMediaName } = useCustomerSettingsContext();

  const valueFormatter = useChartEffectTickFormatter();
  const { valueMultiplier } = useTimeFreqMultiplier();
  const { responseCurve } = useMMDataContext();

  const responseCurveData = responseCurve.data;

  const data: LineScatterDataType<ResponseCurveDatapoint>[] = useMemo(() => {
    const currentData = responseCurveData[activeIndex];
    if (!currentData) {
      return [];
    }

    const generatedData = generateResponseCurveDatapoints(
      [currentData],
      3000,
      valueMultiplier,
      2,
    );

    if (generatedData.length !== 1) {
      return [];
    }

    const responseCurveDataForChannel = generatedData[0];

    const mediaName = getMediaName(currentData.channel);

    const fileName = `s_curve_${mediaName}`;

    const csvData = mapDataToExportData({
      abcA: currentData.abcA,
      abcB: currentData.abcB,
      abcC: currentData.abcC,
      responseCurveDataForChannel,
    });

    setExportData({ csvData, fileName });

    return [
      {
        name: mediaName,
        data: responseCurveDataForChannel.data,
        points: [
          {
            response: responseCurveDataForChannel.meanResponse,
            spend: responseCurveDataForChannel.meanSpend,
          },
        ],
      },
    ];
  }, [
    responseCurveData,
    activeIndex,
    getMediaName,
    valueMultiplier,
    setExportData,
  ]);

  return (
    <LineScatterGraph
      xAxisLabel="Investment"
      yAxisLabel="Effect"
      data={data}
      dataKeys={dataKeys}
      xAxisFormatter={EuroValueTickFormatter}
      yAxisFormatter={valueFormatter}>
      <Tooltip
        offset={25}
        content={
          <ResponseCurveTooltipContent
            valueFormatter={valueFormatter}
            responseCurveData={data}
          />
        }
      />
    </LineScatterGraph>
  );
};
