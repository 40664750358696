import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type TimePeriod = 'monthly' | 'yearly';

export type TimePeriodTabsProps = {
  selectedTab: TimePeriod;
  onSelect: (value: TimePeriod) => void;
};

const tabs: Array<TabOption<TimePeriod>> = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
];

export const TimePeriodTabs: React.FC<TimePeriodTabsProps> = ({
  selectedTab,
  onSelect,
}) => {
  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
