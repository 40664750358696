import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useCurrentAppService } from 'src/hooks/useCurrentAppService';
import { selectModelEvent } from 'src/libs/amp';
import { Campaign, CampaignTag, Media } from 'src/types';
import { getToggledData } from 'src/utils/getToggleFunctions';
import { FilterContext } from '.';
import { useCampaignTags } from './useCampaignTags';
import { useCampaigns } from './useCampaigns';
import { useMedia } from './useMedia';
import { ModelVersion, useModelVersions } from './useModelVersions';
import { useTimerange } from './useTimerange';

export function FilterContextProvider(props: PropsWithChildren) {
  const currentAppService = useCurrentAppService();

  const [currentModelVersion, setCurrentModelVersion] =
    useState<ModelVersion | null>(null);

  const {
    timeRange,
    updateTimeRange,
    setBenchmarkTimerange,
    benchmarkTimerange,
    setBenchmarkEnabled,
    updateTimeRangeFromModel,
    setTimeRangeLocked,
    timeRangeLocked,
  } = useTimerange(currentModelVersion);

  const { modelVersionsState } = useModelVersions(
    currentAppService,
    setCurrentModelVersion,
    updateTimeRangeFromModel,
    timeRangeLocked,
  );

  const { campaignTagGroups, setCampaignTagGroups } = useCampaignTags(
    currentModelVersion,
    currentAppService,
  );

  const {
    campaigns,
    setCampaigns,
    filteredCampaignIds,
    campaignsMap,
    campaignToTags,
    campaignsLocked,
    setCampaignsLocked,
  } = useCampaigns(
    currentModelVersion,
    timeRange,
    currentAppService,
    campaignTagGroups,
  );

  const { media, setMedia, mediaLocked, setMediaLocked, mediaColorMap } =
    useMedia(currentModelVersion);

  useEffect(() => {
    if (currentModelVersion) {
      selectModelEvent({
        clientName: currentModelVersion.customerName,
        modelName: currentModelVersion.alias,
        modelVersion: currentModelVersion.modelVersion,
        modelType: currentModelVersion.modelType,
      });
    }
  }, [currentModelVersion]);

  const value = useMemo(() => {
    // Handle changes to model versions
    const changeCurrentModelVersion = (newModelVersion: string): void => {
      const newModel = modelVersionsState.modelVersions.find((v) => {
        return v.modelVersion === newModelVersion;
      });

      setCurrentModelVersion(newModel ?? null);
      if (newModel && !timeRangeLocked) {
        updateTimeRangeFromModel(newModel);
      }
    };

    // Availablity check handled elsewhere
    const toggleMediaItem = (m: Media) => {
      const updatedMedia: Media[] = media.map((item) => {
        // Toggle active if name equality
        if (item.id === m.id) {
          item.selected = !item.selected;
        }

        return item;
      });
      setMedia(updatedMedia);
    };

    const toggleAllMediaItems = () => {
      const allSelected = media.reduce((prev, curr) => {
        // Ignore items that are not available
        if (!curr.isAvailable) {
          return true;
        }
        return prev && curr.selected;
      }, true);
      const updatedMedia = media.map((item) => {
        if (item.isAvailable) {
          item.selected = !allSelected;
        }
        return item;
      });
      setMedia(updatedMedia);
    };

    // Available and visible checks handled elsewhere checks handled elsewhere
    const toggleTags = (tagsToBeToggled: Array<CampaignTag>) => {
      const allSelected = tagsToBeToggled.reduce((prev, curr) => {
        return prev && !!curr.selected;
      }, true);

      const toggledTagNameSet = new Set(tagsToBeToggled.map((tag) => tag.name));

      const updatedTagGroups = campaignTagGroups.map((tagGroup) => {
        // Ignore non-selected tagGroups
        if (!tagGroup.selected) {
          return tagGroup;
        }

        tagGroup.tags = tagGroup.tags.map((tag) => {
          // Ignore tags that were not toggled
          if (!toggledTagNameSet.has(tag.name)) {
            return tag;
          }

          tag.selected = !allSelected;
          return tag;
        });
        return tagGroup;
      });

      setCampaignTagGroups(updatedTagGroups);
    };

    // Available and visibility checks handled elsewhere in code
    function toggleCampaigns(campaignsToBeToggled: Array<Campaign>) {
      const updatedCampaigns = getToggledData(
        campaigns,
        campaignsToBeToggled,
        (c) => c.id,
      );

      setCampaigns(updatedCampaigns);
    }

    const toggleAllCampaignTagGroups = () => {
      const allSelected = campaignTagGroups.reduce((prev, curr) => {
        return curr.selected && prev;
      }, true);

      const updatedTagGroups = campaignTagGroups.map((tagGroup) => {
        const newTags = tagGroup.tags.map((tag) => {
          tag.visible = !allSelected;
          tag.selected = !allSelected;
          return tag;
        });
        return { ...tagGroup, tags: newTags, selected: !allSelected };
      });
      setCampaignTagGroups(updatedTagGroups);
    };

    // Is available check handled elsewhere
    const toggleCampaignTagGroup = (tagGroup: string) => {
      const updatedTagGroups = campaignTagGroups.map((group) => {
        const isMatchingGroup = group.tagGroup === tagGroup;

        if (!isMatchingGroup) {
          return group;
        }

        const tagGroupIsSelected = !group.selected;

        const newTags = group.tags.map((tag) => {
          const newTag = tag;
          newTag.visible = tagGroupIsSelected;
          newTag.selected = tagGroupIsSelected;
          return newTag;
        });
        return { ...group, tags: newTags, selected: tagGroupIsSelected };
      });
      setCampaignTagGroups(updatedTagGroups);
    };

    return {
      currentAppService,
      currentModelVersion,
      campaignsMap,
      changeCurrentModelVersion,
      modelVersionsState,
      campaigns,
      toggleCampaigns,
      campaignTagGroups,
      filteredCampaignIds,
      toggleAllCampaignTagGroups,
      toggleCampaignTagGroup,
      toggleTags,
      media,
      toggleAllMediaItems,
      toggleMediaItem,
      timeRange,
      updateTimeRange,
      campaignToTags,
      setBenchmarkTimerange,
      setBenchmarkEnabled,
      benchmarkTimerange,
      timeRangeLocked,
      setTimeRangeLocked,
      setMediaLocked,
      mediaLocked,
      campaignsLocked,
      setCampaignsLocked,
      mediaColorMap,
    };
  }, [
    setCampaignTagGroups,
    setCurrentModelVersion,
    updateTimeRangeFromModel,
    updateTimeRange,
    setCampaigns,
    setBenchmarkTimerange,
    setBenchmarkEnabled,
    benchmarkTimerange,
    currentAppService,
    currentModelVersion,
    modelVersionsState,
    campaigns,
    campaignTagGroups,
    filteredCampaignIds,
    media,
    timeRange,
    campaignsMap,
    campaignToTags,
    setMedia,
    setMediaLocked,
    mediaLocked,
    campaignsLocked,
    setCampaignsLocked,
    timeRangeLocked,
    setTimeRangeLocked,
    mediaColorMap,
  ]);

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
}
