export const getToggledData = <A extends { selected: boolean }>(
  allData: Array<A>,
  dataToBeToggled: Array<A>,
  getId: (x: A) => string,
): Array<A> => {
  const allSelected = dataToBeToggled.reduce((prev, curr) => {
    return prev && !!curr.selected;
  }, true);

  const toggledIdSet = new Set(dataToBeToggled.map(getId));

  const newToggledData = allData.map((x) => {
    if (!toggledIdSet.has(getId(x))) {
      return x;
    }

    const newData: A = x;
    newData.selected = !allSelected;
    return newData;
  });

  return newToggledData;
};
