import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { SummaryTableBox } from '../../organisms/SummaryTableBox';
import { getDimensionName } from 'src/features/MM/utils/getDimensionName';

export const Summary: React.FC = () => {
  const { dimension } = useSelectedDimensionContext();

  return (
    <SummaryTableBox
      title={`Summary by ${getDimensionName(dimension)}`}
      colSpan={12}
    />
  );
};
