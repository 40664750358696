import { Dimension, MMDataState, TimeframeData } from '../types';

export const getDimensionLevelTotalData = (
  state: MMDataState,
  dimension: Dimension,
) => {
  switch (dimension) {
    case Dimension.category: {
      return state.totalByCategory;
    }
    case Dimension.campagin:
      return state.totalByCampaignId;
    case Dimension.media:
      return state.totalByMedia;
    case Dimension.tag:
      return state.totalByTag;
  }
};

export const getDimensionLevelTimeframeData = (
  data: TimeframeData,
  dimension: Dimension,
) => {
  switch (dimension) {
    case Dimension.category: {
      return data.byCategory;
    }
    case Dimension.campagin:
      return data.byCampaignId;
    case Dimension.media:
      return data.byMedia;
    case Dimension.tag:
      return data.byTag;
  }
};
