import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import React from 'react';

type ErrorNotifBoxProps = {
  title: string;
  description?: string;
};

export const ErrorNotifBox: React.FC<ErrorNotifBoxProps> = ({
  title,
  description,
}) => {
  return (
    <Alert my={4} status="error">
      <AlertIcon />
      <AlertTitle mr={2}>{title}</AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
    </Alert>
  );
};
