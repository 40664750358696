import React from 'react';
import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAxisStyles } from 'src/hooks/colors/useChartColors';
import { TooltipContent } from '../../atoms/tooltips/TooltipContent';
import { useLegendContext } from '../Legend/LegendContext';

export type BarGraphProps = {
  data: object[];
  xAxisKey: string;
  stacked: boolean;
  xAxisFormatter?: (value: number | string) => string;
  xAxisTooltipFormatter?: (value: number | string) => string;
  yAxisFormatter?: (value: number) => string;
};

// Requires use of legend
export const BarGraph: React.FC<BarGraphProps> = ({
  data,
  xAxisKey,
  stacked,
  xAxisTooltipFormatter,
  xAxisFormatter = (value: number | string) => value.toString(),
  yAxisFormatter = (value: number) => value.toString(),
}) => {
  const axisStyles = useAxisStyles();

  const { selectedBaseLegendValues } = useLegendContext();

  const tooltipLabelFormatter = xAxisTooltipFormatter ?? xAxisFormatter;

  return (
    <ResponsiveContainer>
      <ComposedChart layout={'horizontal'} data={data}>
        <YAxis
          width={70}
          tickFormatter={yAxisFormatter}
          padding={{ bottom: 6 }}
          {...axisStyles}
        />
        <XAxis
          dataKey={xAxisKey}
          padding={{ left: 16, right: 16 }}
          tickFormatter={xAxisFormatter}
          {...axisStyles}
        />
        <Tooltip
          offset={25}
          content={
            <TooltipContent
              valueFormatter={yAxisFormatter}
              labelFormatter={tooltipLabelFormatter}
            />
          }
        />
        {selectedBaseLegendValues.map((v) => {
          return (
            <Bar
              key={v.key}
              dataKey={v.key}
              stackId={stacked ? 'a' : v.key}
              fill={v.color}
              isAnimationActive={false}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
