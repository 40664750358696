import { useMemo } from 'react';
import { DataTable } from 'src/components/molecules/DataTable';
import { KeywordsWithEpoch } from 'src/features/SOS/types';
import { getTableData, useColumns } from './useTableData';

type SosByYearsTableProps = {
  data: Array<KeywordsWithEpoch>;
  csvFileName: string;
};

export const SosByYearsTable: React.FC<SosByYearsTableProps> = ({
  data,
  csvFileName,
}) => {
  const [tableData, years] = useMemo(() => {
    return getTableData(data);
  }, [data]);

  const columns = useColumns(years);

  return (
    <DataTable csvFileName={csvFileName} data={tableData} columns={columns} />
  );
};
