import { useMemo } from 'react';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { useByDimensionNameAndId } from 'src/features/MM/hooks/useByDimensionId';
import { MMDataState } from 'src/features/MM/types';
import { getMMDataStateAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { getDimensionLevelTimeframeData } from 'src/features/MM/utils/getDimensionLevelData';

export type TrendsChartDataRow = {
  utcEpoch: number;
} & Record<string, number>;

export const useChartData = (
  metricState: MMDataState,
  shouldDataBePercent: boolean,
  totalLineLabel?: string,
): { legendKeys: Array<string>; data: Array<TrendsChartDataRow> } => {
  const { aggregateLevel } = useAggregationLevelContext();
  const { dimension } = useSelectedDimensionContext();

  const byDimensionNameAndId = useByDimensionNameAndId();

  const data = useMemo(() => {
    const timeframeData = getMMDataStateAggregateLevelData(
      metricState,
      aggregateLevel,
    );

    const dataRows: Array<TrendsChartDataRow> = [];

    timeframeData.forEach((d) => {
      const total = d.value;
      const dimensionRecord = getDimensionLevelTimeframeData(d, dimension);

      const dataRow: TrendsChartDataRow = {
        utcEpoch: d.utcEpoch,
      };
      if (totalLineLabel) {
        dataRow[totalLineLabel] = total;
      }

      byDimensionNameAndId.forEach(({ name, id }) => {
        const value = dimensionRecord[id] ?? 0;
        const percent = total ? (value * 100) / total : 0;
        dataRow[name] = shouldDataBePercent ? percent : value;
      });

      dataRows.push(dataRow);
    });

    return dataRows;
  }, [
    dimension,
    byDimensionNameAndId,
    aggregateLevel,
    metricState,
    totalLineLabel,
    shouldDataBePercent,
  ]);

  const legendKeys = useMemo(() => {
    return byDimensionNameAndId.map(({ name }) => name);
  }, [byDimensionNameAndId]);

  return {
    data,
    legendKeys,
  };
};
