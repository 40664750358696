import React, { useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { LineGraph } from 'src/components/molecules/graphs/LineGraph';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';
import { getAggregateLevelData } from 'src/features/MM/utils/getAggregateLevelData';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { combineTwoSimplifiedTimeframeData } from 'src/utils/combine';
import { useMMDataContext } from '../../contexts/MMDataContext';
import { TimeseriesDataTableModal } from '../molecules/TimeseriesDataTableModal';
import { PREDICTED_SALES_DEF, SALES_DEF } from 'src/utils/metricDefinitions';

type ProcessedDataPoint = {
  utcEpoch: number;
  Sales: number | null;
  'Predicted sales': number | null;
};

const xAxisKey: keyof ProcessedDataPoint = 'utcEpoch';

const dataKeys = {
  xAxis: xAxisKey,
  data: ['Sales', 'Predicted sales'],
};

const legendProps: DataBoxLegendProps = {
  placement: 'top',
  lineKeys: dataKeys.data,
};

export const SalesAndPredictedSalesBox: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { sales, predictedSales } = useMMDataContext();
  const { aggregateLevel } = useAggregationLevelContext();

  const [infoOpen, setInfoOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const yAxisFormatter = useChartEffectTickFormatter();

  const xAxisFormatter = useGetDateAxisFormatter();

  const processedData = useMemo(() => {
    const salesData = getAggregateLevelData(sales, aggregateLevel);
    const predictedSalesData = getAggregateLevelData(
      predictedSales,
      aggregateLevel,
    );

    const combinedData: Array<ProcessedDataPoint> =
      combineTwoSimplifiedTimeframeData(
        salesData,
        'Sales',
        predictedSalesData,
        'Predicted sales',
      );

    return combinedData;
  }, [sales, predictedSales, aggregateLevel]);

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={`${SALES_DEF}\n\n${PREDICTED_SALES_DEF}`}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        exportEnabled
        exportButtonEnabled={false}
        setTableDataModalIsOpen={setModalIsOpen}
        hasData={processedData.length > 0}
        isError={sales.isError}
        isLoading={sales.isLoading}>
        <>
          <TimeseriesDataTableModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            yFormatter={yAxisFormatter}
            data={processedData}
            headerName={props.title ?? ''}
          />
          <LineGraph
            data={processedData}
            xAxisKey={dataKeys.xAxis}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={yAxisFormatter}
          />
        </>
      </DataBox>
    </>
  );
};
