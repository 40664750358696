import { Fragment, useEffect } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TooltipContent } from 'src/components/atoms/tooltips/TooltipContent';
import { SupportedCsvRowObject } from 'src/components/molecules/ExportCsv';
import { useExportCsvContext } from 'src/components/molecules/ExportCsv/ExportCsvContext';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { SosMonthlyMovingAverageData } from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';
import { dayjs } from 'src/libs/dayjs';
import { baseColorsForCharts } from 'src/theme';
import {
  MonthNameYearDateFormatter,
  MonthYearDateFormatter,
  PercentageValueTickFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';

export type Mode = 'absolute' | 'shareOfSearch';

type SosByMonthsKeywordChartProps = {
  data: Array<SosMonthlyMovingAverageData>;
  mode: Mode;
};

const getColorAndStrokeWidth = (
  keyword: string,
  selectedKeyword: null | string,
): {
  movingAverageStrokeWidth: number;
  strokeWidth: number;
  movingAverageOpacity: number;
  opacity: number;
  dotActive: boolean;
} => {
  let movingAverageStrokeWidth = 2;
  let strokeWidth = 1.5;

  let movingAverageOpacity = 1;
  let opacity = 0.6;

  let dotActive = false;

  // Some keyword is active, update styles
  if (selectedKeyword !== null) {
    if (selectedKeyword !== keyword) {
      opacity = 0.2;
      movingAverageOpacity = 0.1;
    } else {
      strokeWidth = 2;
      movingAverageStrokeWidth = 3;
      opacity = 0.6;
      dotActive = true;
    }
  }

  return {
    movingAverageStrokeWidth,
    strokeWidth,
    movingAverageOpacity,
    opacity,
    dotActive,
  };
};

const getDataKey =
  (keyword: string, mode: Mode) =>
  (d: SosMonthlyMovingAverageData): number => {
    if (mode === 'absolute') {
      return d.absoluteValues[keyword];
    }
    return d.shareOfSearchValues[keyword];
  };

const getMovingAverageDataKey =
  (keyword: string, mode: Mode) => (d: SosMonthlyMovingAverageData) => {
    if (mode === 'absolute') {
      return d.absoluteMovingAverages[keyword];
    }
    return d.shareOfSearchMovingAverages[keyword];
  };

const mapDataToExportData = (
  data: Array<SosMonthlyMovingAverageData>,
  mode: Mode,
  keywords: string[],
): Array<SupportedCsvRowObject> => {
  return data.flatMap(
    ({
      absoluteMovingAverages,
      absoluteValues,
      utcEpoch,
      shareOfSearchValues,
      shareOfSearchMovingAverages,
    }) => {
      const date = dayjs(utcEpoch).format('YYYY-MM');

      return keywords.map((keyword) => {
        const valueColumName =
          mode === 'absolute' ? 'absolute volume' : 'share of search';
        const movingAverageColumn =
          mode === 'absolute'
            ? 'absolute volume moving average'
            : 'share of search moving average';

        const value =
          mode === 'absolute'
            ? absoluteValues[keyword]
            : shareOfSearchValues[keyword];

        const movingAverage =
          mode === 'absolute'
            ? absoluteMovingAverages[keyword]
            : shareOfSearchMovingAverages[keyword];

        const precision = mode === 'absolute' ? 0 : 2;

        return {
          date,
          keyword,
          [valueColumName]: value ? value.toFixed(precision) : value,
          [movingAverageColumn]: movingAverage
            ? movingAverage.toFixed(precision)
            : movingAverage,
        };
      });
    },
  );
};

export const SosByMonthsKeywordChart: React.FC<
  SosByMonthsKeywordChartProps
> = ({ data, mode }) => {
  const { selectedKey: selectedKeyword, legendValues } = useLegendContext();

  const axisStyles = useAxisStyles();
  const { setExportData } = useExportCsvContext();

  useEffect(() => {
    const fileName =
      mode === 'absolute' ? `search_monthly_absolute` : `search_monthly_sos`;

    const keywords = legendValues.map(({ label }) => label);
    const csvData = mapDataToExportData(data, mode, keywords);

    setExportData({ csvData, fileName });
  }, [data, mode, setExportData, legendValues]);

  const { gridColor } = useChartColors();

  const yAxisFormatter =
    mode === 'absolute' ? ValueTickFormatter : PercentageValueTickFormatter;

  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="0" strokeWidth={1} stroke={gridColor} />
        <YAxis
          width={70}
          yAxisId="left"
          tickFormatter={yAxisFormatter}
          {...axisStyles}
        />
        <XAxis
          dataKey={'utcEpoch'}
          tickFormatter={MonthYearDateFormatter}
          {...axisStyles}
        />

        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          offset={25}
          content={
            <TooltipContent
              labelsFromId
              valueFormatter={yAxisFormatter}
              labelFormatter={MonthNameYearDateFormatter}
              allowedIds={
                selectedKeyword
                  ? [selectedKeyword, `${selectedKeyword} moving average`]
                  : undefined
              }
            />
          }
        />

        {legendValues.map(({ key: keyword, color }, i) => {
          const {
            movingAverageStrokeWidth,
            strokeWidth,
            movingAverageOpacity,
            opacity,
            dotActive,
          } = getColorAndStrokeWidth(keyword, selectedKeyword);

          const movingAverageColor = baseColorsForCharts[i];

          const activeDot = dotActive
            ? {
                stroke: color,
                fill: color,
                strokeOpacity: 0.5,
                strokeWidth: 2,
                r: 3,
              }
            : false;

          return (
            <Fragment key={keyword}>
              <Line
                yAxisId="left"
                id={`${keyword} moving average`}
                isAnimationActive={false}
                dataKey={getMovingAverageDataKey(keyword, mode)}
                stroke={movingAverageColor}
                dot={false}
                opacity={movingAverageOpacity}
                strokeWidth={movingAverageStrokeWidth}
                activeDot={activeDot}
              />
              <Line
                id={keyword}
                yAxisId="left"
                isAnimationActive={false}
                dataKey={getDataKey(keyword, mode)}
                stroke={color}
                dot={false}
                strokeWidth={strokeWidth}
                opacity={opacity}
                activeDot={activeDot}
              />
            </Fragment>
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
