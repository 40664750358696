import { useEffect, useState } from 'react';
import { useInvestmentsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { INIT_MM_DATA_STATE, MMDataState } from 'src/features/MM/types';
import { toApiDates } from 'src/utils/date';
import { filterInvestmentsData } from 'src/utils/filters';
import { aggregateApiDataToDataState } from '../../utils/aggregate';

export function useFetchInvestments(enabled: boolean) {
  const {
    currentModelVersion,
    timeRange,
    filteredCampaignIds,
    media,
    campaignToTags,
  } = useFilterContext();

  const [investments, setInvestments] =
    useState<MMDataState>(INIT_MM_DATA_STATE);

  const {
    data: queryData,
    isLoading: queryIsLoading,
    isError: queryIsError,
  } = useInvestmentsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setInvestments(INIT_MM_DATA_STATE);
      return;
    }

    const filteredData = filterInvestmentsData(
      queryData ?? [],
      filteredCampaignIds,
      media,
    );

    const newState = aggregateApiDataToDataState(
      'investments',
      'value',
      filteredData,
      queryIsLoading,
      queryIsError,
      campaignToTags,
    );

    setInvestments(newState);
  }, [
    queryIsLoading,
    queryIsError,
    queryData,
    campaignToTags,
    enabled,
    filteredCampaignIds,
    media,
  ]);

  return { investments };
}
