import { sortEpoch } from 'src/utils/date';
import { SelectableKeyword } from '../hooks/useShareOfSearchCountryMonthly';
import { KeywordsWithEpoch } from '../types';

export const mapAndFilterSosByLocationData = <A extends KeywordsWithEpoch>(
  data: Array<A>,
  selectableKeywords: Array<SelectableKeyword>,
): Array<A> => {
  const selectedKeywordsSet = new Set(
    selectableKeywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword),
  );

  const filteredData = data
    .map((d) => {
      return {
        ...d,
        keywords: d.keywords.filter(({ keyword }) =>
          selectedKeywordsSet.has(keyword),
        ),
      };
    })
    .sort(sortEpoch);

  return filteredData;
};
