import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Menu = {
  baseStyle: (props: StyleFunctionProps) => {
    return {
      list: {
        bg: mode('background.light.lowest', 'background.dark.lowest')(props),
        borderWidth: 'thin',
        borderRadius: '4px',
      },
      item: {
        bg: mode('background.light.lowest', 'background.dark.lowest')(props),
        _hover: {
          bg: mode('background.light.hover', 'background.dark.hover')(props),
        },
      },
    };
  },
};
