import { Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';

type FilterOptionButtonProps = {
  label: string;
  onClick: () => void;
  isSelected: boolean;
};

export const FilterOptionButton: FC<FilterOptionButtonProps> = ({
  label,
  onClick,
  isSelected,
}) => {
  const fontActiveColor = useColorModeValue(
    'text.light.active',
    'text.dark.active',
  );

  return (
    <Text
      as="button"
      fontSize="16px"
      mr="16px"
      style={
        isSelected
          ? {
              borderBottom: '2px solid',
              fontWeight: 700,
            }
          : undefined
      }
      color={isSelected ? fontActiveColor : ''}
      onClick={onClick}>
      {label}
    </Text>
  );
};
