import { useEffect, useState } from 'react';
import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { InvestmentsState } from 'src/features/LT/types';
import { sortEpoch } from 'src/utils/date';
import { getInvestmentsTimeFrameData } from '../utils/investments';
import { getYearlyAndMonthlyLongTermEffectsData } from '../utils/longTermEffects';

const INIT_INVESTMENTS_STATE: Readonly<InvestmentsState> = {
  monthly: [],
  yearly: [],
};

export const useInvestments = (
  data: Array<ApiLongTermEffectsDataPoint>,
  enabled: boolean,
) => {
  const [investments, setInvestments] = useState<InvestmentsState>(
    INIT_INVESTMENTS_STATE,
  );

  useEffect(() => {
    if (!enabled) {
      setInvestments(INIT_INVESTMENTS_STATE);
      return;
    }

    const timeBased = getYearlyAndMonthlyLongTermEffectsData(data);
    const monthly = getInvestmentsTimeFrameData(timeBased.monthly).sort(
      sortEpoch,
    );
    const yearly = getInvestmentsTimeFrameData(timeBased.yearly).sort(
      sortEpoch,
    );

    setInvestments({ monthly, yearly });
  }, [data, enabled]);

  return investments;
};
