import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FilterBarContainer } from 'src/components/molecules/FilterBarContainer';
import { ModelVersionSelect } from 'src/components/molecules/ModelVersionSelect';
import { CreateOptimizationButton } from '../../molecules/CreateOptimizationButton';

export const MOFilterBar: React.FC = () => {
  return (
    <FilterBarContainer>
      <Flex flex={1} flexDir={'column'}>
        <CreateOptimizationButton />
      </Flex>
      <Flex direction="column" alignItems="flex-end" justifyContent="flex-end">
        <ModelVersionSelect />
      </Flex>
    </FilterBarContainer>
  );
};
