import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useSearch } from 'src/hooks/useSearch';
import { Campaign } from 'src/types';
import { MenuList } from '../../molecules/MenuList';

const MENU_BUTTON_NAME = 'campaigns-select';

export const CampaignsMenu: React.FC = () => {
  const { campaigns, toggleCampaigns } = useFilterContext();

  const filteredCampaigns: Campaign[] = campaigns.filter(
    (campaign: Campaign) => campaign.visible,
  );

  const selectedCampaigs = filteredCampaigns.filter((c) => c.selected);

  const {
    results: searchedCampaigns,
    searchValue,
    setSearchValue,
  } = useSearch(filteredCampaigns, ['name']);

  const onAllClick = () => {
    toggleCampaigns(searchedCampaigns.filter((c) => c.isAvailable));
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px">
        {selectedCampaigs.length}/{filteredCampaigns.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedCampaigns.map((campaign) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            label={campaign.name}
            value={campaign.id}
            key={campaign.id}
            isDisabled={!campaign.isAvailable}
            isSelected={campaign.selected}
            onClick={() => {
              toggleCampaigns([campaign]);
            }}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
