import { useCallback } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { dayjs } from 'src/libs/dayjs';
import { sortEpoch } from 'src/utils/date';

export const useMergeBenchmarkTimeseries = () => {
  const { benchmarkTimerange, timeRange } = useFilterContext();
  const benchmarkDiff = timeRange.start.diff(benchmarkTimerange.start);

  const getMergedTimeSeries = useCallback(
    <D extends { utcEpoch: number }, R, B>(
      data: Array<D>,
      benchmark: Array<D>,
      getter: (v: D) => R,
      benchmarkGetter: (v: D) => B,
      benchmarkEnabled: boolean,
      startOf: 'day' | 'week' | 'month',
    ): Array<R & { utcEpoch: number } & B> => {
      type V = R & B & { utcEpoch: number };

      const dataRecord: Record<string, V> = {};

      data.forEach((d) => {
        const date = dayjs(d.utcEpoch).format('YYYY-MM-DD');
        const r = getter(d);
        dataRecord[date] = {
          ...r,
          utcEpoch: d.utcEpoch,
        } as V;
      });

      if (benchmarkEnabled) {
        benchmark.forEach((d) => {
          const targetDate = dayjs(d.utcEpoch)
            .add(benchmarkDiff)
            .startOf(startOf)
            .format('YYYY-MM-DD');

          const b = benchmarkGetter(d);

          if (dataRecord[targetDate]) {
            dataRecord[targetDate] = { ...dataRecord[targetDate], ...b } as V;
          } else {
            dataRecord[targetDate] = {
              utcEpoch: dayjs(d.utcEpoch).add(benchmarkDiff).valueOf(),
              ...b,
            } as V;
          }
        });
      }

      return Object.values(dataRecord).sort(sortEpoch);
    },
    [benchmarkDiff],
  );

  return { getMergedTimeSeries };
};
