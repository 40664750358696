import { Flex, Menu } from '@chakra-ui/react';
import { FilterHeading } from 'src/components/atoms/FilterHeading';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { Dimension } from 'src/features/MM/types';
import { getDimensionName } from 'src/features/MM/utils/getDimensionName';

const MENU_BUTTON_NAME = 'dimension-select';

const options: Array<Dimension> = [
  Dimension.campagin,
  Dimension.media,
  Dimension.category,
  Dimension.tag,
];

const DimensionMenu: React.FC = () => {
  const { dimension, setDimension } = useSelectedDimensionContext();

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME}>
        {getDimensionName(dimension)}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME}>
        {options.map((opt) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            colorSelected
            key={opt}
            label={getDimensionName(opt)}
            value={opt}
            isSelected={opt === dimension}
            onClick={() => setDimension(opt)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};

export const DimensionSelector: React.FC = () => {
  return (
    <>
      <FilterHeading headingText="Dimension" />
      <Flex direction={'row'}>
        <DimensionMenu />
      </Flex>
    </>
  );
};
