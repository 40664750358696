import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  GridItem,
} from '@chakra-ui/react';
import React from 'react';
import { useMODataContext } from 'src/features/MO/contexts/MODataContext';
import { OptimizationHeader } from '../../molecules/OptimizationHeader';
import { OptimizationRow } from '../../molecules/OptimizationRow';
import { OptimizationPanel } from '../../organisms/OptimizationPanel';

export const Optimizations: React.FC = () => {
  const { optimizations } = useMODataContext();

  return (
    <GridItem colSpan={14} w="100%">
      <OptimizationHeader />
      <Accordion defaultIndex={[]} allowMultiple>
        {optimizations.data.map((optimization) => {
          return (
            <AccordionItem key={optimization.optimizationId}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <OptimizationRow optimization={optimization} />
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <OptimizationPanel
                      optimization={optimization}
                      isOpen={isExpanded}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </GridItem>
  );
};
