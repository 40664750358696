import { Center, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Logo } from 'src/components/atoms/Logo';

export const LogoLink: React.FC = () => {
  return (
    <Center h="80px" w="80px">
      <Link as={RouterLink} to="/">
        <Logo height="40px" width="40px" />
      </Link>
    </Center>
  );
};
