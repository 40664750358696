import { Flex } from '@chakra-ui/react';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { OpenDataTableModalButton } from 'src/components/organisms/DataTableModal/OpenDataTableModalButton';
import { ExportCsvButton } from '../ExportCsv';
import { Title } from './Title';

export type LegendPlacement = 'top' | 'right' | 'none';

type DataBoxHeaderProps = {
  showExportButton: boolean;
  hasData: boolean;
  isLegendOnTop: boolean;
  title?: string;
  titleComponent?: React.ReactNode;
  rightContent?: React.ReactNode;
  tabs?: React.ReactNode;
  secondaryTabs?: React.ReactNode;
  setTableDataModalIsOpen?: (isOpen: boolean) => void;
  onInfoButtonClick?: () => void;
};

export const DataBoxHeader: React.FC<DataBoxHeaderProps> = ({
  showExportButton,
  hasData,
  isLegendOnTop,
  title,
  titleComponent,
  rightContent,
  tabs,
  setTableDataModalIsOpen,
  secondaryTabs,
  onInfoButtonClick,
}) => {
  const hasTitle = !!titleComponent || !!title;

  return (
    <Flex
      direction="row"
      alignItems={'center'}
      fontSize={'sm'}
      mb={isLegendOnTop ? '8px' : '12px'}>
      <Flex
        alignItems={'center'}
        flex={1}
        direction="row"
        justify="space-between">
        <Flex alignItems={'center'}>
          {hasTitle && (titleComponent ?? <Title {...{ title }} />)}
          {onInfoButtonClick && (
            <IconButton
              ml="10px"
              size={'xs'}
              title="Info"
              onClick={onInfoButtonClick}
              aria-label={`info-${title}`}
              icon={<Icon iconName="info" />}
            />
          )}
        </Flex>

        {
          <Flex alignItems={'center'}>
            {secondaryTabs}
            {tabs}
          </Flex>
        }
      </Flex>
      {showExportButton && <ExportCsvButton />}
      {setTableDataModalIsOpen && hasData && (
        <OpenDataTableModalButton
          tableTitle={title ?? ''}
          ml="20px"
          onClick={() => setTableDataModalIsOpen(true)}
        />
      )}
      {rightContent}
    </Flex>
  );
};
