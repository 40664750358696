import { ModelFrequency } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { AggregateLevel } from 'src/types';
import { useAggregationLevelContext } from '../contexts/AggregationLevelContext';

const dailyMultiplier = (level: AggregateLevel): number => {
  switch (level) {
    case AggregateLevel.day:
      return 1;
    case AggregateLevel.week:
      return 7;
    case AggregateLevel.month:
      return 30;
    case AggregateLevel.quarter:
      return 91;
    case AggregateLevel.biannual:
      return 182;
    case AggregateLevel.year:
      return 365;
  }
};

const weeklyMultiplier = (level: AggregateLevel): number => {
  switch (level) {
    case AggregateLevel.day:
      return 1 / 7;
    case AggregateLevel.week:
      return 1;
    case AggregateLevel.month:
      return 4;
    case AggregateLevel.quarter:
      return 13;
    case AggregateLevel.biannual:
      return 26;
    case AggregateLevel.year:
      return 52;
  }
};

const monthlyMultiplier = (level: AggregateLevel): number => {
  switch (level) {
    case AggregateLevel.day:
      return 1 / 30;
    case AggregateLevel.week:
      return 1 / 4;
    case AggregateLevel.month:
      return 1;
    case AggregateLevel.quarter:
      return 3;
    case AggregateLevel.biannual:
      return 6;
    case AggregateLevel.year:
      return 12;
  }
};

const getTimeMultiplier = (
  modelFrequency: ModelFrequency,
  level: AggregateLevel,
): number => {
  switch (modelFrequency) {
    case 'daily':
      return dailyMultiplier(level);
    case 'weekly':
      return weeklyMultiplier(level);
    case 'monthly':
      return monthlyMultiplier(level);
  }
};

export const useTimeFreqMultiplier = () => {
  const { currentModelVersion } = useFilterContext();
  const modelFrequency = currentModelVersion?.modelFrequency ?? 'weekly';

  const { aggregateLevel } = useAggregationLevelContext();

  const valueMultiplier = getTimeMultiplier(modelFrequency, aggregateLevel);

  return { valueMultiplier };
};
