import { Box, Flex, Menu, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { FilterHeading } from 'src/components/atoms/FilterHeading';
import { Icon } from 'src/components/atoms/Icon';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';
import { AppServices } from 'src/app/routes/utils';
import { MenuTriangle } from '../../atoms/MenuTriangle';
import { MenuList } from '../MenuList';
import { SelectedModel } from './SelectedModel';
import { getModelDateString } from './getModelDateString';

const MENU_BUTTON_NAME = 'model-version-select';

export const ModelVersionSelect: React.FC = () => {
  const {
    modelVersionsState,
    currentModelVersion,
    changeCurrentModelVersion,
    currentAppService,
  } = useFilterContext();

  let headingText;
  if (currentAppService === AppServices.MM) {
    headingText = 'Marketing Mix Model';
  }
  if (currentAppService === AppServices.DA) {
    headingText = 'DSO Model';
  }
  if (currentAppService === AppServices.MO) {
    headingText = 'Marketing Optimization Model';
  }

  const fontErrorColor = useFontErrorColor();

  return (
    <>
      {<FilterHeading headingText={headingText || 'Model Version'} />}
      {modelVersionsState.isError && (
        <Flex alignItems="center" color={fontErrorColor}>
          <Icon iconName="warning" mr={2} />
          <Text fontSize="16px" fontWeight={700} lineHeight={1}>
            Error fetching model versions
          </Text>
        </Flex>
      )}
      {modelVersionsState.isLoading && (
        <Flex alignItems="center">
          <Spinner size="sm" mr={2} />
          <Text fontSize="16px" fontWeight={700} lineHeight={1}>
            Loading...
          </Text>
        </Flex>
      )}
      {!modelVersionsState.isLoading && !modelVersionsState.isError && (
        <>
          {modelVersionsState?.modelVersions?.length > 1 ? (
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    buttonName={MENU_BUTTON_NAME}
                    lineHeight={1}
                    pt="8px"
                    pb="2px"
                    mb="2px">
                    <Flex align="center">
                      {currentModelVersion && (
                        <SelectedModel model={currentModelVersion} />
                      )}
                      <MenuTriangle isOpen={isOpen} />
                    </Flex>
                  </MenuButton>
                  <MenuList buttonName={MENU_BUTTON_NAME}>
                    {modelVersionsState?.modelVersions?.map((mv) => (
                      <MenuOption
                        onClick={() =>
                          changeCurrentModelVersion(mv.modelVersion)
                        }
                        colorSelected
                        buttonName={MENU_BUTTON_NAME}
                        key={mv.modelVersion}
                        value={mv.modelVersion}
                        label={mv.modelVersion}
                        isSelected={
                          currentModelVersion?.modelVersion === mv.modelVersion
                        }>
                        <Box w="100%">
                          <Flex
                            alignItems={'center'}
                            direction="row"
                            justifyContent={'space-between'}
                            w="100%"
                            minH="24px">
                            {mv.alias ? (
                              <>
                                <Text fontWeight="700">{mv.alias}</Text>
                                <Text ml="20px" fontSize="xs">
                                  {mv.modelID}
                                </Text>
                              </>
                            ) : (
                              <Text fontWeight="700">{mv.modelID}</Text>
                            )}
                          </Flex>
                          <Text as="p" fontSize="xs">
                            {mv.buildID}
                          </Text>
                          <Text as="p" fontSize="xs">
                            {getModelDateString(mv)}
                          </Text>
                        </Box>
                      </MenuOption>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          ) : (
            <>
              {modelVersionsState.modelVersions.length === 1 &&
              currentModelVersion ? (
                <SelectedModel model={currentModelVersion} />
              ) : (
                <Flex alignItems="center">
                  <Text fontSize="16px" lineHeight={1}>
                    No models available
                  </Text>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
