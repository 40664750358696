import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
  CheckIcon,
  CloseIcon,
  DownloadIcon,
  ExternalLinkIcon,
  InfoOutlineIcon,
  LockIcon,
  SearchIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  UnlockIcon,
  WarningIcon,
} from '@chakra-ui/icons';

const MMMIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 12.5H7C6.72386 12.5 6.5 12.7239 6.5 13V18C6.5 18.2761 6.72386 18.5 7 18.5H14.5V12.5ZM7 11C5.89543 11 5 11.8954 5 13V18C5 19.1046 5.89543 20 7 20H16V11H7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 20L9.5 24.5C9.5 24.7761 9.72386 25 10 25L11 25C11.2761 25 11.5 24.7761 11.5 24.5L11.5 20L9.5 20ZM8 24.5C8 25.6046 8.89543 26.5 10 26.5L11 26.5C12.1046 26.5 13 25.6046 13 24.5L13 18.5L8 18.5L8 24.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8125 14.5H3.25C2.93934 14.5 2.6875 14.7239 2.6875 15V16C2.6875 16.2761 2.93934 16.5 3.25 16.5H4.8125V14.5ZM3.25 13C2.00736 13 1 13.8954 1 15V16C1 17.1046 2.00736 18 3.25 18H6.5V13H3.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11.8904V19.1095L24 23.4732V7.52679L16 11.8904ZM14.5 20L24.0211 25.1933C24.6875 25.5568 25.5 25.0745 25.5 24.3154V6.68452C25.5 5.92546 24.6875 5.44315 24.0211 5.80662L14.5 11V20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 13.5V17.5C26.6046 17.5 27.5 16.6046 27.5 15.5C27.5 14.3954 26.6046 13.5 25.5 13.5ZM25.5 12H24V19H25.5C27.433 19 29 17.433 29 15.5C29 13.567 27.433 12 25.5 12Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const OverviewIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 19.5C17.933 19.5 19.5 17.933 19.5 16C19.5 14.067 17.933 12.5 16 12.5C14.067 12.5 12.5 14.067 12.5 16C12.5 17.933 14.067 19.5 16 19.5ZM16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0425 15.7003C28.042 15.7015 28.0415 15.7028 28.0408 15.7043C28.0468 15.6859 28.0491 15.6837 28.0425 15.7003ZM27.877 16C27.7564 15.8077 27.5809 15.5594 27.3466 15.268C26.7764 14.5587 25.9192 13.6697 24.8205 12.8023C22.6126 11.0592 19.5564 9.5 16 9.5C12.4436 9.5 9.38744 11.0592 7.17947 12.8023C6.0808 13.6697 5.22361 14.5587 4.65344 15.268C4.41911 15.5594 4.24363 15.8077 4.12298 16C4.24363 16.1923 4.41911 16.4406 4.65344 16.732C5.22361 17.4413 6.0808 18.3303 7.17947 19.1977C9.38744 20.9408 12.4436 22.5 16 22.5C19.5564 22.5 22.6126 20.9408 24.8205 19.1977C25.9192 18.3303 26.7764 17.4413 27.3466 16.732C27.5809 16.4406 27.7564 16.1923 27.877 16ZM3.95754 15.7003C3.95094 15.6837 3.95315 15.6859 3.95913 15.7042C3.95852 15.7028 3.95799 15.7015 3.95754 15.7003ZM3.95754 16.2997C3.958 16.2985 3.95854 16.2972 3.95917 16.2957C3.95316 16.3141 3.95092 16.3163 3.95754 16.2997ZM28.0408 16.2957C28.0415 16.2972 28.042 16.2985 28.0425 16.2997C28.0491 16.3163 28.0468 16.3141 28.0408 16.2957ZM16 24C24 24 29.5 17 29.5 16C29.5 15 24 8 16 8C8 8 2.5 15 2.5 16C2.5 17 8 24 16 24Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const MMMPerformanceIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17836 24.8184H10.4361C10.8503 24.8184 11.1861 25.1542 11.1861 25.5684C11.1861 25.9827 10.8503 26.3184 10.4361 26.3184H4.40251C4.11893 26.3184 3.85961 26.1585 3.73232 25.9051C2.76447 23.9784 2.21997 21.8028 2.21997 19.5022C2.21997 11.6144 8.61436 5.21997 16.5022 5.21997C24.3901 5.21997 30.7845 11.6144 30.7845 19.5022C30.7845 21.8028 30.24 23.9784 29.2722 25.9051C29.1449 26.1585 28.8856 26.3184 28.602 26.3184H22.5684C22.1542 26.3184 21.8184 25.9827 21.8184 25.5684C21.8184 25.1542 22.1542 24.8184 22.5684 24.8184H23.8261C24.9116 23.3259 25.5515 21.4897 25.5515 19.5022C25.5515 16.6202 24.205 14.053 22.1038 12.3946C20.5629 11.1784 18.6183 10.453 16.5022 10.453C14.3862 10.453 12.4416 11.1784 10.9007 12.3946C9.1193 13.8006 7.88063 15.86 7.54469 18.2086C7.48431 18.6307 7.45301 19.0626 7.45301 19.5022C7.45301 21.4897 8.09291 23.3259 9.17836 24.8184ZM15.7522 6.74161C13.2399 6.88697 10.9221 7.75796 9.00542 9.14814L10.5322 10.8038C12.0366 9.76939 13.8234 9.11488 15.7522 8.97929V6.74161ZM7.84278 10.1C5.80736 11.9756 4.38246 14.5037 3.89999 17.3524H6.17239C6.62784 15.1533 7.76722 13.2058 9.35661 11.7416L7.84278 10.1ZM3.73621 18.8524C3.72543 19.0676 3.71997 19.2843 3.71997 19.5022C3.71997 21.4005 4.13329 23.2004 4.87431 24.8184H7.38865C6.47615 23.2572 5.95301 21.4402 5.95301 19.5022C5.95301 19.2841 5.95965 19.0674 5.97273 18.8524H3.73621ZM25.6158 24.8184H28.1302C28.8712 23.2004 29.2845 21.4005 29.2845 19.5022C29.2845 19.2843 29.2791 19.0676 29.2683 18.8523H27.0318C27.0449 19.0673 27.0515 19.284 27.0515 19.5022C27.0515 21.4402 26.5283 23.2572 25.6158 24.8184ZM26.8322 17.3523H29.1045C28.6368 14.5908 27.2834 12.1306 25.3468 10.274L23.6975 11.7877C25.2612 13.2468 26.3816 15.177 26.8322 17.3523ZM24.1962 9.29401C22.2423 7.81899 19.8514 6.89199 17.2522 6.74161V8.97929C19.2003 9.11624 21.0035 9.7825 22.5173 10.8348L24.1962 9.29401Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9731 13.8506C19.6113 13.6417 19.2155 13.6281 18.88 13.6811C18.5436 13.7343 18.2032 13.8634 17.8816 14.0223C17.2371 14.3406 16.5408 14.8422 15.8908 15.3871C14.6046 16.4651 13.3285 17.8594 12.8505 18.6873C11.8149 20.4809 12.4295 22.7743 14.2231 23.8099C16.0167 24.8454 18.3101 24.2309 19.3457 22.4373C19.8237 21.6094 20.393 19.8071 20.6836 18.1542C20.8304 17.3188 20.9167 16.465 20.8701 15.7477C20.8468 15.3897 20.7885 15.0304 20.6663 14.7125C20.5445 14.3954 20.3349 14.0595 19.9731 13.8506ZM14.1495 19.4373C14.4999 18.8303 15.6233 17.5685 16.8543 16.5367C17.4628 16.0266 18.0545 15.6098 18.5458 15.3672C18.792 15.2456 18.9815 15.1837 19.1141 15.1628C19.1667 15.1545 19.2005 15.154 19.2199 15.1551C19.2306 15.1714 19.2471 15.2009 19.2662 15.2506C19.3143 15.3759 19.3554 15.5709 19.3732 15.8449C19.4088 16.3917 19.3437 17.1125 19.2062 17.8945C18.9281 19.4765 18.397 21.0803 18.0466 21.6873C17.4253 22.7634 16.0492 23.1322 14.9731 22.5108C13.8969 21.8895 13.5282 20.5134 14.1495 19.4373Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const MMMCreatedValueIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1893 1.25H23.8106L28.539 5.97836L16 19.6077L3.46094 5.97836L8.1893 1.25ZM8.41882 3.14181L9.99996 5.25H6.31062L8.41882 3.14181ZM6.20908 6.75H10.9728L14.0353 15.2567L6.20908 6.75ZM17.9647 15.2567L25.7908 6.75H21.0271L17.9647 15.2567ZM22 5.25H25.6893L23.5811 3.14181L22 5.25ZM22 2.75H17.9726L20.389 4.89792L22 2.75ZM18.5273 5.25L16 3.00347L13.4726 5.25H18.5273ZM12.5671 6.75H19.4328L16 16.2858L12.5671 6.75ZM11.6109 4.89792L14.0273 2.75H9.99996L11.6109 4.89792Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 28.5V23C6.5 22.7239 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.7239 5.5 23V28C5.5 28.2761 5.72386 28.5 6 28.5H6.5ZM6 21C4.89543 21 4 21.8954 4 23V28C4 29.1046 4.89543 30 6 30H8V23C8 21.8954 7.10457 21 6 21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7105 25H15V23.5H27.7105C28.5608 23.5 29.25 24.1892 29.25 25.0395C29.25 27.7791 27.0291 30 24.2895 30H6.75V23.75H8.25V28.5H24.2895C26.2007 28.5 27.75 26.9507 27.75 25.0395C27.75 25.0177 27.7323 25 27.7105 25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76025 24.8767C7.10906 22.7839 8.91976 21.25 11.0414 21.25H18C19.5188 21.25 20.75 22.4812 20.75 24V25H19.25V24C19.25 23.3096 18.6904 22.75 18 22.75H11.0414C9.65301 22.75 8.4681 23.7538 8.23984 25.1233L6.76025 24.8767Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const MMMCostPerUnitIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 22.5V28.5H6.5V22.5H4.5ZM4 21C3.44772 21 3 21.4477 3 22V30H8V22C8 21.4477 7.55228 21 7 21H4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 19.5V28.5H13.5V19.5H11.5ZM11 18C10.4477 18 10 18.4477 10 19V30H15V19C15 18.4477 14.5523 18 14 18H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 19.5V28.5H20.5V19.5H18.5ZM18 18C17.4477 18 17 18.4477 17 19V30H22V19C22 18.4477 21.5523 18 21 18H18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 12.5V28.5H27.5V12.5H25.5ZM25 11C24.4477 11 24 11.4477 24 12V30H29V12C29 11.4477 28.5523 11 28 11H25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 29.5C1.25 29.0858 1.58579 28.75 2 28.75H30C30.4142 28.75 30.75 29.0858 30.75 29.5C30.75 29.9142 30.4142 30.25 30 30.25H2C1.58579 30.25 1.25 29.9142 1.25 29.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0001 3.5H26.5001C26.9143 3.5 27.2501 3.83579 27.2501 4.25V7.75H25.7501V6.06066L17.0304 14.7803C16.8897 14.921 16.699 15 16.5001 15H8.81072L6.03039 17.7803L4.96973 16.7197L7.96973 13.7197C8.11038 13.579 8.30114 13.5 8.50006 13.5H16.1894L24.6894 5H23.0001V3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 10.5C14.7091 10.5 16.5 8.70914 16.5 6.5C16.5 4.29086 14.7091 2.5 12.5 2.5C10.2909 2.5 8.5 4.29086 8.5 6.5C8.5 8.70914 10.2909 10.5 12.5 10.5ZM12.5 12C15.5376 12 18 9.53757 18 6.5C18 3.46243 15.5376 1 12.5 1C9.46243 1 7 3.46243 7 6.5C7 9.53757 9.46243 12 12.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.5C14 5.20333 13.912 4.91332 13.7472 4.66665C13.7081 4.60813 13.6651 4.55268 13.6186 4.50062C13.469 4.33319 13.2831 4.20078 13.074 4.11418C13.0498 4.10413 13.0253 4.09474 13.0006 4.08601C12.7466 3.99608 12.4726 3.97607 12.2074 4.02882C11.9164 4.0867 11.6491 4.22956 11.4393 4.43934C11.2296 4.64912 11.0867 4.91639 11.0288 5.20737C10.9761 5.47258 10.9961 5.74662 11.086 6.00062C11.0947 6.02529 11.1041 6.04976 11.1142 6.07403C11.2008 6.2831 11.3332 6.469 11.5006 6.61859C11.5527 6.6651 11.6081 6.70811 11.6666 6.74721C11.9133 6.91203 12.2033 7 12.5 7V6.00062C12.4339 6.00062 12.3689 5.98755 12.3084 5.96251C12.2783 5.95002 12.2493 5.93456 12.2219 5.91625C12.1669 5.87955 12.1201 5.83253 12.0838 5.77813C12.0656 5.75099 12.0501 5.72202 12.0375 5.69158C12.0122 5.63055 11.9994 5.56543 11.9994 5.5C11.9994 5.46737 12.0026 5.43465 12.009 5.40234C12.0283 5.30522 12.076 5.21602 12.146 5.14601C12.216 5.076 12.3052 5.02832 12.4023 5.009C12.4347 5.00257 12.4674 4.99938 12.5 4.99938C12.5654 4.99938 12.6305 5.01221 12.6916 5.03749C12.722 5.0501 12.751 5.06562 12.7781 5.08375C12.8325 5.1201 12.8795 5.16694 12.9162 5.22187C12.9346 5.24927 12.95 5.27827 12.9625 5.30842C12.9875 5.36887 13.0006 5.43394 13.0006 5.5H14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7.5C11 7.79667 11.088 8.08668 11.2528 8.33335C11.2921 8.39212 11.3353 8.44779 11.382 8.50005C11.5315 8.66716 11.7172 8.79933 11.926 8.88582C11.9505 8.89596 11.9752 8.90543 12 8.91423C12.2539 9.00396 12.5277 9.02389 12.7926 8.97118C13.0836 8.9133 13.3509 8.77044 13.5607 8.56066C13.7704 8.35088 13.9133 8.08361 13.9712 7.79263C14.0239 7.52765 14.004 7.25386 13.9142 7.00005C13.9054 6.97516 13.896 6.95046 13.8858 6.92597C13.7993 6.71718 13.6672 6.53149 13.5 6.38201C13.4478 6.33526 13.3921 6.29206 13.3334 6.25279C13.0867 6.08797 12.7967 6 12.5 6L12.5 7.00005C12.566 7.00005 12.631 7.0131 12.6913 7.03811C12.7214 7.05058 12.7504 7.06602 12.7778 7.08431C12.8326 7.12096 12.8794 7.16791 12.9157 7.22224C12.9338 7.24934 12.9493 7.27827 12.9619 7.30868C12.9871 7.36963 12.9999 7.43466 12.9999 7.5C12.9999 7.53259 12.9968 7.56526 12.9903 7.59753C12.9711 7.69452 12.9234 7.7836 12.8535 7.85352C12.7836 7.92344 12.6945 7.97105 12.5975 7.99034C12.5653 7.99676 12.5326 7.99995 12.5 7.99995C12.4347 7.99995 12.3696 7.98714 12.3087 7.96189C12.2783 7.9493 12.2493 7.9338 12.2222 7.91569C12.1679 7.87939 12.121 7.83261 12.0843 7.77776C12.066 7.75039 12.0506 7.72143 12.0381 7.69132C12.0131 7.63096 12 7.56597 12 7.5L11 7.5Z"
        fill="currentColor"
      />
      <path d="M12 3.5H13V4.5H12V3.5Z" fill="currentColor" />
      <path d="M12 8.5H13V9.5H12V8.5Z" fill="currentColor" />
    </ChakraIcon>
  );
};

const MMMModellingIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 22.5V28.5H6.5V22.5H4.5ZM4 21C3.44772 21 3 21.4477 3 22V30H8V22C8 21.4477 7.55228 21 7 21H4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 19.5V28.5H13.5V19.5H11.5ZM11 18C10.4477 18 10 18.4477 10 19V30H15V19C15 18.4477 14.5523 18 14 18H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 19.5V28.5H20.5V19.5H18.5ZM18 18C17.4477 18 17 18.4477 17 19V30H22V19C22 18.4477 21.5523 18 21 18H18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 12.5V28.5H27.5V12.5H25.5ZM25 11C24.4477 11 24 11.4477 24 12V30H29V12C29 11.4477 28.5523 11 28 11H25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 29.25C1.25 28.8358 1.58579 28.5 2 28.5H30C30.4142 28.5 30.75 28.8358 30.75 29.25C30.75 29.6642 30.4142 30 30 30H2C1.58579 30 1.25 29.6642 1.25 29.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 17C6.32843 17 7 16.3284 7 15.5C7 14.6716 6.32843 14 5.5 14C4.67157 14 4 14.6716 4 15.5C4 16.3284 4.67157 17 5.5 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 14C20.3284 14 21 13.3284 21 12.5C21 11.6716 20.3284 11 19.5 11C18.6716 11 18 11.6716 18 12.5C18 13.3284 18.6716 14 19.5 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 7C27.3284 7 28 6.32843 28 5.5C28 4.67157 27.3284 4 26.5 4C25.6716 4 25 4.67157 25 5.5C25 6.32843 25.6716 7 26.5 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7785 13.6963L6.77852 15.6963L6.22144 14.3036L11.2214 12.3036L11.7785 13.6963Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 13.25H13.5V11.75H18.5V13.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5304 6.53039L21.0304 12.0304L19.9697 10.9697L25.4697 5.46973L26.5304 6.53039Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const DAIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 3.75C7.75 2.23122 8.98122 1 10.5 1H21.5C23.0188 1 24.25 2.23122 24.25 3.75V9.75H22.75V3.75C22.75 3.05964 22.1904 2.5 21.5 2.5H10.5C9.80964 2.5 9.25 3.05964 9.25 3.75V27.75C9.25 28.4404 9.80964 29 10.5 29H21.5C22.1904 29 22.75 28.4404 22.75 27.75V21.75H24.25V27.75C24.25 29.2688 23.0188 30.5 21.5 30.5H10.5C8.98122 30.5 7.75 29.2688 7.75 27.75V3.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 2.5H12.5V3C12.5 3.27614 12.7239 3.5 13 3.5H19C19.2761 3.5 19.5 3.27614 19.5 3V2.5ZM11 1V3C11 4.10457 11.8954 5 13 5H19C20.1046 5 21 4.10457 21 3V1H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11C16.8284 11 17.5 10.3284 17.5 9.5C17.5 8.67157 16.8284 8 16 8C15.1716 8 14.5 8.67157 14.5 9.5C14.5 10.3284 15.1716 11 16 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C12.8284 21 13.5 20.3284 13.5 19.5C13.5 18.6716 12.8284 18 12 18C11.1716 18 10.5 18.6716 10.5 19.5C10.5 20.3284 11.1716 21 12 21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C12.8284 14 13.5 13.3284 13.5 12.5C13.5 11.6716 12.8284 11 12 11C11.1716 11 10.5 11.6716 10.5 12.5C10.5 13.3284 11.1716 14 12 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.213 10.8071C22.4932 10.691 22.8158 10.7552 23.0303 10.9697L27.5303 15.4697C27.8232 15.7626 27.8232 16.2375 27.5303 16.5304L23.0303 21.0304C22.8158 21.2449 22.4932 21.309 22.213 21.1929C21.9327 21.0768 21.75 20.8034 21.75 20.5V18.25H17.3107L15.5303 20.0304C15.3897 20.171 15.1989 20.25 15 20.25H13V18.75H14.6893L16.4697 16.9697C16.6103 16.829 16.8011 16.75 17 16.75H22.5C22.9142 16.75 23.25 17.0858 23.25 17.5V18.6894L25.9393 16L23.25 13.3107V14.5C23.25 14.9142 22.9142 15.25 22.5 15.25H17C16.8011 15.25 16.6103 15.171 16.4697 15.0304L14.6893 13.25H13V11.75H15C15.1989 11.75 15.3897 11.829 15.5303 11.9697L17.3107 13.75H21.75V11.5C21.75 11.1967 21.9327 10.9232 22.213 10.8071Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4697 9.96967C16.7626 9.67678 17.2374 9.67678 17.5303 9.96967L18.8107 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H18.5C18.3011 12.75 18.1103 12.671 17.9697 12.5303L16.4697 11.0303C16.1768 10.7374 16.1768 10.2626 16.4697 9.96967Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4697 22.0303C16.7626 22.3232 17.2374 22.3232 17.5303 22.0303L18.8107 20.75H20C20.4142 20.75 20.75 20.4142 20.75 20C20.75 19.5858 20.4142 19.25 20 19.25H18.5C18.3011 19.25 18.1103 19.329 17.9697 19.4697L16.4697 20.9697C16.1768 21.2626 16.1768 21.7374 16.4697 22.0303Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const DAExplorerIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28.5C22.9036 28.5 28.5 22.9036 28.5 16C28.5 9.09644 22.9036 3.5 16 3.5C9.09644 3.5 3.5 9.09644 3.5 16C3.5 22.9036 9.09644 28.5 16 28.5ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
        fill="currentColor"
      />
      <path
        d="M17 26C17 26.5523 16.5523 27 16 27C15.4477 27 15 26.5523 15 26C15 25.4477 15.4477 25 16 25C16.5523 25 17 25.4477 17 26Z"
        fill="currentColor"
      />
      <path
        d="M7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16Z"
        fill="currentColor"
      />
      <path
        d="M27 16C27 16.5523 26.5523 17 26 17C25.4477 17 25 16.5523 25 16C25 15.4477 25.4477 15 26 15C26.5523 15 27 15.4477 27 16Z"
        fill="currentColor"
      />
      <path
        d="M17 6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6C15 5.44772 15.4477 5 16 5C16.5523 5 17 5.44772 17 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1859 13.1859L10.3718 21.6283L18.8142 18.8142L21.6283 10.3718L13.1859 13.1859ZM12.4744 11.8419C12.1758 11.9415 11.9415 12.1758 11.8419 12.4744L8.63251 22.1027C8.37193 22.8844 9.11567 23.6282 9.89742 23.3676L19.5257 20.1582C19.8243 20.0586 20.0586 19.8243 20.1582 19.5257L23.3676 9.89742C23.6282 9.11567 22.8844 8.37193 22.1027 8.63251L12.4744 11.8419Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const DADeepDiveIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 5.5H5C4.72386 5.5 4.5 5.72386 4.5 6V22C4.5 22.2761 4.72386 22.5 5 22.5H9.25C10.2165 22.5 11 23.2835 11 24.25V24.8884L14.2771 22.9768C14.8124 22.6645 15.421 22.5 16.0407 22.5H27C27.2761 22.5 27.5 22.2761 27.5 22V6C27.5 5.72386 27.2761 5.5 27 5.5ZM15.033 24.2724C15.3388 24.094 15.6866 24 16.0407 24H27C28.1046 24 29 23.1046 29 22V6C29 4.89543 28.1046 4 27 4H5C3.89543 4 3 4.89543 3 6V22C3 23.1046 3.89543 24 5 24H9.25C9.38807 24 9.5 24.1119 9.5 24.25V27.0647C9.5 27.2577 9.7093 27.3779 9.87597 27.2807L15.033 24.2724Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 14L14 12.2679L14 15.732L17 14ZM18.5 14.866C19.1667 14.4811 19.1667 13.5188 18.5 13.1339L14 10.5359C13.3333 10.151 12.5 10.6321 12.5 11.4019L12.5 16.598C12.5 17.3678 13.3333 17.849 14 17.4641L18.5 14.866Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 7.75C6.25 7.33579 6.58579 7 7 7H25C25.4142 7 25.75 7.33579 25.75 7.75C25.75 8.16421 25.4142 8.5 25 8.5H7C6.58579 8.5 6.25 8.16421 6.25 7.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 20.25C6.25 19.8358 6.58579 19.5 7 19.5H25C25.4142 19.5 25.75 19.8358 25.75 20.25C25.75 20.6642 25.4142 21 25 21H7C6.58579 21 6.25 20.6642 6.25 20.25Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const DAChannelsIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 28.5C22.9036 28.5 28.5 22.9036 28.5 16C28.5 9.09644 22.9036 3.5 16 3.5C9.09644 3.5 3.5 9.09644 3.5 16C3.5 22.9036 9.09644 28.5 16 28.5ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00292 23.5039C7.33781 25.2823 9.12917 26.6802 11.199 27.5413C13.7337 28.5956 16.5449 28.7863 19.1987 28.0838C21.8525 27.3813 24.2012 25.8248 25.8823 23.6545C27.2551 21.8822 28.1202 19.7811 28.4004 17.5751L16.3977 17.5024L6.00292 23.5039ZM4.69545 24.2588C4.40095 23.8556 4.12715 23.4356 3.87567 23L16 16L29.9998 16.0848C29.9998 16.0852 29.9998 16.0857 29.9998 16.0862C29.9967 16.5887 29.9666 17.0887 29.9101 17.5843C29.6213 20.1205 28.6429 22.5399 27.0682 24.573C25.1854 27.0038 22.5548 28.7471 19.5825 29.5339C16.6103 30.3207 13.4617 30.1071 10.6229 28.9262C8.24848 27.9385 6.20125 26.3199 4.69545 24.2588Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0438 7.01652L16.5438 17.0165L15.4563 15.9834L24.9563 5.9834L26.0438 7.01652Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.75 2.5V16.5H15.25V2.5H16.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </ChakraIcon>
  );
};

const DASearchIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 18.5C15.1421 18.5 18.5 15.1421 18.5 11C18.5 6.85786 15.1421 3.5 11 3.5C6.85786 3.5 3.5 6.85786 3.5 11C3.5 15.1421 6.85786 18.5 11 18.5ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.5C9.97471 6.5 9.45457 6.60346 8.96927 6.80448C8.48396 7.0055 8.04301 7.30014 7.67157 7.67157C7.30014 8.04301 7.0055 8.48396 6.80448 8.96927C6.60346 9.45457 6.5 9.97471 6.5 10.5L5 10.5C5 9.77773 5.14226 9.06253 5.41866 8.39524C5.69506 7.72795 6.10019 7.12164 6.61091 6.61091C7.12163 6.10019 7.72795 5.69506 8.39524 5.41866C9.06253 5.14226 9.77773 5 10.5 5V6.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5304 17.591L21.6146 22.6751C21.9075 22.968 22.3823 22.968 22.6752 22.6751C22.9681 22.3822 22.9681 21.9074 22.6752 21.6145L17.591 16.5303L18.6517 15.4696L23.7359 20.5538C24.6146 21.4325 24.6146 22.8571 23.7359 23.7358C22.8572 24.6145 21.4326 24.6145 20.5539 23.7358L15.4697 18.6516L16.5304 17.591Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8.75H22V7.25H27C28.5188 7.25 29.75 8.48122 29.75 10V26C29.75 27.5188 28.5188 28.75 27 28.75H5C3.48122 28.75 2.25 27.5188 2.25 26V21.5H3.75V26C3.75 26.6904 4.30964 27.25 5 27.25H27C27.6904 27.25 28.25 26.6904 28.25 26V10C28.25 9.30964 27.6904 8.75 27 8.75Z"
        fill="currentColor"
      />
      <path d="M6 25.25H16" stroke="black" strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 23H6V21.5H14V23Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const MOIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 22.5V28.5H6.5V22.5H4.5ZM4 21C3.44772 21 3 21.4477 3 22V30H8V22C8 21.4477 7.55228 21 7 21H4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 19.5V28.5H13.5V19.5H11.5ZM11 18C10.4477 18 10 18.4477 10 19V30H15V19C15 18.4477 14.5523 18 14 18H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 19.5V28.5H20.5V19.5H18.5ZM18 18C17.4477 18 17 18.4477 17 19V30H22V19C22 18.4477 21.5523 18 21 18H18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 12.5V28.5H27.5V12.5H25.5ZM25 11C24.4477 11 24 11.4477 24 12V30H29V12C29 11.4477 28.5523 11 28 11H25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 29.25C1.25 28.8358 1.58579 28.5 2 28.5H30C30.4142 28.5 30.75 28.8358 30.75 29.25C30.75 29.6642 30.4142 30 30 30H2C1.58579 30 1.25 29.6642 1.25 29.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 9.5C12.0523 9.5 12.5 9.05228 12.5 8.5C12.5 7.94772 12.0523 7.5 11.5 7.5C10.9477 7.5 10.5 7.94772 10.5 8.5C10.5 9.05228 10.9477 9.5 11.5 9.5ZM11.5 11C12.8807 11 14 9.88071 14 8.5C14 7.11929 12.8807 6 11.5 6C10.1193 6 9 7.11929 9 8.5C9 9.88071 10.1193 11 11.5 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3541 8.5C18.3031 8.42258 18.2427 8.33553 18.1723 8.23992C17.8341 7.78055 17.32 7.19773 16.6595 6.62832C15.3227 5.47597 13.5329 4.5 11.5 4.5C9.46706 4.5 7.67728 5.47597 6.3405 6.62832C5.67996 7.19773 5.16593 7.78055 4.82772 8.23992C4.75733 8.33553 4.69692 8.42257 4.64593 8.5C4.69692 8.57743 4.75733 8.66447 4.82772 8.76008C5.16593 9.21945 5.67996 9.80227 6.3405 10.3717C7.67728 11.524 9.46706 12.5 11.5 12.5C13.5329 12.5 15.3227 11.524 16.6595 10.3717C17.32 9.80227 17.8341 9.21945 18.1723 8.76008C18.2427 8.66447 18.3031 8.57743 18.3541 8.5ZM11.5 14C16.537 14 20 9.1875 20 8.5C20 7.8125 16.537 3 11.5 3C6.46296 3 3 7.8125 3 8.5C3 9.1875 6.46296 14 11.5 14Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const LtIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8071 8.53701C10.691 8.25676 10.7552 7.93417 10.9697 7.71967L15.4697 3.21967C15.7626 2.92678 16.2375 2.92678 16.5304 3.21967L21.0304 7.71967C21.2449 7.93417 21.309 8.25676 21.1929 8.53701C21.0768 8.81727 20.8034 9 20.5 9L18.25 9L18.25 13.4393L20.0304 15.2197C20.171 15.3603 20.25 15.5511 20.25 15.75L20.25 17.75L18.75 17.75L18.75 16.0607L16.9697 14.2803C16.829 14.1397 16.75 13.9489 16.75 13.75L16.75 8.25C16.75 7.83579 17.0858 7.5 17.5 7.5L18.6894 7.5L16 4.81066L13.3107 7.5L14.5 7.5C14.9142 7.5 15.25 7.83579 15.25 8.25L15.25 13.75C15.25 13.9489 15.171 14.1397 15.0304 14.2803L13.25 16.0607L13.25 17.75L11.75 17.75L11.75 15.75C11.75 15.5511 11.829 15.3603 11.9697 15.2197L13.75 13.4393L13.75 9L11.5 9C11.1967 9 10.9232 8.81727 10.8071 8.53701Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 23.25C8.25 22.8358 8.58579 22.5 9 22.5H23C23.4142 22.5 23.75 22.8358 23.75 23.25C23.75 23.6642 23.4142 24 23 24H9C8.58579 24 8.25 23.6642 8.25 23.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 9C4.80964 9 4.25 9.55964 4.25 10.25V24.25C4.25 24.9404 4.80964 25.5 5.5 25.5H26.5C27.1904 25.5 27.75 24.9404 27.75 24.25V10.25C27.75 9.55964 27.1904 9 26.5 9H22.25V7.5H26.5C28.0188 7.5 29.25 8.73122 29.25 10.25V24.25C29.25 25.7688 28.0188 27 26.5 27H5.5C3.98122 27 2.75 25.7688 2.75 24.25V10.25C2.75 8.73122 3.98122 7.5 5.5 7.5H9.75V9H5.5Z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const SOSIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.88,18.47c0.44-0.7,0.7-1.51,0.7-2.39c0-2.49-2.01-4.5-4.5-4.5s-4.5,2.01-4.5,4.5s2.01,4.5,4.49,4.5 c0.88,0,1.7-0.26,2.39-0.7L21.58,23L23,21.58L19.88,18.47z M16.08,18.58c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 s2.5,1.12,2.5,2.5C18.58,17.46,17.46,18.58,16.08,18.58z M15.72,10.08c-0.74,0.02-1.45,0.18-2.1,0.45l-0.55-0.83l-3.8,6.18 l-3.01-3.52l-3.63,5.81L1,17l5-8l3,3.5L13,6C13,6,15.72,10.08,15.72,10.08z M18.31,10.58c-0.64-0.28-1.33-0.45-2.05-0.49 c0,0,5.12-8.09,5.12-8.09L23,3.18L18.31,10.58z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

const SOSMunicipalityIcon: React.FC<ChakraIconProps> = ({ ...props }) => {
  return (
    <ChakraIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"
        fill="currentColor"
      />
    </ChakraIcon>
  );
};

export type AvailableIcon =
  | 'triangle-up'
  | 'triangle-down'
  | 'warning'
  | 'download'
  | 'check'
  | 'close'
  | 'search'
  | 'overview'
  | 'lock'
  | 'unlock'
  | 'mmm'
  | 'mmm-performance'
  | 'mmm-created-value'
  | 'mmm-cost-per-unit'
  | 'mmm-modelling'
  | 'da'
  | 'da-explorer'
  | 'da-deep-dive'
  | 'da-channels'
  | 'da-search'
  | 'mo'
  | 'lt'
  | 'sos'
  | 'sos-municipality'
  | 'external-link'
  | 'info';

export type IconProps = ChakraIconProps & {
  iconName: AvailableIcon;
};

const getIcon = (iconName: AvailableIcon): React.FC<ChakraIconProps> => {
  switch (iconName) {
    case 'triangle-up':
      return TriangleUpIcon;
    case 'triangle-down':
      return TriangleDownIcon;
    case 'warning':
      return WarningIcon;
    case 'download':
      return DownloadIcon;
    case 'check':
      return CheckIcon;
    case 'close':
      return CloseIcon;
    case 'search':
      return SearchIcon;
    case 'lock':
      return LockIcon;
    case 'unlock':
      return UnlockIcon;
    case 'overview':
      return OverviewIcon;
    case 'mmm':
      return MMMIcon;
    case 'mmm-performance':
      return MMMPerformanceIcon;
    case 'mmm-created-value':
      return MMMCreatedValueIcon;
    case 'mmm-cost-per-unit':
      return MMMCostPerUnitIcon;
    case 'mmm-modelling':
      return MMMModellingIcon;
    case 'da':
      return DAIcon;
    case 'da-explorer':
      return DAExplorerIcon;
    case 'da-deep-dive':
      return DADeepDiveIcon;
    case 'da-channels':
      return DAChannelsIcon;
    case 'da-search':
      return DASearchIcon;
    case 'mo':
      return MOIcon;
    case 'lt':
      return LtIcon;
    case 'sos':
      return SOSIcon;
    case 'sos-municipality':
      return SOSMunicipalityIcon;
    case 'external-link':
      return ExternalLinkIcon;
    case 'info':
      return InfoOutlineIcon;
  }
};

export const Icon: React.FC<IconProps> = ({ iconName, ...chakraIconProps }) => {
  const IconComponent = getIcon(iconName);

  return <IconComponent {...chakraIconProps} />;
};
