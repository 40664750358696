import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { fetchPresignedMasterDataUrl } from 'src/api/fetch';
import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { useFilterContext } from 'src/contexts/FilterContext';

export const MasterDataDownloadButton: React.FC = () => {
  const { currentModelVersion } = useFilterContext();

  const [isDownloading, setIsDownloading] = useState(false);

  if (!currentModelVersion?.masterDataAvailable) {
    return null;
  }

  const onDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    try {
      const presignedUrl = await fetchPresignedMasterDataUrl({
        customer: currentModelVersion.customerName,
        model_build: currentModelVersion.buildID,
        model_id: currentModelVersion.modelID,
      });

      window.location.href = presignedUrl;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Button
      buttonName="master-data"
      borderColor={'transparent'}
      variant={'outline'}
      fontSize={'14px'}
      title="Download Master Data"
      isLoading={isDownloading}
      onClick={onDownloadClick}>
      <Flex alignItems="center">
        <Icon iconName="download" mr="3" />
        Master Data
      </Flex>
    </Button>
  );
};
