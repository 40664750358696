import { chakra, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect } from 'react';
import { Icon } from 'src/components/atoms/Icon';
import { useExportCsvContext } from 'src/components/molecules/ExportCsv/ExportCsvContext';
import { getHightlightCellStyle } from './styles';
import { useDataBoxBackgroundColor } from 'src/hooks/colors/useBackgroundColor';

export type DataRecord = Record<string, number | string | null>;

export type DataTableProps<A extends DataRecord> = {
  data: Array<A>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<A, any>>; // https://github.com/TanStack/table/issues/4382
  csvFileName: string;
};

export const DataTable = <A extends DataRecord>({
  data,
  columns,
  csvFileName,
}: DataTableProps<A>) => {
  const { setExportData } = useExportCsvContext();

  const { bgColor } = useDataBoxBackgroundColor();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    setExportData({
      csvData: data,
      fileName: csvFileName,
    });
  }, [csvFileName, setExportData, data]);

  return (
    <Table variant="unstyled">
      <Thead bg={bgColor} position={'sticky'} top={0}>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <Tr bg={bgColor} key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    cursor={'pointer'}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    whiteSpace="nowrap">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    <chakra.span pl="2">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <Icon
                            iconName="triangle-down"
                            aria-label="sorted descending"
                            height={'14px'}
                          />
                        ) : (
                          <Icon
                            iconName="triangle-up"
                            aria-label="sorted ascending"
                            height={'14px'}
                          />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          );
        })}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const hightlightChangeRange =
                cell.column.columnDef.meta?.hightlightChangeRange;

              const value = cell.getValue();

              const hightlightStyles =
                hightlightChangeRange && typeof value === 'number'
                  ? getHightlightCellStyle(value, hightlightChangeRange)
                  : {};

              return (
                <Td {...hightlightStyles} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
