import React from 'react';
import { useAggregationLevelContext } from 'src/features/MM/contexts/AggregationLevelContext';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { KeyMetricsGrid } from 'src/components/molecules/KeyMetricsGrid';
import { CostPerUnit } from './CostPerUnit';
import { IncrementalSales } from './IncrementalSales';
import { MediaInvestment } from './MediaInvestment';
import { Roi } from './Roi';
import { Romi } from './Romi';
import { useGetDateAxisFormatter } from 'src/features/MM/hooks/useGetDateAxisFormatter';

export const MMKeyMetrics: React.FC = () => {
  const { romiEnabled } = useMMDataContext();
  const { aggregateLevel } = useAggregationLevelContext();

  const dateFormatter = useGetDateAxisFormatter();

  return (
    <KeyMetricsGrid>
      <MediaInvestment
        dateFormatter={dateFormatter}
        aggregateLevel={aggregateLevel}
      />
      <IncrementalSales
        dateFormatter={dateFormatter}
        aggregateLevel={aggregateLevel}
      />
      <Roi dateFormatter={dateFormatter} aggregateLevel={aggregateLevel} />
      {romiEnabled ? (
        <Romi dateFormatter={dateFormatter} aggregateLevel={aggregateLevel} />
      ) : (
        <CostPerUnit
          dateFormatter={dateFormatter}
          aggregateLevel={aggregateLevel}
        />
      )}
    </KeyMetricsGrid>
  );
};
