import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { useSOSDataContext } from '../../../contexts/SOSDataContext';
import { SosByYearsTable } from '../../molecules/SosByYearsTable';

export const SosByYearsLocationsAggregateTableBox: React.FC<
  DataBoxBaseProps
> = ({ ...props }) => {
  const { shareOfSearchLocationsAggregateState } = useSOSDataContext();

  return (
    <DataBox
      {...props}
      exportEnabled
      isError={shareOfSearchLocationsAggregateState.isError}
      isLoading={shareOfSearchLocationsAggregateState.isLoading}
      hasData={shareOfSearchLocationsAggregateState.data.length > 0}>
      <SosByYearsTable
        csvFileName="sos-by-years-locations-aggregate"
        data={shareOfSearchLocationsAggregateState.data}
      />
    </DataBox>
  );
};
