import { useFilterContext } from 'src/contexts/FilterContext';
import { useDADataContext } from '../contexts/DADataContext';

export const useBenchmarkEnabled = () => {
  const { benchmarkTimerange } = useFilterContext();
  const {
    benchmarkConversions,
    benchmarkInvestments,
    benchmarkSales,
    benchmarkOrganicSearch,
  } = useDADataContext();

  const benchmarkTimeRangeOk =
    benchmarkTimerange.enabled && !benchmarkTimerange.error;

  const conversionsBenchmarkEnabled =
    benchmarkTimeRangeOk &&
    !benchmarkConversions.isError &&
    !benchmarkConversions.isLoading &&
    benchmarkConversions.data.length > 0;

  const investmentsBenchmarkEnabled =
    benchmarkTimeRangeOk &&
    !benchmarkInvestments.isError &&
    !benchmarkInvestments.isLoading &&
    benchmarkInvestments.daily.length > 0;

  const salesBenchmarkEnabled =
    benchmarkTimeRangeOk &&
    !benchmarkSales.isError &&
    !benchmarkSales.isLoading &&
    benchmarkSales.day.length > 0;

  const organicSearchBenchmarkEnabled =
    benchmarkTimeRangeOk &&
    !benchmarkOrganicSearch.isError &&
    !benchmarkOrganicSearch.isLoading &&
    benchmarkOrganicSearch.data.length > 0;

  return {
    conversionsBenchmarkEnabled,
    investmentsBenchmarkEnabled,
    salesBenchmarkEnabled,
    organicSearchBenchmarkEnabled,
  };
};
