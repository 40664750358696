import { useCallback, useMemo } from 'react';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useDADataContext } from '../contexts/DADataContext';
import {
  ConversionsDataPointByDimension,
  isByCampaign,
  isByChannel,
  isByTag,
} from '../types';
import {
  BENCHMARK_CLICKS_NAME,
  BENCHMARK_CPA_NAME,
  BENCHMARK_FREQUENCY_NAME,
  BENCHMARK_IMPRESSIONS_NAME,
  BENCHMARK_MODELLED_CONV_NAME,
  BENCHMARK_SPEND_NAME,
  MODELLED_CONV_NAME,
} from '../utils/constants';
import { sortByModelledConversions } from '../utils/sortGraphData';
import { useBenchmarkEnabled } from './useBenchmarkEnabled';

export type MetricsType = 'byChannel' | 'byCampaign' | 'byTag';

export type MetricsKey = 'Channel' | 'Tag' | 'Campaign' | 'Keyword cluster';

export type MetricsData = {
  [MODELLED_CONV_NAME]: number;
  Spend: number;
  Impressions: number;
  CPA: number;
  Clicks: number;
  Frequency: number | null;
  [BENCHMARK_MODELLED_CONV_NAME]?: number;
  [BENCHMARK_SPEND_NAME]?: number;
  [BENCHMARK_IMPRESSIONS_NAME]?: number;
  [BENCHMARK_CPA_NAME]?: number;
  [BENCHMARK_CLICKS_NAME]?: number;
  [BENCHMARK_FREQUENCY_NAME]?: number | null;
  key: string;
};

export const getDataKeys = (benchmarkEnabled: boolean) => {
  const data: Array<keyof MetricsData> = benchmarkEnabled
    ? [
        MODELLED_CONV_NAME,
        BENCHMARK_MODELLED_CONV_NAME,
        'Spend',
        BENCHMARK_SPEND_NAME,
        'Impressions',
        BENCHMARK_IMPRESSIONS_NAME,
        'CPA',
        BENCHMARK_CPA_NAME,
        'Clicks',
        BENCHMARK_CLICKS_NAME,
        'Frequency',
        BENCHMARK_FREQUENCY_NAME,
      ]
    : [
        MODELLED_CONV_NAME,
        'Spend',
        'Impressions',
        'CPA',
        'Clicks',
        'Frequency',
      ];

  return {
    xAxis: 'key',
    data,
  };
};

export const useMetrics = (metricsType: MetricsType) => {
  const { conversions, benchmarkConversions } = useDADataContext();
  const { benchmarkTimerange } = useFilterContext();
  const { conversionsBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMediaName } = useCustomerSettingsContext();
  const { campaignsMap } = useFilterContext();

  const getKeyValue = useCallback(
    (d: ConversionsDataPointByDimension): string => {
      if (isByChannel(d)) {
        return getMediaName(d.channel);
      }
      if (isByTag(d)) {
        return d.tag;
      }
      if (isByCampaign(d)) {
        return campaignsMap[d.campaignId]?.name ?? 'Missing campaign name';
      }
      return '';
    },
    [getMediaName, campaignsMap],
  );

  const metrics: Array<MetricsData> = useMemo(() => {
    const byData = conversions[metricsType];
    if (byData.length === 0) {
      return [];
    }

    const benchmarkRecord: Record<
      string,
      {
        spend: number;
        impressions: number;
        cpa: number;
        clicks: number;
        conversions: number;
        frequency: number | null;
      }
    > = {};

    if (conversionsBenchmarkEnabled) {
      const benchmarkByData = benchmarkConversions[metricsType];
      benchmarkByData.forEach((b) => {
        benchmarkRecord[getKeyValue(b)] = {
          conversions: b.measurements.modelledConversions,
          spend: b.measurements.spend,
          impressions: b.measurements.impressions,
          cpa: b.measurements.cpa,
          clicks: b.measurements.clicks,
          frequency: b.measurements.frequency,
        };
      });
    }

    return byData
      .map((d) => {
        const metricDataPoint = {
          key: getKeyValue(d),
          [MODELLED_CONV_NAME]: d.measurements.modelledConversions,
          Spend: d.measurements.spend,
          Impressions: d.measurements.impressions,
          CPA: d.measurements.cpa,
          Clicks: d.measurements.clicks,
          Frequency: d.measurements.frequency,
        };
        const benchmarkData = benchmarkRecord[metricDataPoint.key];
        if (benchmarkData) {
          // Real value and benchmark values need to be in pairs so that they are rendered next to each other in graphs
          return {
            key: metricDataPoint.key,
            [MODELLED_CONV_NAME]: metricDataPoint[MODELLED_CONV_NAME],
            [BENCHMARK_MODELLED_CONV_NAME]: benchmarkData.conversions,
            Spend: metricDataPoint.Spend,
            [BENCHMARK_SPEND_NAME]: benchmarkData.spend,
            Impressions: metricDataPoint.Impressions,
            [BENCHMARK_IMPRESSIONS_NAME]: benchmarkData.impressions,
            CPA: metricDataPoint.CPA,
            [BENCHMARK_CPA_NAME]: benchmarkData.cpa,
            Clicks: metricDataPoint.Clicks,
            [BENCHMARK_CLICKS_NAME]: benchmarkData.clicks,
            Frequency: d.measurements.frequency,
            [BENCHMARK_FREQUENCY_NAME]: benchmarkData.frequency,
          };
        }
        return metricDataPoint;
      })
      .sort(sortByModelledConversions);
  }, [
    conversions,
    metricsType,
    getKeyValue,
    conversionsBenchmarkEnabled,
    benchmarkConversions,
  ]);

  // Handle datakeys using benchmarkTimerange so that loading state does not cause flashing
  const dataKeys = getDataKeys(
    benchmarkTimerange.enabled && !benchmarkTimerange.error,
  );

  return {
    data: metrics,
    isLoading: conversions.isLoading,
    isError: conversions.isError,
    dataKeys,
  };
};
