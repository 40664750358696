/* eslint-disable no-restricted-imports */
import {
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useDataBoxBackgroundColor } from 'src/hooks/colors/useBackgroundColor';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string | React.ReactNode;
  trapFocus?: boolean;
  children: React.ReactNode;
  closeOnOutsideClick?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onClose,
  header,
  trapFocus = false,
  closeOnOutsideClick = false,
}) => {
  // Use same color as databox bg
  const { bgColor } = useDataBoxBackgroundColor();
  return (
    <ChakraModal
      scrollBehavior="inside"
      isCentered
      closeOnOverlayClick={closeOnOutsideClick}
      trapFocus={trapFocus}
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bgColor} maxW="80%">
        <ModalHeader>{header}</ModalHeader>
        {children}
      </ModalContent>
    </ChakraModal>
  );
};
