import { calculateRoi } from 'src/utils/calculateRoi';
import { sortEpoch } from 'src/utils/date';
import { MMDataState, TimeframeData } from '../types';

const calculateRoiFromRecords = (
  byXInvestments: Record<string, number>,
  byXDecomps: Record<string, number>,
  currentCustomer: string,
): Record<string, number> => {
  const resultRecord: Record<string, number> = {};

  Object.entries(byXInvestments).forEach(([key, investmentValue]) => {
    const decompsValue = byXDecomps[key];

    if (decompsValue === undefined) {
      return;
    }

    resultRecord[key] = calculateRoi({
      mediaIncrementalSales: decompsValue,
      investment: investmentValue,
      customer: currentCustomer,
    });
  });

  return resultRecord;
};

const combineTimeframeData = (
  investmentData: Array<TimeframeData>,
  decompsData: Array<TimeframeData>,
  currentCustomer: string,
): Array<TimeframeData> => {
  const byUtcEpoch: Record<
    number,
    {
      investment: TimeframeData | null;
      decomps: TimeframeData | null;
    }
  > = {};

  const initByUtcEpochRecordIfNecessary = (utcEpoch: number) => {
    if (!byUtcEpoch[utcEpoch]) {
      byUtcEpoch[utcEpoch] = {
        investment: null,
        decomps: null,
      };
    }
  };

  investmentData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].investment = datapoint;
  });

  decompsData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].decomps = datapoint;
  });

  const timeframeData: Array<TimeframeData> = [];

  Object.entries(byUtcEpoch).forEach(([utcEpoch, { investment, decomps }]) => {
    if (!investment || !decomps) {
      return;
    }

    const value = calculateRoi({
      mediaIncrementalSales: decomps.value,
      investment: investment.value,
      customer: currentCustomer,
    });

    const d: TimeframeData = {
      byCampaignId: calculateRoiFromRecords(
        investment.byCampaignId,
        decomps.byCampaignId,
        currentCustomer,
      ),
      byMedia: calculateRoiFromRecords(
        investment.byMedia,
        decomps.byMedia,
        currentCustomer,
      ),
      byTag: calculateRoiFromRecords(
        investment.byTag,
        decomps.byTag,
        currentCustomer,
      ),
      byCategory: calculateRoiFromRecords(
        investment.byCategory,
        decomps.byCategory,
        currentCustomer,
      ),
      utcEpoch: Number(utcEpoch),
      value,
    };

    timeframeData.push(d);
  });

  return timeframeData.sort(sortEpoch);
};

export const aggregateRoi = (
  investments: MMDataState,
  decomps: MMDataState,
  currentCustomer: string,
): MMDataState => {
  const isLoading = investments.isLoading || decomps.isLoading;
  const isError = investments.isError || decomps.isError;

  const dataState: MMDataState = {
    week: combineTimeframeData(investments.week, decomps.week, currentCustomer),
    month: combineTimeframeData(
      investments.month,
      decomps.month,
      currentCustomer,
    ),
    quarter: combineTimeframeData(
      investments.quarter,
      decomps.quarter,
      currentCustomer,
    ),
    biannual: combineTimeframeData(
      investments.biannual,
      decomps.biannual,
      currentCustomer,
    ),
    year: combineTimeframeData(investments.year, decomps.year, currentCustomer),
    total: calculateRoi({
      mediaIncrementalSales: decomps.total,
      investment: investments.total,
      customer: currentCustomer,
    }),
    totalByCategory: calculateRoiFromRecords(
      investments.totalByCategory,
      decomps.totalByCategory,
      currentCustomer,
    ),
    totalByCampaignId: calculateRoiFromRecords(
      investments.totalByCampaignId,
      decomps.totalByCampaignId,
      currentCustomer,
    ),
    totalByMedia: calculateRoiFromRecords(
      investments.totalByMedia,
      decomps.totalByMedia,
      currentCustomer,
    ),
    totalByTag: calculateRoiFromRecords(
      investments.totalByTag,
      decomps.totalByTag,
      currentCustomer,
    ),
    isLoading,
    isError,
  };

  return dataState;
};
