import React, { useMemo, useState } from 'react';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { LineGraph } from 'src/components/molecules/graphs/LineGraph';
import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { ValueTickFormatter } from 'src/utils/TickFormatters';
import { useRoiDef } from 'src/utils/calculateRoi';
import { useAggregationLevelContext } from '../../contexts/AggregationLevelContext';
import { useGetDateAxisFormatter } from '../../hooks/useGetDateAxisFormatter';
import { getAggregateLevelData } from '../../utils/getAggregateLevelData';
import { TimeseriesDataTableModal } from '../molecules/TimeseriesDataTableModal';

const dataKeys = {
  xAxis: 'utcEpoch',
  data: ['value'],
};

const legendProps: DataBoxLegendProps = {
  placement: 'none',
  lineKeys: dataKeys.data,
};

export const ROIBox: React.FC<DataBoxBaseProps> = ({ ...props }) => {
  const { roi } = useMMDataContext();
  const { isLoading, isError } = roi;

  const { aggregateLevel } = useAggregationLevelContext();
  const roiDef = useRoiDef();

  const [infoOpen, setInfoOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const data = useMemo(() => {
    return getAggregateLevelData(roi, aggregateLevel);
  }, [roi, aggregateLevel]);

  const xAxisFormatter = useGetDateAxisFormatter();

  return (
    <>
      <InfoAlert
        headerText={'Return on Investment'}
        bodyText={roiDef}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        exportEnabled
        exportButtonEnabled={false}
        setTableDataModalIsOpen={setModalIsOpen}
        hasData={data.length > 0}
        isError={isError}
        isLoading={isLoading}>
        <>
          <TimeseriesDataTableModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            yFormatter={ValueTickFormatter}
            data={data}
            headerName={props.title ?? ''}
          />
          <LineGraph
            data={data}
            xAxisKey={dataKeys.xAxis}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={ValueTickFormatter}
          />
        </>
      </DataBox>
    </>
  );
};
