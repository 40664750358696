import { createContext, useContext } from 'react';
import { Dimension } from '../../types';

export type SelectedDimensionContextValue = {
  dimension: Dimension;
  setDimension: (dimension: Dimension) => void;
};

export const SelectedDimensionContext =
  createContext<SelectedDimensionContextValue | null>(null);

SelectedDimensionContext.displayName = 'SelectedDimensionContext';

export function useSelectedDimensionContext() {
  const value = useContext(SelectedDimensionContext);
  if (!value) {
    throw new Error(
      'useSelectedDimensionContext has to be used under SelectedDimensionContextProvider',
    );
  }
  return value;
}
