import React, { useMemo, useState } from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import {
  TreemapData,
  TreemapGraph,
} from 'src/components/molecules/graphs/TreemapGraph';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import {
  EuroValueTickFormatter,
  ValueTickFormatter,
} from 'src/utils/TickFormatters';
import { useDADataContext } from '../../../contexts/DADataContext';
import { MediaMatrixTabs, MetricOption } from './MediaMatrixTabs';

export const MediaMatrixBox: React.FC<DataBoxBaseProps> = (props) => {
  const { conversions } = useDADataContext();
  const { mediaColorMap } = useFilterContext();
  const { getMediaName } = useCustomerSettingsContext();

  const [dataKey, setDataKey] = useState<MetricOption>('spend');

  const valueFormatter =
    dataKey === 'spend' ? EuroValueTickFormatter : ValueTickFormatter;

  const data: TreemapData<{
    metricData: number;
  }> = useMemo(() => {
    return conversions.byChannel
      .map((channelData) => {
        const metricData =
          dataKey === 'spend'
            ? channelData.measurements.spend
            : channelData.measurements.modelledConversions;

        return {
          name: getMediaName(channelData.channel),
          metricData,
        };
      })
      .filter(({ metricData }) => {
        return metricData > 0 && !isNaN(metricData);
      });
  }, [conversions, getMediaName, dataKey]);

  return (
    <DataBox
      tabs={<MediaMatrixTabs onSelect={setDataKey} selectedTab={dataKey} />}
      {...props}
      hasData={data.length > 0}
      isError={conversions.isError}
      isLoading={conversions.isLoading}>
      <TreemapGraph
        colorMap={mediaColorMap}
        data={data}
        dataKey={'metricData'}
        valueFormatter={valueFormatter}
      />
    </DataBox>
  );
};
