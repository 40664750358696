import React, { useState } from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { DataTable } from 'src/components/molecules/DataTable';
import { useSelectedDimensionContext } from 'src/features/MM/contexts/SelectedDimensionContext';
import { getDimensionName } from 'src/features/MM/utils/getDimensionName';
import { SummaryInfoAlert } from './SummaryInfoAlert';
import { useColumns, useTableData } from './useTableData';

export const SummaryTableBox: React.FC<DataBoxBaseProps> = (props) => {
  const { dimension } = useSelectedDimensionContext();

  const [infoOpen, setInfoOpen] = useState(false);
  const { data, isError, isLoading } = useTableData();

  const columns = useColumns();

  return (
    <>
      <SummaryInfoAlert
        infoOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        exportEnabled
        hasData={data.length > 0}
        isError={isError}
        isLoading={isLoading}>
        <DataTable
          csvFileName={getDimensionName(dimension)}
          data={data}
          columns={columns}
        />
      </DataBox>
    </>
  );
};
