import { sortDataWithLocation } from 'src/utils/sort';
import { ApiShareOfSearchAggregatedDataPoint } from '../api/queries';
import { ShareOfSearchByRegionPast12MonthsDataPoint } from '../types';
import { SelectableKeyword } from '../hooks/useShareOfSearchCountryMonthly';

export const mapAndFilterAggregateDataToPast12Months = (
  data: Array<ApiShareOfSearchAggregatedDataPoint>,
  selectableKeywords: Array<SelectableKeyword>,
): Array<ShareOfSearchByRegionPast12MonthsDataPoint> => {
  const selectedKeywordsSet = new Set(
    selectableKeywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword),
  );

  const filteredData = data.map(
    (d): ShareOfSearchByRegionPast12MonthsDataPoint => {
      let regionTotal = 0;

      const keywords = d.keywords
        .filter(({ keyword }) => selectedKeywordsSet.has(keyword))
        .map((k) => {
          const searchVolume = k.last12Months;

          regionTotal += searchVolume;

          return {
            keyword: k.keyword,
            searchVolume: k.last12Months,
          };
        });

      return {
        location: d.location,
        regionTotal,
        keywords,
      };
    },
  );

  return sortDataWithLocation(filteredData);
};
