import { Box, Flex } from '@chakra-ui/react';
import { useMODataContext } from 'src/features/MO/contexts/MODataContext';
import {
  CONSTRAINT_WIDTH,
  INVESTMENT_WIDTH,
  MEDIA_EFFECT_WIDTH,
  MR,
  NAME_WIDTH,
  PERIOD_WIDTH,
  ROI_OR_ROMI_WIDTH,
} from './sizeConstants';

export const OptimizationHeader: React.FC = () => {
  const { marginPct } = useMODataContext();

  return (
    <Flex
      fontWeight="bold"
      textAlign="left"
      whiteSpace="nowrap"
      ml={4}
      mr={8}
      mb="10px">
      <Box mr={MR} width={NAME_WIDTH}>
        Optimization name
      </Box>
      <Box mr={MR} width={PERIOD_WIDTH}>
        Period
      </Box>
      <Box mr={MR} width={INVESTMENT_WIDTH}>
        Investment
      </Box>
      <Box mr={MR} width={MEDIA_EFFECT_WIDTH}>
        Media Effect
      </Box>
      {marginPct !== null && (
        <Box mr={MR} width={ROI_OR_ROMI_WIDTH}>
          ROMI
        </Box>
      )}
      <Box mr={MR} width={ROI_OR_ROMI_WIDTH}>
        ROI
      </Box>
      <Box width={CONSTRAINT_WIDTH}>Constraint count</Box>
    </Flex>
  );
};
