import React, { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useRenameByChannel } from 'src/hooks/useRenameByChannel';
import { useLTDataContext } from '../../../contexts/LTDataContext';
import { EffectTabs, EffectType } from '../../molecules/EffectTabs';
import {
  EffectAndInvestmentData,
  EffectAndInvestmewntMonthlyGraph,
} from './EffectAndInvestmentMonthlyGraph';
import {
  INVESTMENT_KEY,
  mapByMediaSpendToInvestmentKeys,
} from './getInvestmentKey';

export const EffectAndInvestmentMonthlyBox: React.FC<DataBoxBaseProps> = ({
  ...props
}) => {
  const { effects, investments, isError, isLoading } = useLTDataContext();

  const { mediaColorMap } = useFilterContext();

  const [effectType, setEffectType] = useState<EffectType>('long');

  const { renameByChannel } = useRenameByChannel();

  const processedChartData: {
    long: Array<EffectAndInvestmentData>;
    short: Array<EffectAndInvestmentData>;
    total: Array<EffectAndInvestmentData>;
    media: Array<string>;
  } = useMemo(() => {
    const medias: Record<string, true> = {};
    const monthly: Record<
      string,
      {
        byMediaShort: Record<string, number>;
        byMediaLong: Record<string, number>;
        byMediaTotal: Record<string, number>;
        byMediaSpend: Record<string, number>;
        utcEpoch: number;
      }
    > = {};

    effects.monthly.forEach(
      ({ utcEpoch, byMediaLong, byMediaShort, byMediaTotal }) => {
        Object.keys(renameByChannel(byMediaTotal)).forEach((media) => {
          medias[media] = true;
        });

        monthly[utcEpoch] = {
          byMediaShort,
          byMediaLong,
          byMediaTotal,
          byMediaSpend: {},
          utcEpoch,
        };
      },
    );

    investments.monthly.forEach(({ utcEpoch, byMedia }) => {
      if (!monthly[utcEpoch]) {
        monthly[utcEpoch] = {
          byMediaShort: {},
          byMediaLong: {},
          byMediaTotal: {},
          byMediaSpend: {},
          utcEpoch,
        };
      }
      monthly[utcEpoch].byMediaSpend = byMedia;
    });

    const longResults: Array<EffectAndInvestmentData> = Object.values(
      monthly,
    ).map(({ byMediaLong, byMediaSpend, utcEpoch }) => {
      const renamedBySpend = mapByMediaSpendToInvestmentKeys(
        renameByChannel,
        byMediaSpend,
      );

      return {
        key: utcEpoch,
        ...renameByChannel(byMediaLong),
        ...renamedBySpend,
      };
    });

    const shortResults: Array<EffectAndInvestmentData> = Object.values(
      monthly,
    ).map(({ byMediaShort, byMediaSpend, utcEpoch }) => {
      const renamedBySpend = mapByMediaSpendToInvestmentKeys(
        renameByChannel,
        byMediaSpend,
      );

      return {
        key: utcEpoch,
        ...renameByChannel(byMediaShort),
        ...renamedBySpend,
      };
    });
    const totalResults: Array<EffectAndInvestmentData> = Object.values(
      monthly,
    ).map(({ byMediaTotal, byMediaSpend, utcEpoch }) => {
      const renamedBySpend = mapByMediaSpendToInvestmentKeys(
        renameByChannel,
        byMediaSpend,
      );

      return {
        key: utcEpoch,
        ...renameByChannel(byMediaTotal),
        ...renamedBySpend,
      };
    });

    return {
      long: longResults,
      short: shortResults,
      total: totalResults,
      media: Object.keys(medias),
    };
  }, [effects, investments, renameByChannel]);

  let data: EffectAndInvestmentData[];
  switch (effectType) {
    case 'long':
      data = processedChartData.long;
      break;
    case 'short':
      data = processedChartData.short;
      break;
    case 'total':
      data = processedChartData.total;
      break;
  }

  const legendProps: DataBoxLegendProps = {
    placement: 'right',
    baseLegendKeys: processedChartData.media,
    lineKeys: [INVESTMENT_KEY],
    keyColorMap: mediaColorMap,
  };

  return (
    <DataBox
      {...props}
      restrictChartHeight
      tabs={<EffectTabs onSelect={setEffectType} selectedTab={effectType} />}
      legendProps={legendProps}
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <EffectAndInvestmewntMonthlyGraph data={data} />
    </DataBox>
  );
};
