import React, { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { useLTDataContext } from 'src/features/LT/contexts/LTDataContext';

import {
  MonthYearDateFormatter,
  ValueTickFormatter,
  YearDateFormatter,
} from 'src/utils/TickFormatters';
import { TimePeriod, TimePeriodTabs } from '../molecules/TimePeriodTabs';

type ProcessedDataPoint = {
  key: number;
  'Short ROI': number;
  'Long ROI': number;
};

type RoiBoxProps = DataBoxBaseProps;

export const RoiBox: React.FC<RoiBoxProps> = (props) => {
  const { roi, isError, isLoading } = useLTDataContext();

  const [timePeriod, setTimePeriod] = useState<TimePeriod>('monthly');

  const processedData: {
    monthly: Array<ProcessedDataPoint>;
    yearly: Array<ProcessedDataPoint>;
  } = useMemo(() => {
    return {
      monthly: roi.monthly.map(
        ({ utcEpoch, shortEffectROI, longEffectROI }) => ({
          key: utcEpoch,
          'Short ROI': shortEffectROI,
          'Long ROI': longEffectROI,
        }),
      ),
      yearly: roi.yearly.map(({ utcEpoch, shortEffectROI, longEffectROI }) => ({
        key: utcEpoch,
        'Short ROI': shortEffectROI,
        'Long ROI': longEffectROI,
      })),
    };
  }, [roi]);

  const dataKeys = {
    xAxis: 'key',
    data: ['Short ROI', 'Long ROI'],
  };

  let data: ProcessedDataPoint[];
  const dateAxisFormatter =
    timePeriod === 'monthly' ? MonthYearDateFormatter : YearDateFormatter;

  switch (timePeriod) {
    case 'monthly':
      data = processedData.monthly;
      break;
    case 'yearly':
      data = processedData.yearly;
      break;
  }

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'none',
  };

  return (
    <DataBox
      {...props}
      tabs={
        <TimePeriodTabs onSelect={setTimePeriod} selectedTab={timePeriod} />
      }
      legendProps={legendProps}
      isError={isError}
      isLoading={isLoading}
      hasData={data.length > 0}>
      <BarGraph
        data={data}
        stacked
        xAxisKey={dataKeys.xAxis}
        xAxisFormatter={dateAxisFormatter}
        yAxisFormatter={ValueTickFormatter}
      />
    </DataBox>
  );
};
