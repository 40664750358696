import { range } from 'lodash';
import { dayjs } from 'src/libs/dayjs';

export const getLast12MonthsEpochs = (targetEpoch: number) => {
  return range(0, 12).map((m) => {
    return dayjs(targetEpoch)
      .utc()
      .subtract(m, 'month')
      .startOf('month')
      .valueOf();
  });
};
