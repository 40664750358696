import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { sortDataWithLocation } from 'src/utils/sort';
import {
  ApiShareOfSearchAggregatedDataPoint,
  useShareOfSearchRegionsQuery,
} from '../api/queries';
import {
  SelectableLocation,
  ShareOfSearchByRegionPast12MonthsDataPoint,
  SosLocation,
} from '../types';
import {
  BenchmarkDates,
  getDefaultBenchmarkDates,
} from '../utils/getDefaultBenchmarkDates';
import { SelectableKeyword } from './useShareOfSearchCountryMonthly';
import { mapAndFilterAggregateDataToPast12Months } from '../utils/mapAndFilter12MonthRegionData';

const mapFilterAndSortRegionsData = (
  regionsData: Array<ApiShareOfSearchAggregatedDataPoint>,
  selectableKeywords: Array<SelectableKeyword>,
  selectableRegions: Array<SelectableLocation>,
): Array<ApiShareOfSearchAggregatedDataPoint> => {
  const selectedKeywordsSet = new Set(
    selectableKeywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword),
  );

  const selectableRegionsSet = new Set(
    selectableRegions
      .filter(({ selected }) => selected)
      .map(({ locationId }) => locationId),
  );

  const filteredRegionsData = regionsData
    .filter((d) => selectableRegionsSet.has(d.location.locationId))
    .map((d) => {
      return {
        ...d,
        keywords: d.keywords.filter(({ keyword }) =>
          selectedKeywordsSet.has(keyword),
        ),
      };
    });

  const data = sortDataWithLocation(filteredRegionsData);

  return data;
};

export type ShareOfSearchRegionsState = {
  data: Array<ApiShareOfSearchAggregatedDataPoint>;
  regionMapData: Array<ShareOfSearchByRegionPast12MonthsDataPoint>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_STATE: Readonly<ShareOfSearchRegionsState> = {
  data: [],
  regionMapData: [],
  isError: false,
  isLoading: false,
};

export const useShareOfSearchByRegionPast12Months = (
  enabled: boolean,
  selectableKeywords: Array<SelectableKeyword>,
  selectedCountry: SosLocation,
  selectableRegions: Array<SelectableLocation>,
) => {
  const { currentCustomer } = useAppContext();

  const [shareOfSearchRegionsState, setShareOfSearchRegionsState] =
    useState<ShareOfSearchRegionsState>(INIT_STATE);

  const [regionsBenchmarkDates, setRegionsBenchmarkDates] =
    useState<BenchmarkDates>(getDefaultBenchmarkDates());

  const {
    data: apiData,
    isError,
    isLoading,
  } = useShareOfSearchRegionsQuery(
    {
      customer: currentCustomer?.customerName ?? 'no_customer',
      country_id: selectedCountry.locationId,
      country_code: selectedCountry.countryCode,
      benchmark_from: regionsBenchmarkDates.benchmarkFrom
        .startOf('month')
        .format('YYYY-MM-DD'),
      benchmark_to: regionsBenchmarkDates.benchmarkTo
        .startOf('month')
        .format('YYYY-MM-DD'),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setShareOfSearchRegionsState(INIT_STATE);
      setRegionsBenchmarkDates(getDefaultBenchmarkDates());
      return;
    }

    const data = apiData ?? [];

    setShareOfSearchRegionsState({
      data: mapFilterAndSortRegionsData(
        data,
        selectableKeywords,
        selectableRegions,
      ),
      // We do not want region filters to effect this, but do want keywords to
      regionMapData: mapAndFilterAggregateDataToPast12Months(
        data,
        selectableKeywords,
      ),
      isError,
      isLoading,
    });
  }, [
    apiData,
    enabled,
    isError,
    isLoading,
    selectableKeywords,
    selectableRegions,
  ]);

  return {
    shareOfSearchRegionsState,
    regionsBenchmarkDates,
    setRegionsBenchmarkDates,
  };
};
