import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  floatCellFunc,
  identityCellFunc,
  intEuroCellFunc,
} from 'src/components/molecules/DataTable/cellFuncs';
import { useTableEffectCellFunc } from 'src/hooks/useEffectFormatter';

export type OptimziationTableData = {
  channelName: string;
  initialRoi: number;
  optimizedRoi: number;
  initialEffect: number;
  optimizedEffect: number;
  initialInvestment: number;
  optimizedInvestment: number;
  initialRomi: number | null;
  optimizedRomi: number | null;
};

const columnHelper = createColumnHelper<OptimziationTableData>();

export const useColumns = (hasRomi: boolean) => {
  const effectFormatter = useTableEffectCellFunc();

  return useMemo(() => {
    const romiHeaders = hasRomi
      ? [
          columnHelper.accessor('optimizedRomi', {
            header: 'ROMI (optimized)',
            cell: floatCellFunc,
            sortingFn: 'basic',
          }),
          columnHelper.accessor('initialRomi', {
            header: 'ROMI (initial)',
            cell: floatCellFunc,
            sortingFn: 'basic',
          }),
        ]
      : [];

    const columns = [
      columnHelper.accessor('channelName', {
        header: 'Channel',
        cell: identityCellFunc,
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('optimizedInvestment', {
        header: 'Investment (optimized)',
        cell: intEuroCellFunc,
        sortingFn: 'basic',
      }),
      columnHelper.accessor('initialInvestment', {
        header: 'Investment (initial)',
        cell: intEuroCellFunc,
        sortingFn: 'basic',
      }),
      columnHelper.accessor('optimizedEffect', {
        header: 'Media effect (optimized)',
        cell: effectFormatter,
        sortingFn: 'basic',
      }),
      columnHelper.accessor('initialEffect', {
        header: 'Media effect (initial)',
        cell: effectFormatter,
        sortingFn: 'basic',
      }),
      ...romiHeaders,
      columnHelper.accessor('optimizedRoi', {
        header: 'ROI (optimized)',
        cell: floatCellFunc,
        sortingFn: 'basic',
      }),
      columnHelper.accessor('initialRoi', {
        header: 'ROI (initial)',
        cell: floatCellFunc,
        sortingFn: 'basic',
      }),
    ];

    return columns;
  }, [hasRomi, effectFormatter]);
};
