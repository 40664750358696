import React from 'react';
import { Flex, Heading, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export default function Page404() {
  const bg = useColorModeValue(
    'background.light.lowest',
    'background.dark.blowest',
  );

  return (
    <Flex w="100%" h="100vh" direction="row" background={bg}>
      <Flex
        flex="1"
        h="100%"
        w="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px="40px"
        py="24px">
        <Flex direction="column" justifyContent="flexStart">
          <Heading as="h1" size="4xl">
            404
          </Heading>
          <Heading as="p" size="lg" mb={4}>
            No page exists.
          </Heading>
          <Text as="p" size="xl">
            Use the menu to select a different page{' '}
            <Link as={RouterLink} to="/">
              or return to the dashboard.
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
