type CalculateMediaEffectParams = {
  predictedSales: number;
  mediaIncrementalSales: number;
};

export const calculateMediaEffect = ({
  predictedSales,
  mediaIncrementalSales,
}: CalculateMediaEffectParams) => {
  if (predictedSales < 1) {
    return 0;
  }
  return (100 * mediaIncrementalSales) / predictedSales;
};
