import { Box, Flex } from '@chakra-ui/react';
import { DataBoxesContainer } from 'src/components/atoms/DataBoxesContainer';
import { Optimization } from 'src/features/MO/types';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import { DeleteOptimizationButton } from '../../molecules/DeleteOptimizationButton';
import { OptimizationMetricChart } from '../../molecules/OptimizationMetricChart';
import { InvestmentByMediaBarGraph } from '../InvestmentByMediaBarGraph';
import { MediaEffectByMediaBarGraph } from '../MediaEffectByMediaBarGraph';
import { OptimizationDataByMediaTable } from '../OptimizationDataByMediaTable';
import { ROIByMediaBarGraph } from '../ROIByMediaBarGraph';
import { ROMIByMediaBarGraph } from '../ROMIByMediaBarGraph';

type OptimizationPanelProps = {
  optimization: Optimization;
  isOpen: boolean;
};

export const OptimizationPanel: React.FC<OptimizationPanelProps> = ({
  optimization,
  isOpen,
}) => {
  const { isEuro } = useKpiUnit();

  return (
    <Box>
      <Flex>
        <OptimizationMetricChart
          isEuro
          name="Investment"
          optimizedValue={optimization.optimizedInvestmentSum}
          initialValue={optimization.initialInvestmentSum}
        />
        <OptimizationMetricChart
          name="Media effect"
          optimizedValue={optimization.optimizedEffectSum}
          initialValue={optimization.initialEffectSum}
          isEuro={isEuro}
        />
        <OptimizationMetricChart
          name="ROI"
          optimizedValue={optimization.totalRoi.optimized}
          initialValue={optimization.totalRoi.initial}
        />
        {optimization.totalRomi && (
          <OptimizationMetricChart
            name="ROMI"
            optimizedValue={optimization.totalRomi.optimized}
            initialValue={optimization.totalRomi.initial}
          />
        )}
        <Flex flex={1} justifyContent="flex-end">
          <DeleteOptimizationButton
            optimizationId={optimization.optimizationId}
          />
        </Flex>
      </Flex>
      {isOpen && (
        <Box mt="20px">
          <DataBoxesContainer>
            <InvestmentByMediaBarGraph
              colSpan={12}
              title="Investment"
              minH={'250px'}
              optimization={optimization}
            />
            <MediaEffectByMediaBarGraph
              colSpan={12}
              title="Media effect"
              minH={'250px'}
              optimization={optimization}
            />
            {optimization.totalRomi && (
              <ROMIByMediaBarGraph
                colSpan={12}
                title="Return on marketing investment (ROMI)"
                minH={'250px'}
                optimization={optimization}
              />
            )}
            <ROIByMediaBarGraph
              colSpan={12}
              title="Return on investment (ROI)"
              minH={'250px'}
              optimization={optimization}
            />
            <OptimizationDataByMediaTable
              colSpan={12}
              title={`Optimization ${optimization.optimizationName}`}
              optimization={optimization}
            />
          </DataBoxesContainer>
        </Box>
      )}
    </Box>
  );
};
