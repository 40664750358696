import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { useBenchmarkEnabled } from 'src/features/DA/hooks/useBenchmarkEnabled';
import { useMergeBenchmarkTimeseries } from 'src/hooks/useMergeBenchmarkTimeseries';
import { useDADataContext } from '../../../contexts/DADataContext';

export const Spend: React.FC = () => {
  const { investments, benchmarkInvestments } = useDADataContext();
  const { investmentsBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMergedTimeSeries } = useMergeBenchmarkTimeseries();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    let sum = 0;
    investments.daily.forEach((x) => {
      sum += x.totalInvestment;
    });

    const data = getMergedTimeSeries(
      investments.daily,
      benchmarkInvestments.daily,
      (v) => ({ value: v.totalInvestment }),
      (v) => ({ benchmark: v.totalInvestment }),
      investmentsBenchmarkEnabled,
      'day',
    );

    let benchmarkSum = 0;
    if (investmentsBenchmarkEnabled) {
      benchmarkInvestments.daily.forEach((x) => {
        benchmarkSum += x.totalInvestment;
      });
    }

    return {
      name: 'Spend',
      data,
      isEuro: true,
      summaryValue: sum,
      benchmarkSummaryValue: investmentsBenchmarkEnabled
        ? benchmarkSum
        : undefined,
      isError: investments.isError,
      isLoading: investments.isLoading,
    };
  }, [
    investments,
    getMergedTimeSeries,
    benchmarkInvestments,
    investmentsBenchmarkEnabled,
  ]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
