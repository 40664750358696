import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type RoiType = 'long' | 'short' | 'total';

export type RoiTabsProps = {
  selectedTab: RoiType;
  onSelect: (value: RoiType) => void;
};

const tabs: Array<TabOption<RoiType>> = [
  {
    label: 'Long term ROI',
    value: 'long',
  },
  {
    label: 'Short term ROI',
    value: 'short',
  },
  {
    label: 'Total ROI',
    value: 'total',
  },
];

export const RoiTabs: React.FC<RoiTabsProps> = ({ selectedTab, onSelect }) => {
  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
