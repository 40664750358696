import { useEffect, useState } from 'react';
import { ApiLongTermEffectsDataPoint } from '../../../api/queries';
import { sortEpoch } from '../../../utils/date';
import { EffectsState } from '../types';
import { getEffectsTimeFrameData } from '../utils/effects';
import { getYearlyAndMonthlyLongTermEffectsData } from '../utils/longTermEffects';

const INIT_EFFECTS_STATE: Readonly<EffectsState> = {
  monthly: [],
  yearly: [],
};
export const useEffects = (
  data: Array<ApiLongTermEffectsDataPoint>,
  enabled: boolean,
) => {
  const [effects, setEffects] = useState<EffectsState>(INIT_EFFECTS_STATE);

  useEffect(() => {
    if (!enabled) {
      setEffects(INIT_EFFECTS_STATE);
      return;
    }

    const timeBased = getYearlyAndMonthlyLongTermEffectsData(data);
    const monthly = getEffectsTimeFrameData(timeBased.monthly).sort(sortEpoch);
    const yearly = getEffectsTimeFrameData(timeBased.yearly).sort(sortEpoch);

    setEffects({ monthly, yearly });
  }, [data, enabled]);

  return effects;
};
