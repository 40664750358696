import {
  DataBox,
  DataBoxLegendProps,
  DataBoxBaseProps,
} from 'src/components/molecules/DataBox';
import { BarGraph } from 'src/components/molecules/graphs/BarGraph';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { Optimization } from '../../types';

type MediaEffectByMediaBarGraphProps = {
  optimization: Optimization;
} & DataBoxBaseProps;

const INITIAL_KEY = 'Initial effect';
const OPTIMIZED_KEY = 'Optimized effect';

export const MediaEffectByMediaBarGraph: React.FC<
  MediaEffectByMediaBarGraphProps
> = ({ optimization, ...dataBoxProps }) => {
  const effectFormatter = useChartEffectTickFormatter();

  const data = optimization.data.map((opt) => ({
    [INITIAL_KEY]: opt.initialEffect,
    [OPTIMIZED_KEY]: opt.optimizedEffect,
    channel: opt.channelName,
  }));

  const dataKeys = {
    xAxis: 'channel',
    data: [INITIAL_KEY, OPTIMIZED_KEY],
  };

  const legendProps: DataBoxLegendProps = {
    baseLegendKeys: dataKeys.data,
    placement: 'top',
  };

  return (
    <DataBox
      {...dataBoxProps}
      legendProps={legendProps}
      isError={false}
      isLoading={false}
      hasData={data.length > 0}>
      <BarGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        stacked={false}
        yAxisFormatter={effectFormatter}
      />
    </DataBox>
  );
};
