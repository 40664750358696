import { useCallback, useMemo, useState } from 'react';
import Map, {
  FillLayer,
  Layer,
  LineLayer,
  MapLayerMouseEvent,
  Source,
  SymbolLayer,
} from 'react-map-gl';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { MAPBOX_ACCESS_TOKEN } from 'src/constants';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { mapPast12MonthRegionDataToSosByRegion } from 'src/features/SOS/utils/mapPast12MonthRegionDataToSos';
import { benchmarkColor } from 'src/theme';
import { HoverInfo, RegionMapTooltip } from './RegionMapTooltip';
import { MapConfig } from './supportedCountries';
import { GeoJsonType } from './types';

const regionFillLayer: Omit<FillLayer, 'source'> = {
  id: 'region',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'highlightedShareOfSearch',
      stops: [
        [0, '#FFF'],
        [100, benchmarkColor],
      ],
      default: '#FFF',
    },
    'fill-opacity': 0.8,
  },
};

const regineOutlineLayer: Omit<LineLayer, 'source'> = {
  id: 'data',
  type: 'line',
  paint: {
    'line-color': '#000000',
  },
};

const textLayer: SymbolLayer = {
  id: 'clusters-label',
  type: 'symbol',
  source: 'clusters',
  layout: {
    'text-field': '{highlightedShareOfSearchString}',
    'text-size': 8,
  },
};

type SosByRegionMapProps = {
  mapConfig: MapConfig;
} & DataBoxBaseProps;

export const SosByRegionMap: React.FC<SosByRegionMapProps> = ({
  mapConfig,
  ...dataBoxProps
}) => {
  const { shareOfSearchRegionsState, highlightedKeywordsForMap } =
    useSOSDataContext();

  const isLoading = shareOfSearchRegionsState.isLoading;
  const isError = shareOfSearchRegionsState.isError;

  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);

  const setOfHighlightedKeywords = useMemo(() => {
    return new Set(
      highlightedKeywordsForMap.filter((k) => k.selected).map((k) => k.keyword),
    );
  }, [highlightedKeywordsForMap]);

  const mappedByRegion = useMemo(() => {
    return mapPast12MonthRegionDataToSosByRegion(
      shareOfSearchRegionsState.regionMapData,
      setOfHighlightedKeywords,
    );
  }, [shareOfSearchRegionsState.regionMapData, setOfHighlightedKeywords]);

  const onHover = useCallback(
    (event: MapLayerMouseEvent) => {
      const {
        features,
        point: { x, y },
      } = event;
      const hoveredFeature = features && features[0];
      const regionName = hoveredFeature?.properties?.name ?? '';

      const regionData = mappedByRegion[regionName];

      if (regionData) {
        setHoverInfo({
          regionData,
          x,
          y,
        });
      } else {
        setHoverInfo(null);
      }
    },
    [mappedByRegion],
  );

  const regionGeoJson = useMemo(() => {
    const features = mapConfig.geoJson.features.map((feature) => {
      const regionName = feature.properties.name;

      const data = mappedByRegion[regionName];

      let shareOfSearchProperties:
        | undefined
        | {
            highlightedShareOfSearch: number;
            highlightedShareOfSearchString: string;
          };

      if (data) {
        shareOfSearchProperties = {
          highlightedShareOfSearch: data.highlightedShareOfSearch,
          highlightedShareOfSearchString: data.highlightedShareOfSearchString,
        };
      }

      return {
        ...feature,
        properties: {
          ...feature.properties,
          ...shareOfSearchProperties,
        },
      };
    });

    return {
      type: 'FeatureCollection',
      features,
    } as GeoJsonType;
  }, [mappedByRegion, mapConfig.geoJson.features]);

  const tooltip = hoverInfo ? (
    <RegionMapTooltip
      setOfHighlightedKeywords={setOfHighlightedKeywords}
      hoverInfo={hoverInfo}
    />
  ) : null;

  return (
    <DataBox
      {...dataBoxProps}
      hasData={shareOfSearchRegionsState.data.length > 0}
      isError={isError}
      isLoading={isLoading}
      tooltip={tooltip}>
      <Map
        {...mapConfig.mapState}
        reuseMaps
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: '100%',
        }}
        interactive={false}
        mapStyle="mapbox://styles/mapbox/light-v9"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        interactiveLayerIds={['region']}
        onMouseOut={() => setHoverInfo(null)}
        onMouseMove={onHover}>
        <Source type="geojson" data={regionGeoJson}>
          <Layer {...regionFillLayer} />
          <Layer {...regineOutlineLayer} />
          <Layer {...textLayer} />
        </Source>
      </Map>
    </DataBox>
  );
};
