import { ShareOfSearchByRegionPast12MonthsDataPoint } from '../types';

export type SoSPast12MonthsByRegion = {
  regionName: string;
  highlightedShareOfSearch: number;
  highlightedShareOfSearchString: string;
  allShareOfSearch: Array<{ shareOfSearch: number; keyword: string }>;
};

export const mapPast12MonthRegionDataToSosByRegion = (
  data: Array<ShareOfSearchByRegionPast12MonthsDataPoint>,
  setOfHighlightedKeywords: Set<string>,
): Record<string, SoSPast12MonthsByRegion> => {
  const results: Array<SoSPast12MonthsByRegion> = data.map(
    ({ keywords, location, regionTotal }) => {
      let highlightedShareOfSearch = 0;

      const allShareOfSearch = keywords
        .map(({ keyword, searchVolume }) => {
          const isHighlightedKeyword = setOfHighlightedKeywords.has(keyword);

          const shareOfSearch =
            regionTotal > 0 ? (100 * searchVolume) / regionTotal : 0;

          if (isHighlightedKeyword) {
            highlightedShareOfSearch += shareOfSearch;
          }

          return { shareOfSearch, keyword };
        })
        .sort((a, b) => b.shareOfSearch - a.shareOfSearch);

      return {
        regionName: location.locationName,
        highlightedShareOfSearch,
        highlightedShareOfSearchString: `${highlightedShareOfSearch.toFixed(0)}%`,
        allShareOfSearch,
      };
    },
  );

  const record: Record<string, SoSPast12MonthsByRegion> = {};

  results.forEach((res) => {
    record[res.regionName] = res;
  });

  return record;
};
