import { useMemo } from 'react';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { BarLineGraph } from 'src/components/molecules/graphs/BarLineGraph';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { MonthNameYearDateFormatter } from 'src/utils/TickFormatters';
import { INVESTMENT_KEY, parseKeyForInvestmentMedia } from './getInvestmentKey';

export type EffectAndInvestmentData = Record<'key' | string, number>;

type EffectAndInvestmewntMonthlyGraphProps = {
  data: Array<EffectAndInvestmentData>;
};

// Needs own component as investment value depends on what
export const EffectAndInvestmewntMonthlyGraph: React.FC<
  EffectAndInvestmewntMonthlyGraphProps
> = ({ data }) => {
  const yAxisFormatter = useChartEffectTickFormatter();

  // Selected media
  const { keyToSelected } = useLegendContext();

  // Selected legends effect what is calculated into the investment.
  const filteredData = useMemo(() => {
    return data.map((d) => {
      let spendTotal = 0;

      const newDataPoint: EffectAndInvestmentData = {
        ...d,
      };

      Object.entries(d).forEach(([key, value]) => {
        const parsedMedia = parseKeyForInvestmentMedia(key);

        if (!parsedMedia) {
          return;
        }

        // Delete original investment combination key
        delete newDataPoint[key];

        // media is selected in legend, add to total investment
        if (keyToSelected[parsedMedia]) {
          spendTotal += value;
        }
      });

      newDataPoint[INVESTMENT_KEY] = spendTotal;

      return newDataPoint;
    });
  }, [data, keyToSelected]);

  return (
    <BarLineGraph
      data={filteredData}
      stacked
      xAxisKey={'key'}
      xAxisFormatter={MonthNameYearDateFormatter}
      yAxisFormatter={yAxisFormatter}
    />
  );
};
