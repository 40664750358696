import { useMMDataContext } from 'src/features/MM/contexts/MMDataContext';
import { IncrementalSalesBox } from '../../organisms/IncrementalSalesBox';
import { MediaInvestmentBox } from '../../organisms/MediaInvestmentBox';
import { ROIBox } from '../../organisms/ROIBox';
import { ROMIBox } from '../../organisms/ROMIBox';
import { SalesAndPredictedSalesBox } from '../../organisms/SalesAndPredictedSalesBox';

const colSpan = 12;
const minH = 300;

export const Overview: React.FC = () => {
  const { romiEnabled } = useMMDataContext();
  return (
    <>
      <MediaInvestmentBox
        title="Media investment"
        colSpan={colSpan}
        minH={minH}
      />
      <IncrementalSalesBox
        title="Incremental sales"
        colSpan={colSpan}
        minH={minH}
      />
      {romiEnabled ? (
        <ROMIBox title="ROMI" colSpan={colSpan} minH={minH} />
      ) : (
        <ROIBox title="ROI" colSpan={colSpan} minH={minH} />
      )}

      <SalesAndPredictedSalesBox title="Sales" colSpan={colSpan} minH={minH} />
    </>
  );
};
