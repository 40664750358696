import { useCallback, useEffect, useState } from 'react';
import { useCampaignTagsQuery } from 'src/api/queries';
import { CampaignTag, CampaignTagGroup } from 'src/types';
import { AppServices } from 'src/app/routes/utils';
import { sortByName } from 'src/utils/sort';
import { ModelVersion } from './useModelVersions';

export type CampaignTagsState = {
  groups: CampaignTagGroup[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_CAMPAIGN_TAGS_STATE: Readonly<CampaignTagsState> = {
  groups: [],
  isError: false,
  isLoading: false,
};

export const useCampaignTags = (
  currentModelVersion: ModelVersion | null,
  currentAppService: AppServices | null,
) => {
  const [campaignTagGroupsState, setCampaignTagGroupsState] =
    useState<CampaignTagsState>(INIT_CAMPAIGN_TAGS_STATE);

  const enabled = !!currentModelVersion && currentAppService !== AppServices.LT;

  const {
    data: campaignTagsData,
    isLoading: campaignTagsLoading,
    isError: campaignTagsError,
  } = useCampaignTagsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_build',
    },
    enabled,
  );

  const setCampaignTagGroups = useCallback(
    (campaignTagGroups: Array<CampaignTagGroup>) => {
      setCampaignTagGroupsState((curr) => {
        return {
          ...curr,
          groups: campaignTagGroups,
        };
      });
    },
    [],
  );

  // Update the list of campaign tags when new data is fetched.
  useEffect(() => {
    if (!enabled) {
      setCampaignTagGroupsState(INIT_CAMPAIGN_TAGS_STATE);
      return;
    }

    const fetchedData = campaignTagsData ?? [];

    const formattedCampaignTagGroups: CampaignTagGroup[] = fetchedData.map(
      (g) => {
        const tagGroupName = g.groupName;

        const formattedTags: CampaignTag[] = g.tags.map((t) => {
          return {
            name: t,
            selected: true,
            visible: true,
          };
        });

        return {
          name: tagGroupName,
          tagGroup: g.tagGroup,
          tags: formattedTags,
          selected: true,
        };
      },
    );

    setCampaignTagGroupsState({
      groups: sortByName(formattedCampaignTagGroups),
      isLoading: campaignTagsLoading,
      isError: campaignTagsError,
    });
  }, [campaignTagsData, campaignTagsLoading, campaignTagsError, enabled]);

  return {
    campaignTagGroups: campaignTagGroupsState.groups,
    setCampaignTagGroups,
  };
};
