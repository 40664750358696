import { Flex } from '@chakra-ui/react';
import { useLegendContext } from './LegendContext';
import { LegendItem } from './LegendItem';

type RightLegendProps = {
  minW?: string;
};

export const RightLegend: React.FC<RightLegendProps> = ({ minW = '120px' }) => {
  const { legendValues, onLegendClick } = useLegendContext();

  return (
    <Flex
      minW={minW}
      direction={'column'}
      mb={0}
      pl={'20px'}
      pr={'10px'}
      overflowY={'scroll'}
      overflowX={'hidden'}>
      {legendValues.map(({ key, selected, color, label, legendType }) => {
        return (
          <LegendItem
            key={key}
            isSelected={selected}
            color={color}
            label={label}
            onClick={() => onLegendClick(key)}
            isLine={legendType === 'line'}
            mb={'4px'}
          />
        );
      })}
    </Flex>
  );
};
