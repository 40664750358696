import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { useBenchmarkEnabled } from 'src/features/DA/hooks/useBenchmarkEnabled';
import { MODELLED_CONV_NAME } from 'src/features/DA/utils/constants';
import { useMergeBenchmarkTimeseries } from 'src/hooks/useMergeBenchmarkTimeseries';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import { useDADataContext } from '../../../contexts/DADataContext';

export const ModelledMediaConversions: React.FC = () => {
  const { conversions, benchmarkConversions } = useDADataContext();
  const { conversionsBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMergedTimeSeries } = useMergeBenchmarkTimeseries();

  const { isEuro } = useKpiUnit();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    let sum = 0;

    conversions.data.forEach((x) => {
      sum += x.measurements.modelledConversions;
    });

    const data = getMergedTimeSeries(
      conversions.data,
      benchmarkConversions.data,
      (v) => ({ value: v.measurements.modelledConversions }),
      (v) => ({ benchmark: v.measurements.modelledConversions }),
      conversionsBenchmarkEnabled,
      'day',
    );

    let benchmarkSum = 0;

    if (conversionsBenchmarkEnabled) {
      benchmarkConversions.data.forEach((x) => {
        benchmarkSum += x.measurements.modelledConversions;
      });
    }

    return {
      name: MODELLED_CONV_NAME,
      data,
      isEuro,
      summaryValue: sum,
      benchmarkSummaryValue: conversionsBenchmarkEnabled
        ? benchmarkSum
        : undefined,
      isError: conversions.isError,
      isLoading: conversions.isLoading,
    };
  }, [
    conversions,
    isEuro,
    getMergedTimeSeries,
    conversionsBenchmarkEnabled,
    benchmarkConversions,
  ]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
