import { IconButtonProps } from '@chakra-ui/react';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';

type CloseButtonProps = {
  onClick: () => void;
} & Omit<IconButtonProps, 'aria-label' | 'icon' | 'size' | 'onClick'>;

export const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
  ...iconButtonProps
}) => {
  return (
    <IconButton
      size={'xs'}
      onClick={onClick}
      aria-label="close"
      icon={<Icon iconName="close" />}
      {...iconButtonProps}
    />
  );
};
