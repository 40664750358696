import React from 'react';
import { Optimizations } from 'src/features/MO/components/templates/Optimizations';
import { Page } from '../../components/molecules/Page';
import { MOFilterBar } from '../../features/MO/components/organisms/MOFilterBar';

export const OptimizationPage: React.FC = () => {
  return (
    <Page filterBar={<MOFilterBar />}>
      <Optimizations />
    </Page>
  );
};
