import React, { useMemo, useState } from 'react';
import { ExportCsvContext, ExportCsvContextValue, ExportNameAndData } from '.';

type ExportCsvProviderProps = {
  children: React.ReactNode;
};

export const ExportCsvProvider: React.FC<ExportCsvProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<ExportNameAndData>({
    fileName: '',
    csvData: [],
  });

  const value: ExportCsvContextValue = useMemo(() => {
    return {
      ...data,
      setExportData: setData,
    };
  }, [data]);

  return (
    <ExportCsvContext.Provider value={value}>
      {children}
    </ExportCsvContext.Provider>
  );
};
