import React, { useMemo, useState } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { LineGraph } from 'src/components/molecules/graphs/LineGraph';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useLTDataContext } from 'src/features/LT/contexts/LTDataContext';

import { useRenameByChannel } from 'src/hooks/useRenameByChannel';
import {
  MonthYearDateFormatter,
  ValueTickFormatter,
  YearDateFormatter,
} from 'src/utils/TickFormatters';
import { RoiTabs, RoiType } from '../molecules/RoiTabs';

type ProcessedDataPoint = Record<'key' | string, number>;

type RoiByMediaBoxProps = {
  timeframe: 'monthly' | 'yearly';
} & DataBoxBaseProps;

export const RoiByMediaBox: React.FC<RoiByMediaBoxProps> = ({
  timeframe,
  ...props
}) => {
  const { roi, isError, isLoading } = useLTDataContext();

  const [roiType, setRoiType] = useState<RoiType>('long');

  const { mediaColorMap } = useFilterContext();

  const { renameByChannel } = useRenameByChannel();

  const processedData: {
    long: Array<ProcessedDataPoint>;
    short: Array<ProcessedDataPoint>;
    total: Array<ProcessedDataPoint>;
    medias: Array<string>;
  } = useMemo(() => {
    const medias: Record<string, true> = {};

    const roiData = timeframe === 'monthly' ? roi.monthly : roi.yearly;

    roiData.forEach(({ roiByTotalMedia }) => {
      Object.keys(renameByChannel(roiByTotalMedia)).forEach((media) => {
        medias[media] = true;
      });
    });

    return {
      medias: Object.keys(medias),
      long: roiData.map(({ utcEpoch, roiByLongMedia }) => ({
        key: utcEpoch,
        ...renameByChannel(roiByLongMedia),
      })),
      short: roiData.map(({ utcEpoch, roiByShortMedia }) => ({
        key: utcEpoch,
        ...renameByChannel(roiByShortMedia),
      })),

      total: roiData.map(({ utcEpoch, roiByTotalMedia }) => ({
        key: utcEpoch,
        ...renameByChannel(roiByTotalMedia),
      })),
    };
  }, [roi, timeframe, renameByChannel]);

  const dataKeys = {
    xAxis: 'key',
    data: processedData.medias,
  };

  let data: ProcessedDataPoint[];
  const dateAxisFormatter =
    timeframe === 'monthly' ? MonthYearDateFormatter : YearDateFormatter;
  switch (roiType) {
    case 'long':
      data = processedData.long;
      break;
    case 'short':
      data = processedData.short;
      break;
    case 'total':
      data = processedData.total;
      break;
  }

  const legendProps: DataBoxLegendProps = {
    placement: 'right',
    lineKeys: dataKeys.data,
    keyColorMap: mediaColorMap,
  };

  return (
    <DataBox
      {...props}
      tabs={<RoiTabs onSelect={setRoiType} selectedTab={roiType} />}
      legendProps={legendProps}
      restrictChartHeight
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <LineGraph
        data={data}
        xAxisKey={dataKeys.xAxis}
        xAxisFormatter={dateAxisFormatter}
        yAxisFormatter={ValueTickFormatter}
      />
    </DataBox>
  );
};
