import { SimplifiedDataState, SimplifiedTimeframeData } from 'src/types';
import { calculateRomi } from 'src/utils/calculateRomi';
import { sortEpoch } from 'src/utils/date';
import { INIT_MM_DATA_STATE, MMDataState, TimeframeData } from '../types';

type CalculateRomiFromDataParams = {
  margin: number;
  sales: number;
  investment: number;
  decomps: number;
  productionCost: number;
};

export const calculateRomiFromData = ({
  margin,
  sales,
  investment,
  decomps,
  productionCost,
}: CalculateRomiFromDataParams) => {
  if (sales < 1) {
    return 0;
  }
  const totalSpend = investment + productionCost;
  const marginPct = margin / sales;

  return calculateRomi({
    marginPct,
    mediaIncrementalSales: decomps,
    totalSpend,
  });
};

type CalculateRomiFromRecordsParams = {
  byXInvestments: Record<string, number>;
  byXDecomps: Record<string, number>;
  byXProductionCost: Record<string, number>;
  margin: number;
  sales: number;
};

const calculateRomiFromRecords = ({
  byXDecomps,
  byXInvestments,
  byXProductionCost,
  margin,
  sales,
}: CalculateRomiFromRecordsParams): Record<string, number> => {
  const aggregateRecord: Record<
    string,
    {
      decomps: number;
      investment: number;
      productionCost: number;
    }
  > = {};

  Object.entries(byXInvestments).forEach(([key, investmentValue]) => {
    if (!aggregateRecord[key]) {
      aggregateRecord[key] = {
        decomps: 0,
        investment: 0,
        productionCost: 0,
      };
    }

    aggregateRecord[key].investment = investmentValue;
  });

  Object.entries(byXDecomps).forEach(([key, decompsValue]) => {
    if (!aggregateRecord[key]) {
      aggregateRecord[key] = {
        decomps: 0,
        investment: 0,
        productionCost: 0,
      };
    }

    aggregateRecord[key].decomps = decompsValue;
  });

  Object.entries(byXProductionCost).forEach(([key, productionCost]) => {
    if (!aggregateRecord[key]) {
      aggregateRecord[key] = {
        decomps: 0,
        investment: 0,
        productionCost: 0,
      };
    }

    aggregateRecord[key].productionCost = productionCost;
  });

  const resultRecord: Record<string, number> = {};

  Object.entries(aggregateRecord).forEach(
    ([key, { decomps, investment, productionCost }]) => {
      resultRecord[key] = calculateRomiFromData({
        margin,
        sales,
        investment,
        decomps,
        productionCost,
      });
    },
  );

  return resultRecord;
};

const combineTimeframeData = (
  investmentData: Array<TimeframeData>,
  decompsData: Array<TimeframeData>,
  marginsData: Array<SimplifiedTimeframeData>,
  salesData: Array<SimplifiedTimeframeData>,
  productionCostData: Array<TimeframeData>,
): Array<TimeframeData> => {
  const byUtcEpoch: Record<
    number,
    {
      investment: TimeframeData | null;
      decomps: TimeframeData | null;
      margin: number | null;
      sales: number | null;
      productionCost: TimeframeData | null;
    }
  > = {};

  const initByUtcEpochRecordIfNecessary = (utcEpoch: number) => {
    if (!byUtcEpoch[utcEpoch]) {
      byUtcEpoch[utcEpoch] = {
        investment: null,
        decomps: null,
        margin: null,
        sales: null,
        productionCost: null,
      };
    }
  };

  investmentData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].investment = datapoint;
  });

  decompsData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].decomps = datapoint;
  });

  marginsData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].margin = datapoint.value;
  });

  salesData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].sales = datapoint.value;
  });

  productionCostData.forEach((datapoint) => {
    const utcEpoch = datapoint.utcEpoch;
    initByUtcEpochRecordIfNecessary(utcEpoch);
    byUtcEpoch[utcEpoch].productionCost = datapoint;
  });

  const timeframeData: Array<TimeframeData> = [];

  Object.entries(byUtcEpoch).forEach(
    ([utcEpoch, { investment, decomps, margin, productionCost, sales }]) => {
      // Allow null production cost
      if (
        investment === null ||
        decomps === null ||
        margin === null ||
        sales === null
      ) {
        return;
      }

      const value = calculateRomiFromData({
        investment: investment.value,
        decomps: decomps.value,
        margin,
        sales,
        productionCost: productionCost?.value ?? 0,
      });

      const d: TimeframeData = {
        byCampaignId: calculateRomiFromRecords({
          byXInvestments: investment.byCampaignId,
          byXDecomps: decomps.byCampaignId,
          byXProductionCost: productionCost?.byCampaignId ?? {},
          margin,
          sales,
        }),
        byMedia: calculateRomiFromRecords({
          byXInvestments: investment.byMedia,
          byXDecomps: decomps.byMedia,
          byXProductionCost: productionCost?.byMedia ?? {}, // Should always be an empty object regardless as productionCost is not handled per media
          margin,
          sales,
        }),
        byTag: calculateRomiFromRecords({
          byXInvestments: investment.byTag,
          byXDecomps: decomps.byTag,
          byXProductionCost: productionCost?.byTag ?? {},
          margin,
          sales,
        }),
        byCategory: calculateRomiFromRecords({
          byXInvestments: investment.byCategory,
          byXDecomps: decomps.byCategory,
          byXProductionCost: productionCost?.byCategory ?? {},
          margin,
          sales,
        }),
        utcEpoch: Number(utcEpoch),
        value,
      };

      timeframeData.push(d);
    },
  );

  return timeframeData.sort(sortEpoch);
};

export const aggregateRomi = (
  margins: SimplifiedDataState,
  investments: MMDataState,
  decomps: MMDataState,
  sales: SimplifiedDataState,
  productionCosts: MMDataState,
): MMDataState => {
  const isLoading =
    margins.isLoading ||
    investments.isLoading ||
    decomps.isLoading ||
    sales.isLoading ||
    productionCosts.isLoading;

  const isError =
    margins.isError ||
    investments.isError ||
    decomps.isError ||
    sales.isError ||
    productionCosts.isError;

  // No margin data, meaning no romi data. Also if isLoading or isError we cannot calculate
  // romi so no need to do aggregations
  if (margins.day.length === 0 || isLoading || isError) {
    return { ...INIT_MM_DATA_STATE, isError, isLoading };
  }

  const totalMargin = margins.total;
  const totalSales = sales.total;

  const total = calculateRomiFromData({
    margin: totalMargin,
    sales: totalSales,
    investment: investments.total,
    decomps: decomps.total,
    productionCost: productionCosts.total,
  });

  const data: MMDataState = {
    isLoading,
    isError,
    week: combineTimeframeData(
      investments.week,
      decomps.week,
      margins.week,
      sales.week,
      productionCosts.week,
    ),
    month: combineTimeframeData(
      investments.month,
      decomps.month,
      margins.month,
      sales.month,
      productionCosts.month,
    ),
    quarter: combineTimeframeData(
      investments.quarter,
      decomps.quarter,
      margins.quarter,
      sales.quarter,
      productionCosts.quarter,
    ),
    biannual: combineTimeframeData(
      investments.biannual,
      decomps.biannual,
      margins.biannual,
      sales.biannual,
      productionCosts.biannual,
    ),
    year: combineTimeframeData(
      investments.year,
      decomps.year,
      margins.year,
      sales.year,
      productionCosts.year,
    ),
    total,
    totalByCampaignId: calculateRomiFromRecords({
      byXDecomps: decomps.totalByCampaignId,
      byXInvestments: investments.totalByCampaignId,
      byXProductionCost: productionCosts.totalByCampaignId,
      margin: totalMargin,
      sales: totalSales,
    }),
    totalByCategory: calculateRomiFromRecords({
      byXDecomps: decomps.totalByCategory,
      byXInvestments: investments.totalByCategory,
      byXProductionCost: productionCosts.totalByCategory,
      margin: totalMargin,
      sales: totalSales,
    }),
    totalByMedia: calculateRomiFromRecords({
      byXDecomps: decomps.totalByMedia,
      byXInvestments: investments.totalByMedia,
      byXProductionCost: productionCosts.totalByMedia, // This is an empty object as production costs have data per campaign
      margin: totalMargin,
      sales: totalSales,
    }),
    totalByTag: calculateRomiFromRecords({
      byXDecomps: decomps.totalByTag,
      byXInvestments: investments.totalByTag,
      byXProductionCost: productionCosts.totalByTag,
      margin: totalMargin,
      sales: totalSales,
    }),
  };

  return data;
};
