import { CampaignToTags } from 'src/types';
import {
  getBiannualStartEpoch,
  getMonthStartEpoch,
  getQuarterStartEpoch,
  getWeekStartEpoch,
  getYearStartEpoch,
  sortEpoch,
} from 'src/utils/date';
import { MMDataState, TimeframeData } from '../types';

const addToRecordTotal = (
  record: Record<string, number>,
  key: string,
  value: number,
) => {
  if (!record[key]) {
    record[key] = 0;
  }

  record[key] += value;
};

export type ApiValueDatapoint<ValueKey extends string> = {
  channel?: string;
  campaignId: string;
} & Record<ValueKey, number>;

export type EpochApiData<
  DatapointsKey extends string,
  ValueKey extends string,
> = {
  utcEpoch: number;
} & Record<DatapointsKey, Array<ApiValueDatapoint<ValueKey>>>;

type ByAggregate<
  DatapointsKey extends string,
  ValueKey extends string,
> = Record<number, Array<EpochApiData<DatapointsKey, ValueKey>>>;

const mapByAggregateToTimeframeData = <
  DatapointsKey extends string,
  ValueKey extends string,
>(
  datapointsKey: DatapointsKey,
  valueKey: ValueKey,
  byAggregate: ByAggregate<DatapointsKey, ValueKey>,
  campaignToTags: CampaignToTags,
): Array<TimeframeData> => {
  const timeframeData: Array<TimeframeData> = [];

  Object.entries(byAggregate).forEach(([utcEpoch, dataForUtcEpoch]) => {
    const d: TimeframeData = {
      byCampaignId: {},
      byMedia: {},
      byTag: {},
      byCategory: {},
      value: 0,
      utcEpoch: Number(utcEpoch),
    };

    dataForUtcEpoch.forEach((data) => {
      data[datapointsKey].forEach((datapoint) => {
        const { campaignId, channel } = datapoint;
        const value = datapoint[valueKey];

        d.value += value;
        addToRecordTotal(d.byCampaignId, campaignId, value);

        // Media not always available (for example production cost data)
        if (channel) {
          addToRecordTotal(d.byMedia, channel, value);
        }
        const category = campaignToTags[campaignId]?.category;

        if (category) {
          addToRecordTotal(d.byCategory, category, value);
        }

        campaignToTags[campaignId]?.allTags.forEach(({ tag }) => {
          addToRecordTotal(d.byTag, tag, value);
        });
      });
    });

    timeframeData.push(d);
  });

  return timeframeData.sort(sortEpoch);
};

export const aggregateApiDataToDataState = <
  DatapointsKey extends string,
  ValueKey extends string,
>(
  datapointsKey: DatapointsKey,
  valueKey: ValueKey,
  filteredData: Array<EpochApiData<DatapointsKey, ValueKey>>,
  isLoading: boolean,
  isError: boolean,
  campaignToTags: CampaignToTags,
): MMDataState => {
  const dataState: MMDataState = {
    week: [],
    month: [],
    quarter: [],
    biannual: [],
    year: [],
    total: 0,
    totalByCategory: {},
    totalByCampaignId: {},
    totalByMedia: {},
    totalByTag: {},
    isLoading,
    isError,
  };

  const byWeek: ByAggregate<DatapointsKey, ValueKey> = {};
  const byMonth: ByAggregate<DatapointsKey, ValueKey> = {};
  const byQuarter: ByAggregate<DatapointsKey, ValueKey> = {};
  const byBiannual: ByAggregate<DatapointsKey, ValueKey> = {};
  const byYear: ByAggregate<DatapointsKey, ValueKey> = {};

  filteredData.forEach((utcDatapoint) => {
    const utcEpoch = utcDatapoint.utcEpoch;
    const weekEpoch = getWeekStartEpoch(utcEpoch);
    const monthEpoch = getMonthStartEpoch(utcEpoch);
    const quarterEpoch = getQuarterStartEpoch(utcEpoch);
    const biannualEpoch = getBiannualStartEpoch(utcEpoch);
    const yearEpoch = getYearStartEpoch(utcEpoch);

    // Week data
    if (!byWeek[weekEpoch]) {
      byWeek[weekEpoch] = [];
    }
    byWeek[weekEpoch].push(utcDatapoint);

    // Month data
    if (!byMonth[monthEpoch]) {
      byMonth[monthEpoch] = [];
    }
    byMonth[monthEpoch].push(utcDatapoint);

    // Quarterly data
    if (!byQuarter[quarterEpoch]) {
      byQuarter[quarterEpoch] = [];
    }
    byQuarter[quarterEpoch].push(utcDatapoint);

    // Biannual data
    if (!byBiannual[biannualEpoch]) {
      byBiannual[biannualEpoch] = [];
    }
    byBiannual[biannualEpoch].push(utcDatapoint);

    // Yearly data
    if (!byYear[yearEpoch]) {
      byYear[yearEpoch] = [];
    }
    byYear[yearEpoch].push(utcDatapoint);
  });

  dataState.week = mapByAggregateToTimeframeData(
    datapointsKey,
    valueKey,
    byWeek,
    campaignToTags,
  );
  dataState.month = mapByAggregateToTimeframeData(
    datapointsKey,
    valueKey,
    byMonth,
    campaignToTags,
  );
  dataState.quarter = mapByAggregateToTimeframeData(
    datapointsKey,
    valueKey,
    byQuarter,
    campaignToTags,
  );
  dataState.biannual = mapByAggregateToTimeframeData(
    datapointsKey,
    valueKey,
    byBiannual,
    campaignToTags,
  );
  dataState.year = mapByAggregateToTimeframeData(
    datapointsKey,
    valueKey,
    byYear,
    campaignToTags,
  );

  // Use year to get total as least amount of datapoints
  dataState.year.forEach((data) => {
    Object.entries(data.byCampaignId).forEach(([campaignId, value]) => {
      addToRecordTotal(dataState.totalByCampaignId, campaignId, value);
    });

    Object.entries(data.byMedia).forEach(([media, value]) => {
      addToRecordTotal(dataState.totalByMedia, media, value);
    });

    Object.entries(data.byTag).forEach(([tag, value]) => {
      addToRecordTotal(dataState.totalByTag, tag, value);
    });

    Object.entries(data.byCategory).forEach(([category, value]) => {
      addToRecordTotal(dataState.totalByCategory, category, value);
    });

    dataState.total += data.value;
  });

  return dataState;
};
