import { GridItem, useDisclosure } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/atoms/Button';
import { FormNumberInput } from 'src/components/molecules/formInput/FormNumberInput';
import { FormSelect } from 'src/components/molecules/formInput/FormSelect';
import { FormTimePeriodInput } from 'src/components/molecules/formInput/FormTimePeriodInput';
import { useMODataContext } from 'src/features/MO/contexts/MODataContext';
import { Media } from 'src/types';
import {
  ConstraintType,
  OptimizationFormInputs,
} from '../OptimizationModal/OptimizationModalContent';

export type ConstraintRowProps = {
  index: number;
  onRemove: (index: number) => void;
  mediaOptions: Array<Media>;
};

const constraintTypes: Array<{ value: ConstraintType; label: string }> = [
  { value: 'FIXED', label: 'Fixed' },
  { value: 'MAX', label: 'Max' },
  { value: 'MIN', label: 'Min' },
];

export const ConstraintRow: React.FC<ConstraintRowProps> = ({
  index,
  onRemove,
  mediaOptions,
}) => {
  const { investments } = useMODataContext();

  const { watch, setValue } = useFormContext<OptimizationFormInputs>();

  const disclosure = useDisclosure();

  const optimizationPeriodStart = watch('periodStart');
  const optimizationPeriodEnd = watch('periodEnd');

  const period = watch(`constraints.${index}.period`);
  const periodInput = period.entirePeriod ? 'Entire period' : undefined;

  const onEntirePeriodPress = () => {
    setValue(`constraints.${index}.period.entirePeriod`, true);
    setValue(`constraints.${index}.period.from`, optimizationPeriodStart);
    setValue(`constraints.${index}.period.to`, optimizationPeriodEnd);
    disclosure.onClose();
  };

  const selectedMediaId = watch(`constraints.${index}.media`);
  const selectedMedia = mediaOptions.find(({ id }) => id === selectedMediaId);

  const mediaInvestment = investments.totalInvestmentByChannel[selectedMediaId];
  const investmentHelperText =
    mediaInvestment !== undefined && selectedMedia
      ? `${selectedMedia.name} investment: ${mediaInvestment.toFixed(0)} €`
      : '';

  return (
    <>
      <GridItem>
        <FormSelect<OptimizationFormInputs, string>
          options={constraintTypes}
          inputPath={`constraints.${index}.type`}
        />
      </GridItem>
      <GridItem>
        <FormSelect<OptimizationFormInputs, string>
          options={mediaOptions.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          inputPath={`constraints.${index}.media`}
          placeholder="Select media"
          required
        />
      </GridItem>
      <GridItem>
        <FormNumberInput<OptimizationFormInputs>
          inputPath={`constraints.${index}.value`}
          required
          placeholder="Investment"
          suffix="€"
          helperText={investmentHelperText}
        />
      </GridItem>
      <GridItem>
        <FormTimePeriodInput<OptimizationFormInputs>
          sx={{ minW: '250px', w: 'unset' }}
          periodFromInputPath={`constraints.${index}.period.from`}
          periodToInputPath={`constraints.${index}.period.to`}
          disclosure={disclosure}
          periodInput={periodInput}
          onDateChange={() => {
            setValue(`constraints.${index}.period.entirePeriod`, false);
          }}>
          <Button
            buttonName="opt-entire-period-constraint"
            mt="8px"
            alignSelf={'center'}
            onClick={onEntirePeriodPress}
            fontSize="16px">
            Entire period
          </Button>
        </FormTimePeriodInput>
      </GridItem>

      <GridItem>
        <Button
          buttonName="remove-constraint"
          backgroundColor={'transparent'}
          _hover={{
            color: 'gray',
          }}
          color={'white'}
          onClick={() => onRemove(index)}>
          X
        </Button>
      </GridItem>
    </>
  );
};
