import { IconButtonProps, Tooltip } from '@chakra-ui/react';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useMenuFontActiveColor } from 'src/hooks/colors/useMenuFontActiveColor';

type FilterType = 'media' | 'campaigns' | 'time range';

type LockFiltersButtonProps = {
  filterType: FilterType;
} & Omit<IconButtonProps, 'aria-label'>;

const useFilterState = (
  filterType: FilterType,
): { filterLocked: boolean; setFilterLocked: (locked: boolean) => void } => {
  const {
    timeRangeLocked,
    setTimeRangeLocked,
    setCampaignsLocked,
    campaignsLocked,
    mediaLocked,
    setMediaLocked,
  } = useFilterContext();

  if (filterType === 'media') {
    return { filterLocked: mediaLocked, setFilterLocked: setMediaLocked };
  }

  if (filterType === 'campaigns') {
    return {
      filterLocked: campaignsLocked,
      setFilterLocked: setCampaignsLocked,
    };
  }

  return {
    filterLocked: timeRangeLocked,
    setFilterLocked: setTimeRangeLocked,
  };
};

export const LockFiltersButton: React.FC<LockFiltersButtonProps> = ({
  filterType,
  ...buttonProps
}) => {
  const { fontActiveColor } = useMenuFontActiveColor();
  const { filterLocked, setFilterLocked } = useFilterState(filterType);

  const tooltipLabel = filterLocked
    ? `Unlock ${filterType} selection`
    : `Lock ${filterType} selection`;

  return (
    <Tooltip label={tooltipLabel}>
      <IconButton
        size={'xs'}
        color={filterLocked ? fontActiveColor : 'white'}
        onClick={() => setFilterLocked(!filterLocked)}
        aria-label={`${filterType}-filter-lock`}
        ml="14px"
        icon={<Icon iconName={filterLocked ? 'lock' : 'unlock'} />}
        {...buttonProps}
      />
    </Tooltip>
  );
};
