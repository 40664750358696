import {
  Avatar,
  Center,
  Flex,
  Menu,
  MenuDivider,
  // eslint-disable-next-line no-restricted-imports
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { buttonClickedEvent, menuOptionClickedEvent } from 'src/libs/amp';
import { UserRole, useAuthContext } from '../../../contexts/AuthContext';
import { MenuTriangle } from '../../atoms/MenuTriangle';
import { MenuList } from '../../molecules/MenuList';

const UserInfo: React.FC = () => {
  const { userProfile } = useAuthContext();

  const fontColor = useColorModeValue(
    'text.light.secondary',
    'text.dark.secondary',
  );
  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      justify="center"
      py="0.4rem"
      px="0.8rem"
      color={fontColor}>
      <Text fontWeight="bold">{userProfile?.email}</Text>
      {userProfile?.role === UserRole.ADMIN && (
        <Text maxWidth="100%">ADMIN</Text>
      )}
      {userProfile?.role === UserRole.ANALYST && (
        <Text maxWidth="100%">Omnicom Analyst</Text>
      )}
    </Flex>
  );
};

const MENU_BUTTON_NAME = 'user-select';

/**
 * Various app actions for the user. Some menu items will
 * be hidden depending on the users role.
 */
export const UserMenu: React.FC = () => {
  const { userProfile, signOut } = useAuthContext();
  const { colorMode, toggleColorMode } = useColorMode();

  const colorModeLabel = colorMode === 'light' ? 'Dark mode' : 'Light mode';

  const onToggleColorModeClick = () => {
    menuOptionClickedEvent({
      buttonName: 'toggle-color',
      label: colorModeLabel,
      value: colorModeLabel,
    });
    toggleColorMode();
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            buttonName={MENU_BUTTON_NAME}
            color="current"
            aria-label="User Options">
            <Center>
              <Avatar size="sm" name={userProfile?.name} fontWeight={700} />
              <MenuTriangle isOpen={isOpen} />
            </Center>
          </MenuButton>
          <MenuList buttonName={MENU_BUTTON_NAME}>
            <UserInfo />
            <MenuDivider />
            <MenuItem onClick={onToggleColorModeClick} closeOnSelect={false}>
              {colorModeLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                buttonClickedEvent({
                  buttonName: 'sign-out',
                });
                signOut();
              }}>
              Sign Out
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
