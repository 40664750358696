import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { SosMonthlyMovingAverageData } from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import { SoSMonthlyForLocationBarChart } from './SoSMonthlyForLocationBarChart';
import { useMemo } from 'react';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';

type SoSMonthlyForLocationBarChartBoxProps = DataBoxBaseProps & {
  data: Array<SosMonthlyMovingAverageData>;
  isLoading: boolean;
  isError: boolean;
};

export const SoSMonthlyForLocationBarChartBox: React.FC<
  SoSMonthlyForLocationBarChartBoxProps
> = ({ data, isLoading, isError, ...dataBoxProps }) => {
  const { selectedKeywords } = useSOSDataContext();

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      placement: 'right',
      baseLegendKeys: selectedKeywords,
      minWidth: '150px',
    };
  }, [selectedKeywords]);

  return (
    <DataBox
      {...dataBoxProps}
      legendProps={legendProps}
      restrictChartHeight
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <SoSMonthlyForLocationBarChart data={data} keywords={selectedKeywords} />
    </DataBox>
  );
};
