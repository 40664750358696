import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { SelectedDateRange } from 'src/components/molecules/SelectedDateRange';
import { useFilterContext } from 'src/contexts/FilterContext';
import { TimePeriod } from 'src/types';

export const SelectedTimeRange: React.FC = () => {
  const { timeRange } = useFilterContext();

  const fontActiveColor = useColorModeValue(
    'text.light.active',
    'text.dark.active',
  );

  return (
    <SelectedDateRange
      bold={timeRange.period === TimePeriod.CUSTOM}
      start={timeRange.start}
      end={timeRange.end}
      fontActiveColor={fontActiveColor}
    />
  );
};
