/**
 * This is a quick function to help prepare a internal customer name for rendering in the UI.
 * As it its not 100% certain what shape the names may take, removing non-word characters &
 * _ makes it a bit more readable. This can be improved in the future if the need arises.
 * @param {string | null} name - The name assigned to the customer.
 * @returns {string | null} The formatted name of the customer or the original string if any error is encountered.
 */

const capitaliseFirstLetter = (name: string): string => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const cleanCustomerName = (
  customerName: string | null,
): string | null => {
  if (!customerName) {
    return null;
  }

  return capitaliseFirstLetter(customerName.replaceAll('_', ' '));
};
