import { useCallback } from 'react';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';

export const useRenameByChannel = () => {
  const { getMediaName } = useCustomerSettingsContext();

  const renameByChannel = useCallback(
    (byChannel: Record<string, number>) => {
      const newByChannel: Record<string, number> = {};
      Object.entries(byChannel).forEach(([mediaId, value]) => {
        newByChannel[getMediaName(mediaId)] = value;
      });
      return newByChannel;
    },
    [getMediaName],
  );

  return { renameByChannel };
};
