import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { BUILD_VERSION } from 'src/constants';

export default function Footer() {
  const textColor = useColorModeValue(
    'text.light.secondary',
    'text.dark.secondary',
  );
  return (
    <>
      {BUILD_VERSION && (
        <Flex
          direction="column"
          alignItems="flex-end"
          justifyContent="center"
          w="100%"
          px="40px"
          pb="24px">
          <Text fontSize="xs" color={textColor}>
            version {BUILD_VERSION}
          </Text>
        </Flex>
      )}
    </>
  );
}
