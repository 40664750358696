import { Grid } from '@chakra-ui/react';
import React from 'react';

type KeyMetricsGridProps = {
  children: React.ReactNode;
};

export const KeyMetricsGrid: React.FC<KeyMetricsGridProps> = ({ children }) => {
  const childrenLength = Array.isArray(children) ? children.length : 1;

  return (
    <Grid
      templateColumns={`repeat(${childrenLength}, 1fr)`}
      gap="24px"
      w="100%"
      minH="250px"
      my="30px">
      {children}
    </Grid>
  );
};
