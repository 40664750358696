import React from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';

import { DataTable } from 'src/components/molecules/DataTable';
import { useMetricTableColumns } from '../../hooks/useMetricTableColumns';
import { useMetrics } from '../../hooks/useMetrics';

type MetricsByCampaignTableProps = DataBoxBaseProps;

export const MetricsByCampaignTable: React.FC<MetricsByCampaignTableProps> = ({
  ...props
}) => {
  const { data, isError, isLoading } = useMetrics('byCampaign');
  const columns = useMetricTableColumns('Campaign');

  return (
    <DataBox
      {...props}
      exportEnabled
      hasData={data.length > 0}
      isError={isError}
      isLoading={isLoading}>
      <DataTable csvFileName="campaigns" data={data} columns={columns} />
    </DataBox>
  );
};
