import { Flex } from '@chakra-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { CloseButton } from 'src/components/molecules/CloseButton';
import { DateRangeSelector } from 'src/components/molecules/DateRangeSelector';
import { EntireDateRangeButton } from 'src/components/molecules/DateRangeSelector/AdditionalDateRangeSelectorButton';
import { useFilterContext } from 'src/contexts/FilterContext';
import { dayjs } from 'src/libs/dayjs';
import { getModelMaxStartAndEndDatePickerDates } from 'src/utils/model';
import { SelectedBenchmarkRange } from './SelectedBenchmarkRange';

export const BenchmarkSelector: React.FC = () => {
  const {
    setBenchmarkTimerange,
    benchmarkTimerange,
    setBenchmarkEnabled,
    currentModelVersion,
  } = useFilterContext();

  const { datePickerMaxDate, datePickerMinDate } =
    getModelMaxStartAndEndDatePickerDates(currentModelVersion);

  const selectedStartDate = dayjs(benchmarkTimerange.start).toDate();
  const selectedEndDate = dayjs(benchmarkTimerange.end).toDate();

  const onDateRangeChange = (startDate: Date, endDate: Date) => {
    setBenchmarkTimerange(dayjs(startDate), dayjs(endDate));
  };

  const additionalDateRangeButton =
    datePickerMaxDate && datePickerMinDate ? (
      <EntireDateRangeButton
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        minDate={datePickerMinDate}
        maxDate={datePickerMaxDate}
        onDateRangeChange={onDateRangeChange}
      />
    ) : null;

  return (
    <Flex alignItems={'center'}>
      <DateRangeSelector
        minDate={datePickerMinDate}
        maxDate={datePickerMaxDate}
        onDateRangeChange={onDateRangeChange}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        additionalDateRangeButton={additionalDateRangeButton}>
        <SelectedBenchmarkRange />
      </DateRangeSelector>
      {benchmarkTimerange.enabled && (
        <CloseButton onClick={() => setBenchmarkEnabled(false)} />
      )}
    </Flex>
  );
};
