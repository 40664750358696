import { Menu } from '@chakra-ui/react';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { useSearch } from 'src/hooks/useSearch';

const MENU_BUTTON_NAME = 'region-select';

export const RegionMenu: React.FC = () => {
  const { toggleRegions, availableRegionsAndCitiesState } = useSOSDataContext();

  const selectableRegions = availableRegionsAndCitiesState.regions;

  const selectedRegions = selectableRegions.filter(({ selected }) => selected);

  const {
    results: searchedRegions,
    searchValue,
    setSearchValue,
  } = useSearch(selectableRegions, ['locationName']);

  const onAllClick = () => {
    toggleRegions(searchedRegions);
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px">
        {selectedRegions.length}/{selectableRegions.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedRegions.map((d) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={d.locationId}
            isSelected={d.selected}
            label={d.locationName}
            value={d.locationId}
            onClick={() => toggleRegions([d])}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
