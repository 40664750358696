import {
  // eslint-disable-next-line no-restricted-imports
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { iconButtonClickedEvent } from 'src/libs/amp';

export type IconButtonProps = ChakraIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ onClick, ...props }, ref) => {
    const buttonName = `icon-button-${props['aria-label']}`;

    return (
      <ChakraIconButton
        ref={ref}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            iconButtonClickedEvent({ buttonName });
          }
        }}
        {...props}
      />
    );
  },
);

IconButton.displayName = 'IconButton';
