import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

type TooltipContentContainerProps = {
  children: React.ReactNode;
} & BoxProps;

export const TooltipContentContainer: React.FC<
  TooltipContentContainerProps
> = ({ children, ...boxProps }) => {
  const bgColor = useColorModeValue(
    'background.light.hover',
    'background.dark.hover',
  );
  return (
    <Box
      zIndex={100}
      borderRadius={'10px'}
      backgroundColor={bgColor}
      boxShadow="0 8px 20px rgba(0,0,0,0.3)"
      px="16px"
      py="12px"
      {...boxProps}>
      {children}
    </Box>
  );
};
