import { useMemo } from 'react';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import { useSOSDataContext } from '../../../contexts/SOSDataContext';
import { SosPastAndCurrentBarChart } from '../../molecules/SosPastAndCurrentBarChart';
import { benchmarkDatesAreDefault } from 'src/features/SOS/utils/getDefaultBenchmarkDates';

type SosRegionsChartBoxProps = DataBoxBaseProps;

export const SosRegionsChartBox: React.FC<SosRegionsChartBoxProps> = ({
  ...dataBoxProps
}) => {
  const { shareOfSearchRegionsState, selectedKeywords, regionsBenchmarkDates } =
    useSOSDataContext();

  const legendProps: DataBoxLegendProps = useMemo(() => {
    return {
      placement: 'right',
      baseLegendKeys: selectedKeywords,
      minWidth: '150px',
    };
  }, [selectedKeywords]);

  const isBenchmarkDefault = benchmarkDatesAreDefault(regionsBenchmarkDates);

  return (
    <DataBox
      {...dataBoxProps}
      legendProps={legendProps}
      restrictChartHeight
      hasData={shareOfSearchRegionsState.data.length > 0}
      isError={shareOfSearchRegionsState.isError}
      isLoading={shareOfSearchRegionsState.isLoading}>
      <SosPastAndCurrentBarChart
        data={shareOfSearchRegionsState.data}
        isBenchmarkDefault={isBenchmarkDefault}
      />
    </DataBox>
  );
};
