import { useMemo } from 'react';
import { SimplifiedDataState } from 'src/types';
import { INIT_MM_DATA_STATE, MMDataState } from '../types';
import { aggregateMediaEffect } from '../utils/aggregateMediaEffect';

export const useMediaEffect = (
  enabled: boolean,
  decomps: MMDataState,
  predictedSales: SimplifiedDataState,
) => {
  return useMemo(() => {
    if (!enabled) {
      return { mediaEffect: INIT_MM_DATA_STATE };
    }

    const mediaEffect = aggregateMediaEffect(decomps, predictedSales);

    return { mediaEffect };
  }, [enabled, decomps, predictedSales]);
};
