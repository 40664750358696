import { useMemo } from 'react';
import { INIT_MM_DATA_STATE, MMDataState } from '../types';
import { aggregateCostPerUnit } from '../utils/aggregateCostPerUnit';

export function useCostPerUnit(
  enabled: boolean,
  investments: MMDataState,
  decomps: MMDataState,
) {
  return useMemo(() => {
    if (!enabled) {
      return { cpu: INIT_MM_DATA_STATE };
    }

    const cpu = aggregateCostPerUnit({ investments, decomps });

    return { cpu };
  }, [decomps, investments, enabled]);
}
