import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router';
import Footer from 'src/components/atoms/Footer';
import { SideNavBar } from 'src/components/templates/SideNavBar';
import { TopNavBar } from 'src/components/templates/TopNavBar';
import { getActiveRoute } from 'src/app/routes/utils';
import { TOP_BAR_HEIGHT } from '../TopNavBar/styles';

export const NavBarLayout: React.FC<FlexProps> = (props) => {
  const location = useLocation();
  return (
    <Flex
      direction="column"
      align="flex-start"
      wrap="wrap"
      minH="100vh"
      w="100%"
      className="page-wrapper"
      {...props}>
      <TopNavBar pos="fixed" className="top-nav" zIndex="2" />
      <SideNavBar />
      <Flex direction="column" w="100%">
        <Flex
          className="content-wrapper"
          flex="1"
          pl={
            location.pathname === '/' ||
            !getActiveRoute(location.pathname)?.subroutes
              ? '80px'
              : '160px'
          }
          pt={TOP_BAR_HEIGHT}
          w="100%">
          {props.children}
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};
