import { useColorModeValue } from '@chakra-ui/react';

export const useMenuBackgroundColor = () => {
  const bgColor = useColorModeValue(
    'background.light.lowest',
    'background.dark.lowest',
  );
  return { bgColor };
};

export const useDataBoxBackgroundColor = () => {
  const bgColor = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );
  return { bgColor };
};
