import React from 'react';
import { Summary } from 'src/features/MM/components/templates/Summary';
import { Page } from '../../components/molecules/Page';
import { FilterBar } from '../../components/organisms/FilterBar';

export const SummaryPage: React.FC = () => {
  return (
    <Page filterBar={<FilterBar includeAggregation={false} includeDimension />}>
      <Summary />
    </Page>
  );
};
