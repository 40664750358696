import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Coordinate } from 'recharts/types/util/types';
import { TooltipColoredLegend } from './TooltipColoredLegend';
import { TooltipContentContainer } from './TooltipContentContainer';

export type TooltipContentExtraMap = Record<
  string,
  {
    label: string;
    valueFormatter: (x: number) => string;
  }
>;

type TooltipContentProps = {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Array<any>;
  label?: string | number;
  valueFormatter: (x: number) => string;
  labelFormatter: (x: number | string) => string;
  extraMap?: TooltipContentExtraMap;
  labelsFromId?: boolean;
  allowedIds?: Array<string>;
  forceMulti?: boolean;
  coordinate?: Coordinate;
};

export const TooltipContent: React.FC<TooltipContentProps> = ({
  active = false,
  payload = [],
  label = '',
  valueFormatter,
  labelFormatter,
  extraMap = {},
  labelsFromId,
  allowedIds,
  forceMulti = false,
}) => {
  if (!active) {
    return null;
  }

  if (payload?.length > 1 || forceMulti) {
    return (
      <TooltipContentContainer>
        <Box opacity={'100%'} zIndex={10}>
          <Text fontSize="16px">{labelFormatter(label)}</Text>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payload.map((item: any) => {
              const dataKey = item?.dataKey;

              if (!item.value && payload.length > 10) {
                return null;
              }

              const id = item.id;

              if (allowedIds) {
                const isAllowed = allowedIds.includes(id);
                if (!isAllowed) {
                  return null;
                }
              }

              const mapData = extraMap[dataKey];
              const vFormatter = mapData?.valueFormatter ?? valueFormatter;
              let l = mapData?.label ?? dataKey;
              let key = dataKey;

              if (labelsFromId) {
                if (!item.id) {
                  return null;
                }
                l = item.id;
                key = item.id;
              }

              return (
                <TooltipColoredLegend
                  key={key}
                  label={l}
                  value={vFormatter(item?.value)}
                  color={item?.stroke || item?.fill}
                />
              );
            })
          }
        </Box>
      </TooltipContentContainer>
    );
  }

  const displayValue = !!payload && payload[0]?.value ? payload[0].value : '';
  return (
    <TooltipContentContainer>
      <Text fontSize="16px">{labelFormatter(label)}</Text>
      <Text fontSize="28px">{valueFormatter(displayValue)}</Text>
    </TooltipContentContainer>
  );
};
