import { useEffect, useState } from 'react';
import {
  CreateOptimizationRequest,
  useCreateOptimizationMutation,
  useDeleteOptimizationMutation,
} from 'src/api/mutations';
import { useOptimizationsQuery } from 'src/api/queries';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { Optimization } from '../types';
import { mapApiOptimizationToOptimization } from '../utils/mapApiOptimizationToOptimization';

export type OptimizationsData = {
  data: Optimization[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_OPTIMIZATIONS_DATA: OptimizationsData = {
  data: [],
  isError: false,
  isLoading: false,
};

export const useOptimizations = (
  enabled: boolean,
  marginPct: number | null,
) => {
  const { getMediaName } = useCustomerSettingsContext();
  const { currentModelVersion } = useFilterContext();

  const [optimizations, setOptimizations] = useState<OptimizationsData>(
    INIT_OPTIMIZATIONS_DATA,
  );

  const {
    data: apiData,
    isLoading,
    isError,
  } = useOptimizationsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      model_id: currentModelVersion?.modelID ?? 'missing_modelid',
    },
    enabled,
  );

  const createOptimizationMutation = useCreateOptimizationMutation();
  const deleteOptimizationMutation = useDeleteOptimizationMutation();

  const createOptimization = async (data: CreateOptimizationRequest) => {
    const mutationData = await createOptimizationMutation.mutateAsync(data);
    setOptimizations((prev) => {
      const newOptimizationsData = [
        mapApiOptimizationToOptimization(mutationData, marginPct, getMediaName),
        ...prev.data,
      ];
      return {
        ...prev,
        data: newOptimizationsData,
      };
    });
  };

  const deleteOptimization = async (optimizationId: string) => {
    const customer = currentModelVersion?.customerName;
    // Should not happen
    if (!customer) {
      // eslint-disable-next-line no-console
      console.error('No customer in context when deleting optimization');
      return;
    }

    await deleteOptimizationMutation.mutateAsync({ optimizationId, customer });
    setOptimizations((prev) => {
      const newOptimizationsData = prev.data.filter(
        (optimization) => optimization.optimizationId !== optimizationId,
      );
      return {
        ...prev,
        data: newOptimizationsData,
      };
    });
  };

  useEffect(() => {
    if (!enabled) {
      setOptimizations(INIT_OPTIMIZATIONS_DATA);
      return;
    }

    const data = apiData ?? [];
    setOptimizations({
      data: data.map((d) =>
        mapApiOptimizationToOptimization(d, marginPct, getMediaName),
      ),
      isLoading,
      isError,
    });
  }, [apiData, isLoading, isError, enabled, marginPct, getMediaName]);

  return { createOptimization, optimizations, deleteOptimization };
};
