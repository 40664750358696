import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAxisStyles, useChartColors } from 'src/hooks/colors/useChartColors';
import { TooltipContent } from '../../atoms/tooltips/TooltipContent';
import { useLegendContext } from '../Legend/LegendContext';

export type LineGraphProps = {
  data: Array<object>;
  xAxisKey: string;
  benchmarkKey?: string;
  xAxisFormatter?: (value: number | string) => string;
  yAxisFormatter?: (value: number) => string;
  tooltip?: ReactJSXElement;
};

export const LineGraph: React.FC<LineGraphProps> = ({
  data,
  xAxisKey,
  benchmarkKey,
  xAxisFormatter = (value: number | string) => value.toString(),
  yAxisFormatter = (value: number) => value.toString(),
  tooltip,
}) => {
  const { selectedLineLegendValues } = useLegendContext();

  const { benchmarkLineColor, gridColor } = useChartColors();

  const axisStyles = useAxisStyles();

  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <CartesianGrid
          strokeDasharray="0"
          strokeWidth={1}
          stroke={gridColor}
          fill="transparent"
        />
        <YAxis width={70} tickFormatter={yAxisFormatter} {...axisStyles} />
        <XAxis
          type="number"
          domain={['auto', 'auto']}
          tickFormatter={xAxisFormatter}
          scale={'linear'}
          dataKey={xAxisKey}
          {...axisStyles}
        />
        {tooltip ?? (
          <Tooltip
            offset={25}
            content={
              <TooltipContent
                valueFormatter={yAxisFormatter}
                labelFormatter={xAxisFormatter}
              />
            }
          />
        )}

        {selectedLineLegendValues.map(({ color, key }) => {
          const lineColor = key === benchmarkKey ? benchmarkLineColor : color;

          return (
            <Line
              key={key}
              dataKey={key}
              connectNulls
              isAnimationActive={false}
              stroke={lineColor}
              dot={false}
              activeDot={{
                stroke: lineColor,
                fill: lineColor,
                strokeOpacity: 0.5,
                strokeWidth: 4,
                r: 6,
              }}
              strokeWidth={2}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
