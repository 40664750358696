import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { getMonthStartEpoch, getYearStartEpoch } from 'src/utils/date';

export const getYearlyAndMonthlyLongTermEffectsData = (
  data: Array<ApiLongTermEffectsDataPoint>,
): {
  monthly: Record<number, ApiLongTermEffectsDataPoint[]>;
  yearly: Record<number, ApiLongTermEffectsDataPoint[]>;
} => {
  const byMonth: Record<number, Array<ApiLongTermEffectsDataPoint>> = {};
  const byYear: Record<number, Array<ApiLongTermEffectsDataPoint>> = {};

  // First group
  data.forEach((d) => {
    const startMonth = getMonthStartEpoch(d.utcEpoch);
    const startYear = getYearStartEpoch(d.utcEpoch);

    if (!byMonth[startMonth]) {
      byMonth[startMonth] = [];
    }
    if (!byYear[startYear]) {
      byYear[startYear] = [];
    }

    byMonth[startMonth].push(d);
    byYear[startYear].push(d);
  });
  return {
    monthly: byMonth,
    yearly: byYear,
  };
};
