import { MODELLED_CONV_NAME } from 'src/features/DA/utils/constants';

export const sortByModelledConversions = <
  T extends { [MODELLED_CONV_NAME]: number },
>(
  a: T,
  b: T,
): number => {
  return a[MODELLED_CONV_NAME] > b[MODELLED_CONV_NAME] ? -1 : 1;
};
