import React, { useMemo, useState } from 'react';
import { Tooltip } from 'recharts';
import { InfoAlert } from 'src/components/atoms/alerts/InfoAlert';
import { ResponseCurveTooltipContent } from 'src/components/atoms/tooltips/ResponseCurveTooltipContent';
import {
  DataBox,
  DataBoxBaseProps,
  DataBoxLegendProps,
} from 'src/components/molecules/DataBox';
import {
  LineScatterDataType,
  LineScatterGraph,
} from 'src/components/molecules/graphs/LineScatterGraph';
import { useCustomerSettingsContext } from 'src/contexts/CustomerSettingsContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useTimeFreqMultiplier } from 'src/features/MM/hooks/useTimeFreqMultiplier';
import { useChartEffectTickFormatter } from 'src/hooks/useEffectFormatter';
import { ResponseCurveDatapoint } from 'src/types';
import { EuroValueTickFormatter } from 'src/utils/TickFormatters';
import { RESPONSE_CURVES_DEF } from 'src/utils/metricDefinitions';
import { generateResponseCurveDatapoints } from 'src/utils/responseCurve';
import { useMMDataContext } from '../../contexts/MMDataContext';

export const ResponseCurvesBox: React.FC<DataBoxBaseProps> = ({ ...props }) => {
  const { getMediaName } = useCustomerSettingsContext();
  const { responseCurve } = useMMDataContext();
  const { mediaColorMap } = useFilterContext();

  const [infoOpen, setInfoOpen] = useState(false);
  const { valueMultiplier } = useTimeFreqMultiplier();
  const isLoading = responseCurve.isLoading;
  const isError = responseCurve.isError;

  const ValueTickFormatter = useChartEffectTickFormatter();

  const dataKeys = {
    xAxis: 'spend',
    yAxis: 'response',
  };

  const data: LineScatterDataType<ResponseCurveDatapoint>[] = useMemo(() => {
    const generatedData = generateResponseCurveDatapoints(
      responseCurve.data,
      600,
      valueMultiplier,
      1.3,
    );

    return generatedData.map((x) => ({
      name: getMediaName(x.channel),
      data: x.data,
      points: [
        {
          response: x.meanResponse,
          spend: x.meanSpend,
        },
      ],
    }));
  }, [responseCurve.data, valueMultiplier, getMediaName]);

  const legendProps: DataBoxLegendProps = {
    placement: 'right',
    lineKeys: data.map((d) => d.name),
    keyColorMap: mediaColorMap,
  };

  return (
    <>
      <InfoAlert
        headerText={props.title ?? ''}
        bodyText={RESPONSE_CURVES_DEF}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
      />
      <DataBox
        {...props}
        onInfoButtonClick={() => setInfoOpen(true)}
        legendProps={legendProps}
        hasData={data.length > 0}
        isError={isError}
        isLoading={isLoading}>
        <LineScatterGraph
          xAxisLabel="Investment"
          yAxisLabel="Effect"
          data={data}
          dataKeys={dataKeys}
          xAxisFormatter={EuroValueTickFormatter}
          yAxisFormatter={ValueTickFormatter}>
          <Tooltip
            offset={25}
            content={
              <ResponseCurveTooltipContent
                valueFormatter={ValueTickFormatter}
                responseCurveData={data}
              />
            }
          />
        </LineScatterGraph>
      </DataBox>
    </>
  );
};
