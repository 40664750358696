import { IconButtonProps } from '@chakra-ui/react';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';

type OpenDataTableModalButtonProps = {
  onClick: () => void;
  tableTitle: string;
} & Omit<IconButtonProps, 'aria-label' | 'icon' | 'size' | 'onClick'>;

export const OpenDataTableModalButton: React.FC<
  OpenDataTableModalButtonProps
> = ({ onClick, tableTitle, ...iconButtonProps }) => {
  return (
    <IconButton
      size={'xs'}
      onClick={onClick}
      title="Open data modal"
      aria-label={`open-data-table-${tableTitle}`}
      icon={<Icon iconName="external-link" />}
      {...iconButtonProps}
    />
  );
};
