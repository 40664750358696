/* eslint-disable no-restricted-imports */
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialog as ChakraAlertDialog,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Button } from '../Button';

export type InfoAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  headerText: string;
  bodyText: string;
};

export const InfoAlert: React.FC<InfoAlertProps> = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <ChakraAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody whiteSpace={'pre-line'}>{bodyText}</AlertDialogBody>
          <AlertDialogFooter>
            <Button buttonName="info-ok" ref={cancelRef} onClick={onClose}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
