import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';

export type EffectType = 'long' | 'short' | 'total';

export type EffectTabsProps = {
  selectedTab: EffectType;
  onSelect: (value: EffectType) => void;
};

const tabs: Array<TabOption<EffectType>> = [
  {
    label: 'Long term effect',
    value: 'long',
  },
  {
    label: 'Short term effect',
    value: 'short',
  },
  {
    label: 'Total effect',
    value: 'total',
  },
];

export const EffectTabs: React.FC<EffectTabsProps> = ({
  selectedTab,
  onSelect,
}) => {
  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
