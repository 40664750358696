import {
  Box,
  Flex,
  Link,
  Tooltip,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Icon } from 'src/components/atoms/Icon';
import { MMMRoute } from 'src/app/routes/utils';

type SideBarRouteProps = {
  route: MMMRoute;
};

const useSidebarLinkColors = () => {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  const colors = {
    default: {
      color:
        colorMode === 'light'
          ? theme.colors?.icon.light.color
          : theme.colors?.icon.dark.color,
    },
    active: {
      borderColor:
        colorMode === 'light'
          ? theme.colors?.icon.light.activeColor
          : theme.colors?.icon.dark.activeColor,
      background:
        colorMode === 'light'
          ? theme.colors?.icon.light.activeBackground
          : theme.colors?.icon.dark.activeBackground,
      color:
        colorMode === 'light'
          ? theme.colors?.icon.light.activeColor
          : theme.colors?.icon.dark.activeColor,
    },
    hover: {
      background:
        colorMode === 'light'
          ? theme.colors?.icon.light.hoverBackground
          : theme.colors?.icon.dark.hoverBackground,
    },
    focus: {
      background:
        colorMode === 'light'
          ? theme.colors?.icon.light.activeBackground
          : theme.colors?.icon.dark.activeBackground,
      border:
        colorMode === 'light'
          ? theme.colors?.border.light.focus
          : theme.colors?.border.dark.focus,
    },
  };

  const iconStyles = {
    defaultStyle: {
      color: colors.default.color,
      borderLeft: 'solid 2px transparent',
      borderRight: 'solid 2px transparent',
    },
    active: {
      color: colors.active.color,
      borderLeft: 'solid 2px transparent',
      borderRight: 'solid 2px',
      borderRightColor: colors.active.borderColor,
      background: colors.active.background,
    },
    hover: {
      borderLeft: 'solid 2px transparent',
      borderRight: `solid 2px`,
      borderRightColor: colors.default.color,
      background: colors.hover.background,
    },
    focus: {
      border: 'solid 1px',
      borderColor: colors.focus.border,
      background: colors.focus.background,
    },
  };

  return iconStyles;
};

const InternalNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    const { active, defaultStyle } = useSidebarLinkColors();

    return (
      <NavLink
        {...props}
        ref={ref}
        style={({ isActive }) => {
          return isActive ? active : defaultStyle;
        }}
      />
    );
  },
);

InternalNavLink.displayName = 'InternalNavLink';

export const SideBarRoute: React.FC<SideBarRouteProps> = ({ route }) => {
  const { hover, focus } = useSidebarLinkColors();

  return (
    <Tooltip key={route.url} hasArrow placement="right" label={route.name}>
      <Link
        boxSizing="border-box"
        width="80px"
        height="90px"
        as={InternalNavLink}
        to={route.url}
        _hover={hover}
        _focus={focus}>
        <Flex
          p={1}
          justifyContent="center"
          alignItems={'center'}
          flexDir="column"
          w="100%"
          h="100%">
          <Box>
            <Icon iconName={route.icon} height="32px" width="32px" />
          </Box>
          <Box mt={2} fontSize={10} textAlign={'center'}>
            {route.name}
          </Box>
        </Flex>
      </Link>
    </Tooltip>
  );
};
