import { EffectAndInvestmentMonthlyBox } from '../../organisms/EffectAndInvestmentMonthlyBox';
import { EvolutionOfBaseBox } from '../../organisms/EvolutionOfBaseBox';
import { RoiBox } from '../../organisms/RoiBox';
import { RoiByMediaBox } from '../../organisms/RoiByMediaBox';

export const LongTermEffects: React.FC = () => {
  return (
    <>
      <EvolutionOfBaseBox title="Evolution of base" colSpan={12} minH="400px" />
      <EffectAndInvestmentMonthlyBox
        title="Effect and investment monthly"
        colSpan={12}
        minH="400px"
      />
      <RoiBox title="Total ROI" colSpan={12} minH="400px" />
      <RoiByMediaBox
        title="ROI by media monthly"
        timeframe="monthly"
        colSpan={12}
        minH="400px"
      />
      <RoiByMediaBox
        title="ROI by media yearly"
        timeframe="yearly"
        colSpan={12}
        minH="400px"
      />
    </>
  );
};
