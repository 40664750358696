import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  FormatterFunc,
  formatterToCellFunc,
  identityCellFunc,
} from 'src/components/molecules/DataTable/cellFuncs';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';

export type TableModalRow = {
  date: string;
} & Record<string, number | null>;

const columnHelper = createColumnHelper<TableModalRow>();

export const useColumns = (yFormatter: FormatterFunc) => {
  const { selectedBaseLegendValues, selectedLineLegendValues } =
    useLegendContext();

  const columns = useMemo(() => {
    const valueCell = formatterToCellFunc(yFormatter);

    const lineColumns = selectedLineLegendValues.map(({ key, label }) => {
      return columnHelper.accessor(key, {
        cell: valueCell,
        header: label,
        sortingFn: 'alphanumeric',
      });
    });

    const baseColumns = selectedBaseLegendValues.map(({ key, label }) => {
      return columnHelper.accessor(key, {
        cell: valueCell,
        header: label,
        sortingFn: 'alphanumeric',
      });
    });

    return [
      columnHelper.accessor('date', {
        cell: identityCellFunc,
        header: 'Date',
      }),
      ...lineColumns,
      ...baseColumns,
    ];
  }, [selectedBaseLegendValues, selectedLineLegendValues, yFormatter]);

  return columns;
};
