import { ApiCampaignDataPoint } from 'src/api/queries';
import { Dayjs } from 'src/libs/dayjs';

export enum AggregateLevel {
  day,
  week,
  month,
  quarter,
  biannual,
  year,
}

export enum TimePeriod {
  WEEK = '7d',
  TWO_WEEKS = '2w',
  MONTH = '1m',
  QUARTER = '3m',
  HALF = '6m',
  YEAR = '1y',
  YTD = 'YTD',
  CUSTOM = 'Custom',
}

export type BenchmarkTimeRange = {
  start: Dayjs;
  end: Dayjs;
  enabled: boolean;
  error: string;
};

export type TimeRange = {
  period: TimePeriod;
  start: Dayjs;
  end: Dayjs;
};

export type Campaign = ApiCampaignDataPoint & {
  selected: boolean;
  visible: boolean;
  isAvailable: boolean; // Used to render campaigns that cannot be used in the model's data
};

export type CampaignTag = {
  name: string;
  selected: boolean;
  visible: boolean;
};

export type CampaignTagGroup = {
  name: string;
  tagGroup: string;
  tags: CampaignTag[];
  selected: boolean;
};

export type Media = {
  id: string;
  name: string;
  selected: boolean;
  isAvailable: boolean;
};

export type CampaignToTags = Record<
  string,
  {
    allTags: Array<{
      tagGroup: string;
      tag: string;
    }>;
    category: string | null;
  }
>;

export type MediaNamesMap = Record<string, string>;

export type ResponseCurveDatapoint = {
  spend: number;
  response: number;
};

export type ResponseCurveData = {
  channel: string;
  meanSpend: number;
  meanResponse: number;
  data: Array<ResponseCurveDatapoint>;
};

export type SimplifiedTimeframeData = {
  utcEpoch: number;
  value: number;
};

export type SimplifiedDataState = {
  isLoading: boolean;
  isError: boolean;
  total: number;
  day: Array<SimplifiedTimeframeData>;
  week: Array<SimplifiedTimeframeData>;
  month: Array<SimplifiedTimeframeData>;
  quarter: Array<SimplifiedTimeframeData>;
  biannual: Array<SimplifiedTimeframeData>;
  year: Array<SimplifiedTimeframeData>;
};

export const INIT_SIMPLIFIED_DATA_STATE: Readonly<SimplifiedDataState> = {
  isLoading: false,
  isError: false,
  total: 0,
  day: [],
  week: [],
  month: [],
  quarter: [],
  biannual: [],
  year: [],
};
