import { capitalize } from 'lodash';

export const INCREMENTAL_SALES_DEF =
  'Incremental Sales is the modelled effect of the selected media. This can be either in euros or units depending on the type of sales data used in the model.';

export const MEDIA_INVESTMENT_DEF =
  'Media Investment is calculated by summing the spend or cost used for each selected media.';

export const SALES_DEF =
  'Sales Data is the actual sales data used in the model.';

export const PREDICTED_SALES_DEF =
  'Predicted Sales is the sales the model predicts.';

export const EURO_ROI_DEF = `ROI is calculated by:
(Incremental Sales - Media Investment) / Media Investment`;

export const UNIT_ROI_DEF = `ROI is calculated by:
Incremental Sales / Media Investment`;

export const ROMI_DEF = `ROMI is calculated by:
Total spend = Media Investment + Production Costs
Modelled Margin = Incremental Sales * Margin Percentage
ROMI = (Modelled Margin - Total Spend) / Total Spend`;

export const TOTAL_ROI_DEF = `Total ROI is calculated by first summing all Incremental Sales and Media Investments before calculating the ROI.`;

export const TOTAL_ROMI_DEF = `Total ROMI is calculated by first summing all Incremental Sales, Media Investments, and Production Costs before calculating the ROMI.`;

export const MEDIA_EFFECT_DEF = `Media effect percentage is calculated by:
(100 * Incremental Sales for the media) / Total Predicted Sales`;

export const getCPUDef = (label: string) => `${capitalizeLabel(
  label,
)} is calculated by:
Media Investment / Incremental Sales for the media`;

export const getTotalCPUDef = (label: string) => {
  const capitalisedLabel = capitalizeLabel(label);
  return `Total ${capitalisedLabel} is calculated by first summing all Incremental Sales and Media Investment before calculating ${capitalisedLabel}.`;
};

export const TOTAL_DECOMPS_DEF = `Total Decompositions group data into Base, Media Effect and other factors in the model that explain sales.

By using the Base selector you are able to effect what factors (other than Media Effect) are part of the Base.`;

export const RESPONSE_CURVES_DEF = `Response Curves model how a change in the media spending would effect sales.

The highlighted point on the curve is the current average spend used for the media.`;

const capitalizeLabel = (label: string) => {
  return label
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};
