import { useMemo } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useAvailableCategories } from 'src/hooks/useAvailableCategories';
import { useAvailableTags } from 'src/hooks/useAvailableTags';
import { useSelectedDimensionContext } from '../contexts/SelectedDimensionContext';
import { Dimension } from '../types';

export const useByDimensionNameAndId = () => {
  const { dimension } = useSelectedDimensionContext();

  const { campaigns, media } = useFilterContext();
  const { selectedTags } = useAvailableTags();

  const { categories } = useAvailableCategories();

  return useMemo(() => {
    switch (dimension) {
      case Dimension.campagin:
        return campaigns
          .filter((c) => c.selected)
          .map((c) => ({ name: c.name, id: c.id }));
      case Dimension.category:
        return categories.map((category) => ({ name: category, id: category }));
      case Dimension.media:
        return media
          .filter((m) => m.selected)
          .map((m) => ({ name: m.name, id: m.id }));
      case Dimension.tag:
        return selectedTags.map((tag) => ({
          name: tag,
          id: tag,
        }));
    }
  }, [dimension, campaigns, media, selectedTags, categories]);
};
