import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { BenchmarkDateRangeSelector } from '../../atoms/BenchmarkDateRangeSelector';

export const RegionsBenchmarkSelector: React.FC = () => {
  const { regionsBenchmarkDates, setRegionsBenchmarkDates } =
    useSOSDataContext();

  return (
    <BenchmarkDateRangeSelector
      benchmarkDates={regionsBenchmarkDates}
      onBenchmarkDatesChange={setRegionsBenchmarkDates}
    />
  );
};
