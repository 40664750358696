import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';
import { DataTable } from 'src/components/molecules/DataTable';
import { Optimization } from '../../../types';
import { OptimziationTableData, useColumns } from './useColumns';

export type OptimizationDataByMediaTableProps = {
  optimization: Optimization;
} & DataBoxBaseProps;

export const OptimizationDataByMediaTable: React.FC<
  OptimizationDataByMediaTableProps
> = ({ optimization, ...dataBoxProps }) => {
  const hasRomi = optimization.totalRomi !== null;

  const data: Array<OptimziationTableData> = optimization.data.map((d) => {
    const { roi, romi, ...restOfData } = d;
    return {
      ...restOfData,
      initialRoi: roi.initial,
      optimizedRoi: roi.optimized,
      initialRomi: romi?.initial ?? null,
      optimizedRomi: romi?.optimizied ?? null,
    };
  });

  const columns = useColumns(hasRomi);

  const csvFileName = `${optimization.optimizationName.replace(
    ' ',
    '_',
  )}_optimization`;

  return (
    <DataBox
      {...dataBoxProps}
      exportEnabled
      hasData={data.length > 0}
      isError={false}
      isLoading={false}>
      <DataTable csvFileName={csvFileName} data={data} columns={columns} />
    </DataBox>
  );
};
