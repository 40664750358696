import React from 'react';
import { DataError } from './DataError';
import { DataLoading } from './DataLoading';
import { DataMissing } from './DataMissing';

type DataStatusProps = {
  hasData: boolean;
  isError: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

export const DataStatusWrapper: React.FC<DataStatusProps> = ({
  hasData,
  isError,
  isLoading,
  children,
}) => {
  if (isError) {
    return <DataError />;
  }
  if (isLoading) {
    return <DataLoading />;
  }
  if (!hasData) {
    return <DataMissing />;
  }
  return <>{children}</>;
};
