import { TabOption, Tabs } from 'src/components/molecules/DataBox/Tabs';
import { Mode } from '../SosByMonthsKeywordChart';

export type SosTabsProps = {
  selectedTab: Mode;
  onSelect: (value: Mode) => void;
};

const tabs: Array<TabOption<Mode>> = [
  {
    label: 'Share of search',
    value: 'shareOfSearch',
  },
  {
    label: 'Absolute',
    value: 'absolute',
  },
];

export const SosTabs: React.FC<SosTabsProps> = ({ selectedTab, onSelect }) => {
  return <Tabs options={tabs} onSelect={onSelect} selectedTab={selectedTab} />;
};
