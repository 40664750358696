import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TooltipContent } from 'src/components/atoms/tooltips/TooltipContent';
import { useLegendContext } from 'src/components/molecules/Legend/LegendContext';
import { SosMonthlyMovingAverageData } from 'src/features/SOS/utils/mapLocationDataToMovingAverageData';
import { useAxisStyles } from 'src/hooks/colors/useChartColors';
import {
  MonthYearDateFormatter,
  PercentageValueTickFormatter,
} from 'src/utils/TickFormatters';

type SoSMonthlyForLocationBarChartProps = {
  data: Array<SosMonthlyMovingAverageData>;
  keywords: Array<string>;
};

const getDataKey =
  (keyword: string) =>
  (d: SosMonthlyMovingAverageData): number | null => {
    return d.shareOfSearchValues[keyword];
  };

export const SoSMonthlyForLocationBarChart: React.FC<
  SoSMonthlyForLocationBarChartProps
> = ({ data, keywords }) => {
  const { selectedBaseLegendValues } = useLegendContext();

  const axisStyles = useAxisStyles();

  const yAxisFormatter = PercentageValueTickFormatter;

  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <YAxis
          width={70}
          yAxisId="left"
          domain={[0, 1]}
          tickFormatter={yAxisFormatter}
          {...axisStyles}
        />
        <XAxis
          dataKey={'utcEpoch'}
          tickFormatter={MonthYearDateFormatter}
          {...axisStyles}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          offset={25}
          payload={keywords.map((k) => {
            return {
              value: k,
              id: k,
              legendType: 'square',
            };
          })}
          content={
            <TooltipContent
              labelsFromId
              valueFormatter={yAxisFormatter}
              labelFormatter={MonthYearDateFormatter}
            />
          }
        />

        {selectedBaseLegendValues.map(({ key: keyword, color }) => {
          return (
            <Bar
              key={keyword}
              yAxisId="left"
              id={keyword}
              dataKey={getDataKey(keyword)}
              stackId={'a'}
              isAnimationActive={false}
              fill={color}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
