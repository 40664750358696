import { Box } from '@chakra-ui/react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';

export const BenchmarkSelectorError: React.FC = () => {
  const {
    benchmarkTimerange: { error, enabled },
  } = useFilterContext();

  const fontErrorColor = useFontErrorColor();

  if (!error || !enabled) {
    return null;
  }

  return (
    <Box color={fontErrorColor} fontSize={'12px'} ml="20px">
      {error}
    </Box>
  );
};
