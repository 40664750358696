import { ModalBody } from '@chakra-ui/react';
import { Modal } from 'src/components/atoms/Modal';
import {
  DataRecord,
  DataTable,
  DataTableProps,
} from 'src/components/molecules/DataTable';
import { DataTableModalHeader } from './DataTableModalHeader';

export type DataTableModalProps<A extends DataRecord> = {
  dataTableProps: DataTableProps<A>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  headerName: string;
};

export const DataTableModal = <A extends DataRecord>({
  dataTableProps,
  isOpen,
  setIsOpen,
  headerName,
}: DataTableModalProps<A>) => {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      header={
        <DataTableModalHeader headerName={headerName} onCloseClick={onClose} />
      }
      onClose={onClose}>
      <ModalBody py={0}>
        <DataTable {...dataTableProps} />
      </ModalBody>
    </Modal>
  );
};
