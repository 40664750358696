import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Button } from 'src/components/atoms/Button';
import { dayjs } from 'src/libs/dayjs';

type DateRangeSelectorButtonProps = {
  isActive: boolean;
  onClick: () => void;
  activeText: string;
  nonActiveText: string;
};

export const DateRangeSelectorButton: React.FC<
  DateRangeSelectorButtonProps
> = ({ isActive, onClick, activeText, nonActiveText }) => {
  const fontActiveColor = useColorModeValue(
    'text.light.active',
    'text.dark.active',
  );

  return (
    <Button
      buttonName="entire-date-range"
      mb="6px"
      variant={'ghost'}
      alignSelf={'center'}
      color={isActive ? fontActiveColor : 'inherit'}
      onClick={onClick}
      fontSize="14px"
      borderRadius={isActive ? 0 : 'inherit'}
      borderBottom={isActive ? '2px solid' : undefined}>
      {isActive ? activeText : nonActiveText}
    </Button>
  );
};

type EntireDateRangeButtonProps = {
  minDate: Date;
  maxDate: Date;
  selectedStartDate: Date;
  selectedEndDate: Date;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
};

export const EntireDateRangeButton: React.FC<EntireDateRangeButtonProps> = ({
  onDateRangeChange,
  minDate,
  maxDate,
  selectedEndDate,
  selectedStartDate,
}) => {
  const onClick = () => {
    onDateRangeChange(minDate, maxDate);
  };

  const isActive =
    dayjs(minDate).isSame(selectedStartDate, 'day') &&
    dayjs(maxDate).isSame(selectedEndDate, 'day');

  return (
    <DateRangeSelectorButton
      activeText="Entire period selected"
      nonActiveText="Select entire period"
      onClick={onClick}
      isActive={isActive}
    />
  );
};
