import { VStack } from '@chakra-ui/react';
import React from 'react';
import { getAllRoutes } from 'src/app/routes/utils';
import { SideBar } from 'src/components/molecules/SideBar';
import { SIDE_BAR_WIDTH } from 'src/components/molecules/SideBar/styles';
import { SideBarRoute } from 'src/components/molecules/SideBarRoute';
import { useActiveRoute } from 'src/hooks/useActiveRoute';
import { TOP_BAR_HEIGHT } from '../TopNavBar/styles';
import { LogoLink } from './LogoLink';

export const SideNavBar: React.FC = () => {
  const { activeRoute } = useActiveRoute();

  return (
    <>
      <SideBar>
        <LogoLink />
        <VStack flex="1" align="center" justify="center" spacing={0}>
          {getAllRoutes().map((route) => (
            <SideBarRoute route={route} key={route.url} />
          ))}
        </VStack>
      </SideBar>
      {activeRoute?.subroutes && (
        <SideBar
          h={`calc(100% - ${TOP_BAR_HEIGHT})`}
          left={SIDE_BAR_WIDTH}
          top={TOP_BAR_HEIGHT}
          justify="center"
          shadow="20px 4px 20px rgba(0, 0, 0, 0.1)">
          {activeRoute.subroutes.map((subroute) => (
            <SideBarRoute route={subroute} key={subroute.url} />
          ))}
        </SideBar>
      )}
    </>
  );
};
