type CalculateRomiParams = {
  marginPct: number;
  totalSpend: number;
  mediaIncrementalSales: number;
};

export const calculateRomi = ({
  marginPct,
  totalSpend,
  mediaIncrementalSales,
}: CalculateRomiParams) => {
  if (totalSpend < 1) {
    return 0;
  }
  // Missing data
  if (mediaIncrementalSales === 0) {
    return 0;
  }

  const modelledMargin = mediaIncrementalSales * marginPct;
  const romi = (modelledMargin - totalSpend) / totalSpend;
  return romi;
};
