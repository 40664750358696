import { useColorModeValue } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useFilterBarHeight } from './styles';

type FilterBarContainerProps = {
  children: React.ReactNode;
};

export const FilterBarContainer: React.FC<FilterBarContainerProps> = ({
  children,
}) => {
  const height = useFilterBarHeight();

  const bgColor = useColorModeValue(
    'background.light.highest',
    'background.dark.highest',
  );

  return (
    <Flex
      h={height}
      px="28px"
      direction="row"
      w="100%"
      alignItems={'center'}
      justifyContent="flex-between"
      background={bgColor}
      boxShadow="15px 10px 20px rgba(6, 11, 41, 0.1)"
      zIndex="1">
      {children}
    </Flex>
  );
};
